import React from "react";
import {Route} from "react-router-dom";

//subpages
import Questionnaire from "./dalyvis-subpages/klausimynas/Questionnaire.jsx";
import Main from "./dalyvis-subpages/mainpage.jsx";
import Profile from "./dalyvis-subpages/asmensduomenys.jsx";
//import Scanning from "./dalyvis-subpages/skenavimas.jsx";
import DalyvisDocs from "./dalyvis-subpages/dokumentai.jsx";
import SelectMessage from "./dalyvis-subpages/pranesimaipasirinkt.jsx";
import MessageList from "./dalyvis-subpages/messagelist";
import Calendar from "./dalyvis-subpages/rezervacija/ReservationPage"
import { Redirect } from "react-router-dom";
// import ProtectedRoute from "./ProtectedRoute.jsx";

class ParticipantForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: "",
      error: "",
      isAllowed: true,
      qrCodeShow: false,
      result: "Nenuskenuota",
      loading: false,
      filenamequest: "Pasirinkite dokumentą",
      filenameatsisakymo: "Pasirinkite dokumentą",
      signedDoc: "",
      dalyvioTipasDalyvis: true,
      atstovas: false,
      currentAtstovas:
        "Nepasirinktas (Pasirinkti 'Mano Duomenys' lango apačioje)",
      user: JSON.parse(sessionStorage.getItem("user")),
      name: "",
      surname: "",
    };
  }

  componentDidMount() {
    // kodas gauti dalyvio tipui ir nustatyti ji
    // nustatyti dalyvio tipa su tam tikrais duomenimis jei true dalyvis atstovauja - jei false dalyvis dalyvauja
    // nustatyti dalyvio tipa su tam tikrais duomenimis jei true dalyvis atstovauja - jei false dalyvis dalyvauja
    var name = "";
    var surname = "";

    if (sessionStorage.getItem("currentAtstovas") !== null) {
      var atstovas = JSON.parse(sessionStorage.getItem("currentAtstovas"));
      name =
        atstovas.name.charAt(0).toUpperCase() +
        atstovas.name.slice(1).toLowerCase();
      surname =
        atstovas.surname.charAt(0).toUpperCase() +
        atstovas.surname.slice(1).toLowerCase();
      this.setState({
        currentAtstovas: name + " " + surname,
      });
    }
    var userType = sessionStorage.getItem("selectedParticipation");
    if (userType === "atstovas") {
      this.setState({
        atstovas: true,
      });
    }
    if (this.state.user !== null) {
      this.setState({
        name: this.state.user.name, //this.state.user.name,
        surname: this.state.user.surname, //this.state.user.surname,
      });
    }

    //   setTimeout(() => {
    //   UserService.getParticipantBoard().then(

    //     response => {

    //       this.setState({
    //         isAllowed: true,
    //         loading: false
    //       });
    //     },
    //     error => {
    //       this.setState({
    //         error:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString(),
    //         isAllowed: true,
    //         loading: false
    //       });

    //       if (error.response && error.response.status === 401) {
    //         EventBus.dispatch("logout");
    //       }
    //       else if(error.message === "Network Error"){
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );

    // }, 500);
  }

  // saveFileForUser() {
  //   if (sessionStorage.getItem("user") !== null) {
  //     const imagefile = document.getElementById("myFile");
  //     var fileName = document
  //       .getElementById("myFile")
  //       .value.split("\\")
  //       .pop()
  //       .split("/")
  //       .pop();
  //     var user = sessionStorage.getItem("user");
  //     var userId = JSON.parse(user).id;
  //     var newestData1 = JSON.parse(sessionStorage.getItem("data"));

  //     var newestData = JSON.parse(sessionStorage.getItem("data"));
  //     newestData.docs.push({ userid: userId, filename: fileName });
  //     sessionStorage.setItem("data", JSON.stringify(newestData));
  //     var newestData = sessionStorage.getItem("data");
  //     window.alert("Failas sėkmingai pateiktas " + fileName);
  //     console.log(newestData.docs);

  //     var firsttime = JSON.parse(sessionStorage.getItem("firsttime"));
  //     firsttime.push({ userid: userId, first: "false" });
  //     sessionStorage.setItem("firsttime", JSON.stringify(firsttime));
  //     // this.props.history.push("/dalyvis");
  //     // window.location.reload();
  //   }
  // }

  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }

  atstovauti(e) {
    e.preventDefault();
    sessionStorage.setItem("selectedParticipation", "atstovas");
    // this.props.history.push("/dalyvis/manoprofilis");
    this.props.history.push("/dalyvis/manoprofilis");
    window.location.reload();
  }

  dalyvauti(e) {
    e.preventDefault();
    sessionStorage.setItem("selectedParticipation", "dalyvis");
    // this.props.history.push("/dalyvis/manoprofilis");
    window.location.reload();
  }

  handleAnchorClick = (e) => {
    // if (!isActive) {
    //   e.preventDefault();
    // }
  };

  render() {
    const isAllowed = this.state.isAllowed;
    const error = this.state.error;
    const loading = this.state.loading;
    const pathname = window.location.pathname;
    // const userActivated = this.state.user && this.state.user.activationState;

    // Default redirection path if no specific subpage is selected
    // if (pathname === "/dalyvis") {
    //   // return <Redirect to={`${this.props.match.path}/dalyviai`} />;
    //   redirect(`${this.props.match.path}/pagrindinis`);
    // }

    return (
      <div>
        {isAllowed ? (
          <section className="news-single section margin-nav">
            <div className="">
              <div className="row">
                <div className="col-lg-1 col-md-2 col-12"></div>
                <div className="col-lg-2 col-md-8 col-12">
                  {/* -------------- */}
                  <div className="">
                    <div
                      className="position-button size"
                      align="center"
                      style={{ marginTop: "30px" }}
                    >
                      {/* className={pathname==='/tyrejams'|| pathname==='/meginiuteikimotvarka' ? 'nav-item dropdown active': 'nav-item dropdown'} */}
                      <a
                        href={`${this.props.match.path}/pagrindinis`}
                        className={
                          pathname === "/dalyvis/pagrindinis" ||
                          pathname === "/dalyvis*/pagrindinis" ||
                          pathname.startsWith("/dalyvis*/pagrindinis")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Pagrindinis
                      </a>
                      <a
                        href={`${this.props.match.path}/manoprofilis`}
                        className={
                          pathname === "/dalyvis/manoprofilis" ||
                          pathname === "/dalyvis*/manoprofilis" ||
                          pathname.startsWith("/dalyvis*/manoprofilis")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Mano duomenys
                      </a>
                      <a
                        href={`${this.props.match.path}/manodokumentai`}
                        className={
                          pathname === "/dalyvis/manodokumentai" ||
                          pathname === "/dalyvis*/manodokumentai" ||
                          pathname.startsWith("/dalyvis*/manodokumentai")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                        onClick={(e) => this.handleAnchorClick(e)}
                      >
                        Mano dokumentai
                      </a>
                      <a
                        href={`${this.props.match.path}/klausimynas`}
                        className={
                          pathname === "/dalyvis/klausimynas" ||
                          pathname === "/dalyvis*/klausimynas" ||
                          pathname.startsWith("/dalyvis*/klausimynas")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                        onClick={(e) => {
                          this.handleAnchorClick(e);
                        }}
                        //onClick={this.changeStateEpidem}
                      >
                        Klausimynas
                      </a>

                      {/* <a
                        href={`${this.props.match.path}/manomeginiai`}
                        className={
                          pathname === "/dalyvis/manomeginiai" ||
                          pathname === "/dalyvis{*}/manomeginiai" ||
                          pathname.startsWith("/dalyvis{*}/manomeginiai")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                        onClick={this.changeStateSkenuoti}
                      >
                        Mano mėginiai
                      </a> */}
                      <a
                        href={`${this.props.match.path}/pranesimai`}
                        className={
                          pathname === "/dalyvis/pranesimai" ||
                          pathname === "/dalyvis*/pranesimai" ||
                          pathname.startsWith("/dalyvis*/pranesimai")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                        onClick={(e) => {
                          this.handleAnchorClick(e);
                        }}
                      >
                        Pranešimai
                      </a>
                    </div>
                  </div>
                  {/* ------------------ */}
                </div>
                <div
                  className="col-lg-8 col-md-12 col-12"
                  style={{ minHeight: "500px" }}
                >
                  <div className="row">
                    {/* <div className="col-12"> */}
                    <div className="single-main" style={{ minHeight: "80px" }}>
                      <div className="col-lg-12">
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: "25px",
                          }}
                        >
                          <h3>Sveiki,</h3>
                          <p style={{ fontSize: "20px" }}>
                            {this.state.name}&nbsp;{this.state.surname}
                          </p>
                        </div>
                        {this.state.atstovas && (
                          <>
                            <div
                              style={{
                                display: "inline-block",
                                marginLeft: "25px",
                              }}
                            >
                              <h3 style={{ margin: "0px 0 0px 0" }}>
                                Atstovaujamas asmuo:
                              </h3>
                              <p style={{ fontSize: "20px" }}>
                                {this.state.currentAtstovas}
                              </p>
                            </div>
                          </>
                        )}
                        {this.state.atstovas ? (
                          <>
                            <button
                              className="btn "
                              style={{
                                margin: "0px 0 25px 25px",
                                fontSize: "20px",
                              }}
                              onClick={(event) => this.dalyvauti(event)}
                            >
                              Grįžti į mano paskyrą
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn "
                              style={{
                                margin: "0px 0 25px 25px",
                                fontSize: "20px",
                              }}
                              onClick={(event) => this.atstovauti(event)}
                            >
                              Atstovauju kitam asmeniui
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                    <Route exact path={`${this.props.match.path}`}>
                      <Redirect
                        to={`${this.props.match.path}/pagrindinis`}
                      ></Redirect>
                    </Route>
                    <Route
                      path={`${this.props.match.path}/pagrindinis`}
                      component={Main}
                    />
                    <Route
                      path={`${this.props.match.path}/manoprofilis`}
                      component={Profile}
                    />

                    {/* <ProtectedRoute
                      path={`${this.props.match.path}/klausimynas`}
                      component={Questionnaire}
                      user={this.state.user}
                    /> */}
                    <Route
                      path={`${this.props.match.path}/klausimynas`}
                      component={Questionnaire}
                    />
                    {/* <ProtectedRoute
                      path={`${this.props.match.path}/manodokumentai`}
                      component={DalyvisDocs}
                      user={this.state.user}
                    /> */}
                    <Route
                      path={`${this.props.match.path}/manodokumentai`}
                      component={DalyvisDocs}
                    />
                    {/* <Route
                      path={`${this.props.match.path}/manomeginiai`}
                      component={Scanning}
                    /> */}
                    {/* <ProtectedRoute
                      path={`${this.props.match.path}/pranesimai`}
                      component={SelectMessage}
                      user={this.state.user}
                    /> */}
                    <Route
                      path={`${this.props.match.path}/pranesimai`}
                      component={SelectMessage}
                    />
                    {/* <ProtectedRoute
                      path={`${this.props.match.path}/messagelist`}
                      component={MessageList}
                      user={this.state.user}
                    /> */}
                    <Route
                      path={`${this.props.match.path}/messagelist`}
                      component={MessageList}
                    />
                    <Route
                        path={`${this.props.match.path}/rezervacija`}
                        component={Calendar}
                    />
                    {/* <ProtectedRoute
                      path={`${this.props.match.path}/atstovaujamiasmenys`}
                      component={}
                      user={this.state.user}
                    /> */}
                    {/* <Route
                      path={`${this.props.match.path}/atstovaujamiasmenys`}
                      component={}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : loading ? (
          <div className="d-flex justify-content-center" style={{}}>
            <div
              className="spinner-grow d-flex justify-content-center"
              style={{
                width: "20rem",
                height: "20rem",
                marginTop: "80px",
                marginBottom: "80px",
                color: "#78003f",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="error-page section ">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  {/* Error Inner */}
                  <div className="error-inner">
                    {/* &nbsp;{error} */}
                    <h1>
                      <span>&nbsp;{error} Prieiga neleista</span>
                    </h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                    <br />
                  </div>
                  {/*/ End Error Inner */}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
export default ParticipantForm;
