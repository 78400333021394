import React from "react";
import { Route } from "react-router-dom";

import UserService from "../../services/user.service-galimaspanaudojimas";
import EventBus from "../../common/EventBus";

//Routes
import BendraInfo from "./tyrejas-subpages/bendrainfo.jsx";
import Profile from "./tyrejas-subpages/asmensduomenys.jsx";
import Inquiries from "./tyrejas-subpages/uzklausos.jsx";
import MyApplications from "./tyrejas-subpages/manoparaiskos.jsx";
import SelectMessage from "./tyrejas-subpages/pranesimaipasirinkt.jsx";

class ExaminatorForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: "",
      error: "",
      isAllowed: true,
      qrCodeShow: false,
      result: "Nenuskenuota",
      loading: false,
      filenamequest: "Pasirinkite dokumentą",
      filenameatsisakymo: "Pasirinkite dokumentą",
      epidemiologinis: true,
      skenuoti: false,
      nuskenuoti: false,
      asmensduomenys: false,
      atstovaujami: false,
      signedDoc: "",
    };
  }

  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
      });
    }
  };
  handleError = (err) => {
    console.error(err);
  };

  componentDidMount() {
    //   setTimeout(() => {
    //   UserService.getParticipantBoard().then(
    //     response => {
    //       // window.alert(response.data);
    //       this.setState({
    //         isAllowed: true,
    //         loading: false
    //       });
    //     },
    //     error => {
    //       this.setState({
    //         error:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString(),
    //         isAllowed: true,
    //         loading: false
    //       });
    //       // window.alert(parseInt(error.response.status));
    //       if (error.response && error.response.status === 401) {
    //         EventBus.dispatch("logout");
    //       }
    //       else if(error.message === "Network Error"){
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );
    // //   if(localStorage.getItem("user") != null){
    // // var user = JSON.parse(localStorage.getItem("user"));
    // // console.log(user);
    // // var userId = user.id;
    // // var data = JSON.parse(localStorage.getItem("data"));
    // // // window.alert(localStorage.getItem("data"));
    // // //  window.alert(data);
    // // for(let i = 0; i < data.docs.length; i++){
    // //   // console.log("data id:" + data.docs[i].userid + "login id:" + userId);
    // //   if(data.docs[i].userid == userId && data.docs[i].filename != ""){
    // //     this.setState({
    // //       signedDoc: "Pasirašytas dokumentas: " + data.docs[i].filename
    // //     })
    // //     // window.alert(data.docs[i].filename);
    // //     break;
    // //   }
    // // }
    // // }
    // }, 500);
  }

  qrCodeShow() {
    this.setState({ qrCodeShow: true });
  }
  saveFileForUser() {
    if (sessionStorage.getItem("user") != null) {
      // const imagefile = document.getElementById('myFile');
      var fileName = document
        .getElementById("myFile")
        .value.split("\\")
        .pop()
        .split("/")
        .pop();
      var user = sessionStorage.getItem("user");
      var userId = JSON.parse(user).id;
      var newestData1 = JSON.parse(sessionStorage.getItem("data"));

      var newestData = JSON.parse(sessionStorage.getItem("data"));
      newestData.docs.push({ userid: userId, filename: fileName });
      sessionStorage.setItem("data", JSON.stringify(newestData));
      var newestData = sessionStorage.getItem("data");
      window.alert("Failas sėkmingai pateiktas " + fileName);
      console.log(newestData.docs);

      var firsttime = JSON.parse(sessionStorage.getItem("firsttime"));
      firsttime.push({ userid: userId, first: "false" });
      sessionStorage.setItem("firsttime", JSON.stringify(firsttime));
      // this.props.history.push("/dalyvis");
      // window.location.reload();
    }

    // var formData = new FormData();

    // formData.append("image", imagefile.files[0]);
    // axios.post('documents', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    // })
  }
  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }

  render() {
    const isAllowed = this.state.isAllowed;
    const error = this.state.error;
    const loading = this.state.loading;
    const pathname = window.location.pathname;

    return (
      <div>
        {isAllowed ? (
          <section className="news-single section margin-nav">
            <div className="">
              <div className="row">
                <div className="col-lg-1 col-md-2 col-12"></div>
                <div className="col-lg-2 col-md-8 col-12">
                  {/* -------------- */}
                  <div className="">
                    <div
                      className="position-button size"
                      align="center"
                      style={{ marginTop: "30px" }}
                    >
                      <a
                        href={`${this.props.match.path}/bendrainfo`}
                        className={
                          pathname === "/tyrejas/bendrainfo" ||
                          pathname === "/tyrejas*/bendrainfo" ||
                          pathname.startsWith("/tyrejas*/bendrainfo")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Bendra informacija{" "}
                      </a>
                      {/* kolkas ruosiama */}
                      <a
                        href={`${this.props.match.path}/manoduomenys`}
                        className={
                          pathname === "/tyrejas/manoduomenys" ||
                          pathname === "/tyrejas*/manoduomenys" ||
                          pathname.startsWith("/tyrejas*/manoduomenys")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Mano duomenys{" "}
                      </a>
                      <a
                        href={`${this.props.match.path}/manouzklausos`}
                        className={
                          pathname === "/tyrejas/manouzklausos" ||
                          pathname === "/tyrejas*/manouzklausos" ||
                          pathname.startsWith("/tyrejas*/manouzklausos")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Mano užklausos{" "}
                      </a>
                      <a
                        href={`${this.props.match.path}/manoparaiskos`}
                        className={
                          pathname === "/tyrejas/manoparaiskos" ||
                          pathname === "/tyrejas*/manoparaiskos" ||
                          pathname.startsWith("/tyrejas*/manoparaiskos")
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Mano paraiškos{" "}
                      </a>
                      {/* <a href={`${this.props.match.path}/pranesimai`} className={pathname === "/tyrejas/pranesimai" || pathname === "/tyrejas/pranesimai" || pathname.startsWith("/tyrejas/pranesimai")? "btn active-sidenav-btn" : "btn"} >Pranešimai </a>  */}
                    </div>
                  </div>
                  {/* ------------------ */}
                </div>
                <div
                  className="col-lg-8 col-md-12 col-12"
                  style={{ minHeight: "500px" }}
                >
                  <div className="row">{/* <div className="col-12"> */}</div>
                  <>
                    <Route
                      path={`${this.props.match.path}/bendrainfo`}
                      component={BendraInfo}
                    />
                    <Route
                      path={`${this.props.match.path}/manoduomenys`}
                      component={Profile}
                    />
                    <Route
                      path={`${this.props.match.path}/manouzklausos`}
                      component={Inquiries}
                    />
                    <Route
                      path={`${this.props.match.path}/manoparaiskos`}
                      component={MyApplications}
                    />
                    {/* <Route path={`${this.props.match.path}/pranesimai`} component={SelectMessage} /> */}
                  </>
                </div>
              </div>
            </div>
          </section>
        ) : loading ? (
          <div className="d-flex justify-content-center" style={{}}>
            <div
              className="spinner-grow d-flex justify-content-center"
              style={{
                width: "20rem",
                height: "20rem",
                marginTop: "80px",
                marginBottom: "80px",
                color: "#78003f",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="error-page section ">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  {/* Error Inner */}
                  <div className="error-inner">
                    {/* &nbsp;{error} */}
                    <h1>
                      <span>&nbsp;{error} Prieiga neleista</span>
                    </h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                    <br />
                  </div>
                  {/*/ End Error Inner */}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default ExaminatorForm;
