import React, { Component } from "react";



export default class RegisterType extends Component {
  onSignIn(googleUser) {
    
    var profile = window.googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  }
  googleSignOut(){
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      console.log('User signed out.');
    });
  }
 

  render() {
    return (
      <div className="col-xl-5 col-centered">
        <div className="get-quote col-centered card card-container" style= {{paddingTop: "50px",paddingBottom: "50px", minHeight: "500px" }}>
        <a className="btn" style={{ display: "block", margin: "0 auto", width: "50%", marginTop: "20px"}} title="Prisijungimas per Elektronių Valdžios Vartų sistema autorizuotų būdų">
          Jungtis per elektroninius valdžios vartus <img src="./img/logoComp/eValdzia-logo.png" alt="Elektroniai valdžios vartai" width="25" height="25"/>(EVV) 
        </a>
        <a href="/register" className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} title="Registracija tiesiogiai per biobanką be autorizacijos - reikalauja pasirašyti dokumentus">
        Jungtis be autorizacijos EVV <i className="fa fa-share-square-o" style={{fontSize:"24px"}}></i>
        </a>
        {/* <div className="g-signin2 col-centered" onClick={this.onSignIn} style={{ marginTop: "20px"}}></div>
       
        
        <a className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px"}}onClick={this.googleSignOut} >
          Atsijungti nuo google
        </a>
       
        <a className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px"}} >
          Jungtis per Facebook
        </a>*/}
        {/* <a href="#" onClick={this.googleSignOut}>Sign out</a> */}
         {/* <GoogleLogIn/>  */}
        
        </div>
      </div>
    );
  }
}
