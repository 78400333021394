import React, { Component } from 'react';

class Footer extends React.Component {
    render() { 
        return (<div>
            

<footer id="footer" className="footer">
  {/* Footer Top */}
  <div className="footer-top">
    <div className="container">
      <div className="row">
        {/* <div className="col-lg-4 col-md-6 col-12">
          <div className="single-footer">
            <h2>Apie mus</h2>
            <p>Pagrindinis biobanko tikslas – kaupti žmogaus biologinės medžiagos mėginius, sveikatos
              informaciją ir suteikti prie jų prieigą tyrėjams, kurie vykdo mokslinius tyrimus ar juos vykdys
              ateityje.</p>
            {/* Social */}
           
        <div className="col-lg-4 col-md-6 col-12">
          <div className="single-footer f-link">
            <h2>Naudingos nuorodos </h2>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <ul>
                  <li>
                    <a href="/"><i className="fa fa-caret-right" aria-hidden="true" />Pagrindinis</a>
                  </li>
                  <li>
                    <a href="https://www.vu.lt/"><i className="fa fa-caret-right" aria-hidden="true" />Vilniaus universitetas</a>
                  </li>
                  <li>
                    <a href="#"><i className="fa fa-caret-right" aria-hidden="true" />Paslaugos </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <ul>
                  <li>
                    <a href="#"><i className="fa fa-caret-right" aria-hidden="true" />Konsultacijų puslapis</a>
                  </li>
                  <li>
                    <a href="#"><i className="fa fa-caret-right" aria-hidden="true" />Kiti puslapiai</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="single-footer">
            <h2>Kontaktai</h2>
            {/* <h2 className="thick" style={{fontSize: "22px"}}>Kur kreiptis iškilus klausimams?</h2> */}
                        <ul className="listStyleText styling-footer">
                          <li><i className="fa fa-caret-right" aria-hidden="true" /> &nbsp;Skambinkite telefonu XXXXX (pirmadieniais–penktadieniais nuo 8:00 iki 16:00). </li>
                          <li><i className="fa fa-caret-right" aria-hidden="true" /> &nbsp;Susisiekite el. paštu: info.biobankas@mf.vu.lt.</li>
                          <li><i className="fa fa-caret-right" aria-hidden="true" /> &nbsp;Atvykite į Biobanką (Žaliųjų ežerų g. 2., Vilnius 12200).</li>
                          
                        </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*/ End Footer Top */}
  {/* Copyright */}
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="copyright-content">
            <p style={{textAlign: "center"}}>© Copyright 2022  |  visos teisės priklauso <a href="/">VU biobankui</a> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*/ End Copyright */}
</footer>

        </div>);
    }
}
 
export default Footer;
