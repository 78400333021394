import React from "react";
import dalyvisService from "../../../services/dalyvis-service";
import {withRouter} from "react-router-dom";

class Pranesimai extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: "", //JSON.parse(sessionStorage.getItem("user")).message,
      user: JSON.parse(sessionStorage.getItem("user")),
      filterType: "all", // all, rec, sent
      loading: true,
      messageList: "",
    };
    console.log(props.state);
    this.setFilterState = this.setFilterState.bind(this);
    this.openAllMessages = this.openAllMessages.bind(this);
    this.fetchData();
  }

  componentDidMount() {
    this.setState({ loading: true });

    const messagesIds = Object.values(this.state.user.message);
    var messages = [];
    var messageList;

    dalyvisService
      .getAllMessages()
      .then((response) => {
        // Process the messages (filter, sort, etc.)
        response.forEach((message) => {
          messagesIds.forEach((id) => {
            if (message.id === id) {
              messages.push(message);
            }
          });
        });

        messageList = messages
          .filter((data) => {
            console.log("data");
            console.log(data);
            if (data.messageType == this.props.state) {
              if (this.state.filterType === "all") {
                console.log("Type: 1");
                return true;
              }
              if (this.state.filterType === "rec") {
                if (data.messageState === 2) {
                  return true;
                }
              }

              if (this.state.filterType === "sent") {
                if (data.messageState === 1) {
                  return true;
                }
              }
            }
            return false;
          })
          .sort(function (a, b) {
            console.log("sort:");
            console.log(a);
            console.log(b);
            if (a.eventDate > b.eventDate) {
              console.log("sort end 1");
              return 1;
            }
            if (a.eventDate < b.eventDate) {
              console.log("sort end -1");
              return -1;
            }
            console.log("sort end 0");
            return 0;
          })
          .map((data) => {
            console.log("map");
            console.log(data);
            return (
              <>
                <button
                  className="applicationList"
                  onClick={() => this.showInfo(data.id)}
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <p style={{ float: "left", display: "inline-block" }}>
                        {data.messageTopic}
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p style={{}}> {data.eventDate} </p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn"
                        style={{ float: "right" }}
                        onClick={(e) => this.openAllMessages(e)}
                      >
                        Visos susijusios žinutės{" "}
                        <i
                          className="fa fa-envelope"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </button>
                    </div>
                    {/* onClick={(e)=> this.openAllMessages(e, data.senderId, data.receiverId)} */}
                  </div>
                </button>
                <li
                  className="col-centered"
                  id={data.id}
                  style={{ display: "none", marginTop: "0", marginBottom: "0" }}
                >
                  <p
                    className="messageDesign"
                    style={{ wordBreak: "break-all" }}
                  >
                    {data.messageText}
                  </p>
                </li>
              </>
            );
          });

        this.setState({
          messages: messageList,
          loading: false,
        });
      })
      .catch((error) => {
        window.alert(error.message);
        this.setState({ loading: true });
      });
  }

  async fetchData() {
    const user = await dalyvisService.getAllPersonalData(this.state.user.id);
    this.setState({
      user: user,
      //loading: false,
    });
    sessionStorage.setItem("user", JSON.stringify(user));
  }
  showInfo(id) {
    // console.log(id);
    var value = document.getElementById(id);
    // for(let i = 0; i < this.state.messages.length; i++){
    //   var innerId = this.state.messages[i].messageText.toLowerCase().trim();
    //   console.log(innerId);
    //   if(id !== innerId){
    //     document.getElementById(innerId).style.display = "none";
    //   }
    // } could be fixable

    if (value.style.display === "none") {
      value.style.display = "block";
    } else {
      value.style.display = "none";
    }
  }
  setFilterState(filterType) {
    this.setState({
      filterType: filterType,
    });
  }
  openAllMessages(e) {
    //,id1, id2
    e.preventDefault();
    // sessionStorage.setItem("msgid1", JSON.stringify(27));
    // sessionStorage.setItem("msgid2", JSON.stringify(86));
    //////----------------------------
    // Should be done by using a converstationId that attaches messages to a specific conversation, would depend on whether
    //////----------------------------
    this.props.history.push("/dalyvis/messagelist");
  }

  render() {
    //     const messages = [{topic: "Žinutė1 ŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė ŽinutėŽinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė ŽinutėŽinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė2 ŽinutėŽinutėŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė3 ŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė4 ŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " }
    // ];

    return (
      <>
        {this.state.loading ? (
          <>
            <div className="d-flex justify-content-center" style={{}}>
              <div
                className="spinner-grow d-flex justify-content-center"
                style={{
                  width: "20rem",
                  height: "20rem",
                  marginTop: "80px",
                  marginBottom: "80px",
                  color: "#78003f",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-centered col-lg-10 col-md-12 col-12">
              <h2
                style={{
                  marginTop: "50px",
                  marginBottom: "25px",
                  fontSize: "1.5rem",
                }}
              >
                {this.props.typetext}
              </h2>
              {this.props.state === "1" ? (
                <div style={{ marginBottom: "25px" }}>
                  <button
                    className={
                      this.state.filterType === "all"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "all" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("all")}
                  >
                    Visi
                  </button>
                  <button
                    className={
                      this.state.filterType === "rec"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "rec" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("rec")}
                  >
                    Gauti
                  </button>
                  <button
                    className={
                      this.state.filterType === "sent"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "sent" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("sent")}
                  >
                    Išsiųsti
                  </button>
                  {/* <button onClick={(e)=> this.openAllMessages(e )}>Test </button> */}
                </div>
              ) : (
                <div></div>
              )}
              <ul>{React.Children.toArray(this.state.messages)}</ul>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(Pranesimai);
