import React, { Component } from 'react';
// import MapContainer from '../components/googlemaps.jsx'


const mapStyles = {
  width: '100%',
  height: '100%'
};

class KontaktaiList extends React.Component {
    render() { 
        return <>
                        {/* <div style={{height:"500px",marginBottom:"25px"}}><MapContainer /></div> */}
                        <ul className="listStyleText">
                            <li><b>Administracija:</b></li>
                            <li style={{marginLeft: "20px"}}>Dr. Violeta Mikštienė, el. paštas:, violeta.mikstiene@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}>Prof. Algirdas Utkus, el. paštas: algirdas.utkus@mf.vu.lt</li>
                            <li><b>Kokybės vadybos skyrius:</b></li>
                            <li style={{marginLeft: "20px"}}>Danutė Balkelienė, el. paštas:, danute.balkeliene@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}>Milda Petkevičienė, el. paštas: milda.petkevičiene@mf.vu.lt</li>
                            <li><b>Laboratorijos:</b></li>
                            <li style={{marginLeft: "20px"}}><b>Nukleorūgščių laboratorija:</b></li>
                            <li style={{marginLeft: "40px"}}>Danutė Balkelienė, el. paštas:, danute.balkeliene@mf.vu.lt</li>
                            <li style={{marginLeft: "40px"}}>Dr. Evelina Siavrienė, el. paštas:, evelina.siavriene@mf.vu.lt</li>
                            <li style={{marginLeft: "40px"}}>Gabija Mazur, el. paštas:, gabija.mazur@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}><b>Ląstelių kultūrų laboratorija:</b></li>
                            <li style={{marginLeft: "40px"}}>Dr. Živilė Maldžienė el. paštas:, zivile.maldziene@mf.vu.lt</li>
                            <li style={{marginLeft: "40px"}}>Dr. Evelina Siavrienė, el. paštas:, evelina.siavriene@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}><b>Biochemijos laboratorija:</b></li>
                            <li style={{marginLeft: "40px"}}>Dr. Jurgita Songailienė el. paštas:, jurgita.songailiene@mf.vu.lt</li>
                            <li><b>IT ir duomenų analizės skyrius:</b></li>
                            <li style={{marginLeft: "20px"}}>Dr.Tautvydas Rančelis, el. paštas:, tautvydas.rancelis@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}> Dr.Ramūnas Dzindzalieta, el. paštas:, ramunas.dzindzalieta@ittc.vu.lt</li>
                            <li><b>Mokslo komitetas:</b></li>
                            <li style={{marginLeft: "20px"}}>Doc.dr. Živilė Maldžienė el. paštas:, zivile.maldziene@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}>Dr. Jurgita Songailienė el. paštas:, jurgita.songailiene@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}>Dr. Tautvydas Rančelis el. paštas:, tautvydas.rancelis@mf.vu.lt</li>
                            <li style={{marginLeft: "20px"}}>Dr. Evelina Siavrienė el. paštas: evelina.siavriene@mf.vu.lt</li>
                            <li><b>Bioetikos ir teisės komitetas:</b></li>
                            <li style={{marginLeft: "20px"}}>Mark Siavris el. paštas:, mark.siavris@mf.vu.lt</li>
                        </ul>
                            
                        </> ;
    }
}
 
export default KontaktaiList;