import React from 'react';
import PartSideNav from '../../partsidenav.jsx'

class Dalyviams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''
      
    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 991
        });
    }, false); 
}
    render() { 
        const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';
        
        return <div>
            
                
                {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}
                
                
              
        <div className="container-fluid" >
       
          <div className={mobileornot} style={{marginTop:"25px", marginBottom: "50px"}}>
            <PartSideNav/>
            <div className="col-lg-9 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                      <div className="row mx-1">
                        <h2 className="thick">Biobanko dalyviams</h2>
                        <ul className="listStyleText">
                          <li>Jūsų dalyvavimas Biobanko veikloje yra labai svarbus: suteikdami Biobankui teisę paimti ir naudoti savo biologinius
                             mėginius ir sveikatos informaciją, prisidėsite prie biomedicinos mokslo pažangos, naujų sveikatinimo, ligų prevencijos, 
                             diagnostikos, gydymo metodų kūrimo.</li>
                          <li>Mokslininkams atliekant biomedicininius tyrimus naujomis technologijomis gali paaiškėti Jūsų sveikatai svarbi informacija, 
                            o tai leistų savalaikiai ir efektyviai suteikti Jums tinkamas sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti 
                            nustatytą sveikatos sutrikimą.</li>
                          <li>Biobanko veikloje gali dalyvauti bet kuris pilnametis Lietuvos pilietis, nepriklausomai nuo sveikatos būklės. Jaunesni nei 18 
                            metų asmenys dalyvauti Biobanko veikloje gali tik sutikus tėvams ar globėjams. </li>
                        </ul>
                        <div className="get-quote col-centered" style={{marginTop: "25px",display: "grid"}}>
                        {/* <a style={{width: "45%",marginRight:"5px",textAlign: "center", paddingLeft: "0px"}}href="/register" className="btn">Registruotis</a> */}
                        <a style={{width: "45%",textAlign: "center", margin:"0 auto"}} href="/login" className="btn">Prisijungti</a>     
                        </div> 
                      </div>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
     
      
        </div>;
    }
}
 
export default Dalyviams;