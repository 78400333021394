import React, { Component } from "react";
import forma1 from "../../../docs/atsisakymoforma1.rtf";
import AuthService from "../../../services/auth.service-reikiaderinti";

function handleSubmit(e) {
  e.preventDefault();
  alert("Sėkmingai išsiųsta!");
  window.location.reload();
}

// function editProfile(idInput, idButtonClick,idButtonEdit){
//   document.getElementById(idButtonEdit).className = "fa fa-save";
//   // console.log("{() => this.saveProfile('" + idInput + "','" + idButtonEdit+ "')}");
//   // document.getElementById(idButtonClick).onClick = "{() => this.saveProfile('" + idInput + "','" + idButtonEdit+ "')}";
// }
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filenamequest: "Pasirinkite dokumentą",
      atstovas: true,
    };

    this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  componentDidMount() {
    // const user = AuthService.getCurrentUser(); user.username
    // document.getElementById("username").value = "test1";
    document.getElementById("name").value = "test1";
    document.getElementById("surname").value = "test1";
    document.getElementById("personalcode").value = 50100000010;
    document.getElementById("phonenumber").value = 37069696969;
    document.getElementById("email").value = "test1@gmail.com";
    document.getElementById("password").value = "deividas1deividas1";
    var userType = sessionStorage.getItem("selectedParticipation");
    if (userType === "atstovas") {
      this.setState({
        atstovas: true,
      });
      if (sessionStorage.getItem("currentAtstovas") !== null) {
        var atstovas = JSON.parse(sessionStorage.getItem("currentAtstovas"));
        document.getElementById("namerepres").value = atstovas.name;
        document.getElementById("surnamerepres").value = atstovas.surname;
        document.getElementById("personalcoderepres").value =
          atstovas.personalcode;
        document.getElementById(atstovas.personalcode).selected = "selected";
      }
    } else {
      this.setState({
        atstovas: false,
      });
    }
  }
  editProfile(e, idInput, idButtonClick, idButtonEdit) {
    e.preventDefault();
    console.log(document.getElementById(idButtonEdit).className);
    if (document.getElementById(idButtonEdit).className === "fa fa-edit") {
      document.getElementById(idInput).readOnly = false;
      document.getElementById(idButtonEdit).className = "fa fa-save";
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    } else {
      var valueToSave = document.getElementById(idInput).value;
      //use services to save new value to database
      document.getElementById(idButtonEdit).className = "fa fa-edit";
      document.getElementById(idInput).readOnly = true;
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    }
    console.log(
      "{() => this.saveProfile('" + idInput + "','" + idButtonEdit + "')}"
    ); //"{(event) => this.saveProfile(event,'" + idInput + "','" + idButtonEdit+ "')}";
    // document.getElementById(idButtonClick).onClick = function() {(event) =>this.saveProfile(event,idInput, idButtonEdit)};
    // document.getElementById(idButtonClick).setAttribute =
  }

  changePassword(e, id) {
    e.preventDefault();
    document.getElementById(id).style.display = "block";
    document.getElementById("cancelpasswordchange").style.display =
      "inline-block";
    document.getElementById("changebutton").disabled = true;
  }
  cancelPassword(e) {
    e.preventDefault();
    document.getElementById("cancelpasswordchange").style.display = "none";
    document.getElementById("newpassword").style.display = "none";
    document.getElementById("changebutton").disabled = false;
  }
  saveNewPassword(e, id, idpass1, idpass2) {
    e.preventDefault();

    var pass1 = document.getElementById(idpass1).value;
    var pass2 = document.getElementById(idpass2).value;
    console.log(pass1.length);
    if (
      pass1 == pass2 &&
      (pass1 != "" || pass2 != "") &&
      (pass1.length > 6 || pass2.length > 6)
    ) {
      //user services to save password to database
      document.getElementById(id).style.display = "none";
      document.getElementById("changebutton").disabled = false;
      window.alert("Sėkmingai pakeitėte slaptažodį!");
    } else if (pass1 == "" || pass2 == "") {
      window.alert("Kai kurie laukeliai neužpildyti!");
    } else if (pass1.length <= 6 || pass2.length <= 6) {
      window.alert("Slaptažodis turi būti ilgesnis nei 6 simboliai (7+)!");
    } else {
      document.getElementById(idpass1).value = "";
      document.getElementById(idpass2).value = "";
      window.alert("Įvęsti slaptažodžiai nėra vienodi!");
    }
  }
  saveRepresChoice(e) {
    e.preventDefault();
    var selection = document.getElementById("represselect").value;
    window.alert("Atstovaujamas asmuo pasirinktas ir pakeistas!");
  }
  changeAtstovaujamas(e) {
    e.preventDefault();
    var atstovas = document.getElementById("represselect").value.split(" ");

    document.getElementById("namerepres").value = atstovas[0];
    document.getElementById("surnamerepres").value = atstovas[1];
    document.getElementById("personalcoderepres").value = atstovas[2];
  }
  saveAtstovaujamas(e) {
    e.preventDefault();
    var atstovas = document.getElementById("represselect").value.split(" ");
    atstovas = JSON.stringify({
      name: atstovas[0],
      surname: atstovas[1],
      personalcode: atstovas[2],
    });
    sessionStorage.setItem("currentAtstovas", atstovas);
    window.alert("Sėkmingai pasirinkti atstovaujamas asmuo!");
    window.location.reload();
  }

  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename != "" && id == "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename != "" && id == "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }
  onlyNumberKey(evt) {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }

  render() {
    const filenamequest = this.state.filenamequest;
    const represent = JSON.parse(sessionStorage.getItem("atstovai"));
    const options = represent.map((person) => {
      return (
        <>
          <option
            id={person.personalcode}
            value={
              person.name.toLowerCase() +
              " " +
              person.surname.toLowerCase() +
              " " +
              person.personalcode.toLowerCase()
            }
          >
            {person.name + " " + person.surname + " | " + person.personalcode}
          </option>
        </>
      );
    });
    // const asmensduomenys = user.roles.map( (data) =>{
    //   return (<>
    //     <a href="#" className="list-group-item list-group-item-action flex-column align-items-start active">
    //       <div className="d-flex w-100 justify-content-between">
    //         <h5 className="mb-1">{data}</h5>
    //         <small>{user.id}</small>
    //       </div>
    //       <p className="mb-1">{data}</p>
    //       <small>Donec id elit non mi porta.</small>
    //     </a>

    //     </>)

    // });

    return (
      <>
        <div className="col-lg-12 col-12">
          <div className="comments-form">
            <h2
              style={{
                marginBottom: "25px",
                paddingTop: "50px",
                fontSize: "2rem",
              }}
            >
              Asmeniniai duomenys
            </h2>

            <form
              className="form col-lg-12 col-md-12 border-edit-button px-1"
              style={{ fontSize: "20px", paddingBottom: "75px" }}
              onSubmit={this.handleSubmitDalyvis}
            >
              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Vardas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="name"
                      readOnly
                      className="form-control"
                      placeholder="Vardenis"
                    />
                    <div style={{}}>
                      <button
                        className="btn"
                        id="changename"
                        onClick={(event) =>
                          this.editProfile(
                            event,
                            "name",
                            "changename",
                            "changenameicon"
                          )
                        }
                        style={{ padding: "0px" }}
                      >
                        <i
                          id="changenameicon"
                          style={{ fontSize: "28px" }}
                          className="fa fa-edit"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Pavardė</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="surname"
                      readOnly
                      className="form-control"
                      placeholder="Pavardenis"
                    />
                    <div style={{}}>
                      <button
                        className="btn"
                        id="changesurname"
                        onClick={(event) =>
                          this.editProfile(
                            event,
                            "surname",
                            "changesurname",
                            "changesurnameicon"
                          )
                        }
                        style={{ padding: "0px" }}
                      >
                        <i
                          id="changesurnameicon"
                          style={{ fontSize: "28px" }}
                          className="fa fa-edit"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Asmens kodas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="personalcode"
                      readOnly
                      className="form-control"
                      placeholder="90050050080"
                      maxLength={11}
                    />
                    <div style={{}}>
                      <button
                        className="btn"
                        id="changepersonalcode"
                        onClick={(event) =>
                          this.editProfile(
                            event,
                            "personalcode",
                            "changepersonalcode",
                            "changepersonalcodeicon"
                          )
                        }
                        style={{ padding: "0px" }}
                      >
                        <i
                          id="changepersonalcodeicon"
                          style={{ fontSize: "28px" }}
                          className="fa fa-edit"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Telefono numeris</label>

                  <div className="input-group mb-1" style={{}}>
                    <div className="input-group-prepend">
                      <div className="input-group-text">+</div>
                    </div>
                    <input
                      type="text"
                      id="phonenumber"
                      title="Įvęskite telefono numerį be simbolių"
                      readOnly
                      className="form-control"
                      placeholder="37069999999"
                      maxLength={11}
                    />
                    <div style={{}}>
                      <button
                        className="btn"
                        id="changephonenumber"
                        onClick={(event) =>
                          this.editProfile(
                            event,
                            "phonenumber",
                            "changephonenumber",
                            "changephonenumbericon"
                          )
                        }
                        style={{ padding: "0px" }}
                      >
                        <i
                          id="changephonenumbericon"
                          style={{ fontSize: "28px" }}
                          className="fa fa-edit"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-8 col-md-8"
                  style={{ marginTop: "25px" }}
                >
                  <label>Elektroninis paštas</label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">@</div>
                    </div>
                    <input
                      type="email"
                      readOnly
                      className="form-control"
                      id="email"
                      placeholder="elektroninis@pastas.com"
                    />
                    <div style={{ marginTop: "0px" }}>
                      <button
                        className="btn"
                        id="changeemail"
                        onClick={(event) =>
                          this.editProfile(
                            event,
                            "email",
                            "changeemail",
                            "changeemailicon"
                          )
                        }
                        style={{ padding: "0px" }}
                      >
                        <i
                          id="changeemailicon"
                          style={{ fontSize: "28px" }}
                          className="fa fa-edit"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Slaptažodis</label>
                  <div className="input-group mb-2">
                    <input
                      type="password"
                      id="password"
                      readOnly
                      className="form-control"
                      placeholder="Slaptažodis"
                    />
                    <div style={{}}>
                      &nbsp;
                      <button
                        className="btn"
                        id="changebutton"
                        onClick={(event) =>
                          this.changePassword(event, "newpassword")
                        }
                        style={{
                          height: "32px",
                          padding: "5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Keisti slaptažodį
                      </button>
                      &nbsp;
                      <button
                        className="btn"
                        id="cancelpasswordchange"
                        onClick={(event) => this.cancelPassword(event)}
                        style={{
                          height: "32px",
                          padding: "5px",
                          display: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="newpassword" style={{ display: "none" }} className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ display: "inline-block", marginTop: "25px" }}
                >
                  <label>Naujas slaptažodis</label>
                  <input
                    type="password"
                    id="newpasswordinput1"
                    className="form-control"
                    placeholder="Naujas slaptažodis"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-6"
                  style={{ display: "inline-block", marginTop: "25px" }}
                >
                  <label htmlFor="inputEmail4">Pakartoti slaptažodį</label>
                  <div className="input-group mb-2">
                    <input
                      type="password"
                      id="newpasswordinput2"
                      className="form-control"
                      placeholder="Pakartoti slaptažodį"
                    />

                    <button
                      id="saveNewPassword"
                      className="btn"
                      onClick={(event) =>
                        this.saveNewPassword(
                          event,
                          "newpassword",
                          "newpasswordinput1",
                          "newpasswordinput2"
                        )
                      }
                      style={{ display: "inline-block", padding: "0px" }}
                    >
                      <i
                        style={{ fontSize: "30px" }}
                        id="saveNewPasswordIcon"
                        className="fa fa-save"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
              {this.state.atstovas && (
                <>
                  <h2
                    style={{
                      marginTop: "50px",
                      marginBottom: "25px",
                      fontSize: "2rem",
                    }}
                  >
                    Atstovaujamo asmens duomenys
                  </h2>
                  <div className="form-group col-lg-4 col-md-4">
                    <label htmlFor="exampleFormControlSelect1">
                      Pasirinkite atstovaujama asmenį:
                    </label>
                    <div className="input-group mb-2">
                      <select
                        id="represselect"
                        className="form-control"
                        onChange={(event) => this.changeAtstovaujamas(event)}
                      >
                        {options}
                      </select>
                      <button
                        className="btn"
                        id="changerepres"
                        onClick={(event) => this.saveAtstovaujamas(event)}
                        style={{ padding: "0" }}
                      >
                        Išsaugoti
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6"
                      style={{ marginTop: "25px" }}
                    >
                      <label>Vardas</label>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          id="namerepres"
                          readOnly
                          className="form-control"
                          placeholder="Vardenis"
                        />
                        <div style={{}}>
                          <button
                            className="btn"
                            id="changenamerepres"
                            onClick={(event) =>
                              this.editProfile(
                                event,
                                "namerepres",
                                "changenamerepres",
                                "changenamerepresicon"
                              )
                            }
                            style={{ padding: "0px" }}
                          >
                            <i
                              id="changenamerepresicon"
                              style={{ fontSize: "28px" }}
                              className="fa fa-edit"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-6 col-md-6"
                      style={{ marginTop: "25px" }}
                    >
                      <label>Pavardė</label>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          id="surnamerepres"
                          readOnly
                          className="form-control"
                          placeholder="Pavardenis"
                        />
                        <div style={{}}>
                          <button
                            className="btn"
                            id="changesurnamerepres"
                            onClick={(event) =>
                              this.editProfile(
                                event,
                                "surnamerepres",
                                "changesurnamerepres",
                                "changesurnamerepresicon"
                              )
                            }
                            style={{ padding: "0px" }}
                          >
                            <i
                              id="changesurnamerepresicon"
                              style={{ fontSize: "28px" }}
                              className="fa fa-edit"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-6 col-md-6"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      <label>Asmens kodas</label>
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          id="personalcoderepres"
                          readOnly
                          className="form-control"
                          placeholder="90050050080"
                          maxLength={11}
                        />
                        <div style={{}}>
                          <button
                            className="btn"
                            id="changepersonalcoderepres"
                            onClick={(event) =>
                              this.editProfile(
                                event,
                                "personalcoderepres",
                                "changepersonalcoderepres",
                                "changepersonalcoderepresicon"
                              )
                            }
                            onKeyPress={(event) => this.onlyNumberKey(event)}
                            style={{ padding: "0px" }}
                          >
                            <i
                              id="changepersonalcoderepresicon"
                              style={{ fontSize: "28px" }}
                              className="fa fa-edit"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-6 col-md-6"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      <label>Gimimo liudijimas</label>
                      <div className="input-group mb-2">
                        <a className=" btn" href={forma1} download>
                          Atsisiųsti
                        </a>
                        <div style={{}}></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
