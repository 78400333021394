import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class TikslaiMisija extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-9 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Pagrindiniai Biobanko tikslai</h2>
                    <ul className="listStyleList">
                      <li>Sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius ir su jais susijusią
                        asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje;</li>
                      <li>Skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi,
                        naujų ligų prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą;</li>
                      <li>Vykdyti akademinę veiklą -  dalyvauti VU MF vykdomose studijų programose; atlikti biomedicininius tyrimus bendradarbiaujant su VU, kitų Lietuvos
                        ir pasaulio mokslo institucijų mokslininkų grupėmis.</li>
                    </ul>
                    <h2 className="thick">Misija </h2>
                    <ul className="listStyleText">
                      <li>VU MF Lietuvos populiacijos ir retųjų ligų biobankas, turėdamas didelį saugomų mėginių skaičių ir duomenų kiekį, yra svarbus
                        Lietuvoje vykstančių eksperimentinių tyrimų strateginis partneris, kuris, teikdamas referencinius Lietuvos populiacijos sveikų ir sergančių
                        retomis ligomis asmenų mėginius ir duomenis, leidžia praplėsti žmogaus genomo tyrimų spektrą Lietuvoje, sukuria galimybę Lietuvos mokslininkams
                        lygiomis teisėmis dalyvauti tarptautinėse genominių/postgenominių tyrimų programose, kartu didinant tyrėjų mokslinę kompetenciją, Vilniaus
                        universiteto mokslinį potencialą bei skatinant mokslo ir ligų diagnostikos vystymąsi Lietuvoje.</li>

                    </ul>

                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default TikslaiMisija;