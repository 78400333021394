import React, { Component } from "react";
import forma1 from "../../../docs/atsisakymoforma1.rtf";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";

function handleSubmit(e) {
  e.preventDefault();
  alert("Sėkmingai išsiųsta!");
  window.location.reload();
}

// function editProfile(idInput, idButtonClick,idButtonEdit){
//   document.getElementById(idButtonEdit).className = "fa fa-save";
//   // console.log("{() => this.saveProfile('" + idInput + "','" + idButtonEdit+ "')}");
//   // document.getElementById(idButtonClick).onClick = "{() => this.saveProfile('" + idInput + "','" + idButtonEdit+ "')}";
// }
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filenamequest: "Pasirinkite dokumentą",
      name: "-----------------",
      surname: "-----------------",
      // personalCode: "-----------------",
      workplace: "-----------------",
      phoneNumber: "-----------------",
      email: "-----------------",
      password: "-----------------",
      user: JSON.parse(sessionStorage.getItem("user")),
    };

    this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  componentDidMount() {
    var user = this.state.user;
    this.setState({
      name: user.name,
      surname: user.surname,
      // personalCode: user.personalCode,
      workplace: user.workplace,
      phoneNumber: user.phoneNumber,
      email: user.email,
      password: user.password,
    });
  }
  editProfile(e, idInput, idButtonClick, idButtonEdit) {
    e.preventDefault();
    console.log(document.getElementById(idButtonEdit).className);
    if (document.getElementById(idButtonEdit).className === "fa fa-edit") {
      document.getElementById(idInput).readOnly = false;
      document.getElementById(idButtonEdit).className = "fa fa-save";
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    } else {
      var valueToSave = document.getElementById(idInput).value;
      var user = JSON.parse(sessionStorage.getItem("user"));
      var object = { [idInput]: valueToSave };
      if (
        idInput === "phoneNumber" &&
        document.getElementById("phoneNumber").value.length !== 11
      ) {
        window.alert("Netinkamas telefono numerio ilgis");
        return;
      }
      // sessionStorage.setItem("tempUser", JSON.stringify(user));
      dalyvisService
        .putCustomObjectPersonalData(user.id, { ...user, ...object })
        .catch((error) => window.alert(error.message));
      document.getElementById(idButtonEdit).className = "fa fa-edit";
      document.getElementById(idInput).readOnly = true;
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    }
    console.log(
      "{() => this.saveProfile('" + idInput + "','" + idButtonEdit + "')}"
    ); //"{(event) => this.saveProfile(event,'" + idInput + "','" + idButtonEdit+ "')}";
    // document.getElementById(idButtonClick).onClick = function() {(event) =>this.saveProfile(event,idInput, idButtonEdit)};
    // document.getElementById(idButtonClick).setAttribute =
  }

  changePassword(e) {
    e.preventDefault();
    // document.getElementById(id).style.display = "block";
    // document.getElementById("cancelpasswordchange").style.display =
    //   "inline-block";
    // document.getElementById("changebutton").disabled = true;
    authService.remindPassword(this.state.user.email).then(() => {
      alert("Slaptažodžio pakeitimo laiškas išsiųstas sėkmingai!");
    });
  }

  cancelPassword(e) {
    e.preventDefault();
    document.getElementById("cancelpasswordchange").style.display = "none";
    document.getElementById("newpassword").style.display = "none";
    document.getElementById("changebutton").disabled = false;
  }
  saveNewPassword(e, id, idpass1, idpass2) {
    e.preventDefault();

    var pass1 = document.getElementById(idpass1).value;
    var pass2 = document.getElementById(idpass2).value;
    console.log(pass1.length);
    if (
      pass1 === pass2 &&
      (pass1 !== "" || pass2 !== "") &&
      (pass1.length > 6 || pass2.length > 6)
    ) {
      //user services to save password to database
      document.getElementById(id).style.display = "none";
      document.getElementById("changebutton").disabled = false;
      window.alert("Sėkmingai pakeitėte slaptažodį!");
    } else if (pass1 === "" || pass2 === "") {
      window.alert("Kai kurie laukeliai neužpildyti!");
    } else if (pass1.length <= 6 || pass2.length <= 6) {
      window.alert("Slaptažodis turi būti ilgesnis nei 6 simboliai (7+)!");
    } else {
      document.getElementById(idpass1).value = "";
      document.getElementById(idpass2).value = "";
      window.alert("Įvęsti slaptažodžiai nėra vienodi!");
    }
  }

  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }
  seePassword(event, id) {
    event.preventDefault();
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  handleChange(event, id) {
    this.setState({ [id]: event.target.value });
  }
  handleChangeNumber(event, id) {
    const value = event.target.value;
    if (!isNaN(value)) {
      this.setState({ [id]: event.target.value });
    }
  }
  handleChangeAlpha(event, id) {
    var value = event.target.value;
    if (/^[a-zA-Ząčęėįšųūž]+$/.test(value) || value === "") {
      this.setState({ [id]: event.target.value });
    }
  }

  render() {
    const filenamequest = this.state.filenamequest;

    // const asmensduomenys = user.roles.map( (data) =>{
    //   return (<>
    //     <a href="#" className="list-group-item list-group-item-action flex-column align-items-start active">
    //       <div className="d-flex w-100 justify-content-between">
    //         <h5 className="mb-1">{data}</h5>
    //         <small>{user.id}</small>
    //       </div>
    //       <p className="mb-1">{data}</p>
    //       <small>Donec id elit non mi porta.</small>
    //     </a>

    //     </>)

    // });

    return (
      <>
        <div className="col-lg-12 col-12">
          <div className="comments-form">
            <h2 style={{ marginBottom: "25px", fontSize: "2rem" }}>
              Mano duomenys
            </h2>

            <form
              className="form col-lg-12 col-md-12 border-edit-button"
              style={{ fontSize: "20px" }}
              onSubmit={this.handleSubmitDalyvis}
            >
              <div className="row">
                {/* <div className="row">
                    <div className="col-lg-6 col-md-6" style={{marginTop: "25px"}}>
                    <label>Prisijungimo vardas</label>
                    <div className="input-group mb-2">
                    
                    
                      <input type="text" id="username"  readOnly className="form-control" placeholder="Prisijungimas"/>
                      <div style={{}}>
                      <button className="btn" id="changeusername" onClick={(event) => this.editProfile(event,"username","changeusername","changeusernameicon")} style={{ padding: "0px"}}><i id="changeusernameicon" style={{fontSize: "28px"}} className="fa fa-edit"></i></button>
                      </div> 
                      </div>
                    </div>
                   
                      </div> */}

                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Vardas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="name"
                        readOnly
                        className="form-control"
                        placeholder="Vardenis"
                        value={this.state.name}
                        onChange={(event) =>
                          this.handleChangeAlpha(event, "name")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changename"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "name",
                              "changename",
                              "changenameicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changenameicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Pavardė</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="surname"
                        readOnly
                        className="form-control"
                        placeholder="Pavardenis"
                        value={this.state.surname}
                        onChange={(event) =>
                          this.handleChangeAlpha(event, "surname")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changesurname"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "surname",
                              "changesurname",
                              "changesurnameicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changesurnameicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-8 col-md-8"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Darbovietės pavadinimas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="workplace"
                        readOnly
                        className="form-control"
                        placeholder="Darbovietė"
                        value={this.state.workplace}
                        onChange={(event) =>
                          this.handleChange(event, "workplace")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changeworkplace"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "workplace",
                              "changeworkplace",
                              "changeworkplaceicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changeworkplaceicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-4 col-md-4"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Telefono numeris</label>

                    <div className="input-group mb-1" style={{}}>
                      <div className="input-group-prepend">
                        <div className="input-group-text">+</div>
                      </div>
                      <input
                        type="text"
                        id="phoneNumber"
                        title="Įvęskite telefono numerį be simbolių"
                        readOnly
                        className="form-control"
                        placeholder="37069999999"
                        maxLength={11}
                        value={this.state.phoneNumber}
                        onChange={(event) =>
                          this.handleChangeNumber(event, "phoneNumber")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changephonenumber"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "phoneNumber",
                              "changephonenumber",
                              "changephonenumbericon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changephonenumbericon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/*<div
                    className="col-lg-4 col-md-4"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Asmens kodas</label>
                     <div className="input-group mb-2">
                      <input
                        type="text"
                        id="personalCode"
                        readOnly
                        className="form-control"
                        placeholder="90050050080"
                        maxLength={11}
                        value={this.state.personalCode}
                        onChange={(event)=> this.handleChange(event, "personalCode")}
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changepersonalcode"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "personalCode",
                              "changepersonalcode",
                              "changepersonalcodeicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changepersonalcodeicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div> 
                  </div>
*/}
                  <div
                    className="col-lg-8 col-md-8"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Elektroninis paštas</label>
                    <div className="input-group mb-2">
                      {/* <div className="input-group-prepend">
                      <div className="input-group-text">@</div>
                    </div> */}
                      <input
                        type="email"
                        readOnly
                        className="form-control"
                        id="email"
                        placeholder="elektroninis@pastas.com"
                        value={this.state.email}
                        onChange={(event) => this.handleChange(event, "email")}
                      />
                      <div style={{ marginTop: "0px" }}>
                        {/* <button
                          className="btn"
                          id="changeemail"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "email",
                              "changeemail",
                              "changeemailicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changeemailicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    {/* <label>Slaptažodis</label> */}
                    <div className="input-group mb-2">
                      {/* <input
                        type="password"
                        id="password"
                        readOnly
                        className="form-control"
                        placeholder="Slaptažodis"
                        value={this.state.password}
                        onChange={(event) => this.handleChange(event, "password")}
                      /> */}
                      <div style={{}}>
                        {/* &nbsp;
                        <button
                          className="btn"
                          id="seepassword1"
                          onClick={(event) =>
                            this.seePassword(event, "password")
                          }
                          style={{
                            height: "32px",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        &nbsp; */}
                        <button
                          className="btn"
                          id="changebutton"
                          onClick={(event) => this.changePassword(event)}
                          style={{
                            height: "32px",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Keisti slaptažodį
                        </button>
                        {/* &nbsp;
                        <button
                          className="btn"
                          id="cancelpasswordchange"
                          onClick={(event) => this.cancelPassword(event)}
                          style={{
                            height: "32px",
                            padding: "5px",
                            display: "none",
                            whiteSpace: "nowrap",
                          }}
                        >
                          X
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="newpassword"
                  style={{ display: "none" }}
                  className="row"
                >
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ display: "inline-block", marginTop: "25px" }}
                  >
                    <label>Naujas slaptažodis</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        id="newpasswordinput1"
                        className="form-control"
                        placeholder="Naujas slaptažodis"
                      />
                      &nbsp;
                      <button
                        className="btn"
                        id="seepassword2"
                        onClick={(event) =>
                          this.seePassword(event, "newpasswordinput1")
                        }
                        style={{
                          height: "32px",
                          padding: "5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ display: "inline-block", marginTop: "25px" }}
                  >
                    <label htmlFor="inputEmail4">Pakartoti slaptažodį</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        id="newpasswordinput2"
                        className="form-control"
                        placeholder="Pakartoti slaptažodį"
                      />
                      &nbsp;
                      <button
                        className="btn"
                        id="seepassword3"
                        onClick={(event) =>
                          this.seePassword(event, "newpasswordinput2")
                        }
                        style={{
                          height: "32px",
                          padding: "5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                      &nbsp;
                      <button
                        id="saveNewPassword"
                        className="btn"
                        onClick={(event) =>
                          this.saveNewPassword(
                            event,
                            "newpassword",
                            "newpasswordinput1",
                            "newpasswordinput2"
                          )
                        }
                        style={{ display: "inline-block", padding: "0px" }}
                      >
                        <i
                          style={{ fontSize: "30px" }}
                          id="saveNewPasswordIcon"
                          className="fa fa-save"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
