import React, { Component } from "react";
import forma1 from "../../../docs/atsisakymoforma1.rtf";
import axios from "axios";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filenamequest: "Pasirinkite dokumentą",
    };

    this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    alert("Sėkmingai išsiųsta!");
    this.props.history.push("/dalyvis/pagrindinis");
  }
  submitInnerForm() {
    var question1 = document.getElementById("question1").value;
    var question2 = document.getElementById("question2").value;
    var question3 = document.getElementById("question3").value;
    var formData = new FormData();
    formData.append("question1", question1);
    formData.append("question2", question2);
    formData.append("question3", question3);

    var url = "http://localhost:8080/api";
    axios.post(url + "/questionnaire", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    });
    this.props.history.push("/dalyvis/pagrindinis");
  }
  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }

  render() {
    const filenamequest = this.state.filenamequest;
    const klausats = {
      quest: "Ar čia yra biobanko projektas?",
      answer:
        "Taip, čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas.",
    };
    const klausimai = [
      {
        quest: "Ar čia yra biobanko projektas?",
        answer:
          "Taip, čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas.",
      },
      {
        quest: "Ar čia yra biobanko projektas?",
        answer:
          "Taip, čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas.",
      },
      {
        quest: "Ar čia yra biobanko projektas?",
        answer:
          "Taip, čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas.",
      },
      {
        quest: "Ar čia yra biobanko projektas?",
        answer:
          "Taip, čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas čia yra biobanko projektas.",
      },
    ];

    const showAnswer = klausimai.map((data) => {
      return (
        <>
          <div className="col-12">
            <label
              style={{ fontWeight: "bold" }}
              htmlFor="exampleFormControlTextarea1"
            >
              {data.quest}
            </label>
            <div className="form-group message">
              <i className="fa fa-pencil" />
              <input
                style={{ height: "5rem" }}
                readOnly
                name={data.quest.toLowerCase()}
                rows={7}
                placeholder="Įrašyti duomenis čia ... "
                defaultValue={data.answer}
              />
            </div>
          </div>
        </>
      );
    });

    return (
      <>
        <div className="col-centered col-lg-8 col-md-10 col-12">
          <div className="comments-form">
            <h2 style={{ marginBottom: "50px" }}>
              Epidemiologinis klausimynas
            </h2>
            {/* Contact Form */}

            <form className="form col-centered col-lg-12 col-md-12 d-flex justify-content-center">
              <div className="row">
                {/* <DevelopmentTesting /> */}

                <div className="">
                  <label
                    style={{ fontSize: "20px" }}
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Ar čia yra biobanko projektas?
                  </label>
                  <div className="form-group message">
                    <i className="fa fa-pencil" />
                    <input
                      style={{ height: "5rem", padding: "10px" }}
                      id="question1"
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    style={{ fontSize: "20px" }}
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Ar čia yra biobanko projektas1?
                  </label>
                  <div className="form-group message">
                    <i className="fa fa-pencil" />
                    <input
                      style={{ height: "5rem", padding: "10px" }}
                      id="question2"
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    style={{ fontSize: "20px" }}
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Ar čia yra biobanko projektas2?
                  </label>
                  <div className="form-group message">
                    <i className="fa fa-pencil" />
                    <input
                      style={{ height: "5rem", padding: "10px" }}
                      id="question3"
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                    />
                  </div>
                </div>

                <div className="">
                  <div className="form-group button">
                    <button
                      type="submit"
                      style={{ float: "right", display: "inline-block" }}
                      className="btn"
                      onClick={(event) => this.submitInnerForm(event)}
                    >
                      <i className="fa fa-save" />
                      &nbsp;Išsaugoti
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {/*/ End Contact Form */}
          </div>
        </div>
        <div className="col-centered col-lg-8 col-md-10 col-12">
          <div className="comments-form">
            <h2>Epidemiologinio klausimyno failas ir laukelis tekstui</h2>
            {/* Contact Form */}
            <form className="form">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="form-group">
                    {/* <div className="get-quote card card-container" style= {{paddingTop: "50px",paddingBottom: "50px" ,textAlign: "center"}}> */}
                    {/* <embed src="./documents/Document.pdf" type="application/pdf" width="100%" height="600px" /> */}
                    <form
                      className="hoover hover-submit get-quote"
                      onSubmit={this.saveFileForUser}
                    >
                      <a className="categor-list-style" href={forma1} download>
                        Atsisiųsti epidimiologinį klausimyną
                      </a>
                      <br />
                      <label
                        id="filelabelquest"
                        className="btn"
                        style={{ paddingTop: "2px", paddingBottom: "2px" }}
                      >
                        <input
                          className=""
                          type="file"
                          id="epidemFile"
                          onChange={() => this.changeButtonWord("epidemFile")}
                        />
                        {filenamequest}
                      </label>

                      {/* <button className="btn" type="submit" style={{paddingTop: "2px",paddingBottom: "2px"}}>Išsaugoti</button> */}
                    </form>
                    {/* <FileUpload/> */}
                  </div>
                  {/* </div> */}
                </div>
                <div className="">
                  <div className="form-group message">
                    <i className="fa fa-pencil" />
                    <input
                      name="message"
                      style={{ padding: "10px" }}
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="form-group button">
                    <button
                      type="submit"
                      style={{ padding: "10px", float: "right" }}
                      className="btn get-quote"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      <i className="fa fa-send" />
                      Išsiųsti
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {/*/ End Contact Form */}
          </div>
        </div>
      </>
    );
  }
}

export default Questionnaire;
