import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; //swiper/react/swiper-react.js
import { EffectCoverflow, Navigation, Pagination, Scrollbar, A11y, Grid } from 'swiper';

// const swiper = new Swiper();
import 'swiper/swiper-bundle.min.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);

  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }
  componentDidUpdate() {
    this.handleLoad();
  }

  handleLoad() {
    // $("biotikslas1") //  $ is available here
    console.log(document.getElementById("biotikslas1").textContent);
    document.getElementById("biotikslas2").innerHTML = document.getElementById("biotikslas1").innerHTML;
    document.getElementById("medziaga2").innerHTML = document.getElementById("medziaga1").innerHTML;
    // document.getElementById("sveikatosinfo2").innerHTML = document.getElementById("sveikatosinfo1").innerHTML;
    // document.getElementById("tipai2").innerHTML = document.getElementById("tipai1").innerHTML;
    document.getElementById("surenkamamedziaga2").innerHTML = document.getElementById("surenkamamedziaga1").innerHTML;
    document.getElementById("nauda2").innerHTML = document.getElementById("nauda1").innerHTML;
    document.getElementById("surinkimas2").innerHTML = document.getElementById("surinkimas1").innerHTML;
    document.getElementById("saugojimas2").innerHTML = document.getElementById("saugojimas1").innerHTML;
    document.getElementById("reiksme2").innerHTML = document.getElementById("reiksme1").innerHTML;
  }

  render() {

    // document.getElementById("biotikslas2").innerHTML = document.getElementById("biotikslas1").innerHTML;
    return (

        <div>

          {/* <div className="preloader">
          <div className="loader">
            <div className="loader-outter" />
            <div className="loader-inner" />
            <div className="indicator" style={{color: "white"}}>
              kraunasi, palaukite...
              <svg width="16px" height="12px">
                <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
              </svg>
            </div>
          </div>
        </div> */}

          {/* End Preloader */}

          {/* Get Pro Button */}

          {/* <ul className="pro-features">
          <a className="get-pro" href="#">Svarbu</a>
          <li className="big-title">Svarbi informacija</li>
          <li className="title" />
          <li><b>Pagrindinis biobanko tikslas</b> – sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius
              ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje
          </li>
          <div className="button">
            <a href="index.html" target="_blank" className="btn">Naujas puslapis informacijai</a>
          </div>
        </ul> */}



          {/* Off Canvas Area Start*/}

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas1" aria-labelledby="offCanvas1Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas1Label">Kas yra biobankas?</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <div>

                <ul className="listStyleText listTextIndent">
                  <h2 className="thick" style={{ fontSize: "18px", textIndent: "0", margin: "0" }}>Pagrindinis biobanko tikslas-</h2>
                  <li style={{ textIndent: "0" }}> sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius
                    ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje;
                    skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų
                    prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą.</li>

                  <li>Šiuo tikslu Biobankas kaupia Lietuvos populiacijos sveikų asmenų ir sergančių retomis ligomis asmenų biologinius mėginius ir sveikatos informaciją.</li>

                  <li>Lietuvos ir užsienio mokslininkai Biobanku gali naudotis kaip biblioteka: užuot kiekvienam moksliniam tyrimui ieškoję vis naujų savanorių, mokslininkai
                    gali pasinaudoti Biobanke jau sukauptais aukštos kokybės mėginiais bei sveikatos informacija ir pasidalinti su Biobanku mokslinių tyrimų metu gauta informacija,
                    tokiu būdu dar padidinant sukauptų mėginių mokslinę vertę.</li>

                  <li>Mokslininkai mokslinių tyrimų metu analizuodami sveikatos informaciją ir biologinių mėginių tyrimų rezultatus, gali atrasti naujus ligų rizikos veiksnius,
                    sukurti naujus visuomenės sveikatos gerinimo būdus, naujus ligų diagnostikos ir gydymo metodus, naujus vaistus ar ligų prevencijos būdus.</li>

                  <li>Biobankas kaupdamas didelį skaičių biologinių mėginių ir vis papildydamas su mėginiais susijusią sveikatos informaciją, palengvins ir pagerins mokslinių
                    tyrimų organizavimą ir atlikimą: bus galima atlikti didelės apimties, patikimesnius ir įvairesnius mokslinius tyrimus.</li>

                </ul>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modal1" tabIndex={-1} aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen-xl-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel1">Kas yra biobankas?</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="biotikslas1" className="modal-body">
                  <ul className="listStyleText listTextIndent">
                    <h2 className="thick" style={{ fontSize: "18px", textIndent: "0", margin: "0" }}>Pagrindinis biobanko tikslas-</h2>
                    <li style={{ textIndent: "0" }}> sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius
                      ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje;
                      skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų
                      prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą.</li>

                    <li>Šiuo tikslu Biobankas kaupia Lietuvos populiacijos sveikų asmenų ir sergančių retomis ligomis asmenų biologinius mėginius ir sveikatos informaciją.</li>

                    <li>Lietuvos ir užsienio mokslininkai Biobanku gali naudotis kaip biblioteka: užuot kiekvienam moksliniam tyrimui ieškoję vis naujų savanorių, mokslininkai
                      gali pasinaudoti Biobanke jau sukauptais aukštos kokybės mėginiais bei sveikatos informacija ir pasidalinti su Biobanku mokslinių tyrimų metu gauta informacija,
                      tokiu būdu dar padidinant sukauptų mėginių mokslinę vertę.</li>

                    <li>Mokslininkai mokslinių tyrimų metu analizuodami sveikatos informaciją ir biologinių mėginių tyrimų rezultatus, gali atrasti naujus ligų rizikos veiksnius,
                      sukurti naujus visuomenės sveikatos gerinimo būdus, naujus ligų diagnostikos ir gydymo metodus, naujus vaistus ar ligų prevencijos būdus.</li>

                    <li>Biobankas kaupdamas didelį skaičių biologinių mėginių ir vis papildydamas su mėginiais susijusią sveikatos informaciją, palengvins ir pagerins mokslinių
                      tyrimų organizavimą ir atlikimą: bus galima atlikti didelės apimties, patikimesnius ir įvairesnius mokslinius tyrimus.</li>

                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel2">Biologinė medžiaga?</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="medziaga1" className="modal-body">
                  <h2 className="thick" style={{ fontSize: "18px", textIndent: "0", margin: "0" }}>Biobankas renka dalyvių, pasirašiusių sutikimą dalyvauti biobanko veikloje, biologinius ėminius:</h2>
                  <ul className="listStyleList">
                    <li>veninį ir (ar) kapiliarinį kraują</li>
                    <li>šlapimą</li>
                    <li>diagnozavimo ar gydymo procedūros metu gauta likutine biologinė medžiaga</li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>


          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas2" aria-labelledby="offCanvas2Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas2Label">Biologinė medžiaga?</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">

              <h2 className="thick" style={{ fontSize: "18px", textIndent: "0", margin: "0" }}>Biobankas renka dalyvių, pasirašiusių sutikimą dalyvauti biobanko veikloje, biologinius ėminius:</h2>
              <ul className="listStyleList">
                <li>veninį ir (ar) kapiliarinį kraują</li>
                <li>šlapimą</li>
                <li>diagnozavimo ar gydymo procedūros metu gauta likutine biologinė medžiaga</li>
              </ul>

            </div>
          </div>

          <div className="modal fade" id="modal3" tabIndex={-1} aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel3">Sveikatos informacija</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="sveikatosinfo1" className="modal-body">
                  <h2 className="thick" style={{ fontSize: "18px", textIndent: "0", margin: "0" }}>Biobankas renka dalyvių, pasirašiusių sutikimą dalyvauti biobanko veikloje, biologinius ėminius:</h2>
                  <ul className="listStyleList">
                    <li>Biobanko dalyvio pateikta informacija atsakant į klausimyno klausimus apie sveikatą, įpročius, dietą, gyvenimo būdą ir kt.</li>
                    <li>Tyrimų duomenys: kraujo spaudimas, ūgis, svoris ir kt.</li>
                    <li>Sveikatos informacija, periodiškai atnaujinama iš Registrų centro Gyventojų registro,
                      Privalomojo sveikatos draudimo informacinės sistemos (SVEIDRA), Elektroninės sveikatos paslaugų ir
                      bendradarbiavimo infrastruktūros informacinės sistemos (ESPBI IS).</li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>


          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas3" aria-labelledby="offCanvas3Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas3Label">Sveikatos Informacija</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">

              <ul className="listStyleList">
                <li>Biobanko dalyvio pateikta informacija atsakant į klausimyno klausimus apie sveikatą, įpročius, dietą, gyvenimo būdą ir kt.</li>
                <li>Tyrimų duomenys: kraujo spaudimas, ūgis, svoris ir kt.</li>
                <li>Sveikatos informacija, periodiškai atnaujinama iš Registrų centro Gyventojų registro,
                  Privalomojo sveikatos draudimo informacinės sistemos (SVEIDRA), Elektroninės sveikatos paslaugų ir
                  bendradarbiavimo infrastruktūros informacinės sistemos (ESPBI IS).</li>
              </ul>



            </div>
          </div>

          <div className="modal fade" id="modal31" tabIndex={-1} aria-labelledby="exampleModalLabel31" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel31">Kokie yra biobankų tipai? Kuo jie skiriasi?</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="tipai1" className="modal-body">
                  <ul className="listStyleText">
                    <li>Paprastai išskiriami du biobankų tipai: populiaciniai biobankai, ligos biobankai (klinikiniai biobankai).</li>

                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas31" aria-labelledby="offCanvas31Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas31Label">Kokie yra biobankų tipai? Kuo jie skiriasi?</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">

              <ul className="listStyleText">
                <li>Paprastai išskiriami du biobankų tipai: populiaciniai biobankai, ligos biobankai (klinikiniai biobankai).</li>

              </ul>



            </div>
          </div>

          <div className="modal fade" id="modal32" tabIndex={-1} aria-labelledby="exampleModalLabel32" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel32">Kaip surenkama biologinė medžiaga?</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="surenkamamedziaga1" className="modal-body">
                  <ul className="listStyleText">
                    <li>Biologinius mėginius biobankas gali surinkti: diagnozavimo ar gydymo procedūros metu (taip gauta biologinė medžiaga vadinama
                      likutine, nes ji lieka po diagnostinės ar gydomosios procedūros) arba  papildomos, specialiai biobankavimo tikslu atliekamos procedūros metu (pvz., biopsija,
                      kraujo paėmimas).</li>

                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas32" aria-labelledby="offCanvas32Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas32Label">Kaip surenkama biologinė medžiaga?</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">

              <ul className="listStyleText">
                <li>Biologinius mėginius biobankas gali surinkti: diagnozavimo ar gydymo procedūros metu (taip gauta biologinė medžiaga vadinama
                  likutine, nes ji lieka po diagnostinės ar gydomosios procedūros) arba  papildomos, specialiai biobankavimo tikslu atliekamos procedūros metu (pvz., biopsija,
                  kraujo paėmimas).</li>

              </ul>



            </div>
          </div>

          <div className="modal fade" id="modal33" tabIndex={-1} aria-labelledby="exampleModalLabel32" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel32">Kokia galima dalyvavimo biobanke nauda?</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="nauda1" className="modal-body">
                  <ul className="listStyleText">
                    <li>Jūs  kartu su kitais Biobanko dalyviais padėsite mokslininkams atsakyti į daugelį su žmogaus sveikata susijusių klausimų.
                      Mokslininkams atliekant biomedicininius tyrimus naujomis technologijomis gali paaiškėti Jūsų sveikatai svarbi informacija,
                      o tai leistų savalaikiai ir efektyviai suteikti Jums tinkamas sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti
                      nustatytą sveikatos sutrikimą. Jeigu sutiksite, Jūsų sveikatai svarbi informacija bus perduodama tiesiogiai Jums (arba asmens
                      sutikimą dalyvauti Biobanko veikloje davusiam kitam sutikimą turinčiam teisę duoti asmeniui) arba Jūsų šeimos gydytojui, kuris
                      informuos Jus (arba asmens sutikimą dalyvauti Biobanko veikloje davusį kitą sutikimą turinčiam teisę duoti asmenį).“</li>

                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>


          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas33" aria-labelledby="offCanvas33Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas33Label">Kokia galima dalyvavimo biobanke nauda?</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">

              <ul className="listStyleText">
                <li>Jūs  kartu su kitais Biobanko dalyviais padėsite mokslininkams atsakyti į daugelį su žmogaus sveikata susijusių klausimų.
                  Mokslininkams atliekant biomedicininius tyrimus naujomis technologijomis gali paaiškėti Jūsų sveikatai svarbi informacija,
                  o tai leistų savalaikiai ir efektyviai suteikti Jums tinkamas sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti
                  nustatytą sveikatos sutrikimą. Jeigu sutiksite, Jūsų sveikatai svarbi informacija bus perduodama tiesiogiai Jums (arba asmens
                  sutikimą dalyvauti Biobanko veikloje davusiam kitam sutikimą turinčiam teisę duoti asmeniui) arba Jūsų šeimos gydytojui, kuris
                  informuos Jus (arba asmens sutikimą dalyvauti Biobanko veikloje davusį kitą sutikimą turinčiam teisę duoti asmenį).“</li>

              </ul>



            </div>
          </div>
          <div className="modal fade" id="modal4" tabIndex={-1} aria-labelledby="exampleModalLabel4" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel4">Duomenų ir mėginių surinkimas</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="surinkimas1" className="modal-body">
                  <ul className='listStyleList'>
                    <li>Epidemiologinio klausimyno pildymas (apie sveikatą, įpročius, dietą, gyvenimo būdą ir kt.).</li>
                    <li>Antropometriniai tyrimai (kraujo spaudimo, ūgio, svorio matavimas ir kt.).</li>
                    <li>Veninio ir (ar) kapiliarinio kraujo ėminio paėmimas.</li>
                    <li>Šlapimo ėminio paėmimas.</li>
                    <li>Diagnostinės arba biobankavimo tikslais atliktos biopsijos audinio ar likutinės medžiagos po operacijos surinkimas.</li>
                    <li>Sveikatos informacijos surinkimas iš Registrų centro, Gyventojų registro, Privalomojo sveikatos draudimo informacinės sistemos (SVEIDRA), Elektroninės sveikatos paslaugų ir bendradarbiavimo infrastruktūros informacinės sistemos (ESPBI IS).</li>

                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas4" aria-labelledby="offCanvas4Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas4Label">Duomenų ir mėginių surinkimas</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className='listStyleList'>
                <li>Epidemiologinio klausimyno pildymas</li>
                <li>Antropometriniai tyrimai (kraujo spaudimo, ūgio, svorio matavimas ir kt.)</li>
                <li>Veninio ir (ar) kapiliarinio kraujo ėminio paėmimas</li>
                <li>Šlapimo ėminio paėmimas</li>
                <li>Diagnostinės arba biobankavimo tikslais atliktos biopsijos audinio ar likutinės medžiagos po operacijos surinkimas</li>
                <li>Sveikatos informacijos surinkimas iš valstybės registrų</li>

              </ul>


            </div>
          </div>

          <div className="modal fade" id="modal5" tabIndex={-1} aria-labelledby="exampleModalLabel5" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel5">Duomenų ir mėginių saugojimas</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="saugojimas1" className="modal-body">
                  <ul className='listStyleList'>

                    <li>Epidemiologiniame klausimyne pateikta informacija.</li>
                    <li>Antropometrinių tyrimų duomenys.</li>
                    <li>DNR/RNR mėginiai iš veninio ir (ar) kapiliarinio kraujo ėminių ir (ar) solidinių audinių.</li>
                    <li>Limfocitų linijos iš veninio kraujo ėminių.</li>
                    <li>Fibroblastų linijos iš odos bioptato ar kitų audinių bioptato.</li>
                    <li>Kraujo plazma ir serumas iš veninio kraujo ėminių.</li>
                    <li>Sveikatos informacija, kuri bus periodiškai atnaujinama iš valstybės registrų.</li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas5" aria-labelledby="offCanvas5Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas5Label">Duomenų ir mėginių saugojimas</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <h2 className="" style={{ fontSize: "18px", textIndent: "0" }}>Biobanke neterminuotai saugomi:</h2>
              <ul className='listStyleList'>

                <li>asmens (Biobanko dalyvio) pateikta informacija atsakant į klausimyno klausimus (apie sveikatą, įpročius, dietą, gyvenimo būdą ir kt.)</li>
                <li>Tyrimų duomenys: kraujo spaudimas, ūgis, svoris ir kt.</li>
                <li>Sveikatos informacija, kuri bus periodiškai atnaujinama iš Registrų centro Gyventojų registro, Privalomojo sveikatos draudimo
                  informacinės sistemos (SVEIDRA), Elektroninės sveikatos paslaugų ir bendradarbiavimo infrastruktūros informacinės sistemos (ESPBI IS)</li>
                <li>DNR/RNR mėginiai iš veninio ir (ar) kapiliarinio kraujo ėminių ir (ar) solidinių audinių</li>
                <li>Limfocitų linijos iš veninio kraujo ėminių</li>
                <li>Fibroblastų linijos iš odos bioptato ar kitų audinių bioptato</li>
                <li>Kraujo plazma ir serumas iš veninio kraujo ėminių</li>
              </ul>
              <ul className='listStyleText listTextIndent'>
                <li>Visa aukščiau išvardinta ir Biobanke saugoma informacija ir mėginiai mokslininkams ir kitiems tyrėjams bus pateikiama nuasmeninta/koduota, be galimybės nustatyti, kieno mėginiai ar sveikatos informacija bus tiriama. Tik Biobanko atsakingas darbuotojas, esant būtinybei, turės galimybę susieti koduotą informaciją su asmens duomenimis.</li>
              </ul>


            </div>
          </div>

          <div className="modal fade" id="modal6" tabIndex={-1} aria-labelledby="exampleModalLabel6" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel6">Dalyvavimo biobanke reikšmė</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div id="reiksme1" className="modal-body">
                  <ul className='listStyleText listTextIndent justified'>
                    <li>Biobanko dalyvių biologiniai ėminiai ir sveikatos informacija padės mokslininkams atlikti įvairius mokslinius tyrimus ir atsakyti į daugelį su žmogaus sveikata susijusių klausimų.</li>
                    <li>Mokslininkams atliekant biomedicininius tyrimus gali paaiškėti Jūsų sveikatai svarbi informacija, kuri Jūsų sutikimu ir pasirinktu būdu yra perduodama tiesiogiai Jums (arba asmens sutikimą dalyvauti Biobanko veikloje davusiam kitam sutikimą turinčiam teisę duoti asmeniui) arba Jūsų šeimos gydytojui, o tai leistų suteikti Jums sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti nustatytą sveikatos sutrikimą.</li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-bs-dismiss="modal">Uždaryti</button>

                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-start" tabIndex={-1} id="offCanvas6" aria-labelledby="offCanvas6Label">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offCanvas6Label">Dalyvavimo biobanke reikšmė</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className='listStyleText listTextIndent'>
                <li>Jūs kartu su kitais Biobanko dalyviais padėsite mokslininkams atsakyti į daugelį su žmogaus sveikata susijusių klausimų.</li>
                <li>Mokslininkams atliekant biomedicininius tyrimus naujomis technologijomis gali paaiškėti Jūsų sveikatai svarbi informacija, o tai leistų suteikti Jums sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti nustatytą sveikatos sutrikimą. Jeigu sutiksite, Jūsų sveikatai svarbi informacija bus perduodama tiesiogiai Jums (arba asmens sutikimą dalyvauti Biobanko veikloje davusiam kitam sutikimą turinčiam teisę duoti asmeniui) arba Jūsų šeimos gydytojui, kuris informuos Jus (arba asmens sutikimą dalyvauti Biobanko veikloje davusį kitą sutikimą turinčiam teisę duoti asmenį).</li>
              </ul>



            </div>
          </div>



          <div className="mainscreen-background" style={{}}>

          </div>

          {/*/ End Slider Area */}


          <Swiper
              autoHeight={true}
              modules={[EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={5}
              slidesPerView={3}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              // initialSlide= {0}
              pagination={{
                clickable: true,
                dynamicBullets: true
              }}
              // loop={true}
              navigation={true}
              // cardStyle={{
              //   height: -1,
              //   width: -1
              // }}
              // effect={"coverflow"}
              // coverflowEffect={{
              //   rotate: 50,
              //   stretch: 0,
              //   depth: 100,
              //   modifier: 1,
              //   slideShadows: false,
              // }}
              // centeredSlides={true}
              breakpoints={{
                // when window width is >= 640px
                0: {
                  slidesPerView: 1,
                },
                640: {

                  slidesPerView: 2,
                },
                // when window width is >= 768px
                991: {

                  slidesPerView: 3,
                },
                1200: {

                  slidesPerView: 3,
                },
                1500: {

                  slidesPerView: 4,

                }

              }}
          >

            <SwiperSlide>
              <section style={{ marginTop: "25px" }} className="schedule">
                <div className="container">
                  <div className="schedule-inner">
                    <div className="single-schedule first" >
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content" >
                          <h4 style={{ fontWeight: "bold" }}>Biologinė medžiaga?</h4>
                          <div id="medziaga2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "14rem", overflow: "hidden" }}>

                            {/* content copied from canvas */}
                          </div>
                          {/* Pagrindinis biobanko tikslas – sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje; skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą. */}
                          <a type="button" href="#modal2" data-bs-toggle="modal" role="button" data-bs-target="#modal2" style={{}}><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section style={{ marginTop: "25px" }} className="schedule">
                <div className="container">
                  <div className="schedule-inner">
                    <div className="single-schedule first" >
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content" >
                          <h4 style={{ fontWeight: "bold" }}>Kas yra biobankas?</h4>
                          <div id="biotikslas2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "14rem", overflow: "hidden" }}>

                            {/* content copied from canvas */}
                          </div>
                          {/* Pagrindinis biobanko tikslas – sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje; skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą. */}
                          <a type="button" href="#modal1" data-bs-toggle="modal" role="button" data-bs-target="#modal1" style={{}}><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </SwiperSlide>
            {/* <SwiperSlide>
        <section style={{marginTop: "25px"}} className="schedule">
        <div className="container">
        <div className="schedule-inner">
        <div className="single-schedule first" >
                    <div className="inner">
                      <div className="icon">
                        <i className="fa fa-ambulance" />
                      </div>
                      <div  className="single-content" >
                        <h4 style={{fontWeight: "bold"}}>Sveikatos informacija</h4>
                          <div id="sveikatosinfo2" className="fadeText" style={{marginTop: "25px", marginBottom:"0px", height: "14rem", overflow: "hidden"}}>


                          </div>

                          <a type="button" href="#modal3" data-bs-toggle="modal" role="button" data-bs-target="#modal3" style={{}}><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>

          </div>
          </div>
        </section>
        </SwiperSlide> */}
            {/* <SwiperSlide>
        <section style={{marginTop: "25px"}} className="schedule">
        <div className="container">
        <div className="schedule-inner">
        <div className="single-schedule first" >
                    <div className="inner">
                      <div className="icon">
                        <i className="fa fa-ambulance" />
                      </div>
                      <div  className="single-content" >
                        <h4 style={{fontWeight: "bold"}}>Kokie yra biobankų tipai? Kuo jie skiriasi?</h4>
                          <div id="tipai2" className="fadeText" style={{marginTop: "25px", marginBottom:"0px", height: "14rem", overflow: "hidden"}}>


                          </div>

                      </div>
                    </div>
                  </div>

          </div>
          </div>
        </section>
        </SwiperSlide> */}
            <SwiperSlide>
              <section style={{ marginTop: "25px" }} className="schedule">
                <div className="container">
                  <div className="schedule-inner">
                    <div className="single-schedule first" >
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content" >
                          <h4 style={{ fontWeight: "bold" }}>Kaip surenkama  biologinė medžiaga?</h4>
                          <div id="surenkamamedziaga2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "14rem", overflow: "hidden" }}>

                            {/* content copied from canvas */}
                          </div>
                          {/* Pagrindinis biobanko tikslas – sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje; skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą. */}
                          <a type="button" href="#modal32" data-bs-toggle="modal" role="button" data-bs-target="#modal32" style={{}}><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section style={{ marginTop: "25px" }} className="schedule">
                <div className="container">
                  <div className="schedule-inner">
                    <div className="single-schedule first" >
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content" >
                          <h4 style={{ fontWeight: "bold" }}>Kokia galima dalyvavimo biobanke nauda?</h4>
                          <div id="nauda2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "14rem", overflow: "hidden" }}>

                            {/* content copied from canvas */}
                          </div>
                          {/* Pagrindinis biobanko tikslas – sistemingai kaupti, saugoti ir valdyti sveikų ir sergančių retomis ligomis Lietuvos gyventojų biologinius mėginius ir su jais susijusią asmens sveikatos informaciją ir suteikti prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje; skatinti nacionalinį ir tarptautinį mokslinį bendradarbiavimą, individualizuotos medicinos plėtrą, modernių biomedicininių tyrimų vystymąsi, naujų ligų prevencijos, diagnostikos, gydymo ir stebėsenos priemonių kūrimą. */}
                          <a type="button" href="#modal33" data-bs-toggle="modal" role="button" data-bs-target="#modal33" style={{}}><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </SwiperSlide>
            ...
          </Swiper>

          {/* Start Feautes */}
          <section className="Feautes section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12">
                  {/* Start Single features */}
                  <div className="single-features">
                    <div className="signle-icon">
                      <i className="icofont icofont-ambulance-cross" />
                    </div>
                    <h3 style={{ marginBottom: "25px" }}>Duomenų ir mėginių surinkimas</h3>
                    <div id="surinkimas2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "20rem", overflow: "hidden" }}>

                      {/* content copied from canvas */}
                    </div>





                    <a className="moreinfo" type="button" href="#modal4" data-bs-toggle="modal" role="button" data-bs-target="#modal4"><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                  </div>
                  {/* End Single features */}
                </div>
                <div className="col-lg-4 col-12">
                  {/* Start Single features */}
                  <div className="single-features">
                    <div className="signle-icon">
                      <i className="icofont icofont-medical-sign-alt" />
                    </div>

                    <h3 style={{ marginBottom: "25px" }}>Duomenų ir mėginių saugojimas</h3>
                    <div id="saugojimas2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "20rem", overflow: "hidden" }}>

                      {/* content copied from canvas */}
                    </div>







                    <a className="moreinfo" type="button" href="#modal5" data-bs-toggle="modal" role="button" data-bs-target="#modal5"><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                  </div>
                  {/* End Single features */}
                </div>
                <div className="col-lg-4 col-12">
                  {/* Start Single features */}
                  <div className="single-features last">
                    <div className="signle-icon">
                      <i className="icofont icofont-stethoscope" />
                    </div>
                    <h3 style={{ marginBottom: "25px" }}>Dalyvavimo biobanke reikšmė</h3>
                    <div id="reiksme2" className="fadeText" style={{ marginTop: "25px", marginBottom: "0px", height: "20rem", overflow: "hidden" }}>

                      {/* content copied from canvas */}
                    </div>


                    <a className="moreinfo" type="button" href="#modal6" data-bs-toggle="modal" role="button" data-bs-target="#modal6"><b>daugiau informacijos</b><i className="fa fa-long-arrow-right" /></a>
                  </div>
                  {/* End Single features */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title">
                    <img src="img/section-img.png" alt="#" />
                    <div className="get-quote large-part-button w-75 mw-100 col-lg-12 col-centered">
                      <a href="/dalyviams" className="btn ">Noriu tapti biobanko dalyviu</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*/ End Feautes */}
          {/* Start Blog Area */}
          {/* <section className="blog section" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>Biobanko naujienos</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">

                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog1.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="blog-single.html">Naujiena apie 1</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-12">

                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog2.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 2</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-12">

                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog3.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 3</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section> */}
          {/* End Blog Area */}
          {/* <div style={{ textAlign: 'center', marginBottom: '50px' }}>
          <a className="moreinfo" style={{ fontSize: "35px" }} href="/naujienos"><b>daugiau naujienų</b><i className="fa fa-long-arrow-right" /></a>
        </div> */}



          {/* Start clients */}
          {/* <div className=""> */}
          {/* <div style={{textAlign: 'center', position: 'relative'}}>
            <h1 style={{color: '#ffffff', marginBottom: '30px'}}>Mūsų partneriai</h1>
          </div> */}
          {/* <div className="container"> */}
          {/* <div className="col-lg-12 col-md-12 col-12 sponsor-images" style={{backgroundImage: "linear-gradient(to right, red , yellow)"}}> */}
          {/* <div className="row sponsor-images">


          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://www.kaunoklinikos.lt/"><img src="img/logoComp/kaunoklinikos.png" alt="kauno klinikos" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://kraujodonoryste.lt/"><img src="img/logoComp/kraujas.png" alt="kraujo donoryste" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="http://www.imcentras.lt/"><img src="img/logoComp/logo-imc.png" alt="logo imc" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://lsmuni.lt/"><img src="img/logoComp/lsmu-herbas.png" alt="lsmu herbas" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://www.nvi.lt/"><img src="img/logoComp/nvi.png" alt="nvi" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://www.santa.lt/"><img src="img/logoComp/santalogo.png" alt="santariskiu klinika" /></a>
          </div>
          <div className="col-lg-2 col-md-4 col-12" style={{ height: "auto", width: "14%", display: "inline-block" }}>
            <a href="https://www.bbmri-eric.eu/"><img src="img/logoComp/bbmri.png" alt="bbmri" /></a>
          </div>

        </div> */}
          <>
            <div style={{ textAlign: 'center', position: 'relative' }}>
              <h1 style={{ color: '#78003f', marginBottom: '30px' }}>Mūsų partneriai ir institucijos</h1>
            </div>
            <div className='client-section-division'>  {/* , backgroundImage: 'url("img/client-bg.jpg")' */}
              <div className='client-swiper clients-overlay' style={{ width: "100%" }}>
                <Swiper
                    autoHeight={true}
                    modules={[EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={0}
                    slidesPerView={4}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    // initialSlide= {0}
                    centeredSlides={true}
                    pagination={{
                      clickable: true,
                      dynamicBullets: true
                    }}
                    loop={true}
                    navigation={true}
                    style={{ marginTop: "50px"}}
                    breakpoints={{
                      // when window width is >= 640px
                      0: {
                        slidesPerView: 1,
                      },
                      640: {

                        slidesPerView: 2,
                      },
                      // when window width is >= 768px
                      991: {

                        slidesPerView: 2,
                      },
                      1200: {

                        slidesPerView: 3,
                      },
                      1500: {

                        slidesPerView: 4,

                      }

                    }}
                >
                  <SwiperSlide><a className="sponsor-image-separate" href="https://www.kaunoklinikos.lt/"><img src="img/logoComp/kaunoklinikos.png" alt="kauno klinikos" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="https://kraujodonoryste.lt/"><img src="img/logoComp/kraujas.png" alt="kraujo donoryste" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="http://www.imcentras.lt/"><img src="img/logoComp/logo-imc.png" alt="logo imc" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="https://lsmuni.lt/"><img src="img/logoComp/lsmu-herbas.png" alt="lsmu herbas" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="https://www.nvi.lt/"><img src="img/logoComp/nvi.png" alt="nvi" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="https://www.santa.lt/"><img src="img/logoComp/santalogo.png" alt="santariskiu klinika" /></a></SwiperSlide>
                  <SwiperSlide><a className="sponsor-image-separate" href="https://www.bbmri-eric.eu/"><img src="img/logoComp/bbmri.png" alt="bbmri" /></a></SwiperSlide>
                  <SwiperSlide><a href="http://bioetika.sam.lt/"><img src="/img/logoComp/bioetika.png" alt="bioetika logo" style={{ width: "150px", height: "150px" }} /></a></SwiperSlide>
                  <SwiperSlide><a href="https://vaspvt.gov.lt/"><img src="/img/logoComp/akreditavimas.png" alt="vaspvt" style={{ width: "150px", height: "150px" }}/></a></SwiperSlide>
                  <SwiperSlide><a href="https://vdai.lrv.lt/"><img src="/img/logoComp/duomapsauga.png" alt="vdai" style={{ width: "150px", height: "150px" }}/></a></SwiperSlide>

                </Swiper>

              </div>
            </div>
          </>

          {/*
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <h1 style={{ color: '#78003f', marginBottom: '30px' }}>Institucijų interneto svetainės </h1>
          </div>
          <div className='client-section-division'>
            <div className='client-swiper clients-overlay'>
              <Swiper
                autoHeight={true}
                modules={[EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={25}
                slidesPerView={6}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                centeredSlides={true}
                // initialSlide= {0}
                pagination={{
                  clickable: true,
                  dynamicBullets: true
                }}

                loop={true}
                navigation={true}
                style={{ marginTop: "50px" }}
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                  },
                  640: {

                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  991: {

                    slidesPerView: 3,
                  },
                  1200: {

                    slidesPerView: 3,
                  },
                  1500: {

                    slidesPerView: 3,

                  }

                }}
              >
                <SwiperSlide><a href="http://bioetika.sam.lt/"><img src="/img/logoComp/bioetika.png" alt="bioetika logo" style={{ width: "50%", height: "150px" }} /></a></SwiperSlide>
                <SwiperSlide><a href="https://vaspvt.gov.lt/"><img src="/img/logoComp/akreditavimas.png" alt="vaspvt" /></a></SwiperSlide>
                <SwiperSlide><a href="https://vdai.lrv.lt/"><img src="/img/logoComp/duomapsauga.png" alt="vdai" /></a></SwiperSlide>


              </Swiper>
            </div>
          </div> */}



          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/*/Ens clients */}

          {/* Start clients */}
          {/* <div className="clients overlay">
          <div style={{textAlign: 'center', position: 'relative'}}>
            <h1 style={{color: '#ffffff', marginBottom: '30px'}}>Institucijų interneto svetainės </h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="">
                  <div className="single-clients">
                    <a href="http://bioetika.sam.lt/"><img src="/img/logoComp/bioetika.png" alt="bioetika logo" /></a>
                  </div>
                  <div className="single-clients">
                  <a href="https://vaspvt.gov.lt/"><img src="/img/logoComp/akreditavimas.png" alt="vaspvt" /></a>
                  </div>
                  <div className="single-clients">
                  <a href="https://vdai.lrv.lt/"><img src="/img/logoComp/duomapsauga.png" alt="vdai" /></a>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div> */}
          {/*/Ens clients */}



          {/* Start Why choose
        <section className="why-choose section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>Kaip naudotis Biobank paslaugomis?</h2>
                  <img src="img/section-img.png" alt="#" />
                  <p>Dažniausiai biobankai mokslo požiūriu yra vertingi, jei leidžia žmogaus biologinės medžiagos
                    mėginį susieti su asmens duomenimis apie jo sveikatą. </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">

                <div className="choose-left">
                  <h3>Kas mes esame</h3>
                  <p>
                    Populiaciniai biobankai renka ir saugo sveikų asmenų biologinius mėginius (audinius, kūno
                    skysčius, ląsteles) ir susijusią sveikatos informaciją. Jie atlieka svarbų vaidmenį vystant
                    epidemiologinius biomedicininius tyrimus, kurių metu siekiama nustatyti tam tikrų biologinių
                    žymenų pasiskirstymą nesergančioje žmonių populiacijoje.
                    Ligos biobankai renka ir saugo tam tikra liga sergančių asmenų biologinę medžiagą ir susijusią
                    sveikatos informaciją.
                  </p>
                  <p>Ligos biobankuose saugomi mėginiai tiriami vykdant skirtingoms ligoms
                    būdingų biologinių žymenų tyrimus. Ligos biobankai gali tyrinėti vieną ar kelias ligų grupes.
                    Pavyzdžiui, Liudvigshafeno rizikos ir kardiovaskulinės sveikatos biobankas (Vokietija) orientuojasi
                    į širdies ir kraujagyslių ligų mokslinius tyrimus.</p>
                  <div className="row">
                    <div className="col-lg-6">
                      <ul className="list">
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul className="list">
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                        <li>
                          <i className="fa fa-caret-right" />.....
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-6 col-12">

                <div className="choose-right">
                  <div className="video-image">

                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                      </div>
                    </div>

                    <a href="https://www.youtube.com/watch?v=A8shwpHeA6Y" className="video video-popup mfp-iframe"><i className="fa fa-play" /></a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section> */}
          {/*/ End Why choose */}



          {/* Start Fun-facts */}
          <div id="fun-facts" className="fun-facts section overlay">
            <div className="container ">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-3 col-md-6 col-12">
                  {/* Start Single Fun */}
                  <div className="single-fun">
                    <i className="icofont icofont-user-alt-3" />
                    <div className="content">
                      <span className="counter">3468</span>
                      <p>Dalyvių</p>
                    </div>
                  </div>
                  {/* End Single Fun */}
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  {/* Start Single Fun */}
                  <div className="single-fun">
                    <i className="fa fa-tint" />
                    <div className="content">
                      <span className="counter">50015</span>
                      <p>Mėginių</p>
                    </div>
                  </div>
                  {/* End Single Fun */}
                </div>
                {/* <div className="col-lg-3 col-md-6 col-12">

                <div className="single-fun">
                  <i className="icofont-simple-smile" />
                  <div className="content">
                    <span className="counter">379</span>
                    <p>Specialistų </p>
                  </div>
                </div>

              </div> */}
                {/* <div className="col-lg-3 col-md-6 col-12">

                <div className="single-fun">
                  <i className="icofont icofont-table" />
                  <div className="content">
                    <span className="counter">32</span>
                    <p>nepatenkintų klientų</p>
                  </div>
                </div>

              </div> */}
              </div>
            </div>
          </div>
          {/*/ End Fun-facts */}

        </div>
    );
  }
}






export default Index;