import React from "react";

import EventBus from "../common/EventBus";
import MobileSideNav from "./sidenavs/mobilesidenav";

//localStorage.setItem(userRoles, {['ROLE_USER'], [ 'ROLE_EXAMINATOR']; 'ROLE_MANAGER':'ROLE_ADMIN'});
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showExaminatorBoard: false,
      showInstitucijosBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showParticipantBoard: false,
      currentUser: undefined,
      isMobile: false,
      user: JSON.parse(sessionStorage.getItem("user")),
      profileAllowed: false,
      // userType: 0,
    };
  }

  componentDidMount() {
    var user = this.state.user;
    if (user && user.firstTime === 1) {
      this.setState({
        profileAllowed: true,
      });
      console.log("disabled");
    }
    // if (user && user.roles[0] === 1) {
    //   this.setState({
    //     userType: 1,
    //   });
    // } else if (user && user.roles[0] === 2) {
    //   this.setState({
    //     userType: 2,
    //   });
    // } else if (user && user.roles[0] === 3) {
    //   this.setState({
    //     userType: 3,
    //   });
    // } else if (user && user.roles[0] === 4) {
    //   this.setState({
    //     userType: 4,
    //   });
    // } else if (user && user.roles[0] === 5) {
    //   this.setState({
    //     userType: 5,
    //   });
    // }
    if (user) {
      if (user.roles[0] === 1) {
        this.setState({
          showParticipantBoard: true,
        });
      }
      if (user.roles[0] === 2) {
        this.setState({
          showExaminatorBoard: true,
        });
      }
      if (user.roles[0] === 3) {
        this.setState({
          showInstitucijosBoard: true,
        });
      }
      if (user.roles[0] === 5) {
        this.setState({
          showAdminBoard: true,
        });
      }
    }

    const updateIsMobile = () => {
      this.setState({ isMobile: window.innerWidth < 1251 });
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();

    // const user = AuthService.getCurrentUser();
    // const user = JSON.parse(sessionStorage.getItem("user"));
    // console.log(user.email);
    if (sessionStorage.getItem("user") !== null) {
      this.setState({
        currentUser: user,
        // showParticipantBoard: user.roles.includes("ROLE_USER"),
        // showExaminatorBoard: user.roles.includes("ROLE_MODERATOR"),
        // showWorkerBoard: user.roles.includes("ROLE_WORKER"),
        // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        name: user.name + " " + user.surname,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    // AuthService.logout();
    sessionStorage.clear();
    this.setState({
      showExaminatorBoard: false,
      showInstitucijosBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showParticipantBoard: false,
      currentUser: undefined,
    });
  }
  handleSideNavOpen() {
    document.getElementById("mySidenav").style.width = "100%";
    document.body.style.overflow = "hidden";
  }
  handleSideNavClose() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.overflow = "scroll";
  }

  render() {
    const pathname = window.location.pathname;
    const {
      currentUser,
      showParticipantBoard,
      showExaminatorBoard,
      showInstitucijosBoard,
      showAdminBoard,
      showSuperAdminBoard,
    } = this.state;

    return (
        <header className="header">
          {/* Header Inner */}
          <nav className=" navbar navbar-expand sticky-top">
            {" "}
            {/*margin-nav */}
            <div
                className="header-inner"
                style={{
                  opacity: "95%",
                  width: "120%",
                  marginLeft: "-20px",
                  marginRight: "-40px",
                }}
            >
              <div className="container-xxl">
                {" "}
                {/*margin-container*/}
                <div className="row" style={{ display: "none" }}></div>
                <div className="row">
                  <div className="col-lg-2 col-md-4 col-sm-3 col-12">
                    {/* Start Logo */}
                    <div className="logo" style={{ marginTop: "10px" }}>
                      <a href="/">
                        <img
                            src="/img/vu-biobankaslogo.png"
                            alt="logo medicinos falkultetas"
                        />
                      </a>
                    </div>
                  </div>
                  {/*{!this.state.isMobile ? (*/}
                      <div
                          id="menubox"
                          style={{ marginTop: "0px" }}
                          className="col-lg-7 col-md-3 col-3"
                      >
                        {/* Main Menu */}
                        <div
                            className="main-menu"
                            style={{ marginTop: "0px", marginBottom: "10px" }}
                        >
                          <button
                              className="navbar-toggler"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#main_nav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                          >
                            <span className="navbar-toggler-icon" />
                          </button>
                          {/*collapse navbar-collapse*/}
                          <div className="" id="main_nav">
                            <ul className="nav menu">
                              <li
                                  className={
                                    pathname === "/apievubio" ||
                                    pathname === "/tikslaimisija" ||
                                    pathname === "/strukturakontaktai" ||
                                    pathname === "/struktura" ||
                                    pathname === "" ||
                                    pathname === "/meginiai" ||
                                    pathname === "/projektai" ||
                                    pathname === "/registraiduomenys" ||
                                    pathname === "/licencija" ||
                                    pathname === "/biobankoteises" ||
                                    pathname === "/teiseteikti" ||
                                    pathname === "/teisesaktai" ||
                                    pathname === "/duomenutvarkyma" ||
                                    pathname === "/institucijos" ||
                                    pathname === "/publikacijos" ||
                                    pathname === "/mesziniasklaidoje" ||
                                    pathname === "/renginiai"
                                        ? "nav-item dropdown active"
                                        : "nav-item dropdown"
                                  }
                                  id="myDropdown"
                              >
                                <a
                                    className="nav-link dropdown-toggle"
                                    style={{
                                      paddingTop: "15px",
                                      paddingBottom: "5px",
                                    }}
                                    href="/apievubio"
                                >
                                  Apie VU biobanką
                                </a>
                                <ul className="dropdown-menu dropdown-hover">
                                  <a
                                      className="dropdown-item"
                                      href="/tikslaimisija"
                                  >
                                    Tikslai ir misija{" "}
                                  </a>
                                  <li>
                                    <a
                                        className="dropdown-item"
                                        href="/strukturakontaktai"
                                    >
                                      Struktūra ir kontaktai{" "}
                                      <b>
                                        <i className="fa fa-angle-right" />
                                      </b>{" "}
                                    </a>
                                    <ul className="submenu dropdown-menu">
                                      <a
                                          className="dropdown-item"
                                          href="/struktura"
                                      >
                                        Struktūra{" "}
                                      </a>
                                    </ul>
                                  </li>
                                  <a className="dropdown-item" href="/meginiai">
                                    Saugomi mėginiai{" "}
                                  </a>
                                  <a className="dropdown-item" href="/projektai">
                                    Projektai{" "}
                                  </a>
                                  {/* <a className="dropdown-item" href="/registraiduomenys" >Registrai ir duomenų bazės </a> */}

                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Teisinė informacija{" "}
                                      <b>
                                        <i className="fa fa-angle-right" />
                                      </b>
                                    </a>
                                    <ul className="submenu dropdown-menu listWidth">
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/licencija"
                                        >
                                          Licencija
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/biobankoteises"
                                        >
                                          Biobanko teisės
                                        </a>
                                      </li>
                                      {/* <li><a className="dropdown-item" href="/teiseteikti">Teisė teikti ėminius ir sveikatos informaciją</a></li> */}
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/teisesaktai"
                                        >
                                          Teisės aktai
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/duomenutvarkymas"
                                        >
                                          Asmens duomenų tvarkymas
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/institucijos"
                                        >
                                          Institucijos vykdančios Biobanko veiklos
                                          valstybinę priežiūrą ir asmens duomenų
                                          tvarkymą
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      Sklaida{" "}
                                      <b>
                                        <i className="fa fa-angle-right" />
                                      </b>
                                    </a>
                                    <ul className="submenu dropdown-menu">
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/publikacijos"
                                        >
                                          Publikacijos
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/mesziniasklaidoje"
                                        >
                                          Mes žiniasklaidoje
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/renginiai"
                                        >
                                          Renginiai
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              <li
                                  className={
                                    pathname === "/dalyviams" ||
                                    pathname === "/kaiptapti" ||
                                    pathname === "/dalyvioteises" ||
                                    pathname === "/teiseatsauktikeisti" ||
                                    pathname === "/duk" ||
                                    pathname === "/formos"
                                        ? "nav-item dropdown active"
                                        : "nav-item dropdown"
                                  }
                                  id="myDropdown"
                              >
                                <a
                                    className="nav-link dropdown-toggle"
                                    style={{
                                      paddingTop: "15px",
                                      paddingBottom: "5px",
                                    }}
                                    href="/dalyviams"
                                >
                                  {" "}
                                  Biobanko dalyviams{" "}
                                </a>

                                <ul className="dropdown-menu dropdown-hover">
                                  <a className="dropdown-item" href="/kaiptapti">
                                    Kaip tapti dalyviu?{" "}
                                  </a>
                                  <a className="dropdown-item" href="/registracija">
                                    Registracija dalyviams{" "}
                                  </a>
                                  <li>
                                    <a
                                        className="dropdown-item"
                                        href="/dalyvioteises"
                                    >
                                      Biobanko dalyvio teisės{" "}
                                      <b>
                                        <i className="fa fa-angle-right"/>
                                      </b>
                                    </a>
                                    <ul className="submenu dropdown-menu">
                                      <li>
                                        <a
                                            className="dropdown-item"
                                            href="/teiseatsauktikeisti"
                                        >
                                          Teisė atšaukti sutikimą
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <a className="dropdown-item" href="/formos">
                                    Sutikimo ir atsisakymo formos{" "}
                                  </a>
                                  <a className="dropdown-item" href="/duk">
                                    DUK{" "}
                                  </a>
                                </ul>
                              </li>
                              <li
                                  className={
                                    pathname === "/tyrejams" ||
                                    pathname === "/meginiuteikimotvarka"
                                        ? "nav-item dropdown active"
                                        : "nav-item dropdown"
                                  }
                              >
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/tyrejams"
                                    style={{
                                      paddingTop: "15px",
                                      paddingBottom: "5px",
                                    }}
                                >
                                  Tyrėjams{" "}
                                </a>
                                <ul className="dropdown-menu dropdown-hover">
                                  <a
                                      className="dropdown-item"
                                      href="/meginiuteikimotvarka"
                                  >
                                    Mėginių ir sveikatos informacijos teikimo tvarka{" "}
                                  </a>
                                  <a
                                      href="/registertypetyrejas"
                                      className="dropdown-item"
                                      style={{ display: "inline-block" }}
                                  >
                                    Registruotis tyrėjams
                                  </a>
                                </ul>
                              </li>
                              <li
                                  className={
                                    pathname === "/naujienos"
                                        ? "nav-item active"
                                        : "nav-item"
                                  }
                              >
                                <a
                                    className="nav-link"
                                    style={{
                                      paddingTop: "15px",
                                      paddingBottom: "5px",
                                    }}
                                    href="/naujienos"
                                >
                                  {" "}
                                  Naujienos{" "}
                                </a>
                              </li>
                              <li
                                  className={
                                    pathname === "/kontaktai"
                                        ? "nav-item active"
                                        : "nav-item"
                                  }
                              >
                                <a
                                    className="nav-link"
                                    style={{
                                      paddingTop: "15px",
                                      paddingBottom: "5px",
                                    }}
                                    href="/kontaktai"
                                >
                                  {" "}
                                  Kontaktai{" "}
                                </a>
                              </li>

                              {showParticipantBoard && (
                                  <li
                                      className={
                                        pathname.includes("/dalyvis")
                                            ? "nav-item active"
                                            : "nav-item"
                                      }
                                  >
                                    <a
                                        className={
                                          this.state.profileAllowed
                                              ? "cursorDisabled"
                                              : ""
                                        }
                                        style={{
                                          paddingTop: "15px",
                                          paddingBottom: "5px",
                                        }}
                                        href="/dalyvis/pranesimai/susisiekti"
                                        disabled={this.state.profileAllowed}
                                    >
                                      Dalyvio paskyra{" "}
                                    </a>
                                  </li>
                              )}
                              {showExaminatorBoard && (
                                  <li
                                      className={
                                        pathname.includes("/tyrejas")
                                            ? "nav-item active"
                                            : "nav-item"
                                      }
                                  >
                                    <a
                                        style={{
                                          paddingTop: "15px",
                                          paddingBottom: "5px",
                                        }}
                                        href="/tyrejas/manoparaiskos"
                                    >
                                      Tyrėjo paskyra{" "}
                                    </a>
                                  </li>
                              )}
                              {showInstitucijosBoard && (
                                  <li
                                      className={
                                        pathname.includes("/darbuotojas")
                                            ? "nav-item active"
                                            : "nav-item"
                                      }
                                  >
                                    <a
                                        style={{
                                          paddingTop: "15px",
                                          paddingBottom: "5px",
                                        }}
                                        href="/darbuotojas/dalyviuinfo"
                                    >
                                      Institucijos paskyra{" "}
                                    </a>
                                  </li>
                              )}
                              {showSuperAdminBoard && (
                                  <li
                                      className={
                                        pathname.includes("/administratorius")
                                            ? "nav-item active"
                                            : "nav-item"
                                      }
                                  >
                                    <a
                                        style={{
                                          paddingTop: "15px",
                                          paddingBottom: "5px",
                                        }}
                                        href="/administratorius/tyreju-info-admin"
                                    >
                                      Super admin paskyra{" "}
                                    </a>
                                  </li>
                              )}
                              {showAdminBoard && (
                                  <li
                                      className={
                                        pathname.includes("/mini-admin")
                                            ? "nav-item active"
                                            : "nav-item"
                                      }
                                  >
                                    <a
                                        style={{
                                          paddingTop: "15px",
                                          paddingBottom: "5px",
                                        }}
                                        href="/mini-admin/tyrejuinfo"
                                    >
                                      Admin paskyra{" "}
                                    </a>
                                  </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        {/*/ End Main Menu */}
                      </div>
                  {/*) : (*/}
                  {/*     <>*/}
                  {/*       <div*/}
                  {/*           id="menubox"*/}
                  {/*           className="col-lg-7 col-md-3 col-sm-3 col-xs-1 d-sm-block"*/}
                  {/*       ></div>*/}
                  {/*     </>*/}
                  {/* )}*/}

                  {currentUser ? (
                      <>
                        <div
                            className="col-lg-3 col-md-5 col-sm-6 col-12 marginMobileNav1"
                            style={{ marginTop: "10px" }}
                        >
                          {" "}
                          {/*  marginTop: "-25px"*/}
                          <div
                              className="row width-change"
                              style={{
                                color: "white",
                                float: "right",
                                marginRight: "15px",
                              }}
                          >
                            {" "}
                            {/* , marginTop: "80px"*/}
                            <div className="col-lg-12 col-md-12 col-12">
                              <a
                                  href="/login"
                                  className="btn-login"
                                  onClick={this.logOut}
                                  style={{ display: "inline-block" }}
                              >
                                &nbsp;
                                <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                ></i>
                                &nbsp;Atsijungti&nbsp;
                              </a>
                              {/* <div className="btn-group width-change" style={{ display: "inline-block" }}>
                              <a href="#" className="btn" style={{ display: "inline-block", padding: "5px" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">LT</a>
                              <div className="dropdown-menu language-dropdown">
                                <a className="dropdown-item" href="#">LT</a>
                                <a className="dropdown-item" href="#">EN</a>
                                <a className="dropdown-item" href="#">RU</a>
                              </div>
                            </div> */}
                              {this.state.isMobile && (
                                  <>
                                    <MobileSideNav />
                                  </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-12">
                            {" "}
                            {/*  marginTop: "-65px"*/}
                            <div style={{ float: "right" }}>
                              {this.state.userType === 1 && (
                                  <a
                                      href={"/dalyvis/manoprofilis"}
                                      className={
                                        this.state.profileAllowed
                                            ? "user-info-style cursorDisabled"
                                            : "user-info-style"
                                      }
                                      style={{ float: "right", textAlign: "right" }}
                                  >
                              <span
                                  className="purple-outline text-outline"
                                  style={{
                                    display: "inline-block",
                                    textTransform: "none",
                                    width: "auto",
                                  }}
                                  disabled={this.state.profileAllowed}
                              >
                                {this.state.name}
                              </span>
                                  </a>
                              )}
                              {this.state.userType === 2 && (
                                  <a
                                      href={"/tyrejas/manoparaiskos"}
                                      className={
                                        this.state.profileAllowed
                                            ? "user-info-style cursorDisabled"
                                            : "user-info-style"
                                      }
                                      style={{ float: "right", textAlign: "right" }}
                                  >
                              <span
                                  className="purple-outline text-outline"
                                  style={{
                                    display: "inline-block",
                                    textTransform: "none",
                                    width: "auto",
                                  }}
                                  disabled={this.state.profileAllowed}
                              >
                                {this.state.name}
                              </span>
                                  </a>
                              )}
                              {this.state.userType === 3 && (
                                  <a
                                      href={"/darbuotojas/dalyviuinfo"}
                                      className={
                                        this.state.profileAllowed
                                            ? "user-info-style cursorDisabled"
                                            : "user-info-style"
                                      }
                                      style={{ float: "right", textAlign: "right" }}
                                  >
                              <span
                                  className="purple-outline text-outline"
                                  style={{
                                    display: "inline-block",
                                    textTransform: "none",
                                    width: "auto",
                                  }}
                                  disabled={this.state.profileAllowed}
                              >
                                {this.state.name}
                              </span>
                                  </a>
                              )}
                              {this.state.userType === 4 && (
                                  <a
                                      href={"/administratorius/tyreju-info-admin"}
                                      className={
                                        this.state.profileAllowed
                                            ? "user-info-style cursorDisabled"
                                            : "user-info-style"
                                      }
                                      style={{ float: "right", textAlign: "right" }}
                                  >
                              <span
                                  className="purple-outline text-outline"
                                  style={{
                                    display: "inline-block",
                                    textTransform: "none",
                                    width: "auto",
                                  }}
                                  disabled={this.state.profileAllowed}
                              >
                                {this.state.name}
                              </span>
                                  </a>
                              )}
                              {this.state.userType === 5 && (
                                  <a
                                      href={"/mini-admin/tyrejuinfo"}
                                      className={
                                        this.state.profileAllowed
                                            ? "user-info-style cursorDisabled"
                                            : "user-info-style"
                                      }
                                      style={{ float: "right", textAlign: "right" }}
                                  >
                              <span
                                  className="purple-outline text-outline"
                                  style={{
                                    display: "inline-block",
                                    textTransform: "none",
                                    width: "auto",
                                  }}
                                  disabled={this.state.profileAllowed}
                              >
                                {this.state.name}
                              </span>
                                  </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                  ) : (
                      <>
                        <div
                            className="col-lg-3 col-md-5 col-sm-6 col-xs-3 col-12"
                            style={{ float: "right", marginTop: "10px" }}
                        >
                          {" "}
                          {/*  marginTop: "-25px"*/}
                          <div
                              className="row width-change"
                              style={{ color: "white" }}
                          >
                            {" "}
                            {/* , marginTop: "80px"*/}
                            <div
                                className="col-lg-8 col-md-8 col-sm-6 col-8 "
                                style={{ float: "right" }}
                            >
                              <div style={{ float: "right" }}>
                                <a
                                    href="/login"
                                    className="btn-login"
                                    style={{ display: "inline-block" }}
                                >
                                  <i className="fa fa-sign-in"></i>
                                  &nbsp;Prisijungti&nbsp;
                                </a>
                                {/* <br /> */}
                                {/* <a href="/registertypetyrejas" className="btn btn-navbar" style={{ display: "inline-block" }}>Registruotis tyrėjams</a> */}
                              </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-4 col-4"
                                style={{ float: "right" }}
                            >
                              {this.state.isMobile && (
                                  <>
                                    <MobileSideNav />
                                  </>
                              )}
                              <br />
                              {/* <div className="btn-group get-quote" style={{ display: "inline-block" }}>
                              <a href="#" className="btn" style={{ display: "inline-block", padding: "5px" }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">LT</a>
                              <div className="dropdown-menu language-dropdown">
                                <a className="dropdown-item" href="#">LT</a>
                                <a className="dropdown-item" href="#">EN</a>
                                <a className="dropdown-item" href="#">RU</a>
                              </div>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                  )}
                </div>
              </div>
            </div>
            {/*/ End Header Inner */}
          </nav>
        </header>
    );
  }
}

export default Header;