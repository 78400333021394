import React from 'react';
import axios from "axios";
import DalyvisService from '../../../services/dalyvis-service';

class Dalyvisforma extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registerType: false,
      atstovas: false,
      user: JSON.parse(sessionStorage.getItem("user"))
    };


  }
  componentDidMount() {
    //if jei useris prisijungė per EVV, Google arba Facebook\
    var authorizedEVV = false;
    if (authorizedEVV) {
      this.setState({
        registerType: authorizedEVV

      });
    }
    var selectPart = sessionStorage.getItem("selectedParticipation");
    if (selectPart === "atstovas") {
      this.setState({
        atstovas: true
      });
    }

  }
  submitForm(e) {
    e.preventDefault();
    // var name = document.getElementById("name").value;
    // var surname = document.getElementById("surname").value;
    // var personalcode = document.getElementById("personalcode").value;
    var phoneNumber = document.getElementById("phonenumber").value;

    // var email = document.getElementById("email").value;
    // var password1 = document.getElementById("passwordinput1").value; // jei useris prisijungė per EVV, Google arba Facebook, turetu leisti nustatyti slaptažodi
    // var password2 = document.getElementById("passwordinput2").value; // jei useris prisijungė per EVV, Google arba Facebook, turetu leisti nustatyti slaptažodi
    var userType = sessionStorage.getItem("selectedParticipation");
    var allItemsRepres = [];
    // if (userType === "atstovas") {
    //   var namerepres = document.getElementById("namerepres").value;
    //   var surnamerepres = document.getElementById("surnamerepres").value;
    //   var personalcoderepres = document.getElementById("personalcoderepres").value;
    //   // var birthcertificate = document.getElementById("birthcertificate");
    //   var allItemsRepres = [namerepres, surnamerepres, personalcoderepres]; //, birthcertificate.value
    //   // var acceptRules = 1;
    // }
    var allItems = [phoneNumber]; //name, surname, personalcode, ,password1,password2
    var continueState = true;


    for (let i = 0; i < allItems.length; i++) {
      console.log("loop1");
      if (allItems[i] === "") {
        continueState = false;
        window.alert("Kai kurie langeliai yra nepilnai užpildyti!");
        break;
      }
      continueState = true;
    }
    if (userType === "atstovas") {
      for (let i = 0; i < allItemsRepres.length; i++) {
        console.log("loop2");
        if (allItemsRepres[i] === "" || allItemsRepres[i] === null) {
          continueState = false;
          window.alert("Kai kurie langeliai yra nepilnai užpildyti!");
          break;
        }
        continueState = true;
      }
    }
    if(phoneNumber.length !== 11){
      window.alert("Įveskite pilną telefono numerį")
      return;
    }
    if (continueState === true) {
      // var value = this.checkPasswords(password1,password2);
      // if(value[0] == true){
      // window.alert(value[1]);
      // var formData = new FormData();
      // formData.append("name", name);
      // formData.append("surname", surname);
      // formData.append("personalcode", personalcode);
      // formData.append("phonenumber", phonenumber);
      // // formData.append("password", password1);
      // if(userType === "atstovas"){
      //   formData.append("namerepres", namerepres);
      //   formData.append("surnamerepres", surnamerepres);
      //   formData.append("personalcoderepres", personalcoderepres);
      //   var atstovai = JSON.parse(localStorage.getItem("atstovai"));
      //   atstovai.push({"name": namerepres, "surname":surnamerepres, "personalcode":personalcoderepres});
      //   localStorage.setItem("atstovai", JSON.stringify(atstovai));
      //   formData.append("birthcertificate", birthcertificate);
      // }

      // var url = "http://localhost:8080/api";
      // axios.post(url + '/userdata', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       "Access-Control-Allow-Origin": "*"
      //     }
      // })
      var firstTime = 0;
      var user = this.state.user;
      var newElements = { "phoneNumber":phoneNumber.trim(), "firstTime": 2 }
      var userObjectUpdated = { ...user, ...newElements }
      DalyvisService.putCustomObjectPersonalData(this.state.user.id, userObjectUpdated).catch(error => window.alert(error.message));
      // if (userType === "atstovas") {
      //   DalyvisService.putPostAddPersonalRepres(userObjectUpdated, namerepres, surnamerepres, personalcoderepres)
      // }
      if (this.state.registerType) {
        this.props.history.push("/dalyvis/pagrindinis");
      }
      else {
        // this.props.history.push("/dalyvavimas/patvirtinimas"); // /dalyvis/pagrindinis
        this.props.history.push("/dalyvis/pagrindinis");

      }
      // }
      //    else{
      //     window.alert(value[1]);
      // }
      window.alert("Duomenys sėkmingai pateikti");
    }
  }

  checkPasswords(pass1, pass2) {
    var state = false;
    var message = "";
    if (pass1 === pass2 && (pass1 !== "" || pass2 !== "") && (pass1.length > 6 || pass2.length > 6)) {
      //user services to save password to database
      message = "Sėkmingai užpildyta forma!";
      state = true;
    }
    else if (pass1 === "" || pass2 === "") {
      message = "Kai kurie laukeliai neužpildyti!";
      state = false;
    }
    else if (pass1.length <= 6 || pass2.length <= 6) {
      message = "Slaptažodis turi būti ilgesnis nei 6 simboliai (7+)!";
      state = false;
    }
    else {
      message = "Įvęsti slaptažodžiai nėra vienodi!";
      state = false;
    }
    return [state, message];
  }

  checkEmpty() {
    // var name = document.getElementById("name").value;
    // var surname = document.getElementById("surname").value;
    // var personalcode = document.getElementById("personalcode").value;
    var phonenumber = document.getElementById("phonenumber").value;
    // var email = document.getElementById("email").value;
    var userType = sessionStorage.getItem("selectedParticipation");
    var allItemsRepres = [];
    // if (userType === "atstovas") {
    //   var namerepres = document.getElementById("namerepres").value;
    //   var surnamerepres = document.getElementById("surnamerepres").value;
    //   var personalcoderepres = document.getElementById("personalcoderepres").value;
    //   // var birthcertificate = document.getElementById("birthcertificate");
    //   var allItemsRepres = [namerepres, surnamerepres, personalcoderepres]; //, birthcertificate.value
    // }
    var allItems = [phonenumber]; // name, surname, personalcode,,password1,password2
    var continueState = true;

    document.getElementById("submitButton").disabled = true;
    var quantity = 0;
    var quantityRepres = 0;
    for (let i = 0; i < allItems.length; i++) {

      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        break;
      }

      quantity += 1;

    }
    if (userType === "atstovas") {
      for (let i = 0; i < allItemsRepres.length; i++) {

        if (allItemsRepres[i] === "" || allItemsRepres[i] === null) {
          continueState = true;

          break;
        }

        quantityRepres += 1;

      }
    }
    // if (userType === "atstovas") {
    //   if (quantity + quantityRepres === allItems.length + allItemsRepres.length && continueState === true && document.getElementById("confirmed").checked === true) {

    //     document.getElementById("submitButton").disabled = false;
    //   }
    // }
    // else {
    if (quantity === allItems.length && continueState === true && document.getElementById("confirmed").checked === true) {

      document.getElementById("submitButton").disabled = false;
    }
    // }
  }


  saveRepresChoice(e) {
    e.preventDefault();
    var selection = document.getElementById("represselect").value;
    window.alert("Atstovaujamas asmuo pasirinktas ir pakeistas!");

  }

  handleChangeNumber(event, id) {
    var value = event.target.value;
    if (!isNaN(value)) {
      this.setState({ [id]: event.target.value });
    }
  }
  handleChangeAlpha(event, id) {
    var value = event.target.value;
    if (/^[a-zA-Ząčęėįšųūž]+$/.test(value) || value === "") {
      this.setState({ [id]: event.target.value });
    }
  }

  render() {


    return <>
      <div className="col-centered col-lg-10 col-md-12 col-12">
        {this.state.atstovas ? <><h2 style={{ marginBottom: "25px", fontSize: "2rem" }}>Atstovo telefono numeris</h2></> :
          <><h2 style={{ marginBottom: "25px", fontSize: "2rem" }}>Dalyvio telefono numeris</h2></>
        }
        <form className="form col-lg-12 col-md-12 border-edit-button" onInput={(event) => this.checkEmpty(event)} onChange={(event) => this.checkEmpty(event)} onSubmit={(event) => this.submitForm(event)} style={{ fontSize: "20px" }}>
          <div className="row">
            {/* <div className="row">
                    <div className="col-lg-6 col-md-6" style={{marginTop: "25px"}}>
                    <label>Prisijungimo vardas</label>
                    <div className="input-group mb-2">
                    
                    
                        <input type="text" id="username"  className="form-control" placeholder="Prisijungimas"/>
                        <div style={{}}>
                        
                        
                        </div>
                    </div>
                    
                        </div> */}

            <div className="row">
              {/* <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
                <label>Vardas*</label>
                <div className="input-group mb-2">
                  <input type="text" id="name" className="form-control" placeholder="Vardenis" />
                  <div style={{}}>

                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
                <label>Pavardė*</label>
                <div className="input-group mb-2">
                  <input type="text" id="surname" className="form-control" placeholder="Pavardenis" />
                  <div style={{}}>

                  </div>
                </div>
              </div>


              <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>

                <label>Asmens kodas*</label>
                <div className="input-group mb-2">
                  <input type="text" id="personalcode" className="form-control" maxLength={11} placeholder="90050050080" />
                  <div style={{}}>

                  </div>
                </div>
              </div> */}

              <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
                <label>Telefono numeris*</label>

                <div className="input-group mb-1" style={{}}>
                  <div className="input-group-prepend">
                    <div className="input-group-text">+</div>
                  </div>
                  <input type="text" id="phonenumber"
                    title="Įvęskite telefono numerį be simbolių" className="form-control" placeholder="37069999999" maxLength={11} />
                  <div style={{}}>

                  </div>
                </div>

              </div>


            </div>
            <div className="row">
              {this.state.registerType && (<>

                hello

              </>)}

              {/* <div className="col-lg-8 col-md-8" style={{marginTop: "25px"}}>
                        
                        <label>Elektroninis paštas*</label>
                        <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">@</div>
                        </div>
                        <input type="email"   className="form-control" id="email" placeholder="elektroninis@pastas.com" />
                        <div style={{marginTop: "0px"}}>
                        
                        </div>
                        </div>
                        </div> */}


            </div>

            {/* <div className="row">
                    <div className="col-lg-6 col-md-6" style={{marginTop: "25px"}}>
                        <label>Slaptažodis</label>
                        <div className="input-group mb-2">
                        <input type="password" id="password" readOnly className="form-control" placeholder="Slaptažodis"/>
                        <div  style={{}}>

                        &nbsp;<button className="btn" id="changebutton" onClick={(event) => this.changePassword(event,"newpassword")} style={{height:"32px", padding: "5px",whiteSpace: "nowrap"}}>Keisti slaptažodį</button>
                        &nbsp;<button className="btn" id="cancelpasswordchange" onClick={ (event) =>this.cancelPassword(event)} style={{height:"32px", padding: "5px", display: "none",whiteSpace: "nowrap"}}>X</button>
                    </div>
                        </div>
                    </div>

                    </div> */}
            {/* {this.state.registerType &&(
                    <div id="newpassword" style={{}}className="row">
                    <div className="col-lg-6 col-md-6" style={{display: "inline-block",marginTop: "25px"}}>
                        <label>Naujas slaptažodis</label>
                        <input type="password" id="passwordinput1" className="form-control" placeholder="Naujas slaptažodis"/>
                    </div>
                    <div className="col-lg-6 col-md-6" style={{display: "inline-block",marginTop: "25px"}}>
                        <label htmlFor="inputEmail4">Pakartoti slaptažodį</label>
                        <div className="input-group mb-2">
                        <input type="password"  id="passwordinput2" className="form-control" placeholder="Pakartoti slaptažodį"/>
                        <div className="col-lg-1 col-md-1" style={{}}>
                        
                        </div>
                        </div>
                    </div>
                    
                    </div>
                    )} */}
            {this.state.atstovas && <>
              {/* <h2 style={{ marginTop: "50px", marginBottom: "25px", fontSize: "2rem" }}>Atstovaujamo asmens duomenys</h2>

              <div className="row">
                <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
                  <label>Vardas*</label>
                  <div className="input-group mb-2">
                    <input type="text" id="namerepres" className="form-control" placeholder="Vardenis" />
                    <div style={{}}>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
                  <label>Pavardė*</label>
                  <div className="input-group mb-2">
                    <input type="text" id="surnamerepres" className="form-control" placeholder="Pavardenis" />
                    <div style={{}}>

                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6" style={{ marginTop: "25px", marginBottom: "50px" }}>

                  <label>Asmens kodas*</label>
                  <div className="input-group mb-2">
                    <input type="text" id="personalcoderepres" className="form-control" maxLength={11} placeholder="90050050080" />
                    <div style={{}}>

                    </div>
                  </div>
                </div> */}

              {/* <div className="col-lg-6 col-md-6" style={{ marginTop: "25px", marginBottom: "50px" }}>

                  <label>Atstovavimo pagrindas*</label>
                  <div className="input-group mb-2">
                    <label className="btn col-lg-12" style={{}}>
                      <input className="" type="file" id="birthcertificate" />
                      Pasirinkite dokumentą
                    </label>
                    <div style={{}}>

                    </div>
                  </div>
                </div> */}

              {/* </div> */}
            </>
            }
            <br />
            <label htmlFor="confirmed" style={{ fontSize: "14px", display: "inline-block", textAlign: "justify" }}>
              <input id="confirmed" type="checkbox" name="confirmed" value="confirmed" style={{ display: "inline-block", fontSize: "20px", transform: "scale(1.5)" }} /> &nbsp;
              Patvirtinu, kad pateikta informacija ir asmens duomenys yra teisingi ir man žinoma, jog nepateikus visos 
              prašomos informacijos ar asmens duomenų ar pateikus tikrovės neatitinkančią informaciją ar asmens duomenis, 
              Biobankas turi teisę vienašališkai sustabdyti mano dalyvavimą Biobanko veikloje.</label><br />
          </div>
          <div className="col-centered">
            <button className="btn col-lg-6 col-md-6 col-sm-12" id="submitButton" type="submit" style={{ float: "right", marginTop: "25px" }} disabled>Pateikti</button>
          </div>
        </form>

      </div>

    </>;
  }
}

export default Dalyvisforma;