import React, { Component } from 'react';



class Approval extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
         
        };
        
        
    }
    

    render() { 
       
        
        return <>
                  <h2>
                    Laukiama patvirtinimo iš darbuotojo. Kai bus patvirtintą bus pranešta el paštu.
                  </h2>

                </>;
    }
}
 
export default Approval;