import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import document1 from "../../../docs/important/3_SUTIKIMAS_dalyviui_LPRLB.pdf";
import document2 from "../../../docs/important/3_SUTIKIMAS_globotiniui_LPRLB.pdf";
import check from "../../../docs/important/check.png";
import TimesNewRoman from "./times.ttf";
import fontkit from "@pdf-lib/fontkit";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";
import { PDFDocument, rgb } from "pdf-lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class UploadDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: "Pasirinkite dokumentą*",
      dalyvis: true,
      signatureDataUrl: "",
      drawn: false,
      partType: "",
      atstovas: false,
      currentDoc: document1,
      user1: "",
      user2: "",
      represUser: "",
      signed: false,
      refreshDocDisabled: true,
      savedDoc: true,
      numPages: null,
      divWidth: 0,
      user: JSON.parse(sessionStorage.getItem("user")),
    };

    this.signatureCanvasRef = React.createRef();
    this.divRef = React.createRef();
  }

  componentDidMount() {
    const storedData = JSON.parse(sessionStorage.getItem("registrationData"));
    if (window.location.pathname.includes("atstovas") && storedData) {
      const { regAtstovas1, regAtstovas2, regRepresented } = storedData;
      this.setState({
        user1: regAtstovas1,
        user2: regAtstovas2,
        represUser: regRepresented,
        partType: "atstovas",
        atstovas: true,
        currentDoc: document2,
      });
    } else {
      this.setState({
        partType: "dalyvis",
        user1: JSON.parse(sessionStorage.getItem("regUser")),
      });
    }

    if (sessionStorage.getItem("selectedParticipation") === "dalyvis") {
      this.setState({ dalyvis: true });
    } else {
      this.setState({ dalyvis: false });
    }

    this.updateDivWidth();
    window.addEventListener("resize", this.updateDivWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDivWidth);
  }

  updateDivWidth = () => {
    if (this.divRef.current) {
      this.setState({ divWidth: this.divRef.current.offsetWidth });
    }
  };

  handleSignature = () => {
    const signatureDataUrl = this.signatureCanvasRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    this.setState({ signatureDataUrl, signed: true });
  };

  clearSignature = () => {
    this.signatureCanvasRef.current.clear();
    this.setState({ signatureDataUrl: "", signed: false });
  };

  saveFileForUser = async (e) => {
    e.preventDefault();

    const res = this.state.atstovas
      ? await this.saveCheckMarksAtstovas(e)
      : await this.saveCheckMarks(e);
    if (!res) return;

    const generatedDoc = this.state.currentDoc;
    const { signatureDataUrl, partType, user1, user2, represUser } = this.state;
    const phoneNumber1 = document.getElementById("phonenumber").value;

    const response = await fetch(generatedDoc);
    if (!response.ok) {
      alert("Netinkamas failas");
      return;
    }
    const blob = await response.blob();

    const registerUser = async (user, role, docPrefix) => {
      try {
        await authService.register(user.email, user.password, user.role);
        // await authService.sendComfirmationEmail(user.email);
        const allUsers = await dalyvisService.getAllUsers();
        const registeredUser = allUsers.find((u) => u.email === user.email);
        const personalData = await dalyvisService.getAllPersonalData(
          registeredUser.id
        );

        Object.assign(personalData, {
          name: user.name,
          surname: user.surname,
          personalCode: user.personalCode,
          uniqueCode: user.uniqueCode,
          workplace: this.state.user.workplace,
          registerType: user.registerType,
          phoneNumber: phoneNumber1.trim(),
          firstTime: 1,
          activationState: false,
        });

        await dalyvisService.putCustomObjectPersonalData(
          personalData.id,
          personalData
        );
        const newRegUser = await dalyvisService.getAllPersonalData(
          personalData.id
        );
        // .then(() => {
        sessionStorage.setItem("regUser", JSON.stringify(newRegUser));
        // });

        const data = new FormData();
        data.append(
          "file",
          blob,
          `${docPrefix}_${user.name}_${user.surname}_${personalData.id}_sutikimas_LPRLB.pdf`
        );

        await dalyvisService.postFileUpload(data);

        return newRegUser;
      } catch (error) {
        console.error("Error during user registration:", error);
        // alert("Failed to register user. Please try again.");
      }
    };

    if (partType === "atstovas") {
      const newUser1 = await registerUser(user1, "representer", "Atstovo");
      const newUser2 = await registerUser(user2, "representer", "Atstovo");

      const newUserRepres = {
        ...represUser,
        workplace: this.state.user.workplace,
        representedPeople: user1.id,
      };
      await dalyvisService.postPersonalDataObject(newUserRepres);

      console.log(
        `Dalyviai užregistruoti. Elektroniniai paštas: ${user1.email}, ${user2.email}`
      );
      console.log(`Slaptažodžiai: ${user1.password}`);

      const registrationData = {
        regAtstovas1: newUser1,
        regAtstovas2: newUser2,
        regRepresented: newUserRepres,
      };

      console.log(registrationData);
      await this.updateSessionStorage("registrationData", registrationData);
      this.props.history.push("/darbuotojas/dalyviu-reg-atstovas/skenavimas");
      // this.updateSessionStorage(
      //   "registrationData",
      //   registrationData,
      //   "/darbuotojas/dalyviu-reg-atstovas/skenavimas"
      // );
    } else {
      const newUser = await registerUser(user1, "user", "Dalyvio");
      console.log(
        `Dalyvis užregistruotas. Elektroninis paštas: ${user1.email}`
      );
      console.log(`Slaptažodis: ${user1.password}`);
      console.log(newUser);
      // sessionStorage.setItem("regUser", JSON.stringify(newUser));
      const pathname = window.location.pathname;
      if(pathname === "/registracija/uploaddocument" ||
          pathname === "/registracija*/uploaddocument")
      {
        this.props.history.push("/login");
      }
      else
      {
        this.props.history.push("/darbuotojas/dalyviu-reg/skenavimas");
      }
      // this.updateSessionStorage(
      //   "regUser",
      //   user1,
      //   "/darbuotojas/dalyviu-reg/skenavimas"
      // );
    }
  };

  updateSessionStorage = async (key, value) => {
    await new Promise((resolve) => {
      sessionStorage.setItem(key, JSON.stringify(value));
      resolve();
    });
  };

  saveCheckMarks = async (e) => {
    e.preventDefault();
    if (!(await this.saveSignature(e))) return false;

    if (document.getElementById("phonenumber").value.length !== 11) {
      alert("Numeris neįvestas arba per trumpas");
      return false;
    }

    const response = await fetch(this.state.currentDoc);
    const blob = await response.blob();
    const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());

    const checkmark = await pdfdoc.embedPng(check);
    await checkmark.embed();

    const page1 = pdfdoc.getPage(0);
    const { width, height } = page1.getSize();
    const scale = 0.25;

    const checks = [
      { id: "check1", x: 103, y: 370 },
      { id: "check2", x: 103, y: 385 },
      { id: "check3", x: 103, y: 400 },
      { id: "check4", x: 103, y: 415 },
    ];

    checks.forEach(({ id, x, y }) => {
      const checkbox = document.querySelector(`input[name="${id}"]`);
      if (checkbox && checkbox.checked) {
        page1.drawImage(checkmark, {
          x,
          y: height - y - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      }
    });

    const radios = [
      { id: "radio1", x: 85, y1: 620, y2: 663 },
      { id: "radio2", x: 113, y1: 747, y2: 65 },
      { id: "radio3", x: 113, y1: 108, y2: 137 },
    ];

    radios.forEach(({ id, x, y1, y2 }) => {
      const radio = document.querySelector(`input[name="${id}"]:checked`);
      if (radio && radio.value === "true") {
        page1.drawImage(checkmark, {
          x,
          y: height - y1 - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      } else {
        page1.drawImage(checkmark, {
          x,
          y: height - y2 - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      }
    });

    pdfdoc.registerFontkit(fontkit);
    const customFontTimes = await fetch(TimesNewRoman).then((res) =>
      res.arrayBuffer()
    );
    const font = await pdfdoc.embedFont(customFontTimes);

    const user = this.state.user1;
    const fontSize = 12;
    const vardas = `${user.name} ${user.surname}`;
    const date = new Date().toLocaleDateString();

    const texts = [
      { text: vardas, x: 180, y: 590 },
      { text: "Savanorių prospektas 1", x: 180, y: 570 },
      { text: document.getElementById("phonenumber").value, x: 180, y: 550 },
      { text: user.email, x: 380, y: 530 },
      { text: date, x: 180, y: 510 },
      { text: vardas, x: 100, y: 350 },
      { text: date, x: 350, y: 350 },
    ];

    texts.forEach(({ text, x, y }) => {
      page1.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
    });

    const pdfBytes = await pdfdoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    this.setState({
      currentDoc: pdfUrl,
      refreshDocDisabled: false,
      savedDoc: true,
    });
    window.scrollTo(0, 0);
    return true;
  };

  saveCheckMarksAtstovas = async (e) => {
    e.preventDefault();
    if (!(await this.saveSignature(e))) return false;

    if (document.getElementById("phonenumber").value.length !== 11) {
      alert("Numeris neįvestas arba per trumpas");
      return false;
    }

    const response = await fetch(this.state.currentDoc);
    const blob = await response.blob();
    const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());

    const checkmark = await pdfdoc.embedPng(check);
    await checkmark.embed();

    const page1 = pdfdoc.getPage(0);
    const { width, height } = page1.getSize();
    const scale = 0.25;

    const checks = [
      { id: "check1", x: 103, y: 410 },
      { id: "check2", x: 103, y: 438 },
      { id: "check3", x: 103, y: 466 },
      { id: "check4", x: 103, y: 494 },
    ];

    checks.forEach(({ id, x, y }) => {
      const checkbox = document.querySelector(`input[name="${id}"]`);
      if (checkbox && checkbox.checked) {
        page1.drawImage(checkmark, {
          x,
          y: height - y - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      }
    });

    const radios = [
      { id: "radio1", x: 85, y1: 730, y2: 80, pageNum: 1 },
      { id: "radio2", x: 113, y1: 165, y2: 220, pageNum: 1 },
      { id: "radio3", x: 113, y1: 275, y2: 330, pageNum: 1 },
    ];

    radios.forEach(({ id, x, y1, y2, pageNum }) => {
      const radio = document.querySelector(`input[name="${id}"]:checked`);
      const page = pdfdoc.getPage(pageNum);
      if (radio && radio.value === "true") {
        page.drawImage(checkmark, {
          x,
          y: height - y1 - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      } else {
        page.drawImage(checkmark, {
          x,
          y: height - y2 - checkmark.height * scale,
          width: checkmark.width * scale,
          height: checkmark.height * scale,
        });
      }
    });

    pdfdoc.registerFontkit(fontkit);
    const customFontTimes = await fetch(TimesNewRoman).then((res) =>
      res.arrayBuffer()
    );
    const font = await pdfdoc.embedFont(customFontTimes);

    const user = this.state.user1;
    const fontSize = 12;
    const vardas = `${user.name} ${user.surname}`;
    const date = new Date().toLocaleDateString();

    const page2 = pdfdoc.getPage(2);
    const texts = [
      { text: vardas, x: 180, y: 365 },
      { text: "Savanorių prospektas 1", x: 180, y: 345 },
      { text: document.getElementById("phonenumber").value, x: 180, y: 325 },
      { text: user.email, x: 380, y: 305 },
      { text: date, x: 180, y: 285 },
      { text: vardas, x: 100, y: 610 },
      { text: date, x: 350, y: 610 },
    ];

    texts.forEach(({ text, x, y }) => {
      page2.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
    });

    const pdfBytes = await pdfdoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    this.setState({
      currentDoc: pdfUrl,
      refreshDocDisabled: false,
      savedDoc: true,
    });
    window.scrollTo(0, 0);
    return true;
  };

  saveSignature = async (e) => {
    e.preventDefault();
    const { signatureDataUrl } = this.state;
    if (!signatureDataUrl) {
      alert("Nepasirašyta");
      return false;
    }

    const response = await fetch(this.state.currentDoc);
    const blob = await response.blob();
    const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());

    const signatureImage = await pdfdoc.embedPng(signatureDataUrl);
    const scale = 0.5;

    let pageNum = 1;
    let position = { x: 400, y: 440 };
    let positionAdd = { x: 350, y: 310 };
    let positionAdd1 = {};
    if (this.state.atstovas) {
      pageNum = 2;
      position = { x: 400, y: 180 };
      positionAdd = { x: 400, y: 535 };
      positionAdd1 = { x: 400, y: 730 };
    }

    let page = pdfdoc.getPage(pageNum);
    const { height } = page.getSize();

    page.drawImage(signatureImage, {
      x: position.x,
      y: height - position.y - signatureImage.height * scale,
      width: signatureImage.width * scale,
      height: signatureImage.height * scale,
    });

    if (!this.state.atstovas) {
      page.drawImage(signatureImage, {
        x: positionAdd.x,
        y: height - positionAdd.y - signatureImage.height * 0.25,
        width: signatureImage.width * 0.25,
        height: signatureImage.height * 0.25,
      });
    } else {
      page = pdfdoc.getPage(pageNum - 1);
      page.drawImage(signatureImage, {
        x: positionAdd.x,
        y: height - positionAdd.y - signatureImage.height * 0.25,
        width: signatureImage.width * 0.25,
        height: signatureImage.height * 0.25,
      });
      page.drawImage(signatureImage, {
        x: positionAdd1.x,
        y: height - positionAdd1.y - signatureImage.height * 0.25,
        width: signatureImage.width * 0.25,
        height: signatureImage.height * 0.25,
      });
    }

    const pdfBytes = await pdfdoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // setTimeout(() => {
      this.setState({ currentDoc: pdfUrl, signed: true });
    // }, 0);

    return true;
  };

  checkEmpty = () => {
    const phonenumber = document.getElementById("phonenumber").value;
    const allItems = [phonenumber];
    let continueState = true;

    this.setState({
      refreshDocDisabled: true,
    });

    let quantity = 0;
    allItems.forEach((item) => {
      if (item === "" || item === null) {
        continueState = false;
        return;
      }
      quantity += 1;
    });

    if (quantity === allItems.length && continueState === true) {
      this.setState({
        refreshDocDisabled: false,
      });
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { numPages, currentDoc, savedDoc, atstovas, divWidth } = this.state;

    return (
      <Container>
        <Row className="justify-content-center mb-4">
          <Col md={10}>
            <div
              ref={this.divRef}
              style={{
                border: "1px solid #ccc",
                height: "600px",
                overflow: "auto",
              }}
            >
              <Document
                file={currentDoc}
                onLoadSuccess={this.onDocumentLoadSuccess}
                onLoadError={console.error}
                className="d-flex flex-column align-items-center"
                options={{
                  cMapUrl: "cmaps/",
                  cMapPacked: true,
                }}
              >
                {Array.from({ length: numPages }, (_, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={divWidth}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="my-0"
                  />
                ))}
              </Document>
            </div>
          </Col>
        </Row>

        <Form
          onInput={this.checkEmpty}
          onChange={this.checkEmpty}
          onSubmit={this.saveFileForUser}
        >
          {!atstovas ? (
            <>
              <Alert variant="info">
                Aš sutinku, kad būtų paimti šie ėminiai (tinkamą pažymėti),
                kurie ateityje bus panaudojami sveikatos moksliniuose tyrimuose:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check1"
                    label="Kraujo (40 ml)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check2"
                    label="Audinio (biopsija)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check3"
                    label="Šlapimo (50-100ml)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check4"
                    label="Audiniai/biopsijos medžiaga likusi po diagnostonių ar gydymo procedūrų"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš suprantu, kad galiu bet kada atšaukti savo SUTIKIMĄ
                nenurodydamas priežasčių ir motyvų, o norėdamas atšaukti
                sutikimą, privalau apie tai raštu informuoti Biobanką.
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true1"
                    name="radio1"
                    value="true"
                    label="Aš sutinku, kad Biobankas praneštų mano sveikatai svarbią informaciją, paaiškėjusią sveikatos mokslinių tyrimų metu"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false1"
                    name="radio1"
                    value="false"
                    defaultChecked
                    label="Aš nesutinku, kad Biobankas praneštų mano sveikatai svarbią informaciją, paaiškėjusią sveikatos mokslinių tyrimų metu"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
                jei prireiktų patikslinti mano sveikatos informaciją:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true2"
                    name="radio2"
                    value="true"
                    label="Sutinku"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false2"
                    name="radio2"
                    value="false"
                    defaultChecked
                    label="Nesutinku"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
                jei prireiktų papildomų biologinių mėginių:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true3"
                    name="radio3"
                    value="true"
                    label="Sutinku"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false3"
                    name="radio3"
                    value="false"
                    defaultChecked
                    label="Nesutinku"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Form.Group as={Row} className="mb-3" controlId="phonenumber">
                <Form.Label column sm="2">
                  Telefono numeris*
                </Form.Label>
                <Col sm="10">
                  <InputGroup>
                    <InputGroup.Text>+</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="37069999999"
                      maxLength={11}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>
            </>
          ) : (
            <>
              <Alert variant="info">
                Aš sutinku, kad būtų paimti šie mano vaiko/atsovaujamojo asmens
                ėminiai (tinkamą pažymėti), kurie ateityje bus panaudojami
                sveikatos moksliniuose tyrimuose:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check1"
                    label="Kraujo (40 ml)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check2"
                    label="Audinio (biopsija)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check3"
                    label="Šlapimo (50-100ml)"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="checkbox"
                    id="check4"
                    label="Audiniai/biopsijos medžiaga likusi po diagnostonių ar gydymo procedūrų"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš suprantu, kad galiu bet kada atšaukti savo SUTIKIMĄ
                nenurodydamas priežasčių ir motyvų, o norėdamas atšaukti
                sutikimą, privalau apie tai raštu informuoti Biobanką.
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true1"
                    name="radio1"
                    value="true"
                    label="Aš sutinku, kad Biobankas praneštų mano vaiko/atstovaujamojo asmens sveikatai svarbią informaciją, paaiškėjusią sveikatos mokslinių metu"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false1"
                    name="radio1"
                    value="false"
                    defaultChecked
                    label="Aš nesutinku, kad Biobankas praneštų mano vaiko/atstovaujamojo asmens sveikatai svarbią informaciją, paaiškėjusią sveikatos mokslinių tyrimų metu"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
                jei prireiktų patikslinti mano vaiko/atstovaujamojo asmens
                sveikatos informaciją:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true2"
                    name="radio2"
                    value="true"
                    label="Sutinku"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false2"
                    name="radio2"
                    value="false"
                    defaultChecked
                    label="Nesutinku"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Alert variant="info">
                Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
                jei prireiktų papildomų biologinių mėginių:
              </Alert>
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="true3"
                    name="radio3"
                    value="true"
                    label="Sutinku"
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Form.Check
                    type="radio"
                    id="false3"
                    name="radio3"
                    value="false"
                    defaultChecked
                    label="Nesutinku"
                  />
                </ListGroup.Item>
              </ListGroup>

              <Form.Group as={Row} className="mb-3" controlId="phonenumber">
                <Form.Label column sm="2">
                  Telefono numeris*
                </Form.Label>
                <Col sm="10">
                  <InputGroup>
                    <InputGroup.Text>+</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="37069999999"
                      maxLength={11}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>
            </>
          )}
          <Row className="justify-content-center mb-3">
            <Col md={6} className="text-center">
              <label>Parašas</label>
              <Button
                variant="secondary"
                onClick={this.clearSignature}
                className="ml-3"
              >
                Išvalyti
              </Button>
              <div className="d-flex justify-content-center mt-2">
                <SignatureCanvas
                  ref={this.signatureCanvasRef}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                    style: { border: "1px solid #000" },
                  }}
                  onEnd={this.handleSignature}
                  clearOnResize={false}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} className="text-center">
              <Button type="submit" disabled={!savedDoc}>
                {atstovas
                  ? "Perskaičiau Asmens informavimo formą ir sutinku, kad mano atstovaujamas asmuo dalyvautų biobanko veikloje"
                  : "Perskaičiau IASF ir sutinku dalyvauti biobanko veikloje"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default UploadDocument;

// import React, { Component } from "react";
// import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
// import { Document, Page, pdfjs } from "react-pdf";

// import axios from "axios";
// import document1 from "../../../docs/important/3_SUTIKIMAS_dalyviui_LPRLB.pdf";
// import document2 from "../../../docs/important/3_SUTIKIMAS_globotiniui_LPRLB.pdf";
// import check from "../../../docs/important/check.png";
// import Signature from "../../signature/signature";
// import SignaturePad from "signature_pad";
// import TimesNewRoman from "./times.ttf";
// import fontkit from "@pdf-lib/fontkit"; // import fontkit
// import dalyvisService from "../../../services/dalyvis-service";
// import authService from "../../../services/auth-service";
// // import SignaturePad from '../../signature/signatura-pad';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// class UploadDocument extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       doc: "Pasirinkite dokumentą*",
//       dalyvis: true,
//       canvas: "",
//       signaturePad: "",
//       image: "",
//       signaturePNG: "",
//       signaturePoints: "",
//       drawn: false,
//       partType: "", //sessionStorage.getItem("selectedParticipation"),
//       atstovas: false,
//       imageTest: "",

//       currentDoc: document1,
//       user1: "", //JSON.parse(sessionStorage.getItem("user")),
//       user2: "",
//       represUser: "",
//       signed: false,
//       refreshDocDisabled: true,
//       savedDoc: true,
//       numPages: null,
//       user: JSON.parse(sessionStorage.getItem("user")),
//     };
//   }
//   componentDidMount() {
//     if (
//       window.location.pathname ===
//       "/darbuotojas/dalyviu-reg-atstovas/uploaddocument"
//     ) {
//       const storedData = JSON.parse(sessionStorage.getItem("registrationData"));
//       if (storedData) {
//         const user1 = storedData.regAtstovas1;
//         const user2 = storedData.regAtstovas2;
//         const userRepres = storedData.regRepresented;

//         this.setState({
//           user1: user1,
//           user2: user2,
//           represUser: userRepres,
//           partType: "atstovas",
//           atstovas: true,
//           currentDoc: document2,
//         });
//       }
//     } else {
//       this.setState({
//         partType: "dalyvis",
//         user1: JSON.parse(sessionStorage.getItem("regUser")),
//       });
//     }

//     const canvas = document.querySelector("canvas");
//     const signaturePad = new SignaturePad(canvas);
//     this.setState({
//       signaturePad: signaturePad,
//       canvas: canvas,
//     });
//     var buttonNext = document.getElementById("checkPdf");
//     // buttonNext.addEventListener('click', (e) => this.saveSignature(e, signaturePad));
//     var buttonClear = document.getElementById("buttonclear");
//     buttonClear.addEventListener("click", (e) =>
//       this.clearSignature(e, signaturePad)
//     );
//     var canvas1 = document.getElementById("canvas");
//     canvas1.addEventListener("mousedown", (e) =>
//       this.onSignatureDraw(e, signaturePad)
//     );

//     if (sessionStorage.getItem("selectedParticipation") === "dalyvis") {
//       this.setState({
//         dalyvis: true,
//       });
//     } else {
//       this.setState({
//         dalyvis: false,
//       });
//     }
//     this.onSignatureDraw = this.onSignatureDraw.bind(this);
//   }

// saveFileForUser = async (e) => {
//   e.preventDefault();

//   var res;
//   if (this.state.atstovas) {
//     res = await this.saveCheckMarksAtstovas(e);
//   } else {
//     res = await this.saveCheckMarks(e);
//   }

//   if (!res) {
//     return;
//   }

//   var generatedDoc = this.state.currentDoc;
//   var signatureImage = this.state.signaturePNG;
//   var signaturePoints = this.state.signaturePoints;

//   var phoneNumber1 = document.getElementById("phonenumber").value;
//   // var userObjectUpdated1 = this.state.user1;
//   // userObjectUpdated1.phoneNumber = phoneNumber.trim();
//   // userObjectUpdated1.firstTime = 2;

//   const response = await fetch(generatedDoc);
//   if (!response.ok) {
//     window.alert("Netinkamas failas");
//     return;
//   }

//   const blob = await response.blob();

//   if (this.state.partType === "atstovas") {
//     authService
//       .register(
//         this.state.user1.email,
//         this.state.user1.password,
//         this.state.user1.role
//       )
//       .then(() => {
//         authService
//           .sendComfirmationEmail(this.state.user1.email)
//           .then(() => {
//             dalyvisService
//               .getAllUsers()
//               .then((response) => {
//                 console.log(response);

//                 const allUsers = response;

//                 if (allUsers !== null) {
//                   allUsers.forEach((user1) => {
//                     if (user1.email === this.state.user1.email) {
//                       dalyvisService
//                         .getAllPersonalData(user1.id)
//                         .then((response) => {
//                           console.log(response);
//                           var newUser1 = response;

//                           newUser1.name = this.state.user1.name;
//                           newUser1.surname = this.state.user1.surname;
//                           newUser1.personalCode =
//                             this.state.user1.personalCode;
//                           newUser1.uniqueCode = this.state.user1.uniqueCode;
//                           newUser1.workplace = this.state.user.workplace;
//                           newUser1.registerType =
//                             this.state.user1.registerType;
//                           newUser1.phoneNumber = phoneNumber1.trim();
//                           newUser1.firstTime = 1;
//                           newUser1.activationState = false;

//                           const data1 = new FormData();
//                           data1.append(
//                             "file",
//                             blob,
//                             "Atstovo_" +
//                               this.state.user1.name +
//                               "_" +
//                               this.state.user1.surname +
//                               "_" +
//                               newUser1.id +
//                               "_sutikimas_LPRLB.pdf"
//                           );

//                           dalyvisService
//                             .postFileUpload(data1)
//                             .then(() => {
//                               dalyvisService
//                                 .putCustomObjectPersonalData(
//                                   newUser1.id,
//                                   newUser1
//                                 )
//                                 .then((response) => {
//                                   authService
//                                     .register(
//                                       this.state.user2.email,
//                                       this.state.user2.password,
//                                       this.state.user2.role
//                                     )
//                                     .then(() => {
//                                       authService
//                                         .sendComfirmationEmail(
//                                           this.state.user2.email
//                                         )
//                                         .then(() => {
//                                           dalyvisService
//                                             .getAllUsers()
//                                             .then((response) => {
//                                               console.log(response);

//                                               const allUsers = response;

//                                               if (allUsers !== null) {
//                                                 allUsers.forEach((user2) => {
//                                                   if (
//                                                     user2.email ===
//                                                     this.state.user1.email
//                                                   ) {
//                                                     dalyvisService
//                                                       .getAllPersonalData(
//                                                         user2.id
//                                                       )
//                                                       .then((response) => {
//                                                         console.log(response);
//                                                         var newUser2 =
//                                                           response;

//                                                         newUser2.name =
//                                                           this.state.user2.name;
//                                                         newUser2.surname =
//                                                           this.state.user2.surname;
//                                                         newUser2.personalCode =
//                                                           this.state.user2.personalCode;
//                                                         newUser2.uniqueCode =
//                                                           this.state.user2.uniqueCode;
//                                                         newUser2.workplace =
//                                                           this.state.user.workplace;
//                                                         newUser2.registerType =
//                                                           this.state.user2.registerType;
//                                                         newUser2.phoneNumber =
//                                                           phoneNumber1.trim();
//                                                         newUser2.firstTime = 1;
//                                                         newUser2.activationState = false;

//                                                         var newUserRepres = {
//                                                           name: this.state
//                                                             .represUser.name,
//                                                           surname:
//                                                             this.state
//                                                               .represUser
//                                                               .surname,
//                                                           personalCode:
//                                                             this.state
//                                                               .represUser
//                                                               .personalCode,
//                                                           uniqueCode:
//                                                             this.state
//                                                               .represUser
//                                                               .uniqueCode,
//                                                           workplace:
//                                                             this.state.user
//                                                               .workplace,
//                                                           representedPeople:
//                                                             user1.id,
//                                                         };

//                                                         const data2 =
//                                                           new FormData();
//                                                         data2.append(
//                                                           "file",
//                                                           blob,
//                                                           "Atstovo_" +
//                                                             this.state.user2
//                                                               .name +
//                                                             "_" +
//                                                             this.state.user2
//                                                               .surname +
//                                                             "_" +
//                                                             newUser2.id +
//                                                             "_sutikimas_LPRLB.pdf"
//                                                         );

//                                                         dalyvisService
//                                                           .postFileUpload(
//                                                             data2
//                                                           )
//                                                           .then(() => {
//                                                             dalyvisService
//                                                               .postPersonalDataObject(
//                                                                 newUserRepres
//                                                               )
//                                                               .then(
//                                                                 (
//                                                                   response
//                                                                 ) => {
//                                                                   //newUser.representedPeople = response;

//                                                                   dalyvisService
//                                                                     .putCustomObjectPersonalData(
//                                                                       newUser2.id,
//                                                                       newUser2
//                                                                     )
//                                                                     .then(
//                                                                       () => {
//                                                                         window.alert(
//                                                                           "Dalyviai užregistruoti"
//                                                                         );
//                                                                         window.alert(
//                                                                           "Elektroniniai paštas: " +
//                                                                             this
//                                                                               .state
//                                                                               .user1
//                                                                               .email +
//                                                                             " " +
//                                                                             this
//                                                                               .state
//                                                                               .user2
//                                                                               .email
//                                                                         );
//                                                                         window.alert(
//                                                                           "Slaptažodiai: " +
//                                                                             this
//                                                                               .state
//                                                                               .user1
//                                                                               .password
//                                                                         );

//                                                                         const registrationData =
//                                                                           {
//                                                                             regAtstovas1:
//                                                                               newUser1,
//                                                                             regAtstovas2:
//                                                                               newUser2,
//                                                                             regRepresented:
//                                                                               newUserRepres,
//                                                                           };

//                                                                         sessionStorage.setItem(
//                                                                           "registrationData",
//                                                                           JSON.stringify(
//                                                                             registrationData
//                                                                           )
//                                                                         );
//                                                                         this.props.history.push(
//                                                                           "/darbuotojas/dalyviu-reg-atstovas/skenavimas"
//                                                                         );
//                                                                       }
//                                                                     )
//                                                                     .catch(
//                                                                       (
//                                                                         error
//                                                                       ) =>
//                                                                         window.alert(
//                                                                           error.message
//                                                                         )
//                                                                     );
//                                                                 }
//                                                               )
//                                                               .catch(
//                                                                 (error) =>
//                                                                   window.alert(
//                                                                     error.message
//                                                                   )
//                                                               );
//                                                           })
//                                                           .catch((error) =>
//                                                             window.alert(
//                                                               error.message
//                                                             )
//                                                           );
//                                                       })
//                                                       .catch((error) =>
//                                                         window.alert(
//                                                           error.message
//                                                         )
//                                                       );
//                                                   }
//                                                 });
//                                               }
//                                             })
//                                             .catch((error) =>
//                                               window.alert(error.message)
//                                             );
//                                         })
//                                         .catch((error) =>
//                                           window.alert(error.message)
//                                         );
//                                     })
//                                     .catch((error) =>
//                                       window.alert(error.message)
//                                     );
//                                 })
//                                 .catch((error) =>
//                                   window.alert(error.message)
//                                 );
//                             })
//                             .catch((error) => window.alert(error.message));
//                         })
//                         .catch((error) => window.alert(error.message));
//                     }
//                   });
//                 }
//               })
//               .catch((error) => window.alert(error.message));
//           })
//           .catch((error) => window.alert(error.message));
//       })
//       .catch((error) => window.alert(error.message));
//   } else {
//     authService
//       .register(
//         this.state.user1.email,
//         this.state.user1.password,
//         this.state.user1.role
//       )
//       .then(() => {
//         authService
//           .sendComfirmationEmail(this.state.user1.email)
//           .then(() => {
//             dalyvisService
//               .getAllUsers()
//               .then((response) => {
//                 console.log(response);

//                 const allUsers = response;

//                 if (allUsers !== null) {
//                   allUsers.forEach((user) => {
//                     if (user.email === this.state.user1.email) {
//                       dalyvisService
//                         .getAllPersonalData(user.id)
//                         .then((response) => {
//                           console.log(response);
//                           var newUser = response;

//                           newUser.name = this.state.user1.name;
//                           newUser.surname = this.state.user1.surname;
//                           newUser.personalCode =
//                             this.state.user1.personalCode;
//                           newUser.uniqueCode = this.state.user1.uniqueCode;
//                           newUser.workplace = this.state.user.workplace;
//                           newUser.registerType =
//                             this.state.user1.registerType;
//                           newUser.phoneNumber = phoneNumber1.trim();
//                           newUser.firstTime = 1;
//                           newUser.activationState = false;

//                           // dalyvisService
//                           //   .putCustomObjectPersonalData(newUser.id, newUser)
//                           //   .then(() => {

//                           const data1 = new FormData();
//                           data1.append(
//                             "file",
//                             blob,
//                             "Dalyvio_" +
//                               this.state.user1.name +
//                               "_" +
//                               this.state.user1.surname +
//                               "_" +
//                               newUser.id +
//                               "_sutikimas_LPRLB.pdf"
//                           );

//                           dalyvisService
//                             .postFileUpload(data1)
//                             .then(() => {
//                               dalyvisService
//                                 .putCustomObjectPersonalData(
//                                   newUser.id,
//                                   newUser
//                                 )
//                                 .then((response) => {
//                                   // window.alert("Duomenys sėkmingai išsiųsti");

//                                   // sessionStorage.setItem("user", JSON.stringify(response));
//                                   // console.log(sessionStorage.getItem("user"));
//                                   // this.props.history.push("/dalyvis/manoprofilis");
//                                   window.alert("Dalyvis užregistruotas");
//                                   window.alert(
//                                     "Elektroninis paštas: " +
//                                       this.state.user1.email
//                                   );
//                                   window.alert(
//                                     "Slaptažodis: " +
//                                       this.state.user1.password
//                                   );

//                                   sessionStorage.setItem(
//                                     "regUser",
//                                     JSON.stringify(newUser)
//                                   );

//                                   this.props.history.push(
//                                     "/darbuotojas/dalyviu-reg/skenavimas"
//                                   );
//                                 })
//                                 .catch((error) =>
//                                   window.alert(error.message)
//                                 );
//                             })
//                             .catch((error) => window.alert(error.message));
//                           // })
//                           // .catch((error) => window.alert(error.message));
//                         })
//                         .catch((error) => window.alert(error.message));
//                     }
//                   });
//                 }
//               })
//               .catch((error) => window.alert(error.message));
//           })
//           .catch((error) => window.alert(error.message));
//       })
//       .catch((error) => window.alert(error.message));
//   }
//   // window.alert(doc);
//   // data.append('file', this.state.currentDoc);
// };

//   changeDocText(e) {
//     e.preventDefault();
//     var fileName = document
//       .getElementById("myFile")
//       .value.split("\\")
//       .pop()
//       .split("/")
//       .pop();
//     this.setState({
//       doc: fileName,
//     });
//     document.getElementById("button1").disabled = false;
//   }

//   async saveSignatureAtstovas(e, signaturePad) {
//     e.preventDefault();
//     if (!signaturePad.isEmpty()) {
//       await this.resetDoc();
//       // var pdfdoc;
//       // if (this.state.dalyvis) {
//       const response = await fetch(this.state.currentDoc);
//       const blob = await response.blob();
//       const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());
//       // }
//       // else {

//       //     const response = await fetch(document2);
//       //     const blob = await response.blob();
//       //     pdfdoc = await PDFDocument.load(await blob.arrayBuffer());
//       // }
//       var pageNum = 1;
//       var position = { x: 400, y: 440 };
//       var positionAdd = { x: 350, y: 310 };
//       if (this.state.atstovas) {
//         pageNum = 2;
//         position = { x: 400, y: 180 };
//         positionAdd = { x: 400, y: 440 };
//       }

//       const page = pdfdoc.getPage(pageNum);

//       const { width, height } = page.getSize();

//       const scale = 0.5;

//       const signaturePNG = signaturePad.toDataURL();
//       const signatureImage = await pdfdoc.embedPng(signaturePNG);
//       await signatureImage.embed();

//       const signaturePoints = signaturePad.toData();

//       page.drawImage(signatureImage, {
//         x: position.x,
//         y: height - position.y - signatureImage.height * scale,
//         width: signatureImage.width * scale,
//         height: signatureImage.height * scale,
//       });

//       // const { width1, height1 } = page.getSize();
//       // const position1 = { x: 800, y: 800};
//       // const scale1 = 0.25;
//       page.drawImage(signatureImage, {
//         x: positionAdd.x,
//         y: height - positionAdd.y - signatureImage.height * 0.25,
//         width: signatureImage.width * 0.25,
//         height: signatureImage.height * 0.25,
//       });

//       sessionStorage.setItem("signaturePNG", signatureImage);
//       sessionStorage.setItem("signaturePoints", signaturePoints);
//       this.setState({
//         signaturePNG: signatureImage,
//         signaturePoints: signaturePoints,
//       });
//       const pdfBytes = await pdfdoc.save();
//       const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" }); // pdf dokumentas su parašu
//       // signaturePNG - pats parašo paveikslėlis/
//       // signaturePoints - taškai kuriu atžvilgiu parašas įvyko
//       // Dokumentas turi būti išsaugotas duombazėj - docfileDTO
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       this.setState({
//         currentDoc: pdfUrl,
//         signed: true,
//       });
//       // window.open(pdfUrl);
//       // window.alert("Sėkminga");
//       // this.props.history.push("/dalyvavimas/infoforma");
//     } else {
//       window.alert("Nepasirašyta");
//     }
//   }
//   async saveCheckMarks(e) {
//     e.preventDefault();

//     var res;
//     res = await this.saveSignature(e, this.state.signaturePad);

//     if (!res) {
//       return false;
//     }

//     if (
//       this.state.signed &&
//       document.getElementById("phonenumber").value.length === 11
//     ) {
//       const response = await fetch(this.state.currentDoc);
//       const blob = await response.blob();
//       const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());

//       const checkmark = await pdfdoc.embedPng(check);
//       await checkmark.embed();

//       var pageNum1 = 0;

//       const page1 = pdfdoc.getPage(pageNum1);

//       var position11 = { x: 103, y: 370 };
//       var position12 = { x: 103, y: 385 };
//       var position13 = { x: 103, y: 400 };
//       var position14 = { x: 103, y: 415 };

//       const { width, height } = page1.getSize();

//       const scale = 0.25;
//       if (document.querySelector('input[name="check1"]').checked) {
//         page1.drawImage(checkmark, {
//           x: position11.x,
//           y: height - position11.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check2"]').checked) {
//         page1.drawImage(checkmark, {
//           x: position12.x,
//           y: height - position12.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check3"]').checked) {
//         page1.drawImage(checkmark, {
//           x: position13.x,
//           y: height - position13.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check4"]').checked) {
//         page1.drawImage(checkmark, {
//           x: position14.x,
//           y: height - position14.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position21 = { x: 85, y: 620 };
//       var position22 = { x: 85, y: 663 };

//       if (
//         document.querySelector('input[name="radio1"]:checked').value === "true"
//       ) {
//         page1.drawImage(checkmark, {
//           x: position21.x,
//           y: height - position21.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         page1.drawImage(checkmark, {
//           x: position22.x,
//           y: height - position22.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }

//       var position31 = { x: 113, y: 747 };
//       var position32 = { x: 113, y: 65 };
//       pageNum1 = 1;
//       const page2 = pdfdoc.getPage(pageNum1);
//       if (
//         document.querySelector('input[name="radio2"]:checked').value === "true"
//       ) {
//         page1.drawImage(checkmark, {
//           x: position31.x,
//           y: height - position31.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         page2.drawImage(checkmark, {
//           x: position32.x,
//           y: height - position32.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position41 = { x: 113, y: 108 };
//       var position42 = { x: 113, y: 137 };
//       if (
//         document.querySelector('input[name="radio3"]:checked').value === "true"
//       ) {
//         page2.drawImage(checkmark, {
//           x: position41.x,
//           y: height - position41.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         page2.drawImage(checkmark, {
//           x: position42.x,
//           y: height - position42.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position51 = { x: 180, y: 590 };
//       var position52 = { x: 180, y: 570 };
//       var position53 = { x: 180, y: 550 };
//       var position54 = { x: 180, y: 530 };
//       var position55 = { x: 380, y: 530 };
//       var position56 = { x: 180, y: 510 };

//       pdfdoc.registerFontkit(fontkit);
//       const customFontTimes = await fetch(TimesNewRoman).then((res) =>
//         res.arrayBuffer()
//       );
//       // console.log(typeof customFontTimes)
//       const font = await pdfdoc.embedFont(customFontTimes);
//       var user = this.state.user1;
//       const fontSize = 12;
//       const vardas = user.name + " " + user.surname;

//       page2.drawText(vardas, {
//         x: position51.x,
//         y: position51.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const adresas = "Savanorių prospektas 1";
//       page2.drawText(adresas, {
//         x: position52.x,
//         y: position52.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       // const asmens = user.personalCode;
//       // page2.drawText(asmens, {
//       //   x: position53.x,
//       //   y: position53.y,
//       //   size: fontSize,
//       //   font: font,
//       //   color: rgb(0, 0, 0),
//       // });

//       const telefonas = document.getElementById("phonenumber").value;
//       page2.drawText(telefonas, {
//         x: position54.x,
//         y: position54.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const elpastas = user.email;
//       page2.drawText(elpastas, {
//         x: position55.x,
//         y: position55.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const date = new Date();
//       const dateString = date.toLocaleDateString();
//       page2.drawText(dateString, {
//         x: position56.x,
//         y: position56.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//       var position61 = { x: 100, y: 350 };
//       var position62 = { x: 350, y: 350 };
//       page2.drawText(vardas, {
//         x: position61.x,
//         y: position61.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//       page2.drawText(dateString, {
//         x: position62.x,
//         y: position62.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const pdfBytes = await pdfdoc.save();
//       const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" }); // pdf dokumentas su parašu
//       // signaturePNG - pats parašo paveikslėlis/
//       // signaturePoints - taškai kuriu atžvilgiu parašas įvyko
//       // Dokumentas turi būti išsaugotas duombazėj - docfileDTO
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       this.setState({
//         currentDoc: pdfUrl,
//         refreshDocDisabled: false,
//         savedDoc: true,
//       });
//       window.scrollTo(0, 0);

//       return true;
//     } else {
//       window.alert("Numeris neįvestas arba per trumpas");
//       return false;
//     }
//   }
//   async saveCheckMarksAtstovas(e) {
//     e.preventDefault();

//     var res;
//     res = await this.saveSignature(e, this.state.signaturePad);

//     if (!res) {
//       return false;
//     }

//     if (
//       this.state.signed &&
//       document.getElementById("phonenumber").value.length === 11
//     ) {
//       const response = await fetch(this.state.currentDoc);
//       const blob = await response.blob();
//       const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());

//       const checkmark = await pdfdoc.embedPng(check);
//       await checkmark.embed();

//       var pageNum1 = 0;

//       var page = pdfdoc.getPage(pageNum1);

//       var position11 = { x: 103, y: 410 };
//       var position12 = { x: 103, y: 438 };
//       var position13 = { x: 103, y: 466 };
//       var position14 = { x: 103, y: 494 };

//       const { width, height } = page.getSize();

//       const scale = 0.25;
//       if (document.querySelector('input[name="check1"]').checked) {
//         page.drawImage(checkmark, {
//           x: position11.x,
//           y: height - position11.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check2"]').checked) {
//         page.drawImage(checkmark, {
//           x: position12.x,
//           y: height - position12.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check3"]').checked) {
//         page.drawImage(checkmark, {
//           x: position13.x,
//           y: height - position13.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       if (document.querySelector('input[name="check4"]').checked) {
//         page.drawImage(checkmark, {
//           x: position14.x,
//           y: height - position14.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position21 = { x: 85, y: 730 };
//       var position22 = { x: 85, y: 80 };

//       if (
//         document.querySelector('input[name="radio1"]:checked').value === "true"
//       ) {
//         page.drawImage(checkmark, {
//           x: position21.x,
//           y: height - position21.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         pageNum1 = pageNum1 + 1;
//         page = pdfdoc.getPage(pageNum1);
//         page.drawImage(checkmark, {
//           x: position22.x,
//           y: height - position22.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }

//       var position31 = { x: 113, y: 165 };
//       var position32 = { x: 113, y: 220 };
//       pageNum1 = 1;
//       page = pdfdoc.getPage(pageNum1);
//       if (
//         document.querySelector('input[name="radio2"]:checked').value === "true"
//       ) {
//         page.drawImage(checkmark, {
//           x: position31.x,
//           y: height - position31.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         page.drawImage(checkmark, {
//           x: position32.x,
//           y: height - position32.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position41 = { x: 113, y: 275 };
//       var position42 = { x: 113, y: 330 };
//       if (
//         document.querySelector('input[name="radio3"]:checked').value === "true"
//       ) {
//         page.drawImage(checkmark, {
//           x: position41.x,
//           y: height - position41.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       } else {
//         page.drawImage(checkmark, {
//           x: position42.x,
//           y: height - position42.y - checkmark.height * scale,
//           width: checkmark.width * scale,
//           height: checkmark.height * scale,
//         });
//       }
//       var position51 = { x: 180, y: 365 };
//       var position52 = { x: 180, y: 345 };
//       var position53 = { x: 180, y: 325 };
//       var position54 = { x: 180, y: 305 };
//       var position55 = { x: 380, y: 305 };
//       var position56 = { x: 180, y: 285 };

//       // var position51 = { x: 180, y: 390 };
//       // var position52 = { x: 180, y: 370 };
//       // var position53 = { x: 180, y: 350 };
//       // var position54 = { x: 180, y: 330 };
//       // var position55 = { x: 380, y: 330 };
//       // var position56 = { x: 180, y: 310 };

//       pdfdoc.registerFontkit(fontkit);
//       const customFontTimes = await fetch(TimesNewRoman).then((res) =>
//         res.arrayBuffer()
//       );
//       // console.log(typeof customFontTimes)
//       const font = await pdfdoc.embedFont(customFontTimes);
//       var user = this.state.user1;
//       const fontSize = 12;
//       const vardas = user.name + " " + user.surname;

//       page.drawText(vardas, {
//         x: position51.x,
//         y: position51.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const adresas = "Savanorių prospektas 1";
//       page.drawText(adresas, {
//         x: position52.x,
//         y: position52.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       // const asmens = user.personalCode;
//       // page.drawText(asmens, {
//       //   x: position53.x,
//       //   y: position53.y,
//       //   size: fontSize,
//       //   font: font,
//       //   color: rgb(0, 0, 0),
//       // });

//       const telefonas = document.getElementById("phonenumber").value;
//       page.drawText(telefonas, {
//         x: position54.x,
//         y: position54.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const elpastas = user.email;
//       page.drawText(elpastas, {
//         x: position55.x,
//         y: position55.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const date = new Date();
//       const dateString = date.toLocaleDateString();
//       page.drawText(dateString, {
//         x: position56.x,
//         y: position56.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       pageNum1 = 2;
//       page = pdfdoc.getPage(pageNum1);
//       var position61 = { x: 100, y: 610 };
//       var position62 = { x: 350, y: 610 };
//       // var position61 = { x: 100, y: 350 };
//       // var position62 = { x: 350, y: 350 };
//       page.drawText(vardas, {
//         x: position61.x,
//         y: position61.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//       page.drawText(dateString, {
//         x: position62.x,
//         y: position62.y,
//         size: fontSize,
//         font: font,
//         color: rgb(0, 0, 0),
//       });

//       const pdfBytes = await pdfdoc.save();
//       const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" }); // pdf dokumentas su parašu
//       // signaturePNG - pats parašo paveikslėlis/
//       // signaturePoints - taškai kuriu atžvilgiu parašas įvyko
//       // Dokumentas turi būti išsaugotas duombazėj - docfileDTO
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       this.setState({
//         currentDoc: pdfUrl,
//         refreshDocDisabled: false,
//         savedDoc: true,
//       });
//       window.scrollTo(0, 0);

//       return true;
//     } else {
//       window.alert("Numeris neįvestas arba per trumpas");
//       return false;
//     }
//   }
//   async saveSignature(e, signaturePad) {
//     e.preventDefault();
//     if (!signaturePad.isEmpty()) {
//       await this.resetDoc();
//       // var pdfdoc;
//       // if (this.state.dalyvis) {
//       const response = await fetch(this.state.currentDoc);
//       const blob = await response.blob();
//       const pdfdoc = await PDFDocument.load(await blob.arrayBuffer());
//       // }
//       // else {

//       //     const response = await fetch(document2);
//       //     const blob = await response.blob();
//       //     pdfdoc = await PDFDocument.load(await blob.arrayBuffer());
//       // }
//       var pageNum = 1;
//       var position = { x: 400, y: 440 };
//       var positionAdd = { x: 350, y: 310 };
//       var positionAdd1 = {};
//       if (this.state.atstovas) {
//         pageNum = 2;
//         position = { x: 400, y: 180 };
//         positionAdd = { x: 400, y: 535 };
//         positionAdd1 = { x: 400, y: 730 };
//       }

//       var page = pdfdoc.getPage(pageNum);

//       const { width, height } = page.getSize();

//       const scale = 0.5;

//       const signaturePNG = signaturePad.toDataURL();
//       const signatureImage = await pdfdoc.embedPng(signaturePNG);
//       await signatureImage.embed();

//       const signaturePoints = signaturePad.toData();

//       page.drawImage(signatureImage, {
//         x: position.x,
//         y: height - position.y - signatureImage.height * scale,
//         width: signatureImage.width * scale,
//         height: signatureImage.height * scale,
//       });

//       // const { width1, height1 } = page.getSize();
//       // const position1 = { x: 800, y: 800};
//       // const scale1 = 0.25;
//       if (!this.state.atstovas) {
//         page.drawImage(signatureImage, {
//           x: positionAdd.x,
//           y: height - positionAdd.y - signatureImage.height * 0.25,
//           width: signatureImage.width * 0.25,
//           height: signatureImage.height * 0.25,
//         });
//       } else {
//         page = pdfdoc.getPage(pageNum - 1);
//         page.drawImage(signatureImage, {
//           x: positionAdd.x,
//           y: height - positionAdd.y - signatureImage.height * 0.25,
//           width: signatureImage.width * 0.25,
//           height: signatureImage.height * 0.25,
//         });
//         page.drawImage(signatureImage, {
//           x: positionAdd1.x,
//           y: height - positionAdd1.y - signatureImage.height * 0.25,
//           width: signatureImage.width * 0.25,
//           height: signatureImage.height * 0.25,
//         });
//       }

//       sessionStorage.setItem("signaturePNG", signatureImage);
//       sessionStorage.setItem("signaturePoints", signaturePoints);
//       this.setState({
//         signaturePNG: signatureImage,
//         signaturePoints: signaturePoints,
//       });
//       const pdfBytes = await pdfdoc.save();
//       const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" }); // pdf dokumentas su parašu
//       // signaturePNG - pats parašo paveikslėlis/
//       // signaturePoints - taškai kuriu atžvilgiu parašas įvyko
//       // Dokumentas turi būti išsaugotas duombazėj - docfileDTO
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       this.setState({
//         currentDoc: pdfUrl,
//         signed: true,
//       });
//       // window.open(pdfUrl);
//       // window.alert("Sėkminga");
//       // this.props.history.push("/dalyvavimas/infoforma");
//       return true;
//     } else {
//       window.alert("Nepasirašyta");
//       return false;
//     }
//   }

//   resetDoc() {
//     if (this.state.atstovas) {
//       this.setState({
//         currentDoc: document2,
//       });
//       return;
//     }
//     this.setState({
//       currentDoc: document1,
//     });
//   }

//   onSignatureDraw(e, signaturePad) {
//     // console.log("drawing");
//     e.preventDefault();
//     if (!signaturePad.isEmpty()) {
//       this.setState({
//         drawn: true,
//       });
//       document.getElementById("button1").disabled = false;
//     }
//   }

//   clearSignature(e, signaturePad) {
//     e.preventDefault();
//     signaturePad.clear();
//     this.resetDoc();
//   }
//   checkEmpty() {
//     var phonenumber = document.getElementById("phonenumber").value;
//     var userType = sessionStorage.getItem("selectedParticipation");
//     var allItemsRepres = [];

//     var allItems = [phonenumber]; // name, surname, personalcode,,password1,password2
//     var continueState = true;

//     this.setState({
//       refreshDocDisabled: true,
//     });
//     var quantity = 0;
//     var quantityRepres = 0;
//     for (let i = 0; i < allItems.length; i++) {
//       if (allItems[i] === "" || allItems[i] === null) {
//         continueState = false;

//         break;
//       }

//       quantity += 1;
//     }
//     if (userType === "atstovas") {
//       for (let i = 0; i < allItemsRepres.length; i++) {
//         if (allItemsRepres[i] === "" || allItemsRepres[i] === null) {
//           continueState = true;

//           break;
//         }

//         quantityRepres += 1;
//       }
//     }

//     if (quantity === allItems.length && continueState === true) {
//       this.setState({
//         refreshDocDisabled: false,
//       });
//     }
//   }
//   async submitForm(e) {
//     e.preventDefault();

//     var signature = this.state.signaturePNG;
//     var signaturePoints = this.state.signaturePoints;
//     const data = new FormData();

//     dalyvisService
//       .postFileUpload()
//       .catch((error) => window.alert(error.message));
//     var phoneNumber = document.getElementById("phonenumber").value;
//     var userType = sessionStorage.getItem("selectedParticipation");
//     var allItemsRepres = [];

//     var allItems = [phoneNumber]; //name, surname, personalcode, ,password1,password2
//     var continueState = true;

//     for (let i = 0; i < allItems.length; i++) {
//       console.log("loop1");
//       if (allItems[i] === "") {
//         continueState = false;
//         window.alert("Kai kurie langeliai yra nepilnai užpildyti!");
//         break;
//       }
//       continueState = true;
//     }
//     if (userType === "atstovas") {
//       for (let i = 0; i < allItemsRepres.length; i++) {
//         console.log("loop2");
//         if (allItemsRepres[i] === "" || allItemsRepres[i] === null) {
//           continueState = false;
//           window.alert("Kai kurie langeliai yra nepilnai užpildyti!");
//           break;
//         }
//         continueState = true;
//       }
//     }
//     if (phoneNumber.length !== 11) {
//       window.alert("Įveskite pilną telefono numerį");
//       return;
//     }
//     if (continueState === true) {
//       var user = this.state.user1;
//       var newElements = { phoneNumber: phoneNumber.trim(), firstTime: 2 };
//       var userObjectUpdated = { ...user, ...newElements };
//       dalyvisService
//         .putCustomObjectPersonalData(this.state.user1.id, userObjectUpdated)
//         .catch((error) => window.alert(error.message));

//       if (this.state.registerType) {
//         this.props.history.push("/dalyvis/pagrindinis");
//       } else {
//         this.props.history.push("/dalyvis/pagrindinis");
//       }

//       window.alert("Duomenys sėkmingai išsiųsti");
//     }
//   }

//   onDocumentLoadSuccess = ({ numPages }) => {
//     this.setState({ numPages });
//   };

//   render() {
//     const { numPages } = this.state;

//     return (
//       <>
//         {/* this.state.dalyvis ? document1 : document2 */}
//         {/* <embed
//           src={this.state.currentDoc}
//           type="application/pdf"
//           width="100%"
//           height="600px"
//         /> */}
//         <div className="d-flex justify-content-center">
//           <div
//             style={{
//               width: "100%",
//               overflow: "auto",
//               height: "80vh",
//               border: "5px solid #78003f",
//             }}
//           >
//             <Document
//               file={this.state.currentDoc}
//               onLoadSuccess={this.onDocumentLoadSuccess}
//               className="d-flex flex-column align-items-center"
//               options={{
//                 cMapUrl: "cmaps/",
//                 cMapPacked: true,
//               }}
//             >
//               {Array.from({ length: numPages }, (_, index) => (
//                 <Page
//                   key={`page_${index + 1}`}
//                   pageNumber={index + 1}
//                   width={window.innerWidth * 0.7}
//                   renderTextLayer={false}
//                   renderAnnotationLayer={false}
//                   className="my-0"
//                 />
//               ))}
//             </Document>
//           </div>
//         </div>

//         {!this.state.atstovas ? (
//           <>
//             <form
//               // onSubmit={(e) => this.saveFileForUser(e)}
//               className="col-centered doc-p-style"
//               onInput={(event) => this.checkEmpty(event)}
//               onChange={(event) => this.checkEmpty(event)}
//             >
//               <p>
//                 Aš sutinku, kad būtų paimti šie ėminiai (<i>tinkamą pažymėti</i>
//                 ), kurie ateityje bus panaudojami sveikatos moksliniuose
//                 tyrimuose:
//               </p>
//               <ul
//                 className="checkbox-style"
//                 style={{ listStyleType: "none", marginRight: "25px" }}
//               >
//                 <li>
//                   <input type="checkbox" id="check1" name="check1" />
//                   <label htmlFor="scales">Kraujo (40 ml)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check2" name="check2" />
//                   <label htmlFor="horns">Audinio (biopsija)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check3" name="check3" />
//                   <label htmlFor="horns">Šlapimo (50-100ml)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check4" name="check4" />
//                   <label htmlFor="horns">
//                     Audiniai/biopsijos medžiaga likusi po diagnostonių ar gydymo
//                     procedūrų
//                   </label>
//                 </li>
//               </ul>

//               <p>
//                 Aš suprantu, kad galiu bet kada atšaukti savo SUTIKIMĄ
//                 nenurodydamas priežasčių ir motyvų, o norėdamas atšaukti
//                 sutikimą, privalau apie tai raštu informuoti Biobanką.
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="radio" id="true1" name="radio1" value="true" />
//                   <label for="true1">
//                     Aš sutinku, kad Biobankas praneštų mano sveikatai svarbią
//                     informaciją, paaiškėjusią sveikatos mokslinių tyrimų metu
//                   </label>
//                 </li>
//                 <li>
//                   {" "}
//                   <input
//                     type="radio"
//                     id="false1"
//                     name="radio1"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false1">
//                     Aš nesutinku, kad Biobankas praneštų mano sveikatai svarbią
//                     informaciją, paaiškėjusią sveikatos mokslinių tyrimų metu{" "}
//                   </label>
//                 </li>
//               </ul>

//               <p>
//                 Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
//                 jei prireiktų patikslinti mano sveikatos informaciją:
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="radio" id="true2" name="radio2" value="true" />
//                   <label for="true2">Sutinku</label>
//                 </li>
//                 <li>
//                   <input
//                     type="radio"
//                     id="false2"
//                     name="radio2"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false2">Nesutinku</label>
//                 </li>
//               </ul>

//               <p>
//                 Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
//                 jei prireiktų papildomų biologinių mėginių:
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="radio" id="true3" name="radio3" value="true" />
//                   <label for="true3">Sutinku</label>
//                 </li>
//                 <li>
//                   <input
//                     type="radio"
//                     id="false3"
//                     name="radio3"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false3">Nesutinku</label>
//                 </li>
//               </ul>

//               <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
//                 <label>Telefono numeris*</label>

//                 <div className="input-group mb-1" style={{}}>
//                   <div className="input-group-prepend">
//                     <div className="input-group-text">+</div>
//                   </div>
//                   <input
//                     type="text"
//                     id="phonenumber"
//                     title="Įvęskite telefono numerį be simbolių"
//                     className="form-control"
//                     placeholder="37069999999"
//                     maxLength={11}
//                   />
//                   <div style={{}}></div>
//                 </div>
//               </div>
//             </form>

//             {/* <button
//               id="checkPdf"
//               className="btn"
//               onClick={(event) => this.saveCheckMarks(event)}
//             >
//               Įrašyti pakeitimus dalyviui
//             </button>
//             <button
//               className="btn"
//               onClick={(e) => this.resetDoc(e)}
//               disabled={this.state.refreshDocDisabled}
//             >
//               Ištrinti dokumento pakeitimus
//             </button> */}
//           </>
//         ) : (
//           <>
//             <form
//               // onSubmit={(e) => this.saveFileForUser(e)}
//               className="col-centered doc-p-style"
//               onInput={(event) => this.checkEmpty(event)}
//               onChange={(event) => this.checkEmpty(event)}
//             >
//               <p>
//                 Aš sutinku, kad būtų paimti šie mano vaiko/atsovaujamojo asmens
//                 ėminiai (<i>tinkamą pažymėti</i>
//                 ), kurie ateityje bus panaudojami sveikatos moksliniuose
//                 tyrimuose:
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="checkbox" id="check1" name="check1" />
//                   <label htmlFor="scales">Kraujo (40 ml)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check2" name="check2" />
//                   <label htmlFor="horns">Audinio (biopsija)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check3" name="check3" />
//                   <label htmlFor="horns">Šlapimo (50-100ml)</label>
//                 </li>
//                 <li>
//                   <input type="checkbox" id="check4" name="check4" />
//                   <label htmlFor="horns">
//                     Audiniai/biopsijos medžiaga likusi po diagnostonių ar gydymo
//                     procedūrų
//                   </label>
//                 </li>
//               </ul>

//               <p>
//                 Aš suprantu, kad galiu bet kada atšaukti savo SUTIKIMĄ
//                 nenurodydamas priežasčių ir motyvų, o norėdamas atšaukti
//                 sutikimą, privalau apie tai raštu informuoti Biobanką.
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="radio" id="true1" name="radio1" value="true" />
//                   <label for="true1">
//                     Aš sutinku, kad Biobankas praneštų mano vaiko/atstovaujamojo
//                     asmens sveikatai svarbią informaciją, paaiškėjusią sveikatos
//                     mokslinių metu
//                   </label>
//                 </li>
//                 <li>
//                   <input
//                     type="radio"
//                     id="false1"
//                     name="radio1"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false1">
//                     Aš nesutinku, kad Biobankas praneštų mano
//                     vaiko/atstovaujamojo asmens sveikatai svarbią informaciją,
//                     paaiškėjusią sveikatos mokslinių tyrimų metu{" "}
//                   </label>
//                 </li>
//               </ul>

//               <p>
//                 Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
//                 jei prireiktų patikslinti mano vaiko/atstovaujamojo asmens
//                 sveikatos informaciją:
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   {" "}
//                   <input type="radio" id="true2" name="radio2" value="true" />
//                   <label for="true2">Sutinku</label>
//                 </li>
//                 <li>
//                   {" "}
//                   <input
//                     type="radio"
//                     id="false2"
//                     name="radio2"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false2">Nesutinku</label>
//                 </li>
//               </ul>

//               <p>
//                 Aš sutinku, kad su manimi būtų pakartotinai susisiekta ateityje,
//                 jei prireiktų papildomų biologinių mėginių:
//               </p>
//               <ul className="checkbox-style">
//                 <li>
//                   <input type="radio" id="true3" name="radio3" value="true" />
//                   <label for="true3">Sutinku</label>
//                 </li>
//                 <li>
//                   <input
//                     type="radio"
//                     id="false3"
//                     name="radio3"
//                     value="false"
//                     defaultChecked
//                   />
//                   <label for="false3">Nesutinku</label>
//                 </li>
//               </ul>

//               <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>
//                 <label>Telefono numeris*</label>

//                 <div className="input-group mb-1" style={{}}>
//                   <div className="input-group-prepend">
//                     <div className="input-group-text">+</div>
//                   </div>
//                   <input
//                     type="text"
//                     id="phonenumber"
//                     title="Įvęskite telefono numerį be simbolių"
//                     className="form-control"
//                     placeholder="37069999999"
//                     minLength={11}
//                     maxLength={11}
//                   />
//                   <div style={{}}></div>
//                 </div>
//               </div>
//             </form>

//             {/* <button
//               id="checkPdf"
//               className="btn"
//               onClick={(event) => this.saveCheckMarksAtstovas(event)}
//             >
//               Įrašyti pakeitimus atstovui
//             </button>

//             <button
//               className="btn"
//               onClick={(e) => this.resetDoc(e)}
//               disabled={this.state.refreshDocDisabled}
//             >
//               Ištrinti dokumento pakeitimus
//             </button> */}
//           </>
//         )}
//         <form
//           className=""
//           style={{ margin: "0 auto" }}
//           onSubmit={this.saveFileForUser}
//         >
//           {/* <a className="btn col-lg-12" href={this.state.dalyvis ? document1 : document2} download style={{ marginTop: "25px" }}>Atsisiųsti sutikimo formą</a>
//                 <label className="btn col-lg-12" style={{ marginTop: "25px" }}>
//                     <input className="" type="file" id="myFile" accept="application/pdf,image/png, image/gif, image/jpeg" onInput={(event) => this.changeDocText(event)} />
//                     {this.state.doc}
//                 </label> */}
//           <div className="">
//             {/* <button id="buttonsave">Save</button> */}
//             <div
//               className="d-flex justify-content-center"
//               style={{ margin: "20px 20px" }}
//             >
//               <label style={{}}>Parašas</label>&nbsp;{" "}
//               <button className="btn" id="buttonclear">
//                 Išvalyti
//               </button>
//             </div>
//             {/* onChange={(e) => this.onSignatureDraw(e, this.state.signaturePad)} */}
//             {/* <div className="d-flex justify-content-center">
//               <canvas
//                 id="canvas"
//                 style={{ border: "solid", borderWidth: "2px" }}
//               ></canvas>
//             </div> */}
//             <Signature></Signature>
//             {/* onChange={(e) => this.onSignatureDraw(e, this.state.signaturePad)} */}
//           </div>
//           {/* <SignaturePad/> */}

//           {/* {this.state.atstovas ? (
//             <div className="mt-4 text-center">
//               <button
//                 id="checkPdf"
//                 className="btn"
//                 onClick={(event) => this.saveCheckMarksAtstovas(event)}
//               >
//                 Įrašyti pakeitimus atstovui
//               </button>
//             </div>
//           ) : (
//             <div className="mt-4 text-center">
//               <button
//                 id="checkPdf"
//                 className="btn"
//                 onClick={(event) => this.saveCheckMarks(event)}
//               >
//                 Įrašyti pakeitimus dalyviui
//               </button>
//             </div>
//           )} */}

//           {this.state.atstovas ? (
//             <>
//               <button
//                 id="button1"
//                 className="btn col-9"
//                 type="submit"
//                 disabled={!this.state.savedDoc}
//                 style={{
//                   display: "block",
//                   margin: "0 auto",
//                   marginTop: "10px",
//                   marginBottom: "20px",
//                 }}
//               >
//                 Perskaičiau Asmens informavimo formą ir sutinku, kad mano
//                 atstovaujamas asmuo dalyvautų biobanko veikloje
//               </button>
//             </>
//           ) : (
//             <>
//               <button
//                 id="button1"
//                 className="btn col-9"
//                 type="submit"
//                 disabled={!this.state.savedDoc}
//                 style={{
//                   display: "block",
//                   margin: "0 auto",
//                   marginTop: "10px",
//                   marginBottom: "20px",
//                 }}
//               >
//                 Perskaičiau IASF ir sutinku dalyvauti biobanko veikloje
//               </button>
//             </>
//           )}
//           {/* <a
//             href="/nedalyvis"
//             className="btn col-9"
//             style={{
//               display: "block",
//               margin: "0 auto",
//               marginTop: "10px",
//               marginBottom: "20px",
//             }}
//           >
//             Nedalyvausiu
//           </a> */}
//           {/* <img src={this.state.imageTest} alt="test image"></img> */}
//         </form>
//       </>
//     );
//   }
// }

// export default UploadDocument;

// import React, { Component } from "react";
// import { PDFDocument } from "pdf-lib";
// import document1 from "../../../docs/important/3_SUTIKIMAS_dalyviui_LPRLB.pdf";
// import document2 from "../../../docs/important/3_SUTIKIMAS_globotiniui_LPRLB.pdf";
// import Signature from "../../signature/signature";

// class UploadDocument extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentDoc: document1,
//       participantType: "participant",
//       signatureImage: null,
//       phoneNumber: "",
//       isSigned: false,
//     };
//   }

//   componentDidMount() {
//     // Setup the initial state based on the route
//     const participantType = window.location.pathname.includes("atstovas")
//       ? "representative"
//       : "participant";
//     this.setState({
//       currentDoc: participantType === "representative" ? document2 : document1,
//       participantType,
//     });
//   }

//   handleSignature = (signatureDataUrl) => {
//     this.setState({ signatureImage: signatureDataUrl, isSigned: true });
//   };

//   saveDocument = async () => {
//     if (!this.state.signatureImage) {
//       alert("Please provide a signature before saving the document.");
//       return;
//     }

//     // Load the PDF document
//     const existingPdfBytes = await fetch(this.state.currentDoc).then((res) =>
//       res.arrayBuffer()
//     );
//     const pdfDoc = await PDFDocument.load(existingPdfBytes);

//     // Embed the signature image
//     const signatureImage = await pdfDoc.embedPng(this.state.signatureImage);
//     const pages = pdfDoc.getPages();
//     const firstPage = pages[0];
//     firstPage.drawImage(signatureImage, {
//       x: 50,
//       y: 50,
//       width: 200,
//       height: 100,
//     });

//     // Save the modified PDF
//     const pdfBytes = await pdfDoc.save();
//     const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     this.setState({ currentDoc: pdfUrl });

//     // Here you might want to call a service to save the document
//     console.log("Document saved and updated!");
//   };

//   render() {
//     const { currentDoc, isSigned } = this.state;

//     return (
//       <div>
//         <iframe
//           src={currentDoc}
//           width="100%"
//           height="600px"
//           title="Preview Document"
//         ></iframe>

//         <Signature onSave={this.handleSignature} />

//         <button onClick={this.saveDocument} disabled={!isSigned}>
//           Save Document
//         </button>
//       </div>
//     );
//   }
// }

// export default UploadDocument;
