import React, { Component } from 'react';
import { Route } from "react-router-dom";

import UserService from "../../services/user.service-galimaspanaudojimas";
import EventBus from "../../common/EventBus";

//subpages
import Messages from './mini-admin-subpages/messages'
import Applications from './adminas-subpages/tyreju-info-subpages/applications';
import Forms from './institucijos-subpages/perdavimoformos'


class MiniAdminForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: "",
      error: "",
      isAllowed: true,
      qrCodeShow: false,
      result: 'Nenuskenuota',
      loading: false,
      filenamequest: "Pasirinkite dokumentą",
      filenameatsisakymo: "Pasirinkite dokumentą",
      signedDoc: "",
      currentAtstovas: "Nepasirinktas (Pasirinkti apačioje)"
    };

  }


  componentDidMount() {


    //   setTimeout(() => {   
    //   UserService.getWorkerBoard().then(

    //     response => {

    //       this.setState({
    //         isAllowed: true,
    //         loading: false
    //       });
    //     },
    //     error => {
    //       this.setState({
    //         error:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString(),
    //         isAllowed: true,
    //         loading: false
    //       });


    //       if (error.response && error.response.status === 401) {
    //         EventBus.dispatch("logout");
    //       }
    //       else if(error.message === "Network Error"){
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );

    // }, 500);
  }

  saveFileForUser() {
    if (localStorage.getItem("user") != null) {
      const imagefile = document.getElementById('myFile');
      var fileName = document.getElementById('myFile').value.split('\\').pop().split('/').pop();
      var user = localStorage.getItem("user");
      var userId = JSON.parse(user).id;
      var newestData1 = JSON.parse(localStorage.getItem("data"));

      var newestData = JSON.parse(localStorage.getItem("data"));
      newestData.docs.push({ "userid": userId, "filename": fileName })
      localStorage.setItem("data", JSON.stringify(newestData));
      var newestData = localStorage.getItem("data");
      window.alert("Failas sėkmingai pateiktas " + fileName);
      console.log(newestData.docs);

      var firsttime = JSON.parse(localStorage.getItem("firsttime"));
      firsttime.push({ "userid": userId, "first": "false" });
      localStorage.setItem("firsttime", JSON.stringify(firsttime));
      // this.props.history.push("/dalyvis");
      // window.location.reload();
    }



  }
  changeButtonWord(id) {
    var filename = document.getElementById(id).value.split('\\').pop().split('/').pop();
    //document.getElementById("filelabelquest").innerHTML = '<input className="" type="file" id="myFile" onChange={this.changeButtonWord}//>'+filename;
    //this.state.filenamequest = filename;
    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    }
    else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }





  render() {
    const isAllowed = this.state.isAllowed;
    const error = this.state.error;
    const loading = this.state.loading;
    const pathname = window.location.pathname;

    return (<div>
      {isAllowed ? (

        <section className="news-single section margin-nav">
          <div className="">
            <div className="row">
              <div className="col-lg-1 col-md-2 col-12">
              </div>
              <div className="col-lg-2 col-md-8 col-12">
                {/* -------------- */}
                <div className="">
                  <div className="position-button size" align="center" style={{ marginTop: "30px" }}>
                    {/* <a href={`${this.props.match.path}/manoprofilis`} className="btn">Mano profilis </a> */}

                    <a href={`${this.props.match.path}/tyrejuinfo`} className={pathname === "/mini-admin/tyrejuinfo" || pathname === "/mini-admin*/tyrejuinfo" ? "btn active-sidenav-btn" : "btn"}>Tyrėjų informacija</a>
                    <a href={`${this.props.match.path}/perdavimo-formos`} className={pathname === "/administratorius/perdavimo-formos" || pathname === "/administratorius*/perdavimo-formos" ? "btn active-sidenav-btn" : "btn"}>Perdavimo formos</a>

                  </div>
                </div>
                {/* ------------------ */}
              </div>
              <div className="col-lg-8 col-md-12 col-12" style={{ minHeight: "500px" }}>
                <div className="row">

                  <div className="comments-form">
                    {/* <Route path={`${this.props.match.path}/manoprofilis`} component={Profile} /> */}
                    <Route path={`${this.props.match.path}/tyrejuinfo`} component={Applications} />
                    <Route path={`${this.props.match.path}/perdavimo-formos`} component={Forms} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>)
        : (loading ?
          <div className="d-flex justify-content-center" style={{}}>
            <div className="spinner-grow d-flex justify-content-center" style={{ width: "20rem", height: "20rem", marginTop: "80px", marginBottom: "80px", color: "#78003f" }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          : <section className="error-page section ">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  {/* Error Inner */}
                  <div className="error-inner">
                    {/* &nbsp;{error} */}
                    <h1><span>&nbsp;{error} Prieiga neleista</span></h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                    <br />
                  </div>
                  {/*/ End Error Inner */}
                </div>
              </div>
            </div>
          </section>
        )
      }
    </div>);
  }
}
export default MiniAdminForm;