import React from 'react';



class Contact extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
         
        };
        
        
    }
    componentDidMount(){
        //document.getElementById("name").value = "test1";
        //document.getElementById("surname").value = "test1";
        //document.getElementById("email").value = "test1@gmail.com";
    }
    submitMessage(e){
      e.preventDefault();
      var topic = document.getElementById("topic");
      var message = document.getElementById("message");
      if(topic.value === "" || topic.value === null || message.value === "" || message.value === null){
        window.alert("Žinutės informacija nepilnai užpildyta!")
      }
      else{
      window.alert("Sėkmingai susisiekta!");
      //i bakenda nusiusti
      window.location.reload();
    }
    }

    checkEmpty(e){
      e.preventDefault();
      var topic = document.getElementById("topic").value;
      var message = document.getElementById("message").value;
      var quantity = 0;
      var continueState = true;
      var allItems = [topic,message];
      //leidimas.value, ,applicationpdf,additionalinfo
      for(let i = 0; i < allItems.length; i++){
        
        if(allItems[i] === "" || allItems[i] === null){
          continueState = false;
          document.getElementById("submitButton").disabled = true;
          break;
        }
        quantity+=1;
        
      }
      
      if(quantity === allItems.length && continueState=== true){
       
        document.getElementById("submitButton").disabled = false;
      }
     
     
    }

    


    render() { 
        const messages = [{topic: "Žinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
        {topic: "Žinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
        {topic: "Žinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
        {topic: "Žinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " }
    ];
    


        
        return <>
                  
                  
       
                 
                <div className="col-centered col-lg-10 col-md-12 col-12">
                {/* <h2 style={{marginTop: "50px",marginBottom: "25px",fontSize: "2rem"}}>Susisiekti su Biobanku</h2> */}
                      
                <form onInput={(event) => this.checkEmpty(event)} onChange={(event) => this.checkEmpty(event)} onSubmit={(event) => this.submitMessage(event)}>
                <h2 className='thick' style={{marginTop :"25px"}}>Žinutė</h2>
                      <div className="row">
                    {/* <div className="col-lg-4 col-md-4" style={{marginTop: "25px"}}>
                    <label>Vardas</label>
                    <div className="input-group mb-2">
                      <input type="text" id="name"  readOnly className="form-control" placeholder="Vardenis"/>
                      <div style={{}}>
                     
                      </div>
                      </div>
                    </div> */}
                   
                    {/* <div className="col-lg-4 col-md-4" style={{marginTop: "25px"}}>
                      <label>Pavardė</label>
                      <div className="input-group mb-2">
                      <input type="text" id="surname"  readOnly className="form-control" placeholder="Pavardenis"/>
                      <div style={{}}>
                     
                      </div>
                      </div>
                    </div> */}

                    {/* <div className="col-lg-4 col-md-4" style={{marginTop: "25px"}}>
                      <label>Elektroninis paštas</label>
                      <div className="input-group mb-2">
                      <input type="email" id="email"  readOnly className="form-control" placeholder="elektroninis@pastas.com"/>
                      <div style={{}}>
                     
                      </div>
                      </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12" style={{marginTop: "25px"}}>
                      {/* <label>Tema</label> */}
                      <div className="input-group mb-2">
                      <input type="text" id="topic" className="form-control" placeholder="Tema"/>
                      <div style={{}}>
                     
                      </div>
                      </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="form-group message">
                        <i className="fa fa-pencil" />
                        <textarea name="message" id="message" rows={7} placeholder="Įrašyti žinutę čia... " defaultValue={""} />
                        </div>
                    </div>
                    
                
              </div>

              <div className="col-12">
                          <div className="form-group button">	
                            <button  id="submitButton" type="submit" className="btn" disabled><i className="fa fa-send" />Pateikti</button>
                    </div>
                  </div>
            </form>
              </div>
                </>;
    }
}
 
export default Contact;