import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class Licencija extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Licencija</h2>

                    <ul className="listStyleText">
                      <li>Valstybinės akreditavimo sveikatos priežiūros veiklai tarnybos išduota <a href="/dokumentas" className="hrefStyle">licencija</a> Biobanko veiklai vykdyti.</li>
                    </ul>



                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default Licencija;