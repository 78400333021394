import * as XLSX from "xlsx";

export function parseQuestionnaire(file, callback) {
  const reader = new FileReader();
  reader.onload = function (event) {
    const data = new Uint8Array(event.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

    const questionnaire = parseData(json);
    callback(questionnaire);
  };
  reader.readAsArrayBuffer(file);
}

function parseData(data) {
  const questionnaire = [];

  const splitRows = (data) => data?.split("\n").map((answer) => answer.replace("\r", ""));

  const parseAnswers = (answerRows) =>
      answerRows?.reduce((answers, answer) => {
        const [number, ...textParts] = answer.split(" ");
        answers[number] = textParts.join(" ");
        return answers;
      }, {});

  for (let i = 1; i < data.length; i++) {
    const [id, question, answers, type, rules] = data[i];

    questionnaire.push({
      id,
      ...(question && { question }),
      ...(type && { type }),
      ...(answers && { answers: parseAnswers(splitRows(answers)) }),
      ...(rules && { rules: splitRows(rules) }),
    });
  }

  return questionnaire;
}