import React from "react";
import dalyvisService from "../../../services/dalyvis-service";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
    };
  }
  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;
    this.setState({
      date: today,
    });
  }
  submitMessage(e) {
    e.preventDefault();
    var topic = document.getElementById("topic");
    var message = document.getElementById("message");
    if (
      topic.value === "" ||
      topic.value === null ||
      message.value === "" ||
      message.value === null
    ) {
      window.alert("Žinutės informacija nepilnai užpildyta!");
    } else {
      //i bakenda nusiusti
      var userObjectUpdated = JSON.parse(sessionStorage.getItem("user"));
      console.log(userObjectUpdated);

      const messageSend = {
        messageText: message.value.trim(),
        messageState: 1,
        messageType: 1,
        messageTopic: topic.value.trim(),
        eventDate: new Date(),
      };

      dalyvisService
        .postMessage(messageSend)
        .then((response) => {
          userObjectUpdated.message.push(response);
          console.log(userObjectUpdated);

          dalyvisService
            .putCustomObjectPersonalData(
              userObjectUpdated.id,
              userObjectUpdated
            )
            .then((response) => {
              console.log(response);
              dalyvisService
                .getAllPersonalData(userObjectUpdated.id)
                .then((response) => {
                  window.alert("Sėkmingai susisiekta!");
                  sessionStorage.setItem("user", JSON.stringify(response));
                  window.location.reload();
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));
      // this.props.history.push("./pranesimai/pranesimai3");
      // postMessages(messageText,messageTopic, messageType,messageState, addedAt)
      // window.location.reload();
    }
  }

  checkEmpty(e) {
    e.preventDefault();
    var topic = document.getElementById("topic").value;
    var message = document.getElementById("message").value;
    var quantity = 0;
    var continueState = true;
    var allItems = [topic, message];
    //leidimas.value, ,applicationpdf,additionalinfo
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;
        document.getElementById("submitButton").disabled = true;
        break;
      }
      quantity += 1;
    }

    if (quantity === allItems.length && continueState === true) {
      document.getElementById("submitButton").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="col-centered col-lg-10 col-md-12 col-12">
          {/* <h2 style={{marginTop: "50px",marginBottom: "25px",fontSize: "2rem"}}>Susisiekti su Biobanku</h2> */}

          <form
            onInput={(event) => this.checkEmpty(event)}
            onChange={(event) => this.checkEmpty(event)}
            onSubmit={(event) => this.submitMessage(event)}
          >
            <table className="table">
              <thead className="thead-dark">
                {/* <tr>
                      
                        <th scope="col">Skiltis</th>
                        <th scope="col">Įvedama informacija</th>
                       
                      </tr> */}
              </thead>
              <tbody>
                <tr>
                  {/* <th scope="row">1</th> */}
                  <th scope="row">Data</th>
                  <td>
                    <input
                      type="text"
                      id="date"
                      name="projectname"
                      style={{ width: "100%" }}
                      readOnly
                      value={this.state.date}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tema*</th>
                  <td>
                    <input
                      type="text"
                      id="topic"
                      name="projectacronym"
                      style={{ width: "100%" }}
                      placeholder="Tema..."
                    />
                  </td>
                </tr>
                <tr>
                  <th>Tekstas*</th>
                  <td>
                    <textarea
                      id="message"
                      style={{ height: "5rem" }}
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-12">
              <div className="form-group button">
                <button
                  id="submitButton"
                  type="submit"
                  className="btn"
                  disabled
                >
                  <i className="fa fa-send" />
                  Pateikti
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default Contact;
