import React from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import './CalendarComponent.css'; // Optional for additional styling

const CalendarComponent = ({ availableDates, onDateSelect }) => {
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const formattedDate = date.toISOString().split('T')[0];
            return !availableDates.includes(formattedDate);
        }
        return false;
    };

    return (
        // <Calendar
        //     tileDisabled={tileDisabled}
        //     onClickDay={onDateSelect}
        // />
        <></>
    );
};

export default CalendarComponent;