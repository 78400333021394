import React from 'react';
import PartSideNav from '../../partsidenav.jsx'

class KaipTapti extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''
      
    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 991
        });
    }, false); 
}
    render() { 
        const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';
        
        return <div>
            
                
                {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}
                
                
              
        <div className="container-fluid" >
       
          <div className={mobileornot} style={{marginTop:"25px", marginBottom: "50px"}}>
            <PartSideNav/>
            <div className="col-lg-9 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                      <div className="row mx-1">
                      <h2 className="thick">Kaip tapti dalyviu?	  </h2>

                        <h2 className="thick" style={{fontSize: "18px"}}>Jeigu jūs nuspręsite dalyvauti Bobanko veikloje, prašysime jūsų:</h2>
                        <ul className="listStyleText" style={{textAlign: "justify"}}>
                          <li>Biobanko veikloje kviečiame dalyvauti bet kurį Lietuvoje gyvenantį asmenį, nepriklausomai nuo jo sveikatos būklės. 
Jaunesni nei 18 metų asmenys dalyvauti Biobanko veikloje gali tik sutikus tėvams ar globėjams. 
Dalyvavimas Biobanko veikloje yra savanoriškas ir galimas tik pasirašius sutikimą. 
Jei sutiksite dalyvauti Biobanko veikloje: bus sukurta Jūsų asmeninė paskyra Biobanke, kurioje Jūs galėsite sužinoti, 
kokie moksliniai tyrimai vyksta, kuriuose moksliniuose tyrimuose yra/buvo naudojami Jūsų biologiniai mėginiai ir sveikatos informacija, 
gauti žinutę apie mokslinių tyrimų metu gautą sveikatai svarbią informaciją.</li>
                        </ul>
                        <ol className="listStyleText">
                          {/* <li>Registruotis<i style={{color: "red"}}><a className="hrefStyle" href="/register"> Biobanko dalyvio paskyroje </a></i></li>    */}
                          <li>Perskaityti<i style={{color: "red"}}><a download className="hrefStyle" href="#"> Asmens informavimo formą (IAF)</a></i> ir pasirašyti
                          <i style={{color: "red"}}><a className="hrefStyle" href="#"> Informuoto asmens sutikimo formą (IASF) </a></i></li>  
                          <li>Atsakyti į <i style={{color: "red"}}><a className="hrefStyle" href="/klausimynas"> epidemiologinio klausimyno  </a></i> klausimus apie Jūsų kilmę, įpročius, gyvenimo aplinką.</li>  
                          <li>Atvykti į Biobanką ar nacionalinį kraujo centrą biologinių mėginių paėmimo procedūrai.</li>                        
                        </ol>
                        
                      </div>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
     
      
        </div>;
    }
}
 
export default KaipTapti;