import React from "react";
import tyrejasService from "../../../../services/tyrejas-service";
import dalyvisService from "../../../../services/dalyvis-service";

var sortValueGlobal = "newdate";

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      filterValue: "all",
      sortValue: "newdate",
      administrator: "",
      checkupType: "",
      checkupState: "",
      showType: false,
      openedApplicationId: "",
      checkUpButtonDisabled: true,
      user: sessionStorage.getItem("user"),
      adminChecks: {},
      considerChecks: {},
      lastStageChecks: {},
      examData: {},
      checksShow: {},
      sampleFormAdd: {},
      guaranteeFilesShow: {},
      maxCheckup1: {},
      maxCheckup2: {},
      maxCheckup3: {},
      showCurrentApplication: {},
      examinationInfos: tyrejasService.getAllExaminationInfos().then((data) => {
        sessionStorage.setItem("examinationinfos", JSON.stringify(data));
        return data;
      }),

      applications: "",
      loading: true,
      checkupPDFFile: "",
      checkupPDFFileName: "",
    };

    //this.fetchData();
  }

  async fetchData() {
    const applications = await tyrejasService.getAllApplications();
    this.setState({
      applications: applications,
      loading: false,
    });
    sessionStorage.setItem("applications", JSON.stringify(applications));
  }
  componentDidMount() {
    tyrejasService
      .getAllApplications()
      .then((response) => {
        sessionStorage.setItem("applications", JSON.stringify(response));
        // sessionStorage.setItem("applications", JSON.stringify(response.data));
        this.setState({
          applications: response,
          loading: false,
        });
        // Construct dynamic state variables with default values
        const dynamicStatesAdmin = {};
        const dynamicStatesConsider = {};
        const dynamicStatesLastStage = {};
        const dynamicStatesChecksShow = {};
        const dynamicStatesSampleFormAdd = {};
        const dynamicStatesMaxCheckup1 = {};
        const dynamicStatesMaxCheckup2 = {};
        const dynamicStatesMaxCheckup3 = {};
        const dynamicStatesGuaranteeFiles = {};
        const dynamicStatesCurrentApplication = {};

        response.forEach((data) => {
          dynamicStatesCurrentApplication["display" + data.id] = "none";
          if (
            this.getCurrentApplicationId() !== null &&
            this.getCurrentApplicationId() === "application" + data.id
          ) {
            dynamicStatesCurrentApplication["display" + data.id] = "block";
          }
          dynamicStatesChecksShow["table" + data.id] = false;
          // applicationsList.push(data);

          if (data.checkup.length !== null) {
            if (data.checkup.length > 0) {
              dynamicStatesChecksShow["table" + data.id] = true;
            }
          }
          dynamicStatesAdmin["admincheck" + data.id] = false;
          // dynamicStatesAdmin["admincheck" + data.id] = true;
          if (data.administerState !== "1" && data.administerState !== "0") {
            dynamicStatesAdmin["admincheck" + data.id] = true;
          }
          dynamicStatesConsider["considercheck" + data.id] = true;
          if (data.administerState === "2") {
            dynamicStatesConsider["considercheck" + data.id] = false;
          }
          dynamicStatesLastStage["laststagecheck" + data.id] = true;
          if (data.administerState === "3") {
            dynamicStatesLastStage["laststagecheck" + data.id] = false;
          }
          dynamicStatesSampleFormAdd["formadd" + data.id] = false;

          if (data.administerState === "4") {
            dynamicStatesSampleFormAdd["formadd" + data.id] = true;
          }

          dynamicStatesGuaranteeFiles["guaranteefile" + data.id] = false;
          if (
            data.guaranteeFileLink !== null &&
            data.guaranteeFileLink !== "" &&
            data.guaranteeFileLink !== undefined
          ) {
            dynamicStatesGuaranteeFiles["guaranteefile" + data.id] = true;
          }

          dynamicStatesMaxCheckup1["maxcheckup" + data.id] = [];
          dynamicStatesMaxCheckup2["maxcheckup" + data.id] = [];
          dynamicStatesMaxCheckup3["maxcheckup" + data.id] = [];
          // this.getMaxCheckup(data.id, 1, "maxcheckup" + data.id);
          // this.getMaxCheckup(data.id, 2, "maxcheckup" + data.id);
          // this.getMaxCheckup(data.id, 3, "maxcheckup" + data.id);
        });

        // Update the state with dynamic state variables and default values
        this.setState({
          adminChecks: dynamicStatesAdmin,
          considerChecks: dynamicStatesConsider,
          lastStageChecks: dynamicStatesLastStage,
          checksShow: dynamicStatesChecksShow,
          sampleFormAdd: dynamicStatesSampleFormAdd,
          guaranteeFilesShow: dynamicStatesGuaranteeFiles,
          maxCheckup1: dynamicStatesMaxCheckup1,
          maxCheckup2: dynamicStatesMaxCheckup2,
          maxCheckup3: dynamicStatesMaxCheckup3,
          showCurrentApplication: dynamicStatesCurrentApplication,
        });
        response.forEach((data) => {
          this.getMaxCheckup(data.id, 1, "maxcheckup" + data.id);
          this.getMaxCheckup(data.id, 2, "maxcheckup" + data.id);
          this.getMaxCheckup(data.id, 3, "maxcheckup" + data.id);
        });
        for (let i = 0; i < response.length; i++) {
          this.setState({
            [response[i].id + "bioeticdoc"]: "Pasirinkite dokumentą",
          });
        }
      })
      .catch((error) => window.alert(error.message));
  }

  closeRecentApplication() {
    if (this.state.openedApplicationId !== "") {
      document.getElementById(this.state.openedApplicationId).style.visibility =
        "hidden";
      document.getElementById(this.state.openedApplicationId).style.display =
        "none";
      document.getElementById(this.state.openedApplicationId).style.maxHeight =
        "0";
    }
  }

  showInfo(id, applicationId) {
    this.saveCurrentApplicationId(id);
    var value = document.getElementById(id);

    if (
      this.state.showCurrentApplication["display" + applicationId] === "block"
    ) {
      this.setState((prevState) => ({
        showCurrentApplication: {
          ...prevState.showCurrentApplication,
          ["display" + applicationId]: "none",
        },
      }));
    } else if (
      this.state.showCurrentApplication["display" + applicationId] === "none"
    ) {
      this.setState((prevState) => ({
        showCurrentApplication: {
          ...prevState.showCurrentApplication,
          ["display" + applicationId]: "block",
        },
      }));
    }
    //window.location.reload();
  }

  changeDocText(e, state, file) {
    e.preventDefault();

    var value = document.getElementById(file).value;
    if (value !== "" || value !== null) {
      var fileName = value.split("\\").pop().split("/").pop();
      this.setState({
        [state]: fileName,
      });
    } else {
      this.setState({
        [state]: "Pasirinkite dokumentą",
      });
    }
  }

  filterItemsSearch = () => {
    var searchTermInside = document.getElementById("searchData").value;
    this.setState({ searchTerm: searchTermInside });
  };
  filterItems = (e) => {
    e.preventDefault();
    var filterValue1 = document.getElementById("filter-selection").value;
    this.setState({ filterValue: filterValue1 });
    document.getElementById("searchData").value = "";
    this.setState({ searchTerm: "" });
  };
  sortItems = (e) => {
    e.preventDefault();
    var sortValue = document.getElementById("sort-selection").value;

    this.setState({ sortValue: sortValue });
    sortValueGlobal = sortValue;
  };

  setShowButton = (e, dataid) => {
    e.preventDefault();
    var value = document.getElementById(dataid);

    if (value.style.display === "none") {
      value.style.display = "block";
      value.style.visibility = "visible";
    } else if (value.style.display === "block") {
      value.style.display = "none";
      value.style.visibility = "hidden";
    }
  };
  setAdministrationState = (e, buttonid, applicationid) => {
    e.preventDefault();

    if (
      document.getElementById(buttonid).textContent === "Administruoti" &&
      window.confirm("Ar tikrai norite administruoti?")
    ) {
      document.getElementById(buttonid).textContent = "Atsisakyti";
      //Turi būti priskirtas prie tam tikro userio kaip administruojančio asmens
      this.setState({ administrator: "Administratorius: " });
      //addAdministratorStateToApplication
    } else {
      document.getElementById(buttonid).textContent = "Administruoti";
      this.setState({ administrator: "" });
      //removeAdministratorStateFromApplication
    }
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   // Only update if the props or state we care about have changed
  //   if (nextProps.arrayOfData !== this.props.arrayOfData) {
  //     return true;
  //   }
  //   return false;
  // }
  enableCheckUpForm() {}
  setAdministered(id) {
    tyrejasService
      .getApplications(id)
      .then((response) => {
        var status = { administerState: "1", applicationStatus: 2 };
        var applicationObjectUpdated = { ...response, ...status };
        tyrejasService.putApplicationsObject(id, applicationObjectUpdated);
      })
      .catch((error) => window.alert(error.message));

    //
  }
  confirmCheckupSend(e, application) {
    e.preventDefault();

    var state = sessionStorage.getItem("state");
    var type = sessionStorage.getItem("type");
    var filename = this.state.checkupPDFFileName;
    if (
      Number(type) === 2 &&
      (filename === null || filename === "" || filename === undefined)
    ) {
      window.alert("Failas nepasirinktas");
      return;
    }
    var object = {
      checkupState: state,
      requirementsState: type,
      addedAt: new Date(),
    };
    if (Number(type) === 2) {
      object.additionalFileLinkCheckup = "https://link.com/" + filename;
    }
    // window.alert("checkupstate: " + state + " ||" + "requirementsState: " + type);
    tyrejasService
      .postCheckUpsCustom(application, object)
      .catch((error) => window.alert(error.message));
    var administerState;
    var applicationStatus;
    if (Number(type) === 1) {
      //application status administruojama
      administerState = "2";
      applicationStatus = 2;
    } else if (Number(type) === 2) {
      administerState = "3";
      applicationStatus = 2;
    } else if (Number(type) === 3) {
      administerState = "4";
      applicationStatus = 1;
    }
    var applicationObject = {
      ...application,
      ...{
        administerState: administerState,
        applicationStatus: applicationStatus,
      },
    };
    tyrejasService
      .putApplicationsCustom(application.id, applicationObject)
      .catch((error) => window.alert(error.message));
  }
  declineCheckupSend(e, application, textId, tableId, reasonsId) {
    e.preventDefault();
    var text = document.getElementById(textId).value;
    var state = sessionStorage.getItem("state");
    var type = sessionStorage.getItem("type");
    var object = {
      checkupState: state,
      requirementsState: type,
      reasonRequirementList: text,
      addedAt: new Date(),
    };
    // window.alert("checkupState: " + state + " ||" + "requirementsState: " + type);
    tyrejasService
      .postCheckUpsCustom(application, object)
      .catch((error) => window.alert(error.message));

    var administerState;
    var applicationStatus;
    var object2 = {};
    if (Number(type) === 1) {
      //application status administruojama
      administerState = "1";
      applicationStatus = 3;
      var event = e.target;
      var person = event.person1;
      var file1 = event.file1;
      var file2 = event.file2;
      var file3 = event.file3;
      // window.alert(person.checked);
      // window.alert(file3.checked);
      // if (person.checked) {
      //   object2 = { ...object2, ...{ "projectExaminatorFullname": null } };
      // }
      if (file1.checked) {
        object2 = { ...object2, ...{ protocolFileLink: null } };
      }
      if (file2.checked) {
        object2 = { ...object2, ...{ permitFileLink: null } };
      }
      if (file3.checked) {
        object2 = { ...object2, ...{ applicationPDFLink: null } };
      }
    } else if (Number(type) === 2) {
      administerState = "5";
      applicationStatus = 4;
    } else if (Number(type) === 3) {
      administerState = "3";
      applicationStatus = 3;
    }
    object2 = {
      ...object2,
      ...{
        administerState: administerState,
        applicationStatus: applicationStatus,
      },
    };
    var applicationObject = { ...application, ...object2 };

    tyrejasService
      .putApplicationsCustom(application.id, applicationObject)
      .catch((error) => window.alert(error.message));
    document.getElementById(tableId).style.display = "none";
    document.getElementById(reasonsId).value = "";
  }
  changeCheckUpDataSubmit = (
    event,
    state,
    tableId,
    reasonsId,
    submitButtonId,
    acceptButtonId,
    declineButtonId,
    application
  ) => {
    event.preventDefault();

    // this.setCheckUpState(state);
    sessionStorage.setItem("state", state);
    if (state === 0) {
      // this.setState({ checkUpState: state });
      document.getElementById(reasonsId).readOnly = false;
      // document.getElementById(submitButtonId).disabled = false;
      document.getElementById(acceptButtonId).disabled = true;
      document.getElementById(declineButtonId).disabled = true;
    } else {
      // document.getElementById(submitButtonId).disabled = false;
      // this.setState({ checkUpState: state }, () => {
      this.confirmCheckupSend(event, application);
      // });

      document.getElementById(tableId).style.display = "None";
    }
  };
  setCheckUpType = (
    event,
    type,
    formId,
    submitButtonId,
    acceptButtonId,
    declineButtonId,
    reasonsId
  ) => {
    event.preventDefault();
    document.getElementById("checkuptableid" + formId).style.display = "block";
    document.getElementById(submitButtonId).disabled = true;
    document.getElementById(acceptButtonId).disabled = false;
    document.getElementById(declineButtonId).disabled = false;
    document.getElementById(reasonsId).readOnly = true;
    // this.setState({ checkUpType: type });
    sessionStorage.setItem("type", type);
    if (type === 1) {
      this.setState({ showType: 1 });
      this.setAdministered(formId);
    } else if (type === 2) {
      this.setState({ showType: 2 });
    } else {
      this.setState({ showType: 3 });
    }
  };
  closeAdministration(e, formId) {
    e.preventDefault();
    document.getElementById(formId).style.display = "none";
  }

  checkIfReasonNotEmpty = (event, reasonInputId, submitButtonId) => {
    var value = document.getElementById(reasonInputId).value;
    if (value.trim().length > 0) {
      this.setState({ checkUpButtonDisabled: false });
    } else {
      this.setState({ checkUpButtonDisabled: true });
    }
  };

  giveGuaranteeFile(event, applicationId, guaranteeFile) {
    event.preventDefault();
    if (document.getElementById(guaranteeFile).value !== "") {
      var value = document
        .getElementById(guaranteeFile)
        .value.split("\\")
        .pop()
        .split("/")
        .pop();

      if (window.confirm("Ar tikrai norite išsaugoti šitą failą?")) {
        tyrejasService
          .getApplications(applicationId)
          .then((data) => {
            var object = {
              ...data,
              ...{ guaranteeFileLink: "https://link.com/" + value },
            };
            tyrejasService
              .putApplicationsCustom(data.id, object)
              .catch((error) => window.alert(error.message));
          })
          .catch((error) => window.alert(error.message));
      }
    } else {
      window.alert("Failas nepasirinktas");
    }
  }
  deleteGuaranteeFile(event, applicationId) {
    if (window.confirm("Ar tikrai norite panaikinti šitą failą?")) {
      tyrejasService
        .getApplications(applicationId)
        .then((data) => {
          var object = { ...data, ...{ guaranteeFileLink: null } };
          tyrejasService
            .putApplicationsCustom(data.id, object)
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));
    }
  }
  findMaxCheckup(applicationId, type) {
    return this.state.applications
      .find((foundData) => {
        if (foundData.id == applicationId) {
          return foundData;
        }
      })
      .checkups.filter((data) => {
        if (data.requirementsState === type) {
          return data;
        }
      })
      .reduce((prev, current) => {
        return prev.addedAt > current.addedAt ? prev : current;
      }, 0);
  }
  findExaminationInfo(applicationId) {
    var examinationInfos = JSON.parse(
      sessionStorage.getItem("examinationinfos")
    );

    var examinationInfo = examinationInfos.find((data) => {
      if (data.applicationId === applicationId) {
        return data;
      }
    });
    if (typeof examinationInfo === "undefined") {
      examinationInfo = null;
    }
    return examinationInfo;
  }
  getMaxCheckup(applicationId, type, maxState) {
    var maxCheckup;
    const dynamicStatesMaxCheckup1 = {};
    const dynamicStatesMaxCheckup2 = {};
    const dynamicStatesMaxCheckup3 = {};
    tyrejasService.getApplications(applicationId).then((data) => {
      var checkups = null;
      try {
        checkups = data.checkup.filter(
          (checkup) => checkup.requirementsState === type
        );
      } catch (error) {
        window.alert(error.message);
      }

      if (checkups.length > 0) {
        maxCheckup = checkups.reduce((prev, current) => {
          return prev.addedAt > current.addedAt ? prev : current;
        });

        if (maxCheckup !== null) {
          if (Number(maxCheckup.requirementsState) === 1) {
            this.setState({
              maxCheckup1: (this.state.maxCheckup1[maxState] = maxCheckup),
            });
          }
          if (Number(maxCheckup.requirementsState) === 2) {
            this.setState({
              maxCheckup2: (this.state.maxCheckup2[maxState] = maxCheckup),
            });
          }
          if (Number(maxCheckup.requirementsState) === 3) {
            this.setState({
              // maxCheckup3: this.state.maxCheckup3[maxState] = maxCheckup
            });
          }
        }
      }
      // this.setState({
      //   maxCheckup3:
      // })
    });
    // return maxCheckup;
  }
  saveFileLinkForCheckup(e, applicationId, type) {
    e.preventDefault();
    var value = e.target.value.split("\\").pop().split("/").pop();
    var file = e.target.files[0];
    var data = this.state.applications.find((foundData) => {
      if (foundData.id == applicationId) {
        return foundData;
      }
    });
    var maxCheckup = this.findMaxCheckup(applicationId, type);

    // maxCheckup = Math.max(...data.checkups.map(o => o.addedAt));
    tyrejasService
      .getCheckUps(maxCheckup.id)
      .then((data) => {
        // window.alert(data);
        var object = {
          ...data,
          ...{ additionalFileLinkCheckup: "https://link.com/" + value },
        };

        tyrejasService
          .putCheckUpsCustom(maxCheckup.id, object)
          .then((data) => {
            window.location.reload();
          })
          .catch((error) => window.alert(error.message));
      })
      .catch((error) => window.alert(error.message));
  }

  saveFileState(e, applicationId, type) {
    e.preventDefault();
    var value = e.target.value.split("\\").pop().split("/").pop();
    var file = e.target.files[0];
    if (
      window.confirm(
        "Ar pasirinktas teisingas failas su pavadinimu: '" + value + "'?"
      )
    ) {
      // window.alert(value);
      this.setState({
        checkupPDFFile: file,
        checkupPDFFileName: value,
      });
    }
  }
  savePDFFileWithCheckup(e) {}

  saveNewFile(e, application, fileType) {
    e.preventDefault();

    let file = e.target.files[0];
    if (file !== null && file !== undefined) {
      var object = {};
      if (
        window.confirm(
          "Ar tikrai norite išsaugoti '" +
            e.target.value.split("\\").pop().split("/").pop() +
            "' failą?"
        )
      ) {
        const formData = new FormData();
        const fileName =
          "Garantinis_rastas_" +
          application.projectName +
          "_" +
          application.uniqueCode +
          ".pdf";

        const newFile = new File([file], fileName, {
          type: file.type,
        });

        formData.append("file", newFile);

        dalyvisService
          .postFileUpload(formData)
          .then(() => {
            object = {
              [fileType]: "https://covid19.mf.vu.lt/proxy/api/files/download/" + fileName,
              applicationStatus: 1,
            };
            tyrejasService
              .putApplicationsCustom(application.id, {
                ...application,
                ...object,
              })
              .then(() => window.location.reload())
              .catch((error) => window.alert(error.message));
          })
          .catch((error) => window.alert(error.message));
      }
    }
  }

  saveCurrentApplicationId(applicationId) {
    if (
      sessionStorage.getItem("currentApplicationId") === null ||
      sessionStorage.getItem("currentApplicationId") !== applicationId
    ) {
      sessionStorage.setItem("currentApplicationId", applicationId);
    } else if (
      sessionStorage.getItem("currentApplicationId") === applicationId
    ) {
      sessionStorage.removeItem("currentApplicationId");
    }
  }
  getCurrentApplicationId() {
    return sessionStorage.getItem("currentApplicationId");
  }

  downloadFile(e, url) {
    e.preventDefault();

    if (url !== null && url !== "") {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const segments = pathname.split("/");
      const fileName = segments.pop() || "";

      //const url = "https://covid19.mf.vu.lt/proxy/api/files/" + fileName;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a new object URL for the blob
          const pdfBlob = new Blob([blob], { type: "application/pdf" });

          const blobUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = fileName; // Set the desired file name here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => console.error("Error downloading the file:", error));
    }
  }

  render() {
    var responseConverted = JSON.parse(sessionStorage.getItem("applications"));
    // if(responseConverted === null){
    //   // window.location.reload();
    //   this.forceUpdate;
    // }
    const paraiskos = Object.values(this.state.applications)
      .filter((application) => {
        this.closeRecentApplication();
        // && this.filterValue== "all"
        // && this.filterValue === application.applStatus
        // if(this.filterValue === "all"){

        // if(this.searchTerm === ""){
        //   if( this.state.filterValue === "all"){
        //   return application;
        // }
        // else if(this.state.filterValue === application.applStatus){
        //   return application;
        // }
        // }

        if (
          application.uniqueCode !== null &&
          application.projectExaminatorFullname !== null
        ) {
          if (
            this.state.filterValue === "all" &&
            this.state.searchTerm === ""
          ) {
            return application;
          } else if (
            Number(this.state.filterValue) ===
              Number(application.applicationStatus) &&
            this.state.searchTerm === ""
          ) {
            return application;
          } else if (
            (application.uniqueCode
              .toLowerCase()
              .replaceAll(" ", "")
              .trim()
              .startsWith(
                this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
              ) ||
              application.projectExaminatorFullname
                .toLowerCase()
                .replaceAll(" ", "")
                .trim()
                .startsWith(
                  this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
                )) &&
            this.state.searchTerm !== "" &&
            this.state.filterValue === "all"
          ) {
            return application;
          } else if (
            application.uniqueCode
              .toLowerCase()
              .replaceAll(" ", "")
              .trim()
              .startsWith(
                this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
              ) &&
            this.state.searchTerm !== "" &&
            this.state.filterValue === application.applicationStatus
          ) {
            return application;
          } else {
            return "";
          }
        }
      })
      .sort(function (a, b) {
        if (sortValueGlobal == "newdate") {
          if (a.addedDate > b.addedDate) return -1;
          if (a.addedDate < b.addedDate) return 1;
          return 0;
        } else if (sortValueGlobal == "olddate") {
          if (a.addedDate < b.addedDate) return -1;
          if (a.addedDate > b.addedDate) return 1;
          return 0;
        } else if (sortValueGlobal == "uniquecode") {
          if (a.uniqueCode < b.uniqueCode) return -1;
          if (a.uniqueCode > b.uniqueCode) return 1;
          return 0;
        } else {
          if (a !== null && a !== null && a !== undefined) {
            if (
              a.projectExaminatorFullname.toLowerCase() <
              b.projectExaminatorFullname.toLowerCase()
            )
              return -1;
            if (
              a.projectExaminatorFullname.toLowerCase() >
              b.projectExaminatorFullname.toLowerCase()
            )
              return 1;
          } else {
            return 0;
          }
        }
      })

      .map((data) => {
        var dateBefore = new Date(data.addedDate);
        var monthMax = parseInt(dateBefore.getMonth()) + 1;
        var datePassed =
          dateBefore.getFullYear() +
          "/" +
          (monthMax.toString().length > 1
            ? monthMax.toString()
            : "0" + monthMax.toString()) +
          "/" +
          dateBefore.getDate();
        var administered = false;
        var biotetic = data.id + "bioeticdoc";
        var tyrimai = data.id + "tyrimaidoc";
        var meginiai = data.id + "meginiaidoc";
        var bioeticfile = "";
        var openAppliId = this.state.openedApplicationId;
        for (let i = 0; i < data.length; i++) {
          if (openAppliId !== data[i].id && openAppliId != null) {
            document.getElementById("buttonInfo" + data.id).textContent =
              "Peržiūrėti";

            document.getElementById(data[i].id).style.visibility = "hidden";
            document.getElementById(data[i].id).style.display = "none";
            // document.getElementById(listApplications[i].id).style.maxHeight = "0";
          }
        }
        // for(let i = 0; listApplications.length; i++){
        if (bioeticfile !== null || bioeticfile !== "") {
          // this.setState({[data+ "showBioetic"]: false});
        }
        let administeredApplications;
        let consideredApplications;
        let lastStageApplications;
        var num1 = 0;
        var num2 = 0;
        var num3 = 0;
        if (data.checkup.length > 0) {
          administeredApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 1) {
                // console.log("checkUp.requirementsState === 1: ");
                return 1;
              }
            })
            .sort(function (a, b) {
              if (a.addedAt < b.addedAt) return -1;
              if (a.addedAt > b.addedAt) return 1;
              return 0;
            })
            .map((checkUp) => {
              num1 += 1;
              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num1}</th>
                    <td>{checkUp.addedAt}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });

          consideredApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 2) {
                return 1;
              }
            })
            .sort(function (a, b) {
              if (a.addedAt < b.addedAt) return -1;
              if (a.addedAt > b.addedAt) return 1;
              return 0;
            })
            .map((checkUp) => {
              num2 += 1;
              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num2}</th>
                    <td>{checkUp.addedAt}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka: <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });
          lastStageApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 3) {
                return 1;
              }
            })
            .sort(function (a, b) {
              if (a.addedAt < b.addedAt) return -1;
              if (a.addedAt > b.addedAt) return 1;
              return 0;
            })
            .map((checkUp) => {
              num3 += 1;
              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num3}</th>
                    <td>{checkUp.addedAt}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka: <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });
        }
        var status = "";
        var color = "";
        var statusButton = document.getElementById("statusbtn");
        //  new - 0; accepted - 1; administered - 2; wresponse - 3; declined - 4

        if (data.applicationStatus === 0) {
          status = "Nauja";
          color = "#6699ff";
          // statusButton.style.backgroundColor = "blue";
        } else if (data.applicationStatus === 2) {
          status = "Administruojama";
          color = "#ffff00";
        } else if (data.applicationStatus === 3) {
          status = "Laukiama atsakymo";
          color = "#ff3300";
        } else if (data.applicationStatus === 4) {
          status = "Atmesta";
          color = "#FE0007";
        } else if (data.applicationStatus === 1) {
          status = "Priimta";
          color = "#33cc33";
        } else {
          status = "Kažkas blogai";
          color = "grey";
        }
        // const stateArray = Object.entries(this.state);
        // stateArray.forEach(([key, value]) => {
        //   console.log(`${key}: ${value}`);
        // });
        // this.setState({
        //   ["admincheck" + data.id]: true,
        //   ["considercheck" + data.id]: true
        // });

        var examinationInfo = this.findExaminationInfo(data.id);

        return (
          <>
            {/* <a
              className="btn-nohover applicationList"
              style={{ width: "100%", borderColor: color }}
              onClick={(event) =>
                this.setShowButton(event, data.id)}
            > */}
            <tr
              className="application-row"
              onClick={(event) =>
                this.showInfo("application" + data.id, data.id)
              }
            >
              <th scope="row">{data.uniqueCode}</th>
              <td>{datePassed}</td>
              <td>{data.projectExaminatorFullname}</td>
              <td>
                <button
                  id="statusbtn"
                  className="status-button"
                  style={{
                    float: "left",
                    marginRight: "25px",
                    borderColor: color,
                  }}
                >
                  {status}
                </button>
              </td>
            </tr>
            <td colspan="4" style={{ height: 0 }}>
              <div>
                {this.state.showCurrentApplication["display" + data.id] ===
                  "block" && (
                  <li
                    key={data.id}
                    id={data.id}
                    style={{
                      display:
                        this.state.showCurrentApplication["display" + data.id],
                    }}
                  >
                    {/* {data.administerState !== 0 && data.applicationStatus !== 1 && data.applicationStatus !== 2 && data.applicationStatus !== 3 && data.applicationStatus !== 4 && <button className="btn" onClick={event => this.setAdministered(data.id)}>Administruojama</button>} */}
                    <table className="table">
                      <thead className="thead-dark">
                        {/* <tr>
                      
                      <th scope="col">Skiltis</th>
                      <th scope="col">Įvesta informacija</th>
                      
                    </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <th scope="row">Paraiškos unikalus numeris</th>
                          <td>{data.uniqueCode}</td>
                        </tr>
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <th scope="row">Projekto pavadinimas</th>
                          <td>{data.projectName}</td>
                        </tr>
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <th scope="row">Pagrindinis tyrėjas</th>
                          <td>{data.projectExaminatorFullname}</td>
                        </tr>
                        <tr>
                          {/* <th scope="row">2</th> */}
                          <th>Tyrimo protokolo santrauka</th>
                          <td>
                            {/* <a
                              className="btn"
                              href={data.protocolFileLink}
                              download
                            >
                              Atsisiųsti tyrimo protokolą
                            </a> */}
                            <button
                              className="btn"
                              onClick={(e) =>
                                this.downloadFile(e, data.protocolFileLink)
                              }
                            >
                              Atsisiųsti tyrimo protokolą
                            </button>
                          </td>
                        </tr>
                        <tr>
                          {/* <th scope="row">3</th> */}
                          <th>Bioetikos leidimas</th>
                          <td>
                            {data.permitFileLink !== null ? (
                              // <a
                              //   className="btn"
                              //   href={data.permitFileLink}
                              //   style={{ marginBottom: "2px" }}
                              //   download
                              // >
                              //   Atsisiųsti bioetikos leidimą
                              // </a>
                              <button
                                className="btn"
                                style={{ marginBottom: "2px" }}
                                onClick={(e) =>
                                  this.downloadFile(e, data.permitFileLink)
                                }
                              >
                                Atsisiųsti bioetikos leidimą
                              </button>
                            ) : (
                              <p>Bioetikos leidimas nepateiktas</p>
                            )}
                            {/* <button className="btn" id={data+"savebioetic"} style={{padding: "2px", border: "none", marginLeft: "5px"}} disabled><i className="fa fa-save" style={{fontSize: "20px"}}></i></button><br/> */}
                          </td>
                        </tr>
                        <tr>
                          {/* <th scope="row">3</th> */}
                          <th>Garantinis raštas</th>
                          <td>
                            {this.state.guaranteeFilesShow[
                              "guaranteefile" + data.id
                            ] ? (
                              <>
                                <a
                                  className="btn"
                                  href={data.guaranteeFileLink}
                                  download
                                >
                                  Garantinis raštas - atsisiųsti
                                </a>
                                <button
                                  className="btn"
                                  onClick={(e) =>
                                    this.downloadFile(e, data.guaranteeFileLink)
                                  }
                                >
                                  Atsisiųsti garantinį laišką
                                </button>
                                <button
                                  className="btn"
                                  onClick={(e) =>
                                    this.deleteGuaranteeFile(e, data.id)
                                  }
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <>
                                <label
                                  className="btn"
                                  style={{
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                    margin: 0,
                                    borderRadius: 0,
                                  }}
                                >
                                  <input
                                    className="col-centered btn"
                                    type="file"
                                    id={"guaranteefile" + data.id}
                                    accept=".pdf"
                                    onInput={(event) =>
                                      this.saveNewFile(
                                        event,
                                        data,
                                        "guaranteeFileLink"
                                      )
                                    }
                                    style={{ margin: 0, borderRadius: 0 }}
                                  />
                                  Pasirinkite dokumentą
                                </label>
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          {/* <th scope="row">4</th> */}
                          <th>Projekto pradžia ir pabaiga</th>
                          <td style={{ width: "70%" }}>
                            Pradžia:&nbsp;{" "}
                            {data.startDate !== null
                              ? data.startDate
                              : "Data nebuvo nustatyta"}{" "}
                            <br />
                            Pabaiga:&nbsp;{" "}
                            {data.endDate !== null
                              ? data.endDate
                              : "Data nebuvo nustatyta"}
                          </td>
                          {/* <td>Pabaiga: 2009-01-01</td> */}
                        </tr>
                        <tr>
                          {/* <th scope="row">5</th> */}
                          <th>Paraiškos PDF</th>
                          <td>
                            {/* <a className="btn" href="/paraiskapdf" download>
                              Atsisiųsti paraiškos PDF
                            </a> */}
                            <button
                              className="btn"
                              onClick={(e) =>
                                this.downloadFile(e, data.applicationPdflink)
                              }
                            >
                              Atsisiųsti paraiškos PDF
                            </button>
                          </td>
                        </tr>
                        <tr>
                          {/* <th scope="row">6</th> */}
                          <th>Papildoma informacija</th>
                          <td>{data.additionalRemarks}</td>
                        </tr>
                        <tr>
                          {/* <th scope="row">7</th> */}
                          <th>Pateikimo data</th>
                          <td>
                            {data.addedDate !== null
                              ? data.addedDate.slice(
                                  0,
                                  data.addedDate.indexOf("T")
                                )
                              : "Data nebuvo nustatyta"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* {this.state.checksShow["table" + data.id] && <> */}
                    {["0", "1", "2", "3", "4", "5"].includes(
                      data.administerState
                    ) && (
                      <>
                        <h2>
                          <b>Administracinės patikros</b>
                          <button
                            disabled={
                              this.state.adminChecks["admincheck" + data.id]
                            }
                            className="btn"
                            style={{
                              margin: "0 0 25px 0",
                              paddingRight: "25px",
                              width: "50%",
                              float: "right",
                            }}
                            onClick={(event) =>
                              this.setCheckUpType(
                                event,
                                1,
                                data.id,
                                "checkupbuttonid" + data.id + "checkup1",
                                "acceptbutton" + data.id,
                                "declinebutton" + data.id,
                                "id" + data.id + "checkup1"
                              )
                            }
                          >
                            Pridėti naują patikrą<i className="fa fa-plus"></i>
                          </button>
                        </h2>

                        <table
                          className="table"
                          style={{ marginBottom: "50px" }}
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Data</th>
                              <th scope="col">Atitinka</th>
                              <th scope="col">Neatitinka</th>
                            </tr>
                          </thead>
                          <tbody>{administeredApplications}</tbody>
                        </table>
                      </>
                    )}
                    {["2", "3", "4", "5"].includes(data.administerState) && (
                      <>
                        <h2>
                          <b>LPRLB mokslo komiteto vertinimas</b>
                          <button
                            disabled={
                              this.state.considerChecks[
                                "considercheck" + data.id
                              ]
                            }
                            className="btn"
                            style={{
                              margin: "0 0 25px 0",
                              paddingRight: "25px",
                              width: "50%",
                              float: "right",
                            }}
                            onClick={(event) =>
                              this.setCheckUpType(
                                event,
                                2,
                                data.id,
                                "checkupbuttonid" + data.id + "checkup1",
                                "acceptbutton" + data.id,
                                "declinebutton" + data.id,
                                "id" + data.id + "checkup1"
                              )
                            }
                          >
                            Pridėti naują vertinimą
                            <i className="fa fa-plus"></i>
                          </button>
                        </h2>

                        <table
                          className="table"
                          style={{ marginBottom: "50px" }}
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Data</th>
                              <th scope="col">Suteikti</th>
                              <th scope="col">Nesuteikti</th>
                            </tr>
                          </thead>
                          <tbody>{consideredApplications}</tbody>
                        </table>
                        <div style={{ marginBottom: "25px" }}>
                          {this.findMaxCheckup(data.id, 2) !== null &&
                          this.findMaxCheckup(data.id, 2)
                            .additionalFileLinkCheckup !== null ? (
                            <>
                              <a
                                className="btn"
                                download
                                href={
                                  this.findMaxCheckup(data.id, 2)
                                    .additionalFileLinkCheckup
                                }
                              >
                                Atsisiusti PDF dokumentą
                              </a>{" "}
                              <br />
                            </>
                          ) : (
                            <>
                              <p>Dokumentas dar nepridėtas</p> <br />
                            </>
                          )}
                          <label>Pridėti PDF dokumentą iš naujo&nbsp;</label>
                          <label
                            className="btn"
                            style={{
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              margin: 0,
                              borderRadius: 0,
                            }}
                          >
                            <input
                              className="col-centered btn"
                              type="file"
                              onInput={(e) =>
                                this.saveFileLinkForCheckup(e, data.id, 2)
                              }
                              style={{ margin: 0, borderRadius: 0 }}
                              accept="application/pdf"
                            />
                            Pasirinkite dokumentą
                          </label>
                        </div>
                      </>
                    )}
                    {["3", "4", "5"].includes(data.administerState) && (
                      <>
                        <h2>
                          <b>HBRC mokslo komiteto svarstymas </b>
                          <button
                            disabled={
                              this.state.lastStageChecks[
                                "laststagecheck" + data.id
                              ]
                            }
                            className="btn"
                            style={{
                              margin: "0 0 25px 0",
                              paddingRight: "25px",
                              width: "50%",
                              float: "right",
                            }}
                            onClick={(event) =>
                              this.setCheckUpType(
                                event,
                                3,
                                data.id,
                                "checkupbuttonid" + data.id + "checkup1",
                                "acceptbutton" + data.id,
                                "declinebutton" + data.id,
                                "id" + data.id + "checkup1"
                              )
                            }
                          >
                            Pridėti naują svarstymą
                            <i className="fa fa-plus"></i>
                          </button>
                        </h2>

                        <table
                          className="table"
                          style={{ marginBottom: "50px" }}
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Data</th>
                              <th scope="col">Suteikti</th>
                              <th scope="col">Nesuteikti</th>
                            </tr>
                          </thead>
                          <tbody>{lastStageApplications}</tbody>
                        </table>
                      </>
                    )}
                    {/* this.state.maxCheckup3["maxcheckup" + data.id].additionalFileLinkCheckup */}
                    {this.state.sampleFormAdd["formadd" + data.id] &&
                      this.findMaxCheckup(data.id, 3).checkupState === 1 && (
                        <>
                          {this.findMaxCheckup(data.id, 3)
                            .additionalFileLinkCheckup === null ? (
                            <>
                              <label>
                                Pridėti Atiduodamų mėginių sąrašą &nbsp;
                              </label>
                            </>
                          ) : (
                            <>
                              <label>
                                Pridėti Atiduodamų mėginių sąrašą iš naujo&nbsp;
                              </label>
                            </>
                          )}

                          <label
                            className="btn"
                            style={{
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              margin: 0,
                              borderRadius: 0,
                            }}
                          >
                            <input
                              className="col-centered btn"
                              type="file"
                              onInput={(e) =>
                                this.saveFileLinkForCheckup(e, data.id, 3)
                              }
                              style={{ margin: 0, borderRadius: 0 }}
                            />
                            Pasirinkite dokumentą
                          </label>

                          {this.findMaxCheckup(data.id, 3)
                            .additionalFileLinkCheckup !== null &&
                            this.findMaxCheckup(data.id, 3).checkupState ===
                              1 && (
                              <>
                                <br />
                                <label>Pridėta mėgintuvėlių formą:</label>
                                <a
                                  className="btn"
                                  href={
                                    this.findMaxCheckup(data.id, 3)
                                      .additionalFileLinkCheckup !== null &&
                                    this.findMaxCheckup(data.id, 3)
                                      .checkupState === 1
                                      ? this.findMaxCheckup(data.id, 3)
                                          .additionalFileLinkCheckup
                                      : "https://link.com/unkown"
                                  }
                                  download
                                >
                                  Atsisiųsti
                                </a>
                              </>
                            )}
                          {/* <form id={data + "form"} onInput={(event) => this.checkEmpty(event, data + "tyrimastext", data + "tyrimasdoc", data + "grazinamimeginiai", data + "dataButton")} onSubmit={(event) => this.saveExaminationInfo(event, data.id)}>
                          <h2><b>Atliktų tyrimų duomenys</b></h2>

                          <table className="table">

                            <thead style={{ width: "29%" }} className="thead-dark">
                              <tr>
                              </tr>
                            </thead>
                            <tbody style={{ width: "70%" }}>

                              <tr>
                                <th style={{ width: "20%" }}>Tekstas*</th>
                                {examinationInfo === null ?
                                  <>

                                    <td >
                                      <label>Tekstas dar nepateiktas</label>

                                    </td>
                                  </> :
                                  <>
                                    <td >
                                      <label>{examinationInfo.examText}</label>

                                    </td>
                                  </>}
                              </tr>

                              <tr>
                                <th style={{ width: "20%" }}>Dokumentas*</th>
                                {examinationInfo === null ? <>
                                  <td>
                                    <label>Atliktų tyrimų dokumentas dar nepaduotas</label>
                                  </td>
                                </> :
                                  <>
                                    <td>
                                      <a className='btn' href={examinationInfo.examFileLink} download> Atsisiųsti atlikto tyrimo dokumentą</a>
                                    </td>
                                  </>}
                              </tr>


                            </tbody>

                          </table>

                          <h2><b>Grąžinami mėginiai</b></h2>

                          <table className="table">

                            <thead className="thead-dark">
                              <tr style={{ width: "20%" }}>


                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <th style={{ width: "20%" }}>Grąžinimo data</th>
                                {(examinationInfo === null) ? <>
                                  <td >
                                    <label>Gražinimo datos dar nėra</label>

                                  </td>
                                </> : <>
                                  <label>{examinationInfo.sendingDate}</label>
                                </>

                                }

                              </tr>

                              <tr>
                               
                                <td >
                                  <label>Gražinamų nepanaudotų mėginių formą nebuvo pridėta</label>
                                  {this.state[meginiai]}
                                </td>
                                {(examinationInfo === null) ? <></>
                                  :
                                  <>
                                    {(examinationInfo.samplesFileLink !== null) ? <>
                                      <a className='btn' href={examinationInfo.samplesFileLink} download></a>
                                    </> : <>
                                      <label>Gražinamų nepanaudotų mėginių formą nebuvo pridėta</label>
                                    </>}

                                  </>}


                              </tr>


                            </tbody>

                          </table>
                          <button id={data + "dataButton"} type="submit" className="btn" disabled style={{ float: "right", marginBottom: "25px" }}><i className="fa fa-send" />Pateikti</button>
                        </form> */}
                        </>
                      )}
                    {/* </>

                    } */}

                    <form
                      onSubmit={(event) =>
                        this.declineCheckupSend(
                          event,
                          data,
                          "id" + data.id + "checkup1",
                          "checkuptableid" + data.id,
                          "id" + data.id + "checkup1"
                        )
                      }
                    >
                      <div
                        id={"checkuptableid" + data.id}
                        style={{ display: "none" }}
                      >
                        <h2>
                          {this.state.showType === 1 && (
                            <b>Naujas administracinis patikrinimas</b>
                          )}
                          {this.state.showType === 2 && (
                            <b>Naujas LPRLB komiteto vertinimas</b>
                          )}
                          {this.state.showType === 3 && (
                            <b>Naujas HBRC mokslo komiteto svarstymas </b>
                          )}
                        </h2>

                        <table className="table">
                          <thead
                            style={{ width: "29%" }}
                            className="thead-dark"
                          >
                            <tr>
                              {/* <th scope="col">Laukelis</th>
                      <th scope="col">Įvedama informacija</th> */}
                            </tr>
                          </thead>
                          <tbody style={{ width: "70%" }}>
                            <tr>
                              <th style={{ width: "20%" }}>Sprendimas</th>
                              <td>
                                <button
                                  id={"acceptbutton" + data.id}
                                  className="btn"
                                  style={{
                                    display: "inline-block",
                                    margin: "0 25px 0 0",
                                  }}
                                  onClick={(event) =>
                                    this.changeCheckUpDataSubmit(
                                      event,
                                      1,
                                      "checkuptableid" + data.id,
                                      "id" + data.id + "checkup1",
                                      "checkupbuttonid" + data.id + "checkup1",
                                      "acceptbutton" + data.id,
                                      "declinebutton" + data.id,
                                      data
                                    )
                                  }
                                >
                                  Patvirtinti <i className="fa fa-check"></i>
                                </button>
                                <button
                                  id={"declinebutton" + data.id}
                                  className="btn"
                                  style={{
                                    display: "inline-block",
                                    margin: "0 25px 0 0",
                                  }}
                                  onClick={(event) =>
                                    this.changeCheckUpDataSubmit(
                                      event,
                                      0,
                                      "checkuptableid" + data.id,
                                      "id" + data.id + "checkup1",
                                      "checkupbuttonid" + data.id + "checkup1",
                                      "acceptbutton" + data.id,
                                      "declinebutton" + data.id,
                                      data
                                    )
                                  }
                                >
                                  Atmesti&nbsp;
                                  <i className="fa fa-times-circle"></i>
                                </button>
                                <button
                                  className="btn"
                                  onClick={(e) =>
                                    this.closeAdministration(
                                      e,
                                      "checkuptableid" + data.id
                                    )
                                  }
                                >
                                  Atšaukti
                                </button>
                                <br />

                                {this.state[tyrimai]}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ width: "20%" }}>Priežastys</th>
                              <td>
                                <input
                                  type="text"
                                  id={"id" + data.id + "checkup1"}
                                  name={"id" + data.id + "checkup1"}
                                  style={{ width: "100%" }}
                                  readOnly
                                  onChange={(event) =>
                                    this.checkIfReasonNotEmpty(
                                      event,
                                      "id" + data.id + "checkup1",
                                      "checkupbuttonid" + data.id + "checkup1",
                                      "checkuptableid" + data.id
                                    )
                                  }
                                />
                                <p style={{ margin: 0 }}>
                                  *Paspaudus mygtuką "Atmesti" bus duota
                                  galimybė įrašyti priežastys
                                </p>
                                <p style={{ margin: "0 0 25px 0" }}>
                                  *Priežastys aprašykite su "," simboliu, norint
                                  padaryti sąrašą priežaščių
                                </p>
                              </td>
                            </tr>

                            <tr>
                              {[1].includes(this.state.showType) && (
                                <>
                                  <th>
                                    Informacija ir dokumentai, kuriuos reikia
                                    pakeisti
                                  </th>
                                  <td>
                                    {/* <input type="checkbox" id="person1" name="person1" value="person" />
                              <label for="person1"> &nbsp;Pagrindinis tyrėjas</label><br /> */}
                                    <input
                                      type="checkbox"
                                      id="file1"
                                      name="file1"
                                      value="file1"
                                    />
                                    <label htmlFor="file1">
                                      {" "}
                                      &nbsp;Tyrimo protokolo santrauka
                                    </label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="file2"
                                      name="file2"
                                      value="file2"
                                    />
                                    <label htmlFor="file2">
                                      {" "}
                                      &nbsp;Bioetikos leidimas
                                    </label>
                                    <br />
                                    <input
                                      type="checkbox"
                                      id="file3"
                                      name="file3"
                                      value="file3"
                                    />
                                    <label htmlFor="file3">
                                      {" "}
                                      &nbsp;Paraiškos PDF
                                    </label>
                                    <br />
                                  </td>
                                </>
                              )}
                            </tr>
                          </tbody>
                        </table>
                        {[2].includes(this.state.showType) && (
                          <>
                            <label>Pridėti PDF dokumentą&nbsp;</label>
                            <label
                              className="btn"
                              style={{
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                margin: 0,
                                borderRadius: 0,
                              }}
                            >
                              <input
                                className="col-centered btn"
                                type="file"
                                onInput={(e) =>
                                  this.saveFileState(e, data.id, 2)
                                }
                                style={{ margin: 0, borderRadius: 0 }}
                                accept="application/pdf"
                              />
                              Pasirinkite dokumentą
                            </label>
                          </>
                        )}
                        <button
                          id={"checkupbuttonid" + data.id + "checkup1"}
                          // type="submit"
                          className="btn"
                          disabled={this.state.checkUpButtonDisabled}
                          style={{ float: "right", marginBottom: "25px" }}
                          type="submit"
                        >
                          <i className="fa fa-send" />
                          Pateikti
                        </button>
                      </div>
                    </form>
                  </li>
                )}
              </div>
            </td>
          </>
        );
      });

    const dropdownListFilter = [
      { name: "Naujos paraiškos", value: 0 },
      { name: "Administruojamos paraiškos", value: 2 },
      { name: "Laukiančios atsakymo paraiškos", value: 3 },
      { name: "Atmesta", value: 4 },
      { name: "Priimtos paraiškos", value: 1 },
    ];
    const dropdownListSort = [
      { name: "Seniausią datą", value: "olddate" },
      { name: "Unikalų paraiškos kodą", value: "uniquecode" },
      { name: "Tyrėją", value: "examinator" },
    ];
    const optionsFilter = dropdownListFilter.map((drop, i) => {
      return (
        <>
          <option key={i} id={drop.value} value={drop.value}>
            {drop.name}
          </option>
        </>
      );
    });

    const optionsSort = dropdownListSort.map((drop, i) => {
      return (
        <>
          <option key={i} id={drop.value} value={drop.value}>
            {drop.name}
            {/* <button>word</button> */}
          </option>
        </>
      );
    });

    return (
      <>
        {this.state.loading ? (
          <>
            <div className="d-flex justify-content-center" style={{}}>
              <div
                className="spinner-grow d-flex justify-content-center"
                style={{
                  width: "20rem",
                  height: "20rem",
                  marginTop: "80px",
                  marginBottom: "80px",
                  color: "#78003f",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-lg-12 col-md-12 col-12">
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-12 "
                style={{
                  margin: 0,
                  padding: "0 0 25px 0",
                  display: "inline-block",
                }}
              >
                <label>Filtruoti pagal:</label>
                <div className="" style={{ paddingRight: "25px" }}>
                  <select
                    id="filter-selection"
                    className="form-select"
                    onChange={(event) => this.filterItems(event)}
                    style={{ display: "inline-block" }}
                  >
                    <option key="all" id="all" value="all">
                      Visos paraiškos
                    </option>
                    {React.Children.toArray(optionsFilter)}
                  </select>

                  {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                </div>
              </div>

              <div
                className="form-group col-lg-6 col-md-6 col-sm-12 col-12 "
                style={{
                  margin: 0,
                  padding: "0 0 25px 0",
                  display: "inline-block",
                }}
              >
                <label htmlFor="exampleFormControlSelect1">
                  Rūšiuoti pagal:
                </label>
                <div className="input-group" style={{ paddingRight: "25px" }}>
                  <select
                    id="sort-selection"
                    className="form-select"
                    onChange={(event) => this.sortItems(event)}
                  >
                    {/* <i className="fa fa-sort-down"></i> */}
                    <option key="newdate" id="newdate" value="newdate">
                      Naujausią datą
                    </option>
                    {React.Children.toArray(optionsSort)}
                  </select>
                  {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                </div>
              </div>

              <div
                className="col-centered"
                style={{ display: "block", margin: "auto" }}
              >
                <input
                  id="searchData"
                  type="text"
                  placeholder="Ieškoti..."
                  onChange={this.filterItemsSearch}
                />
              </div>

              <br />
              <h2>
                <b>Paraiškos</b>
              </h2>
              <br />
              <div className="list-group"></div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Paraiškos numeris</th>
                    <th scope="col">Data</th>
                    <th scope="col">Pagrindinis tyrėjas</th>
                    <th scope="col">Statusas</th>
                  </tr>
                </thead>
                <tbody>{React.Children.toArray(paraiskos)}</tbody>
              </table>
              {/* <ul className="designed-list">{React.Children.toArray(paraiskos)}</ul> */}
            </div>
          </>
        )}
      </>
    );
  }
}
export default Applications;
