import React from "react";
import dalyvisService from "../../../../services/dalyvis-service";
var sortValueGlobal = "newdate";
const ITEMS_PER_PAGE = 10;
class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      searchTerm2: "",
      filterValue: "all",
      sortValue: "newdate",
      disabledState: true,
      allMessages: [],
      allUsers: [],
      loading: true,
      currentPage: 1,
      user: JSON.parse(sessionStorage.getItem("user")),
    };
    this.fetchData();
  }
  async fetchData() {
    const messages = await dalyvisService
      .getAllMessages()
      .catch((error) => window.alert(error.message));
    const users = await dalyvisService
      .getAllUsers()
      .catch((error) => window.alert(error.message));

    console.log(users);
    console.log(messages);
    this.setState({
      allMessages: messages,
      allUsers: users,
      loading: false,
    });
    sessionStorage.setItem("messages", JSON.stringify(messages));
    sessionStorage.setItem("users", JSON.stringify(users));
  }
  async componentDidMount() {}

  showInfo(id) {
    // -------- has to set message to seen on click if its new
    // const list = [
    //   "uzklausa apie mane",
    //   "uzklausa apie jus",
    //   "uzklausa apie meginius",
    // ]; // get from database
    var value = document.getElementById(id);
    // for (let i = 0; i < list.length; i++) {
    //   if (id !== list[i]) {
    //     document.getElementById(list[i]).style.visibility = "hidden";
    //     document.getElementById(list[i]).style.display = "none";
    //     document.getElementById(list[i]).style.maxHeight = "0";
    //   }
    // }
    if (value.style.visibility === "hidden") {
      value.style.display = "block";
      value.style.visibility = "visible";
      value.style.maxHeight = "100%";
      // value.style.lineHeight = "100%";

      // value.style.height = "auto";
    } else {
      value.style.display = "none";
      value.style.visibility = "hidden";
      value.style.maxHeight = "0";
      // value.style.lineHeight = "0";

      // value.style.height = 0;
    }
  }

  filterItemsSearch = () => {
    var searchTermInside = document.getElementById("searchDataInquiry").value;
    this.setState({ searchTerm: searchTermInside });
  };
  filterUsers = () => {
    var searchTermInside = document.getElementById("searchusers").value;
    this.setState({ searchTerm2: searchTermInside });
  };
  filterItems = (e) => {
    e.preventDefault();
    var filterValue1 = document.getElementById(
      "filter-inquiry-selection"
    ).value;
    this.setState({ filterValue: filterValue1 });
    document.getElementById("searchDataInquiry").value = "";
    this.setState({ searchTerm: "" });
  };
  sortItems = (e) => {
    e.preventDefault();
    var sortValue1 = document.getElementById("sort-inquiry-selection").value;
    this.setState({ sortValue: sortValue1 });
    // }
  };
  checkEmptyMessage = () => {
    var userCode = document.getElementById("user-selection");
    var message = document.getElementById("message").value;
    var checkbox = document.getElementById("msgcheckbox");
    if (checkbox.checked == true && message != "") {
      this.setState({
        disabledState: false,
      });
    } else {
      this.setState({
        disabledState: true,
      });
    }
  };
  sendMessage = () => {
    var userId = document.getElementById("user-selection").value;
    var message = document.getElementById("message").value;
    var messagetopic = document.getElementById("messagetopic").value;
    var checkbox = document.getElementById("msgcheckbox");
    dalyvisService
      .getPersonalData(userId)
      .catch((error) => window.alert(error.message))
      .then((response) => {
        var messageType = 1;
        if (response.userRole === 2) {
          messageType = 4;
        }

        let sendMessage = {
          messageText: message,
          messageState: 2,
          messageType: messageType,
          messageTopic: messagetopic,
          eventDate: new Date(),
        };

        dalyvisService
          .postMessage(sendMessage)
          .then((messageId) => {
            dalyvisService
              .getPersonalData(userId)
              .then((response) => {
                let user = response;
                user.message.push(messageId);

                dalyvisService
                  .putCustomObjectPersonalData(userId, user)
                  .catch((error) => window.alert(error.message));
              })
              .catch((error) => window.alert(error.message));
          })
          .catch((error) => window.alert(error.message));
      })
      .catch((error) => window.alert(error.message));

    // send message to specific user to back end

    // window.location.reload();
  };
  handlePageChange = (pageNumber, total) => {
    if (pageNumber < 1) {
      pageNumber = 1;
    }
    if (pageNumber > total) {
      pageNumber = total;
    }
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { allMessages, currentPage, allUsers } = this.state;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const totalPages = Math.ceil(allMessages.length / ITEMS_PER_PAGE);
    const pageNumbers = Array.from(Array(totalPages).keys()).map(
      (num) => num + 1
    );
    var sortValue1 = this.state.sortValue;
    const paraiskos = Object.values(allMessages)
      .filter((inquiry) => {
        var filterValue = this.state.filterValue;
        if (filterValue === "all" && this.state.searchTerm === "") {
          return inquiry;
        } else if (
          filterValue === inquiry.messageState &&
          this.state.searchTerm === ""
        ) {
          return inquiry;
        } else if (
          inquiry.messageTopic
            .toLowerCase()
            .replaceAll(" ", "")
            .trim()
            .startsWith(
              this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
            ) &&
          this.state.searchTerm !== "" &&
          filterValue === "all"
        ) {
          return inquiry;
        } else if (
          (inquiry.messageTopic
            .toLowerCase()
            .replaceAll(" ", "")
            .trim()
            .startsWith(
              this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
            ) &&
            this.state.searchTerm !== "" &&
            Number(filterValue) === inquiry.messageState) ||
          Number(filterValue) === inquiry.messageType
        ) {
          return inquiry;
        } else if (
          this.state.user.id === inquiry.senderId &&
          Number(filterValue) === 11
        ) {
          return inquiry;
        } else if (Number(filterValue) === 22) {
          return inquiry;
        }
        // else if(this.state.user.id !== inquiry.senderId){
        //   return inquiry;
        // }
        else {
          return "";
        }
      })
      .sort((a, b) => {
        if (sortValue1 === "newdate") {
          if (a.eventDate > b.eventDate) return -1;
          if (a.eventDate < b.eventDate) return 1;
        }

        if (sortValue1 === "olddate") {
          if (a.eventDate < b.eventDate) return -1;
          if (a.eventDate > b.eventDate) return 1;
        }

        return 0;
      })
      .slice(startIndex, endIndex)
      .map((inquiry) => {
        var dateBefore = new Date(inquiry.eventDate);
        var monthMax = parseInt(dateBefore.getMonth()) + 1;
        var datePassed =
          dateBefore.getFullYear() +
          "/" +
          (monthMax.toString().length > 1
            ? monthMax.toString()
            : "0" + monthMax.toString()) +
          "/" +
          dateBefore.getDate();

        // const senderData = this.state.allUsers.find(
        //   (user) => user.id === inquiry.senderId
        // );

        const senderData = allUsers.find(user => user.message && user.message.includes(inquiry.id));

        return (
          <>
            {
              senderData &&
                <>
                  <button
                      className={`applicationList ${
                          inquiry.state === "new" ? "message-styling" : ""
                      }`}
                      onClick={() => this.showInfo("uniqueinquiry" + inquiry.id)}
                      style={{width: "100%"}}
                  >
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="d-inline col-lg-4 col-md-6 col-12">
                        <p style={{float: "left", display: "inline-block"}}>
                          {senderData.name + " " + senderData.surname + " "}{" "}
                        </p>
                      </div>
                      <div className="d-inline col-lg-4 col-md-6 col-12">
                        <p style={{float: "center", display: "inline-block"}}>
                          {senderData.personalCode !== null
                              ? "Asmens kodas: " + senderData.personalCode
                              : ""}
                        </p>
                      </div>
                      {/* <div className="d-inline col-lg-4 col-md-6 col-12"><p style={{ float: "center", display: "inline-block" }}>{inquiry.personalCode}</p></div> */}
                      <div className="d-inline col-lg-4 col-md-6 col-12">
                        <p style={{float: "right", display: "inline-block"}}>
                          {datePassed}{" "}
                        </p>
                      </div>
                    </div>
                  </button>
                  <div>
                    <li
                        key={inquiry.id}
                        id={"uniqueinquiry" + inquiry.id}
                        style={{display: "none", visibility: "hidden"}}
                    >
                      <table className="table" style={{}}>
                        <thead className="thead-dark">
                        {/* <tr>

                    <th scope="col">Skiltis</th>
                    <th scope="col">Įvesta informacija</th>

                  </tr> */}
                        </thead>
                        <tbody>
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <th scope="row">Data</th>
                          <td>{datePassed}</td>
                        </tr>
                        <tr>
                          <th scope="row">Tema</th>
                          <td>{inquiry.messageTopic}</td>
                        </tr>
                        <tr>
                          <th>Tekstas</th>
                          <td
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                              }}
                          >
                            {inquiry.messageText}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </li>
                  </div>
                </>
            }

          </>
        );
      });
    const dropdownListFilter = [
      {name: "Naujausios žinutės/užklausos", value: "1"},
      {name: "Peržiūrėtos žinutės/užklausos", value: "2"},
      {name: "Tyrėjų užklausos", value: "4"},
      // { name: "Tyrėjų žinutės", value: "exam" },
      {name: "Dalyvių žinutės", value: "1"},
      {name: "Išsiųstos žinutės", value: "11"},
      {name: "Gautos žinutės", value: "22"},
    ];
    //new - 1
    //seen - 2
    // inquiry - 4
    // part - 1 (1 2 3)
    // sent or received - depends if sender id matches or not
    const dropdownListSort = [
      {name: "Seniausią datą", value: "olddate"},
      // { name: "Unikalų paraiškos kodą", value: "uniquecode" },
      // { name: "Tyrėją", value: "examinator" },
    ];
    // const allUsers = [
    //   { name: "Bobas", surname: "Babevicius", uniqueCode: "fawf2a+f5wfaw" },
    //   { name: "Antanas", surname: "Babevicius", uniqueCode: "fawf2d2+f5wfaw" },
    //   { name: "Jozefas", surname: "Babevicius", uniqueCode: "fawf2das+f5wfaw" },
    //   // { name: "Unikalų paraiškos kodą", value: "uniquecode" },
    //   // { name: "Tyrėją", value: "examinator" },
    // ];
    const optionsFilter = dropdownListFilter.map((drop, i) => {
      return (
          <>
            <option key={i} id={drop.value} value={drop.value}>
              {drop.name}
            </option>
          </>
      );
    });

    const optionsSort = dropdownListSort.map((drop, i) => {
      return (
          <>
            <option key={i} id={drop.value} value={drop.value}>
              {drop.name}
              {/* <button>word</button> */}
            </option>
          </>
      );
    });

    const allParticipants = Object.values(this.state.allUsers)
        .filter((user) => {
          if (user && (user.uniqueCode || (user.name && user.surname))) {
            if (this.state.searchTerm2 === "") {
              return user;
            } else if (
                (user.uniqueCode
                        .toLowerCase()
                        .replaceAll(" ", "")
                        .trim()
                        .startsWith(
                            this.state.searchTerm2.toLowerCase().replaceAll(" ", "").trim()
                        ) ||
                    user.name
                        .toLowerCase()
                        .replaceAll(" ", "")
                        .trim()
                        .startsWith(
                            this.state.searchTerm2
                                .toLowerCase()
                                .replaceAll(" ", "")
                                .trim()
                        ) ||
                    user.surname
                        .toLowerCase()
                        .replaceAll(" ", "")
                        .trim()
                        .startsWith(
                            this.state.searchTerm2
                                .toLowerCase()
                    .replaceAll(" ", "")
                    .trim()
                )) &&
            this.state.searchTerm2 !== "" &&
            this.state.filterValue === "all"
          ) {
            return user;
          }
        }
      })
      .map((user, i) => {
        return (
          <>
            <option key={i} id={user.uniqueCode} value={user.id}>
              {user.name +
                " " +
                user.surname +
                " - " +
                user.uniqueCode +
                " - " +
                user.personalCode}
              {/* <button>word</button> */}
            </option>
          </>
        );
      });

    return (
      <>
        <div>
          {this.state.loading ? (
            <>
              <div className="d-flex justify-content-center" style={{}}>
                <div
                  className="spinner-grow d-flex justify-content-center"
                  style={{
                    width: "20rem",
                    height: "20rem",
                    marginTop: "80px",
                    marginBottom: "80px",
                    color: "#78003f",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="modal fade"
                id="new-message-modal"
                tabIndex={-1}
                aria-labelledby="new-message-modal"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="new-message-modal-label">
                        Nauja žinutė
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div id="new-message-body" className="modal-body">
                      <form onChange={this.checkEmptyMessage}>
                        <ul className="listStyleText">
                          <label>Paieška</label>
                          <input
                            id="searchusers"
                            type="text"
                            placeholder="Ieškoti..."
                            onChange={this.filterUsers}
                            style={{ width: "100%" }}
                          />
                          <div
                            className="input-group"
                            style={{ paddingRight: "0px" }}
                          >
                            <select id="user-selection" className="form-select">
                              {/* <option key="newdate" id="newdate" value="newdate">
                    Naujausią datą
                  </option> */}

                              {React.Children.toArray(allParticipants)}
                            </select>
                            {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                          </div>
                          <div className="col-12">
                            <div className="form-group message">
                              {/* <i className="fa fa-pencil" /> */}
                              <input
                                id="messagetopic"
                                type="text"
                                placeholder="Tema... "
                                style={{ marginTop: "25px", width: "100%" }}
                              />
                              <textarea
                                name="message"
                                id="message"
                                rows={7}
                                placeholder="Įrašyti žinutę čia... "
                                defaultValue={""}
                                style={{ marginTop: "25px", width: "100%" }}
                              />
                              <label
                                style={{
                                  display: "inline-block",
                                  fontSize: "16px",
                                }}
                              >
                                Patvirtinti siuntimą pasirinktam žmogui&nbsp;
                              </label>
                              <input
                                type="checkbox"
                                className="message-checkbox"
                                id="msgcheckbox"
                                name="subscribe"
                                value="newsletter"
                                style={{
                                  display: "inline-block",
                                  marginTop: "20px",
                                }}
                              />
                            </div>
                          </div>
                        </ul>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn"
                        data-bs-dismiss="modal"
                      >
                        Uždaryti
                      </button>
                      <button
                        type="button"
                        id="sendbtn"
                        className="btn"
                        onClick={this.sendMessage}
                        disabled={this.state.disabledState}
                      >
                        Išsiųsti
                        <i
                          className="fa fa-send-o"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12 col-12"
                style={{ marginTop: "50px" }}
              >
                <div
                  className="col-lg-6 col-md-6 col-sm-12 col-12 "
                  style={{
                    margin: 0,
                    padding: "0 0 25px 0",
                    display: "inline-block",
                  }}
                >
                  <a
                    type="button"
                    className="btn"
                    href="#new-message-modal"
                    data-bs-toggle="modal"
                    role="button"
                    data-bs-target="#new-message-modal"
                    style={{ marginBottom: "25px" }}
                  >
                    <b>Naujas pranešimas dalyviui arba tyrėjui</b>{" "}
                    <i className="fa fa-send-o" />
                  </a>
                  <br />
                  <label>Filtruoti pagal:</label>
                  <div className="" style={{ paddingRight: "25px" }}>
                    <select
                      id="filter-inquiry-selection"
                      className="form-select"
                      onChange={(event) => this.filterItems(event)}
                      style={{ display: "inline-block" }}
                    >
                      <option key="all" id="all" value="all">
                        Visos žinutės / užklausos
                      </option>
                      {React.Children.toArray(optionsFilter)}
                    </select>

                    {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                  </div>
                </div>

                <div
                  className="form-group col-lg-6 col-md-6 col-sm-12 col-12 "
                  style={{
                    margin: 0,
                    padding: "0 0 25px 0",
                    display: "inline-block",
                  }}
                >
                  <label htmlFor="exampleFormControlSelect1">
                    Rūšiuoti pagal:
                  </label>
                  <div className="input-group" style={{ paddingRight: "25px" }}>
                    <select
                      id="sort-inquiry-selection"
                      className="form-select"
                      onChange={(event) => this.sortItems(event)}
                    >
                      <option key="newdate" id="newdate" value="newdate">
                        Naujausią datą
                      </option>
                      {React.Children.toArray(optionsSort)}
                    </select>
                    {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                  </div>
                </div>
                <label>Paieška</label>
                <div
                  className="col-centered"
                  style={{ display: "block", margin: "auto" }}
                >
                  <input
                    id="searchDataInquiry"
                    type="text"
                    placeholder="Ieškoti..."
                    onChange={this.filterItemsSearch}
                  />
                </div>

                <h2 style={{ marginTop: "25px" }}>
                  <b>Pranešimai/Užklausos</b>
                </h2>
                <ul className="designed-list">
                  {React.Children.toArray(paraiskos)}
                </ul>
              </div>

              <nav aria-label="Page navigation example ">
                <div className="col-centered">
                  <ul className="pagination" style={{ display: "flex" }}>
                    <li className="page-item">
                      <button
                        className="btn"
                        href="#"
                        aria-label="Previous"
                        onClick={() =>
                          this.handlePageChange(
                            this.state.currentPage - 1,
                            totalPages
                          )
                        }
                      >
                        <span aria-hidden="true">«</span>
                        <span className="sr-only">Previous</span>
                      </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={"page-item"}
                        onClick={() => this.handlePageChange(pageNumber)}
                      >
                        <button
                          className={
                            pageNumber === currentPage
                              ? "btn active-page"
                              : "btn"
                          }
                          href="#"
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        className="btn"
                        href="#"
                        aria-label="Next"
                        onClick={() =>
                          this.handlePageChange(
                            this.state.currentPage + 1,
                            totalPages
                          )
                        }
                      >
                        <span aria-hidden="true">»</span>
                        <span className="sr-only">Next</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </>
    );
  }
}
export default Messages;
