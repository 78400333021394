import axios from "axios";

const API_URL = "https://covid19.mf.vu.lt/proxy/api/auth";

class AuthService {
    async login(email, password) {
        try {
            const response = await axios.post('https://covid19.mf.vu.lt/proxy/api/auth/signin', {
                email: email,
                password: password,
            }, {
                withCredentials: true // This ensures cookies are included in the request
            });

            // Extract the token from the Set-Cookie header
            // const setCookieHeader = response.headers['set-cookie'];
            // if (setCookieHeader) {
            //     const biobankToken = setCookieHeader
            //         .find((cookie) => cookie.startsWith('biobank='))
            //         .split(';')[0];
            //
            //     console.log('Extracted Biobank Token:', biobankToken);
            //
            //     sessionStorage.setItem('biobankToken', biobankToken);
            // } else {
            //     console.error('Set-Cookie header not found in response');
            // }

            return response.data;
        } catch (error) {
            console.error('Error during sign-in:', error);
            // throw error;
        }
    }

  // async login(email, password) {
  //   return await axios
  //     .post(API_URL + "/signin", {
  //       username: email, //temporary
  //       email: email,
  //       password: password,
  //     })
  //     .then((response) => {
  //       // if (response.data.biobank) {
  //       //   sessionStorage.setItem("auth", JSON.stringify(response.data.biobank));
  //       // }
  //       return response.data;
  //     });
  // }

  async register(email, password, role) {
    return await axios
      .post(API_URL + "/signup", {
        email: email,
        role: role,
        password: password,
      })
      .then((response) => {
        // if (response.data.biobank) {
        //   sessionStorage.setItem("auth", JSON.stringify(response.data.biobank));
        // }

        return response.data;
      });
  }

  async remindPassword(email) {
    return await axios
      .post(API_URL + "/remindByEmail?email=" + email)
      .then((response) => {
        return response.data;
      });
  }

  async changePassword(email, password, token) {
    return await axios
      .post(API_URL + "/updatePassword", {
        password: password,
        email: email,
        token: token,
      })
      .then((response) => {
        return response.data;
      });
  }

  async sendComfirmationEmail(email) {
    return await axios
      .post(API_URL + "/register", {
        email: email,
      })
      .then((response) => {
        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem("user");
    sessionStorage.clear();
  }

  generatePassword(length) {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    //const symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

    const allCharacters = uppercase + lowercase + numbers;
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters[randomIndex];
    }

    return password;
  }
}

export default new AuthService();
