import React from "react";
import dalyvisService from "../../../services/dalyvis-service";
import axios from "axios";

// import Webcam from "react-webcam";
// import Quagga from "quagga";
import { error, last } from "pdf-lib";
import BarcodeScanner from "react-qr-barcode-scanner";
import "../dalyvis-subpages/overlayStyles.css";

const temp = {};
class Scanning extends React.Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.isScanning = false;

    var seconds = 0;
    if (sessionStorage.getItem("seconds") !== null) {
      seconds = sessionStorage.getItem("seconds");
    }

    this.state = {
      time: {},
      seconds: seconds,
      qrCodeShow: false,
      result: "Nenuskenuota",
      date: "Nenustatyta",
      dateToday: "-",
      disabled: true,
      //disabled1: true,
      timeLimit: false,
      samples: [],
      screenshot: "",
      type: "",
      user: "", //JSON.parse(sessionStorage.getItem("user")),
    };
    this.timer = 0;
    this.qrCodeShow = this.qrCodeShow.bind(this);
    this.saveSample = this.saveSample.bind(this);
    // this.unlockQRCode = this.unlockQRCode.bind(this);
    // this.checkContentLength = this.checkContentLength.bind(this);
    // this.startTimer = this.startTimer.bind(this);
    // this.countDown = this.countDown.bind(this);
    this.calculateOffsetDateTimeString =
      this.calculateOffsetDateTimeString.bind(this);
  }
  componentDidMount() {
    let user;
    if (
      window.location.pathname ===
      "/darbuotojas/dalyviu-reg-atstovas/skenavimas"
    ) {
      user = JSON.parse(
        sessionStorage.getItem("registrationData")
      ).regRepresented;
    } else {
      user = JSON.parse(sessionStorage.getItem("regUser"));
      // user.samples = [];
    }

    if (user) {
      this.setState(
        {
          user: user,
        },
        () => {
          document.getElementById("saveqr").disabled = true;

          console.log(this.state.user);
          this.state.user.samples.forEach((sample) => {
            dalyvisService
              .getForUsersamples(sample)
              .then((response) => {
                console.log("Response: " + response);
                var temp = Object.values(this.state.samples);
                temp.push(response);

                console.log(temp);

                this.setState({ samples: temp });
              })
              .catch((error) => window.alert(error.message));
          });
        }
      );
    }

    // var seconds = 900;
    // if (sessionStorage.getItem("seconds") !== null) {
    //   seconds = sessionStorage.getItem("seconds");
    //   this.setState({
    //     disabled1: false,
    //     disabled: false,
    //     timeLimit: true,
    //   });

    //   document.getElementById("pin-code").value = "";
    //   if (this.timer === 0 && this.state.seconds > 0) {
    //     this.timer = setInterval(this.countDown, 1000);
    //   }
    // }

    // this.setState({
    //   seconds: seconds,
    // });

    //-------------
    //function here

    // dalyvisService.getAllSamples().then((response) => {
    //   this.setState({ samples: Object.values(response) });
    // });

    // dalyvisService.getAllSamples().then((response) => {stopScan
    //   console.log("Response: " + response);
    //   // const samples = response.data;
    //   this.setState({ samples: response });
    //   // temp = response;
    // }).catch(error => window.alert(error.message));
    // axios.get(`http://158.129.163.249:8080/samples`)
    // .then(res => {
    //   const samples = res.data;
    //   this.setState({ samples });
    // })
    // console.log(temp);
  }

  componentWillUnmount() {
    this.stopScan();
  }

  startScan = () => {
    if (!this.isScanning) {
      console.log("Started scanning");
      this.isScanning = true;
      // const checkCameraReady = setInterval(() => {
      //   console.log(this.webcamRef.current.video.readyState);
      //   if (this.webcamRef.current.video.readyState >= 4) {
      //     clearInterval(checkCameraReady);
      //     this.isScanning = true;
      //     //this.scanBarcode();
      //     //console.log("Ended scanning barcode");
      //   }
      // }, 100);
    }
  };

  stopScan = () => {
    this.isScanning = false;
  };

  scanBarcode = (code) => {
    console.log("Scanned barcode");
    if (!this.isScanning) return;

    this.setState({
      result: code,
      // screenshot: this.webcamRef.current.getScreenshot(),
    });
    document.getElementById("saveqr").disabled = false;
    console.log("Scanned: " + this.state.result);
  };

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    sessionStorage.setItem("seconds", seconds);
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.timer = 0;
      document.getElementById("saveqr").disabled = true;
      document.getElementById("scanqr").style.display = "block";
      // sessionStorage.setItem("seconds", 900);
      this.setState({
        timeLimit: false,
        qrCodeShow: false,
        seconds: 900,
        disabled1: true,
      });
    }
  }

  handleScan = (data) => {
    if (data) {
      //var dataUpdated = info;
      //localStorage.setItem("data", JSON.stringify(dataUpdated));
      // var user = localStorage.getItem("user");
      // var userId = JSON.parse(user).id;
      // var data1 = JSON.parse(localStorage.getItem("data"));
      // data1.samples.push({"userid":userId, "meginys":data})
      // localStorage.setItem("data",JSON.stringify(data1));
      document.getElementById("saveqr").disabled = false;
      var today = new Date();
      this.setState({
        dateToday: today,
      });
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      this.setState({
        result: data,
        date: today,
      });
      // window.location.reload();
    }
  };
  handleError = (err) => {
    console.error(err);
  };

  calculateOffsetDateTimeString() {
    const currentTime = new Date();
    const isoTimeString = currentTime.toISOString().split(".")[0]; // "YYYY-MM-DDTHH:mm:ss"
    const timezoneOffset = currentTime.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const offsetMinutes = Math.abs(timezoneOffset % 60);
    const offsetSign = timezoneOffset > 0 ? "-" : "+";
    const formattedOffset = `${offsetSign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMinutes).padStart(2, "0")}`;
    const offsetDateTimeString = `${isoTimeString}${formattedOffset}`;

    return offsetDateTimeString;
  }

  async saveSample() {
    var institution = JSON.parse(sessionStorage.getItem("user"));
    const allowedCodes = [71, 72, 20, 21, 16, 18, 24, 25, 52];

    let codeToCheck = parseInt(this.state.result.substring(0, 2));

    if (
      this.state.result.length !== 12 ||
      !allowedCodes.includes(codeToCheck)
    ) {
      this.setState({
        // qrCodeShow: false,
        result: "Neteisingas kodas",
        date: "Nenustatyta",
      });
    }

    if (
      this.state.result.toLowerCase !== "nenuskenuota" &&
      this.state.result.toLowerCase !== "Neteisingas kodas"
    ) {
      // if (
      //   window.confirm(
      //     "Ar tikrai norite išsaugoti mėgintuvėlį su institucijos pasirinkimu: " +
      //       institution
      //   )
      // ) {
      //išsiųsti informaciją į duombazę kad būtų išsaugota
      // const today = new Date();
      // const month = today.getMonth() + 1;
      // const year = today.getFullYear();
      // const date = today.getDate();
      // const currentDate = year + "/" + month + "/" + date;
      // console.log(year, month, date);

      const time = this.calculateOffsetDateTimeString();
      this.setState({ dateToday: time });

      console.log(
        this.state.user.id,
        this.state.result,
        time,
        "type",
        institution.workplace,
        1
      );

      await dalyvisService
        .postSamples(this.state.result, time, "type", institution.workplace, 1)
        .then((id) => {
          dalyvisService
            .getAllPersonalData(this.state.user.id)
            .then((response) => {
              var newUser = response;

              newUser.samples.push(id);

              dalyvisService
                .putCustomObjectPersonalData(newUser.id, newUser)
                .then(() => {
                  Promise.all(
                    newUser.samples.map((sample) =>
                      dalyvisService.getForUsersamples(sample)
                    )
                  )
                    .then((results) => {
                      const temp = results;
                      console.log(temp);
                      // sessionStorage.setItem("user", newUser);
                      // this.setState({ samples: temp });

                      this.setState({ samples: temp, user: newUser }, () => {
                        if (
                          window.location.pathname ===
                          "/darbuotojas/dalyviu-reg-atstovas/skenavimas"
                        ) {
                          const registrationData = {
                            regRepresented: this.state.user,
                          };
                          sessionStorage.setItem(
                            "registrationData",
                            JSON.stringify(registrationData)
                          );
                        } else {
                          sessionStorage.setItem(
                            "regUser",
                            JSON.stringify(this.state.user)
                          );
                        }
                      });
                    })
                    .catch((error) => window.alert(error.message));

                  // this.setState({ samples: temp, user: newUser }, () => {
                  //   try {
                  //     sessionStorage.setItem("user", JSON.stringify(newUser));
                  //   } catch (error) {
                  //     console.error("Failed to update sessionStorage: ", error);
                  //   }
                  // });
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));

      // window.alert("Qr kodas: " + this.state.result + " išsaugotas!");
      document.getElementById("saveqr").disabled = true;
      // document.getElementById("scanqr").style.display = "block";
      this.setState({
        // qrCodeShow: false,
        result: "Nenuskenuota",
        date: "Nenustatyta",
      });
      // window.location.reload();
    }
    // } else {
    //   window.alert("Nenuskenuota");
    // }
  }
  checkContentLength() {
    // document.getElementById("pin-code").value;
    if (document.getElementById("pin-code").value.length === 4) {
      // document.getElementById("unlockqr").disabled = false;
      this.setState({
        disabled: false,
      });
    } else {
      // document.getElementById("unlockqr").disabled = true;
      this.setState({
        disabled: true,
      });
    }
  }

  unlockQRCode = () => {
    // e.preventDefault();

    var pin = document.getElementById("pin-code").value;
    var pinMatch = true;
    console.log("-------------");
    // Send request to check if pin is matching
    // the state of approval has to be checked every minute
    // document.getElementById("scanqr").disabled = false;

    if (pinMatch) {
      // document.getElementById("scanqr").disabled = false;
      this.setState({
        disabled1: false,
        disabled: true,
        timeLimit: true,
      });
      document.getElementById("pin-code").value = "";
      if (this.timer === 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    } else {
    }
  };

  qrCodeShow() {
    this.setState({ qrCodeShow: true });
    this.startScan();

    document.getElementById("scanqr").style.display = "none";
  }

  render() {
    //   var user = JSON.parse(localStorage.getItem("user"));
    //     console.log(user);
    //     var userId = user.id;
    //     console.log("user|userid:" + userId);
    //     var samples1 = JSON.parse(localStorage.getItem("data")).samples;
    //     var samples2 = []
    // for(let i = 0; i < samples1.length; i++){
    //   if(userId == samples1[i].userid){
    //     samples2.push("Dalyvio id: "+ samples1[i].userid +" | Mėginio kodas: " + samples1[i].meginys);
    //   }
    // }

    //--------------------------------------------------------------------------
    //var samplesList = Object.values(this.state.user.samples);
    //--------------------------------------------------------------------------
    var samplesList = Object.values(this.state.samples);

    // console.log("----sampleList"+samplesList);
    //   const samples = {};
    //   if(samplesList !== {}){
    const samples = samplesList
      .sort(function (a, b) {
        if (a.addedAt > b.addedAt) {
          return -1;
        }
        if (a.addedAt < b.addedAt) {
          return 1;
        }
        return 0;
      })
      .map((data) => {
        return (
          <>
            <tr>
              <td>{data.addedAt}</td>
              <td>{data.sampleCode}</td>
            </tr>
          </>
        );
      });
    // }
    // const samples ={};
    // console.log("----" + this.state.samples);
    // const samples = samplesfromdatabase.map((data) => {
    //   return (
    //     <>
    //       <tr>
    //         <td>{data.date}</td>
    //         <td>{data.qrcode}</td>
    //       </tr>
    //     </>
    //   );
    // });

    return (
      <>
        {/* <div className="col-12"> */}
        {/* <div className="single-main" style={{ marginTop: "30px" }}> */}
        {/* <h2 style={{display: "inline-block"}}>Priimanti institucija:</h2> &nbsp;
                    <h2 style={{display: "inline-block",color: "#78003f"}}>
                      LPRLB</h2> */}

        {/* <form>
            <label>
              <h2 style={{ display: "inline-block" }}>
                Priimanti institucija:&nbsp;
              </h2>
            </label>
            <select
              className="btn select-style"
              id="institucija"
              style={{
                fontSize: "20px",
                width: "50%",
                maxWidth: "75%",
                marginLeft: "10px",
              }}
            >
              <option value="lprlb">LPRLB</option>
              <option value="nks">NKS </option>
            </select>
            <br />
            <br />
          </form>
          <br /> */}

        {/* <h3>Seselė (specialistas), kuris paėmė:</h3>
                    <p>Eliza Borislova</p> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="col-12"> */}
        <div className="single-main">
          <div
            className="col-centered col-lg-6 col-md-6 col-sm-10 col-12 qrcode-float-effect"
            style={{
              textAlign: "center",
              marginBottom: "20px",
              padding: 0,
              minWidth: "550px",
            }}
          >
            <div
              className="col-centered col-12 "
              id="reader"
              style={{
                display: "inline-block",
                marginBottom: "0",
                position: "relative",
                padding: "0px",
                border: "1px solid silver",
                textAlign: "center",
                minWidth: "550px",
              }}
            >
              <div
                id="qrcodescannerbox"
                style={{
                  textAlign: "left",
                  margin: "0px",
                  fontSize: "20px",
                  marginBottom: "0",
                  padding: "10px",
                  minWidth: "550px",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    margin: "10px",
                    fontWeight: "800",
                    color: "#78003f",
                  }}
                >
                  QR kodų skaitytuvas
                </span>
                {this.state.qrCodeShow ? (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <BarcodeScanner
                          width={500}
                          height={500}
                          onUpdate={(err, result) => {
                            if (result) {
                              this.scanBarcode(result.text);
                            }
                          }}
                          delay={50}
                        />
                        <div className="scanner-overlay">
                          <div className="scan-border top-left"></div>
                          <div className="scan-border top-right"></div>
                          <div className="scan-border bottom-left"></div>
                          <div className="scan-border bottom-right"></div>
                        </div>
                      </div>
                    </div>

                    <p>{this.state.result}</p>
                  </div>
                ) : (
                  // <img
                  //   width={"100%"}
                  //   src={this.state.screenshot}
                  //   alt="QR code"
                  //   style={{ opacity: "0.3" }}
                  // />
                  <div></div>
                )}
                <br />
                {/* {this.state.timeLimit && (
                  <div className="col-centered">
                    <button className="btn">{this.state.time.m}</button>
                    <button className="btn">{this.state.time.s}</button>
                  </div>
                )}
                <label
                  style={{ width: "80%", marginLeft: "8%", marginRight: "8%" }}
                >
                  Darbuotojo įvedamas PIN kodas
                </label>
                <input
                  id="pin-code"
                  type="password"
                  placeholder="PIN kodas"
                  style={{ width: "80%", marginLeft: "8%", marginRight: "8%" }}
                  maxLength="4"
                  onChange={this.checkContentLength}
                />
                <button
                  className="btn"
                  id="unlockqr"
                  style={{ width: "80%", marginLeft: "8%", marginRight: "8%" }}
                  onClick={() => this.unlockQRCode()}
                  disabled={this.state.disabled}
                >
                  Atrakinti skenavimą
                </button> */}
                <button
                  className="btn"
                  id="scanqr"
                  style={{ width: "80%", marginLeft: "8%", marginRight: "8%" }}
                  onClick={this.qrCodeShow}
                  // disabled={this.state.disabled1}
                >
                  Skenuoti QR kodą
                </button>
              </div>
            </div>
            <br />
            <ul
              className="qr-scanned-style col-12 col-centered"
              style={{
                display: "inline-block",
                border: "none",
                marginBottom: 0,
                padding: "10px",
              }}
            >
              <p className="col-lg-12 " style={{ textAlign: "left" }}>
                Data: {this.state.date}
              </p>

              <p
                className="col-lg-12 "
                style={{
                  textAlign: "left",
                  whiteSpace: "initial",
                  overflow: "break-word",
                  wordBreak: "break-all",
                }}
              >
                Rezultatas: {this.state.result}
              </p>
              <button
                className="btn "
                id="saveqr"
                title="Išsaugoti"
                style={{
                  float: "center",
                  fontSize: "40px",
                  marginRight: "20px",
                }}
                onClick={this.saveSample}
              >
                Išsaugoti<i className="fa fa-save"></i>
              </button>
            </ul>
            <div className="empty" />
            <div id="scanned-result" />
          </div>
        </div>
        {/* </div> */}
        {/* <div className="col-12 col-centered"> */}
        <div className="single-main">
          <h3 style={{ marginBottom: "25px" }}>Biobanke saugomi mėginiai</h3>

          <div className="col-12 col-centered">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Data</th>
                  <th scope="col">QR kodas</th>
                </tr>
              </thead>
              <tbody
                style={{
                  whiteSpace: "initial",
                  overflow: "break-word",
                  wordBreak: "break-all",
                }}
              >
                {React.Children.toArray(samples)}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default Scanning;
