import React from 'react';
import ExamSideNav from '../../sidenavs/examsidenav.jsx'

class MeginiaiTyrejai extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <ExamSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Mėginių ir sveikatos informacijos teikimo tvarka</h2>


                    <h2 className="thick" style={{ fontSize: "25px", textAlign: "center" }}>Tyrėjo paskyros sukūrimas, užklausos
                      ir paraiškos pateikimas LPRLB informacinėje sistemoje
                    </h2>
                    <h2 className="thick" style={{ fontSize: "25px", textAlign: "center" }}>Instrukcija</h2>
                    <h2 className="thick" style={{ fontSize: "20px", textAlign: "left" }}>Tyrėjo paskyros susikūrimas:</h2>
                    <ol className="listStyleText">
                      <li>Lietuvos populiacijos ir retųjų ligų biobanko svetainės puslapyje pasirenkama: <a href="/registerselect">Registruotis</a></li>
                      <li>Atsidariusiame puslapyje pasirenkama skiltis <a href="/registertypetyrejas">Tyrėjams</a>.</li>
                      <li>Užpildomi informaciniai laukeliai: vardas, pavardė, asmens kodas, darbovietės pavadinimas, telefono numeris, elektroninis paštas, slaptažodis (2 kartus), spaudžiama <b>Pateikti</b>.</li>
                      <li>Į registracijos metu nurodytą arba EVV nurodytą elektroninį adresą gaunamas registracijos patvirtinimas. Paspaudus ant gautos nuorodos, patvirtinama registracija.</li>
                      <li>Paspaudus nuoroda yra aktyvuojama paskyra, ir Tyrėjas tada gali <a href="/login">Prisijungti</a>.</li>
                    </ol>
                    <h2 className="thick" style={{ fontSize: "20px", textAlign: "left" }}>Prisijungimas prie Tyrėjo paskyros ir Užklausos pateikimas:</h2>
                    <ol className="listStyleText">
                      <li>Lietuvos populiacijos ir retųjų ligų biobanko svetainės puslapyje pasirenkama: <a href="/login">Prisijungti</a>.</li>
                      <li>Paspaudus atsidaro du langeliai: <b>Elektroninis paštas</b> ir <b>Slaptažodis</b>.</li>
                      <li>Įvedus duomenis ir paspaudus mygtuką atsidaro <b>Tyrėjo paskyra</b></li>
                      <li>Atsidariusiame puslapyje kairėje esančiame meniu pasirenkama skiltis <b>Mano užklausos</b> ir spaudžiama <b>Pildyti naują užklausą</b>. Įvedama Tema ir formuluojamas užklausos tekstas laukelyje Tekstas. Spaudžiama <b>Pateikti</b>.</li>
                      <li>Kairiajame meniu pasirinkus skiltį <b>Mano užklausos</b> ir pasirinkus <b>Pateiktos užklausos</b>, galima matyti visas Tyrėjo išsiųstas užklausas. Skiltis <b>Pildyti naują užklausą</b> gali būti naudojamos ir susisiekimui su biobanku įvairiais rūpimais klausimais.</li>
                    </ol>
                    <h2 className="thick" style={{ fontSize: "20px", textAlign: "left" }}>Paraiškos pateikimas:</h2>
                    <ol className="listStyleText">
                      <li>Prisijungus prie <a href="/registertypetyrejas">Tyrėjo paskyros</a>, kairiajame meniu pasirenkama skiltis <b>Mano paraiškos</b>, ir <b>Pildyti naują paraišką</b>.</li>
                      <li>Atsivėrusiame lange pildomi langai (žvaigždute pažymėti laukai yra privalomi): <b>Projekto pavadinimas, Projekto akronimas, Projekto pagrindinis tyrėjas, Tyrimo protokolo santrauka (prisegamas dokumentas), Bioetikos leidimas (prisegamas dokumentas), Projekto pradžia ir pabaiga, prisegama Paraiška pdf formatu</b>, jei reikalinga, pildomas langas <b>Papildoma informacija</b>. Spaudžiama <b>Pateikti</b>.</li>
                      <li>Kairiajame meniu pasirinkus skiltį <b>Mano paraiškos</b> ir pasirinkus <b>Pateiktos paraiškos</b>, galima matyti visas Tyrėjo biobankui pateiktas paraiškas.</li>
                    </ol>
                    <h2 className="thick" style={{ fontSize: "20px", textAlign: "left" }}>Tolimesni veiksmai Tyrėjui pateikus Paraišką Tyrėjo paskyroje:</h2>
                    <ol className="listStyleText">
                      <li>Biobanko Administratorius per 5 darbo dienas. įvykdo administracinį Paraiškos vertinimą. Jei Paraiška neatitinka reikalavimų, Administratorius tai nurodo Naudotojo paskyroje, Naudotojas turi galimybę papildyti Paraišką per Administratoriaus nustatytą terminą. Naudotojui papildžius Paraišką, siunčiamas automatinis pranešimas į Administratoriaus paskyrą, Administratorius vykdo pakartotinį administracinį Paraiškos vertinimą.</li>
                      <li>Jei Paraiška tenkina administracinio vertinimo reikalavimus, Administratorius persiunčia Paraišką Biobanko Mokslo komitetui. Mokslo komitetą, Biobanko vadovo paskyrimu, sudaro Biobanko vadovas, bioetikos specialistas, asmens duomenų apsaugos specialistas, mokslininkai. Mokslo komitetas mokslinį vertinimą įvykdo per 10 d.d. </li>
                      <li>Biobanko Mokslo komitetui priėmus neigiamą sprendimą, Naudotojas savo paskyroje gali teikti papildomus dokumentus bei papildomą informaciją. Papildžius Paraišką, sistema automatiškai informuoja Administratorių, papildyta Paraiška perduodama Mokslo komitetui pakartotiniam moksliniam vertinimui, per 10 darbo dienų.</li>
                      <li>Mokslo komiteto atstovas teigiamą mokslinio vertinimo rezultatą ir pasirašyta Garantinį raštą arba neigiamą mokslinio vertinimo rezultatą pateikia Naudotojui jo paskyroje per 3 darbo dienas. nuo sprendimo priėmimo. </li>
                      <li>Praėjus mokslinį vertinimo etapą, su Naudotoju pasirašoma Biobanke saugomų biologinių ėminių ir sveikatos informacijos perdavimo sutartis.</li>
                      <li>Biologiniai ėminiai ir sveikatos informacija perduodama tik Biomedicininių tyrimų etikos įstatymo galiojančios redakcijos 17 straipsnio 1 ir 2 dalyse nurodytiems asmenims, t.y. Naudotojams turintiems LBTEK/RBTEK pritarimą biomedicinos tyrimui.</li>
                      <li>Biologinius ėminius ir sveikatos informaciją Naudotojui teikia Biobankas tik Naudotojui įvykdžius pateiktas sąlygas pasirašant Mėginių ir sveikatos informacijos perdavimo-priėmimo aktą.</li>
                      <li>Už moksliniams tyrimams vykdyti perduotus Mėginius ir surinktą sveikatos informaciją Naudotojų bus prašoma padengti jų surinkimo, apdorojimo, saugojimo, transportavimo ir kt. kaštus.</li>
                      <li>Naudotojas įsipareigoja nurodyti Mėginių ir Sveikatos informacijos gavimo šaltinį (Centras, Šalis (ys)) savo mokslinėse publikacijose bei kitaip viešinant gautuosius rezultatus.</li>
                      <li>Pasibaigus sutarčiai su Naudotoju, per mėnesį Naudotojas įsipareigoja pateikti trumpą ataskaitą apie projekto eigą ir pasiektus fizinius rodiklius, apie Biobanko kolekcijų teikimo sutarties sąlygų vykdymo pagrindinius aspektus/nukrypimus/rizikas atsakingam asmeniui (administratoriui).</li>
                    </ol>
                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default MeginiaiTyrejai;