import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class ApieVUbio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Apie VU biobanką</h2>
                    <ul className="listStyleText">
                      <li>Biobankas kaupia Lietuvos populiacijos sveikų asmenų ir sergančių retomis ligomis asmenų biologinius mėginius ir su jais susijusią asmens
                        sveikatos informaciją bei suteikia prieigą prie jų tyrėjams, kurie vykdo mokslinius tyrimus ar vykdys juos ateityje. </li>
                      <li>Lietuvos ir užsienio mokslininkai Biobanku gali naudotis kaip biblioteka: užuot kiekvienam moksliniam tyrimui ieškoję
                        vis naujų savanorių, mokslininkai gali pasinaudoti Biobanke jau sukauptais aukštos kokybės mėginiais bei sveikatos informacija ir
                        pasidalinti su Biobanku mokslinių tyrimų metu gauta informacija, tokiu būdu dar padidinant sukauptų mėginių mokslinę vertę.
                        Mokslininkai, mokslinių tyrimų metu analizuodami sveikatos informaciją ir biologinių mėginių tyrimų rezultatus, gali atrasti naujus
                        ligų rizikos veiksnius, sukurti naujus visuomenės sveikatos gerinimo būdus, naujus ligų diagnostikos ir gydymo metodus, naujus vaistus
                        ar ligų prevencijos būdus. Mokslininkams atliekant biomedicininius tyrimus gali paaiškėti Jūsų sveikatai svarbi informacija, o tai
                        leistų savalaikiai ir efektyviai suteikti Jums tinkamas sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti nustatytą sveikatos sutrikimą.</li>
                      <li>Biobankas, kaupdamas didelį skaičių biologinių mėginių ir vis papildydamas su mėginiais susijusią sveikatos informaciją, palengvins ir pagerins mokslinių
                        tyrimų organizavimą ir atlikimą: bus galima atlikti didelės apimties, patikimesnius ir įvairesnius mokslinius tyrimus.</li>
                      <li>VU MF Lietuvos populiacijos ir retųjų ligų biobanko veikla finansuojama VU ir Lietuvos Respublikos biudžeto spec. programos lėšomis.</li>
                      <li>Infrastruktūra sukurta Europos sąjungos struktūrinių fondų (Europos regioninės plėtros fondo) finansuojamų projektų  lėšomis „Vilniaus
                        universiteto Medicinos fakulteto Mokslo centro sukūrimas“ (projekto Nr. 01.1.1-CPVA-V-701-12-0001) ir „Žmogaus biologinių išteklių centras“
                        (projekto Nr. 01.1.1-CPVA-V-701-12-0001) pagal 2014-2020 metų Europos sąjungos fondų investicijų veiksmų programos 1 prioriteto „Mokslinių tyrimų,
                        eksperimentinės plėtros ir inovacijų skatinimas“ įgyvendinimo priemonę Nr.01.1.1-CPVA-V-701 „Mokslinių tyrimų, eksperimentinės plėtros ir inovacijų
                        infrastruktūros plėtra ir integracija į europines infrastruktūras“.</li>
                    </ul>

                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default ApieVUbio;