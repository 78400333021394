import React, { Component } from "react";

import Header from "./components/mainheader.jsx";
import Index from "./components/index.jsx";
import Footer from "./components/footer.jsx";

import ParticipantForm from "./components/user-pages/dalyvis.jsx";

import ExaminatorForm from "./components/user-pages/tyrejas.jsx";
import WorkerForm from "./components/user-pages/institucijospaskyra.jsx";
import AdminForm from "./components/user-pages/administratoriauspaskyra.jsx";
import MiniAdminForm from "./components/user-pages/mini-admin.jsx";
import News from "./components/naujienos.jsx";
import Error from "./components/404.jsx";
import Login from "./components/login.jsx";
import RegisterSelect from "./components/registration/registerselection.jsx";
import RegisterTypeTyrejas from "./components/registration/registertypetyrejas.jsx";
import RemindPassword from "./components/loginpassword"
import ChangePassword from "./components/remindpassword";
import UserRegistration from "./components/user-pages/registration/registration";
//login user start
import RegisterType from "./components/loginuser/registertype.jsx";
import Participation from "./components/loginuser/participation.jsx";
import Participation2 from "./components/loginuser/participation2.jsx";
import NotParticipating from "./components/loginuser/participation-subpages/notparticipating.jsx";
//login user end

import LoginType from "./components/login/logintype.jsx";

import ExamGroups from "./components/tyrejugrupems.jsx";
import Contacts from "./components/kontaktai.jsx";

//Naujas Apie
import ApieVUbio from "./components/page-info-pages/apieatnaujintas/apievubio.jsx";
import TikslaiMisija from "./components/page-info-pages/apieatnaujintas/tikslaimisija.jsx";
import StrukturaKontaktai from "./components/page-info-pages/apieatnaujintas/strukturakontaktai.jsx";
import Struktura from "./components/page-info-pages/apieatnaujintas/struktura.jsx";
import Meginiai from "./components/page-info-pages/apieatnaujintas/meginiai.jsx";
import Projektai from "./components/page-info-pages/apieatnaujintas/projektai.jsx";
import Licencija from "./components/page-info-pages/apieatnaujintas/licencija.jsx";
import BiobankoTeises from "./components/page-info-pages/apieatnaujintas/biobankoteises.jsx";
// import TeiseTeikti from './components/apieatnaujintas/teiseteikti.jsx'
import TeisesAktai from "./components/page-info-pages/apieatnaujintas/teisesaktai.jsx";
import AsmensDuomenys from "./components/page-info-pages/apieatnaujintas/asmensduomenys.jsx";
import Institucijos from "./components/page-info-pages/apieatnaujintas/institucijos.jsx";
import Publikacijos from "./components/page-info-pages/apieatnaujintas/publikacijos.jsx";
import Ziniasklaida from "./components/page-info-pages/apieatnaujintas/ziniasklaida.jsx";
import Renginiai from "./components/page-info-pages/apieatnaujintas/renginiai.jsx";
//Ending Naujas Apie

//Naujas dalyviams
import Dalyviams from "./components/page-info-pages/dalyviamsatnaujintas/dalyviams.jsx";
import KaipTapti from "./components/page-info-pages/dalyviamsatnaujintas/kaiptapti.jsx";
import DalyvioTeises from "./components/page-info-pages/dalyviamsatnaujintas/dalyvioteises.jsx";
import TeiseAtsaukti from "./components/page-info-pages/dalyviamsatnaujintas/teiseatsaukti.jsx";
import DUK from "./components/page-info-pages/dalyviamsatnaujintas/duk.jsx";
import Formos from "./components/page-info-pages/dalyviamsatnaujintas/formos.jsx";
//Naujas dalyviams END

//tyrejams
import Tyrejams from "./components/page-info-pages/tyrejams/tyrejams.jsx";
import MeginiaiTyrejai from "./components/page-info-pages/tyrejams/meginiaityrejai.jsx";

//---

import DevelopmentTesting from "./components/developmenttesting.jsx";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import ApiTest from "./components/testingapi.jsx";
import UserRegister from "./components/user-pages/registration/registration";

class App extends Component {
  render() {
    return (
        <div className="App">
          <Header />
          <BrowserRouter>
            <Switch>
              {/*Main navbar START*/}

              <Route path="/" exact component={Index}></Route>
              <Route path="/subpagrindinis" exact component={Index}></Route>
              {/* <Route path='/duk' exact component= {Duk}></Route> */}
              <Route path="/login" exact component={Login}></Route>
              <Route path="/remindlogin" exact component={RemindPassword}></Route>
              <Route path="/remindpassword" exact component={ChangePassword}></Route>
              <Route
                  path="/registerselect"
                  exact
                  component={RegisterSelect}
              ></Route>
              {/* <Route path='/register' exact component= {Register}></Route> */}
              {/* login user */}
              <Route
                  path="/registertypeuser"
                  exact
                  component={RegisterType}
              ></Route>
              <Route
                  path="/registertypetyrejas"
                  exact
                  component={RegisterTypeTyrejas}
              ></Route>
              {/* <Route path='/loginselect' exact component= {LoginSelect}></Route> */}
              <Route path="/logintype" exact component={LoginType}></Route>
              {/* <Route path="/dalyvavimas" component={Participation}></Route>
            <Route path="/dalyvavimas*" exact component={Participation}></Route>
            <Route path="/nedalyvis" exact component={NotParticipating}></Route> */}
              <Route exact path="/selection" component={Participation2}></Route>
              {/*Formos */}
              <Route path="/dalyvis" component={ParticipantForm}></Route>
              <Route path="/dalyvis*" exact component={ParticipantForm}></Route>
              {/* <Route path='/dalyvis/klausimynas' component={Questionnaire} /> */}

              <Route path="/tyrejas" component={ExaminatorForm}></Route>
              <Route path="/tyrejas*" exact component={ExaminatorForm}></Route>

              <Route path="/darbuotojas" component={WorkerForm}></Route>
              <Route path="/darbuotojas*" exact component={WorkerForm}></Route>

              <Route path="/administratorius" component={AdminForm}></Route>
              <Route
                  path="/administratorius*"
                  exact
                  component={AdminForm}
              ></Route>

              <Route path="/mini-admin" component={MiniAdminForm}></Route>
              <Route path="/mini-admin*" exact component={MiniAdminForm}></Route>

              {/* <Route path='/institucijos-paskyra' exact component= {WorkerForm}></Route>
            <Route path='/institucijos-paskyra*' exact component= {WorkerForm}></Route> */}

              <Route path="/naujienos" exact component={News}></Route>
              {/* <Route path='/dalyviams' exact component= {PartGroups}></Route> */}
              <Route path="/tyrejugrupems" exact component={ExamGroups}></Route>
              <Route path="/kontaktai" exact component={Contacts}></Route>

              {/*Main navbar END*/}

              {/* Naujas Apie */}
              <Route
                  path="/tikslaimisija"
                  exact
                  component={TikslaiMisija}
              ></Route>
              <Route path="/apievubio" exact component={ApieVUbio}></Route>
              {/* Probably together with contacts */}
              <Route
                  path="/strukturakontaktai"
                  exact
                  component={StrukturaKontaktai}
              ></Route>
              <Route path="/struktura" exact component={Struktura}></Route>
              <Route path="/meginiai" exact component={Meginiai}></Route>
              <Route path="/projektai" exact component={Projektai}></Route>
              {/* <Route path='/registraiduomenys' exact component= {RegistraiDuombazes}></Route> */}
              <Route path="/licencija" exact component={Licencija}></Route>
              <Route
                  path="/biobankoteises"
                  exact
                  component={BiobankoTeises}
              ></Route>
              {/* <Route path='/teiseteikti' exact component= {TeiseTeikti}></Route> */}
              <Route path="/teisesaktai" exact component={TeisesAktai}></Route>
              <Route
                  path="/duomenutvarkymas"
                  exact
                  component={AsmensDuomenys}
              ></Route>
              <Route path="/institucijos" exact component={Institucijos}></Route>
              <Route path="/publikacijos" exact component={Publikacijos}></Route>
              <Route
                  path="/mesziniasklaidoje"
                  exact
                  component={Ziniasklaida}
              ></Route>
              <Route path="/renginiai" exact component={Renginiai}></Route>

              {/* EndingNaujas Apie */}

              {/* naujas Dalyviams */}
              <Route path="/dalyviams" exact component={Dalyviams}></Route>
              <Route path="/kaiptapti" exact component={KaipTapti}></Route>
              <Route
                  path="/registracija"
                  component={UserRegistration}
              ></Route>
              <Route
                  exact path="/registracija*"
                  component={UserRegistration}
              ></Route>
              <Route
                  path="/dalyvioteises"
                  exact
                  component={DalyvioTeises}
              ></Route>
              <Route
                  path="/teiseatsauktikeisti"
                  exact
                  component={TeiseAtsaukti}
              ></Route>
              <Route path="/duk" exact component={DUK}></Route>
              <Route path="/formos" exact component={Formos}></Route>
              {/* naujas Dalyviams end*/}

              {/* naujas tyrejams */}
              <Route path="/tyrejams" exact component={Tyrejams}></Route>
              <Route
                  path="/meginiuteikimotvarka"
                  exact
                  component={MeginiaiTyrejai}
              ></Route>
              {/*end naujas tyrejams */}

              {/* Footer linkai */}
              <Route path="/vilniausuni" exact component={Index}></Route>
              <Route path="/paslaugos" exact component={Index}></Route>
              <Route path="/konsultacijos" exact component={Index}></Route>
              <Route path="/kitipuslapiai" exact component={Index}></Route>
              <Route path="/404" exact component={Error}></Route>
              {/*Role Base Routing*/}
              {/* <RoleBasedRouting exact path="/admin" component={AdminPage} roles={['ROLE_ADMIN']} /> */}

              <Route path="/test" exact component={ApiTest}></Route>
              <Route
                  path="/development"
                  exact
                  component={DevelopmentTesting}
              ></Route>
              <Route path="*" exact component={Error}></Route>
            </Switch>
          </BrowserRouter>

          <Footer />
        </div>
    );
  }
}

export default App;
