import React, { Component } from 'react';
import dalyvisService from '../../../services/dalyvis-service';


class Pranesimai extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          messages: ""
        };
        
        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }
    componentDidMount(){
      dalyvisService.getAllMessages().then((response)=>{
        this.setState({
          messages: response
        });
      }).catch(error => window.alert(error.message));
    }
    showInfo(id){
    //   const messages = [{topic: "Žinutė1 ŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė2 ŽinutėŽinutėŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė3 ŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė4 ŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " }
    // ]; // is duombazes gaunamos zinutes

    var value = document.getElementById(id);
    // for(let i = 0; i < messages.length; i++){
    //   var innerId = messages[i].topic.toLowerCase().trim();
    //   if(id !== innerId){
    //     document.getElementById(innerId).style.display = "none";
    //   }
    // }

    if(value.style.display === "none"){
      value.style.display = "block";
     
    }   
    else{
    value.style.display = "none";
    
  }

    }

    render() { 
    //     const messages = [{topic: "Žinutė1 ŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė2 ŽinutėŽinutėŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė3 ŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " },
    //     {topic: "Žinutė4 ŽinutėŽinutėŽinutėŽinutėŽinutė", message: "Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė Žinutė " }
    // ];
    const messages = Object.values(this.state.messages);
    const messageList = messages.filter((data)=>{
      if(data.messageState == 4){
        return data;
      }
    }).map((data) => {
      var id = data.messageText.toLowerCase().trim();
      return (<>

          <button  className="applicationList" onClick={() => this.showInfo(id)} style={{width: "100%", border: "none"}}>
              
                    <p style={{float:"left"}}>{data.messageText}</p>
                    <p style={{float:"right"}}> {data.addedAt} </p>
                 
                 </button>
                 <li className= "col-centered" id={data.messageText.toLowerCase().trim()} style= {{display: "none"}}>
                    <p className= "messageDesign" style={{}}>
                      {data.messageText}
                    </p>

                 </li>

      </>
      )
    });


        
        return <>
                  
                  
       
                 
                <div className="col-centered col-lg-10 col-md-12 col-12">
                {/* <h2 style={{marginTop: "50px",marginBottom: "25px",fontSize: "2rem"}}>Pranešimai</h2> */}
                  <ul>
                  {React.Children.toArray(messageList)}
                  </ul>
      
              </div>
                </>;
    }
}
 
export default Pranesimai;