import React from 'react';



class DocumentAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: "",

        };

        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }

    showInfo(id) {

        var value = document.getElementById(id);


        if (value.style.display === "none") {
            value.style.display = "block";

        }
        else {
            value.style.display = "none";

        }


    }

    render() {
        // var allMessages = JSON.parse(sessionStorage.getItem("messagelist"));
        // // this.props.allMessages
        // var messageList = allMessages.map(data => {
        //   return (<>

        //     <p className="applicationList" onClick={() => this.showInfo(data.id)} style={{ width: "100%" }}>
        //       <div className="row">
        //         <div className="col-lg-4"><p style={{ float: "left", display: "inline-block" }}>{data.messageTopic}</p></div>
        //         <div className="col-lg-4"><p style={{}}> {data.addedAt.slice(0, data.addedAt.indexOf("T"))} </p></div>
        //         <div className="col-lg-4"><button className='btn' style={{ float: "right", }}>Visos žinutės <i className="fa fa-envelope" style={{ fontSize: "24px" }}></i></button></div>

        //       </div>

        //     </p>
        //     <li className="col-centered" id={data.id} style={{ display: "none", marginTop: "0", marginBottom: "0" }}>
        //       <p className="messageDesign" style={{ wordBreak: "break-all" }}>
        //         {data.messageText}
        //       </p>
        //     </li>
        //   </>
        //   )
        // })

        return <>


            {/* <div className="col-lg-12 col-12"> */}
            {/* <div className="comments-form"> */}


            <div className='row'>

                <div className="col-lg-8 col-md-6 col-sm-12">
                    <h1 style={{ color: "#78003f", margin: "25px" }}>
                        Dokumentai
                    </h1>

                </div>
                <div>
                    <table className="table" style={{}}>
                        <thead className="thead-dark">
                            <tr>

                                <th scope="col" style={{width: "40%"}}>
                                    Duomenys

                                </th>
                                <th scope="col">Dokumentai atsiųsti</th>
                                <th scope="col">Atstovaujami duomenys?</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <th scope="row">1</th> */}
                                {/* <th scope="row">Data</th> */}
                                <td>
                                    Unikalus kodas<br />
                                    Asmens kodas<br />
                                    Vardas Pavardė<br />
                                    {/* {datePassed} */}
                                </td>
                                <td>
                                    <a href="" className="btn" download>Asmens sutikimo forma</a><br/><br/>
                                    <a href="" className="btn" download>Atsisakymo forma</a>
                                    {/* {datePassed} */}
                                </td>
                                <td>
                                    Taip ar ne
                                    {/* {datePassed} */}
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                {/* {React.Children.toArray(messageList)} */}
            </div>
            {/* </div> */}
            {/* </div> */}




        </>;
    }
}

export default DocumentAdmin;