import axios from 'axios';
import authHeader from './auth-header.js';

const API_URL = "http://localhost:8080/api/test/";

class UserService {
    getPublicContent(){
        return axios.get(API_URL = 'all');
    }

    getWorkerBoard(){
        
        return axios.get(API_URL + 'admin', {headers : authHeader() });
    }

    getExaminatorBoard(){
        return axios.get(API_URL + 'mod', {headers: authHeader() });
    }
    
    getParticipantBoard(){
        // window.alert(JSON.stringify(authHeader()));
        // const user = JSON.parse(localStorage.getItem('user'));
        // window.alert(user.accessToken);
        
        return axios.get(API_URL + 'user', { headers: authHeader() ,"Access-Control-Allow-Origin": "*"});
    }
}
 
export default new UserService();