import React, { Component } from 'react';
import document1 from "../../../../docs/important/3_SUTIKIMAS_globotiniui_LPRLB.pdf"


class Atstovas2 extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            authorizedEVVLink: "/dalyvavimas/uploaddocument"
        };
        
        
    }
    componentDidMount(){
      //implementuoti checka state jei prisijungia per evv
      var authorizedevv = false;
      if(authorizedevv){
          this.setState({
              authorizedEVVLink: "/dalyvavimas/infoforma"
          });

      }
      else{
          this.setState({
              authorizedEVVLink: "/dalyvavimas/uploaddocument"
          });
      }
  }

    render() { 
       
        
        return <>
            <embed src={document1} type="application/pdf" width="100%" height="600px" />
          <a href={this.state.authorizedEVVLink} className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateUploadAtstovas} >
          Perskaičiau Asmens informavimo formą ir sutinku, kad mano atstovaujamas asmuo dalyvautų biobanko veikloje
          </a>
          <a href="/nedalyvis" className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateNedalyvausiu}>
            Nesutinku
          </a>

                </>;
    }
}
 
export default Atstovas2;