import React from 'react';



class MessageList extends React.Component {
  constructor(props) {
    super(props);
    var user = sessionStorage.getItem("user");
    this.state = {
      messageUser1: "Deivid", // Has to be set based on specific values
      messageUser2: "Tester",
      id1: JSON.parse(sessionStorage.getItem("msgid1")),
      id2: JSON.parse(sessionStorage.getItem("msgid2")),
      user: user,
      messages: user.messages
    };

    //this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  showInfo(id) {

    var value = document.getElementById(id);


    if (value.style.display === "none") {
      value.style.display = "block";

    }
    else {
      value.style.display = "none";

    }


  }

  render() {
    var allMessages = JSON.parse(sessionStorage.getItem("messagelist"));
    // this.props.allMessages
    var messageList = allMessages.map(data => {
      return (<>

        <button className="applicationList" onClick={() => this.showInfo(data.id)} style={{ width: "100%" }}>
          <div className="row">
            <div className="col-lg-4"><p style={{ float: "left", display: "inline-block" }}>{data.messageTopic}</p></div>
            <div className="col-lg-4"><p style={{}}> {data.addedAt.slice(0, data.addedAt.indexOf("T"))} </p></div>
            {/* <div className="col-lg-4"><button className='btn' style={{ float: "right", }}>Visos žinutės <i className="fa fa-envelope" style={{ fontSize: "24px" }}></i></button></div> */}

          </div>

        </button>
        <li className="col-centered" id={data.id} style={{ display: "none", marginTop: "0", marginBottom: "0" }}>
          <p className="messageDesign" style={{ wordBreak: "break-all" }}>
            {data.messageText}
          </p>
        </li>
      </>
      )
    })

    return <>


      {/* <div className="col-lg-12 col-12"> */}
      <div className="comments-form">


        <div className='row'>

          <div className="col-lg-8 col-md-6 col-sm-12">
            <h1 style={{ color: "#78003f", margin: "25px" }}>
              Žinutės tarp {this.state.messageUser1}
              <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
              <i class="fa fa-arrow-right" aria-hidden="true"></i> {this.state.messageUser2}
            </h1>

          </div>
          {React.Children.toArray(messageList)}
        </div>
      </div>
      {/* </div> */}




    </>;
  }
}

export default MessageList;