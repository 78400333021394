import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class BiobankoTeises extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Biobanko teisės</h2>
                    <h2 className="thick" style={{ fontSize: "18px" }}>Teisė tvarkyti mėginius ir sveikatos informaciją po procedūrų</h2>
                    <ul className="listStyleText">
                      <li>Biobanke gali būti saugomi Žmogaus biologiniai mėginiai, likę po chirurginių operacijų, invazinių ir (ar) intervencinių procedūrų, ir sveikatos
                        informacija pagal <a className="hrefStyle" href="https://www.e-tar.lt/portal/lt/legalAct/TAR.234B15954C2F/asr">Lietuvos Respublikos Biomedicininių tyrimų etikos įstatymo</a> 8 straipsnio 8 dalyje nustatytą tvarką vieną mėnesį po chirurginės operacijos,
                        invazinės ir (ar) intervencinės procedūros atlikimo ir praėjus šiam terminui nedelsiant sunaikinami, jeigu asmuo, ar kitas  asmens sutikimą dalyvauti biobanko
                        veikloje turintis teisę duoti asmuo per tą laikotarpį nepasirašo asmens sutikimo dalyvauti biobanko veikloje. Jeigu asmuo per vieną mėnesį po chirurginės
                        operacijos, invazinės ir (ar) intervencinės procedūros atlikimo duoda asmens sutikimą dalyvauti biobanko veikloje, išsaugoti mėginiai ir sveikatos informacija
                        toliau gali būti tvarkomi biobanke.</li>

                    </ul>
                    <h2 className="thick" style={{ fontSize: "18px" }}>Teisė gauti sveikatos informaciją</h2>
                    <ul className="listStyleText">
                      <li>Pagal <a className="hrefStyle" href="https://www.e-tar.lt/portal/lt/legalAct/TAR.234B15954C2F/asr">Lietuvos Respublikos Biomedicininių tyrimų etikos įstatymo</a> 15 straipsnį biobankas, gavęs asmens sutikimą
                        dalyvauti biobanko veikloje, turi teisę gauti asmens, kurio žmogaus biologinis mėginys ir sveikatos informacija tvarkoma biobanke, sveikatos informaciją iš:</li>
                    </ul>
                    <ol className="listStyleText">
                      <li>sveikatos priežiūros įstaigų, registrų ir (ar) valstybės informacinių sistemų įstatymų nustatyta tvarka;</li>
                      <li>kitų juridinių asmenų, asmens nurodytų asmens sutikime dalyvauti biobanko veikloje, jeigu šios sveikatos informacijos nėra registruose
                        ir (ar) valstybės informacinėse sistemose ir jos neturi sveikatos priežiūros įstaigos arba jos suteikimas iš sveikatos priežiūros įstaigų pareikalautų
                        nepagrįstai didelių materialinių ir (ar) laiko sąnaudų.</li>
                    </ol>
                    <ul className="listStyleText">
                      <li>Šia teise biobankas naudojasi savo iniciatyva arba biomedicininio tyrimo užsakovo, jo įgalioto atstovo ar pagrindinio tyrėjo prašymu.</li>
                    </ul>
                    <h2 className="thick" style={{ fontSize: "18px" }}>Teisė teikti mėginius ir sveikatos informaciją</h2>
                    <ul className="listStyleText">
                      <li>Pagal<a className="hrefStyle" href="https://www.e-tar.lt/portal/lt/legalAct/TAR.234B15954C2F/asr"> Lietuvos Respublikos Biomedicininių tyrimų etikos įstatymo</a> 17 ir 20 straipsnį biobanke tvarkomi žmogaus biologiniai mėginiai ir sveikatos informacija gali būti teikiami:</li>

                    </ul>
                    <ul className="listStyleList">
                      <li >biomedicininio tyrimo užsakovui, kai yra gautas Regioninio biomedicininių tyrimų etikos komiteto arba Lietuvos bioetikos komiteto leidimas atlikti biomedicininį tyrimą;</li>
                      <li >klinikinio vaistinio preparato tyrimo užsakovui, kai yra gautas Lietuvos bioetikos komiteto liudijimas ir Valstybinės vaistų kontrolės tarnybos prie Lietuvos Respublikos sveikatos apsaugos ministerijos leidimas atlikti klinikinį vaistinio preparato tyrimą;</li>
                      <li >kitiems Lietuvos Respublikos, Europos Sąjungos valstybių narių, kitų Europos ekonominės erdvės valstybių ir trečiųjų valstybių biobankams, biomedicininio tyrimo užsakovui, jo įgaliotam atstovui ar pagrindiniam tyrėjui, atliekantiems biomedicininį tyrimą ne Lietuvos Respublikoje, žmogaus biologinius mėginius ir sveikatos informaciją teikiančiam biobankui gavus Lietuvos bioetikos komiteto leidimą dėl biobanke tvarkomų žmogaus biologinių mėginių ir (ar) sveikatos informacijos teikimo.</li>

                    </ul>




                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default BiobankoTeises;