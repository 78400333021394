import React, { Component } from "react";



export default class RegisterSelect extends Component {
  

  render() {
    return (
      <div className="col-xl-5 col-centered">
        <div className="get-quote col-centered card card-container" style= {{paddingTop: "50px",paddingBottom: "50px" }}>
        {/* <a href="/registertypeuser" className="btn" style={{ display: "block", margin: "0 auto", width: "50%", marginTop: "20px"}}>
          Dalyviams
        </a> */}
        <a href="/registertypetyrejas" className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px"}}>
          Tyrėjams
        </a>
        {/* <a href="/registertypetyrejas" className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}}>
          Darbuotojams
        </a> */}
        </div>
      </div>
    );
  }
}
