import axios from "axios";
import authHeader from "./auth-header.js";

// var user = { 'id' : 1, 'username': 'deivid', 'accessToken' : 12345, 'roles': ['ROLE_USER','ROLE_MODERATOR', 'ROLE_WORKER', 'ROLE_ADMIN']};
const API_URL = "https://covid19.mf.vu.lt/proxy/api/";
// if (sessionStorage.getItem("user") !== null) {
//   var userId = JSON.parse(sessionStorage.getItem("user")).id; //
// }
class ExamService {
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  addZeroToNumber(number, desiredLength) {
    // Convert the number to a string
    var numberString = number.toString();

    // Check if the number is shorter than the desired length
    if (numberString.length < desiredLength) {
      // Calculate the number of zeros to add
      var zerosToAdd = desiredLength - numberString.length;

      // Prepend the necessary number of zeros
      for (var i = 0; i < zerosToAdd; i++) {
        numberString = "0" + numberString;
      }
    }

    // Return the modified number string
    return numberString;
  }
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }
  getPersonalData(id) {
    return axios.get(API_URL + "/users/" + id).then((response) => {
      return response.data;
    });
  }

  userSet(userId) {
    return this.getPersonalData(userId).then((user) => {
      sessionStorage.setItem("user", JSON.stringify(user));
      return user.data;
    });
  }

  async postApplications(object) {
    return await axios
      .post(API_URL + "/application", object)
      .then((response) => {
        return response.data;
      });
  }
  // postApplications(
  //   projectName,
  //   projectAcronym,
  //   projectExaminatorFullname,
  //   protocolFileLink,
  //   permitFileLink,
  //   startDate,
  //   endDate,
  //   applicationPDFLink,
  //   additionalRemarks,
  //   addedDate
  // ) {
  //   // turetu turet ID backende
  //   var todays = new Date();
  //   var year = todays.getFullYear();
  //   return this.getAllApplications().then((data) => {
  //     //   let maxApplication = 0;
  //     //   data.forEach((application) => {
  //     //     if (application.id > maxApplication) {
  //     //         maxApplication = application.id;
  //     //     }
  //     //   });
  //     var maxApplication = data.reduce(function (max, obj) {
  //       return Math.max(max, obj.id);
  //     }, -Infinity);
  //     maxApplication += 1;
  //     var resultNum = this.addZeroToNumber(maxApplication, 5);
  //     return axios
  //       .post(API_URL + "/application", {
  //         uniqueCode: "LPRLB-" + year + "-" + resultNum,
  //         // + this.makeid(10)
  //         administerState: 0,
  //         projectName,
  //         projectAcronym,
  //         projectExaminatorFullname,
  //         protocolFileLink,
  //         permitFileLink,
  //         startDate,
  //         endDate,
  //         applicationPDFLink,
  //         additionalRemarks,
  //         addedDate,
  //         applicationStatus: 0,
  //       })
  //       .then((response) => {
  //         this.relationshipApplicationUser(userId, response.data.id).then(response => {
  //             // this.userSet(userId).then(response => {  });
  //         })

  //         // if (response.data.accessToken) {
  //         //     localStorage.setItem("user", JSON.stringify(response.data));

  //         // }
  //         return response.data;
  //       });
  //   });
  // }
  putApplicationsObject(id, object) {
    return axios
      .put(API_URL + "/application/" + id, object)
      .then((response) => {
        // if (response.data.accessToken) {
        console.log("After put");
        // this.userSet(userId).then(response => { this.setTimeout(window.location.reload(), 500) });
        // window.location.reload();
        return response.data;
      });
  }
  async getApplications(id) {
    // turetu turet ID backende
    return await axios
      .get(API_URL + "/application/" + id)
      .then((response) => {
        // if (response.data.accessToken) {
        //     localStorage.setItem("user", JSON.stringify(response.data));

        // }
        return response.data;
      });
  }
  async getAllApplications() {
    // turetu turet ID backende
    return axios.get(API_URL + "/application").then((response) => {
      return response.data;
    });
  }
  // getAllForSpecificApplications() {
  //   // turetu turet ID backende
  //   return axios.get(API_URL + "/application/" + userId).then((response) => {
  //     // if (response.data.accessToken) {
  //     //     localStorage.setItem("user", JSON.stringify(response.data));
  //     // }
  //     // return response.data;
  //   });
  // }
  // putApplications(
  //   administerState,
  //   projectName,
  //   projectAcronym,
  //   projectExaminatorFullname,
  //   protocolFileLink,
  //   permitFileLink,
  //   startDate,
  //   endDate,
  //   guaranteeFileLink,
  //   additionalRemarks,
  //   addedDate
  // ) {
  //   // turetu turet ID backende
  //   return axios
  //     .put(API_URL + "/application", {
  //       administerState,
  //       projectName,
  //       projectAcronym,
  //       projectExaminatorFullname,
  //       protocolFileLink,
  //       permitFileLink,
  //       startDate,
  //       endDate,
  //       guaranteeFileLink,
  //       additionalRemarks,
  //       addedDate,
  //     })
  //     .then((response) => {
  //       this.userSet(userId).then((response) => {
  //         window.location.reload();
  //       });
  //     });
  // }
  async putApplicationsCustom(id, object) {
    const response = await axios.put(
      API_URL + "/application/" + id,
      object
    );
    return response.data;
  }

  async putTestApplications(id, administerState) {
    // turetu turet ID backende
    const response = await axios.put(API_URL + "/application/" + id, {
      administerState,
    });
  }

  // postCheckUps(
  //   checkupState,
  //   checkupDate,
  //   requirementsState,
  //   reasonRequirementList,
  //   additionalFileLinkCheckup,
  //   additionalInfoCheckup,
  //   applicationId
  // ) {
  //   return axios
  //     .post(API_URL + "/checkup", {
  //       checkupState,
  //       checkupDate,
  //       requirementsState,
  //       reasonRequirementList,
  //       additionalFileLinkCheckup,
  //       additionalInfoCheckup,
  //     })
  //     .then((response) => {
  //       this.relationshipApplicationCheckup(response.id, applicationId).then(
  //         (response) => {
  //           this.userSet(userId).then((response) => {
  //             window.location.reload();
  //           });
  //         }
  //       );
  //     });
  // }
  postCheckUpsCustom(application, object) {
    return axios.post(API_URL + "/checkup", object).then((response) => {
      this.relationshipApplicationCheckup(
        response.data.id,
        application.id
      ).then((response) => {
        // this.userSet(userId).then(response => { window.location.reload() });
        window.location.reload();
      });
    });
  }
  // putCheckUps(
  //   checkupState,
  //   checkupDate,
  //   requirementsState,
  //   reasonRequirementList,
  //   additionalFileLinkCheckup,
  //   additionalInfoCheckup
  // ) {
  //   return axios
  //     .put(API_URL + "/checkup", {
  //       checkupState,
  //       checkupDate,
  //       requirementsState,
  //       reasonRequirementList,
  //       additionalFileLinkCheckup,
  //       additionalInfoCheckup,
  //     })
  //     .then((response) => {
  //       this.userSet(userId).then((response) => {
  //         window.location.reload();
  //       });
  //     });
  // }
  putCheckUpsCustom(id, object) {
    return axios.put(API_URL + "/checkup/" + id, object).then((response) => {
      // window.location.reload()
      return response.data;
    });
  }
  getCheckUps(checkupId) {
    return axios.get(API_URL + "/checkup/" + checkupId).then((response) => {
      // this.userSet(userId).then(response => { window.location.reload() });
      // window.location.reload()
      return response.data;
    });
  }

  // postExaminationInfo(examText, examFileLink, sendingDate, applicationId) {
  //   return axios
  //     .post(API_URL + "/examinationinfo", {
  //       examText,
  //       examFileLink,
  //       sendingDate,
  //       applicationId,
  //     })
  //     .then((response) => {
  //       this.userSet(userId).then((response) => {
  //         window.location.reload();
  //       });
  //     });
  // }

  postExaminationInfoObject(applicationId, object) {
    return axios
      .post(API_URL + "/examinationinfo/", object)
      .then((response) => {
        // this.userSet(userId).then((response) => {
        //   window.location.reload();
        // });
        return response.data;
      });
  }
  async getAllExaminationInfos() {
    return await axios
      .get(API_URL + "/examinationinfo")
      .then((response) => {
        return response.data;
      });
  }

  applicationPatch(id) {}
  relationshipApplicationUser(userId, applicationId) {
    return axios
      .post(API_URL + "/applicationuser", {
        userId,
        applicationId,
      })
      .then((response) => {
        return response.data;
      });
  }
  relationshipApplicationCheckup(checkupId, applicationId) {
    return axios.post(API_URL + "/checkupapplication", {
      checkupId,
      applicationId,
    });
  }
}

export default new ExamService();
