import React, { Component } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";

class DalyviuInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      filteredUsers: [],
      selectedUser: null,
      showModal: false,
      selectedUserSamples: [],
      filters: {
        institution: "",
        firstName: "",
        lastName: "",
        uniqueCode: "",
        userRole: "",
      },
    };
  }

  componentDidMount() {
    dalyvisService.getAllUsers().then((response) => {
      this.setState({ allUsers: response });
    });
  }

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      filters: { ...prevState.filters, [name]: value },
    }));
  };

  filterUsers = () => {
    const { allUsers, filters } = this.state;
    const { institution, firstName, lastName, uniqueCode, userRole } = filters;
    const filtered = allUsers.filter((user) => {
      return (
        (institution === "" || user.workplace === institution) &&
        (firstName === "" ||
          user.name?.toLowerCase().includes(firstName.toLowerCase())) &&
        (lastName === "" ||
          user.surname?.toLowerCase().includes(lastName.toLowerCase())) &&
        (uniqueCode === "" || user.uniqueCode?.includes(uniqueCode)) &&
        (userRole === "" || user.roles.includes(parseInt(userRole)))
      );
    });
    this.setState({ filteredUsers: filtered });
  };

  handleUserClick = (user) => {
    this.setState({ selectedUser: user, showModal: true }, this.fetchSamples);
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedUser: null,
      selectedUserSamples: [],
    });
  };

  handleSaveChanges = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      const {
        email,
        name,
        surname,
        workplace,
        personalCode,
        phoneNumber,
        firstTime,
      } = selectedUser;
      dalyvisService
        .putPersonalData(
          email,
          null,
          name,
          surname,
          workplace,
          personalCode,
          phoneNumber,
          firstTime,
          selectedUser.id
        )
        .then(() => {
          this.setState({ showModal: false });
          alert("Paskyros informacija pakeista sėkmingai!");
          dalyvisService.getAllUsers().then((response) => {
            this.setState({ allUsers: response }, () => {
              this.filterUsers();
            });
          });
        });
    }
  };

  handleSendPasswordReset = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      authService.remindPassword(selectedUser.email).then(() => {
        alert("Slaptažodžio pakeitimo laiškas išsiųstas sėkmingai!");
      });
    }
  };

  fetchSamples = () => {
    const { selectedUser } = this.state;
    if (selectedUser && selectedUser.samples.length > 0) {
      const samplePromises = selectedUser.samples.map((sampleId) =>
        dalyvisService
          .relationshipSampleUser(sampleId)
          .then((response) => response.data)
      );
      Promise.all(samplePromises).then((samples) => {
        this.setState({ selectedUserSamples: samples });
      });
    }
  };

  render() {
    const {
      filteredUsers,
      selectedUser,
      showModal,
      filters,
      allUsers,
      selectedUserSamples,
    } = this.state;

    return (
      <Container>
        <h2>Paskyrų Informacija</h2>
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="formInstitution">
                <Form.Label>Institucija</Form.Label>
                <Form.Control
                  as="select"
                  name="institution"
                  value={filters.institution}
                  onChange={this.handleFilterChange}
                >
                  <option value="">Visos</option>
                  <option value="LPRLB">LPRLB</option>
                  <option value="NKC">NKC</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formFirstName">
                <Form.Label>Vardas</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Įveskite vardą"
                  value={filters.firstName}
                  onChange={this.handleFilterChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>Pavardė</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Įveskite pavardę"
                  value={filters.lastName}
                  onChange={this.handleFilterChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formUniqueCode">
                <Form.Label>Unikalus kodas</Form.Label>
                <Form.Control
                  type="text"
                  name="uniqueCode"
                  placeholder="Įveskite unikalų kodą"
                  value={filters.uniqueCode}
                  onChange={this.handleFilterChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formUserRole">
                <Form.Label>Paskyrų tipai</Form.Label>
                <Form.Control
                  as="select"
                  name="userRole"
                  value={filters.userRole}
                  onChange={this.handleFilterChange}
                >
                  <option value="">Visi</option>
                  <option value="1">Dalyvis</option>
                  <option value="2">Tyrėjas</option>
                  <option value="4">Darbuotojas</option>
                  <option value="5">Administratorius</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" onClick={this.filterUsers}>
            Ieškoti
          </Button>
        </Form>

        <ul className="list-group mt-3">
          {filteredUsers.map((user) => (
            <li key={user.id} className="list-group-item">
              <Row>
                <Col>
                  <strong>Unikalus kodas:</strong> {user.uniqueCode}
                </Col>
                <Col>
                  <strong>Rolė:</strong>{" "}
                  {user.roles.includes(1)
                    ? "Dalyvis"
                    : user.roles.includes(2)
                    ? "Tyrėjas"
                    : user.roles.includes(4)
                    ? "Darbuotojas"
                    : "Administratorius"}
                </Col>
                <Col>
                  <strong>Vardas:</strong> {user.name} {user.surname}
                </Col>
                <Col>
                  <strong>Institucija:</strong> {user.workplace}
                </Col>
                <Col>
                  <Button
                    variant="info"
                    onClick={() => this.handleUserClick(user)}
                  >
                    Plačiau...
                  </Button>
                </Col>
              </Row>
            </li>
          ))}
        </ul>

        {selectedUser && (
          <Modal show={showModal} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Paskyros Informacija</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formEmail">
                  <Form.Label>Elektroninis paštas</Form.Label>
                  <Form.Control
                    type="email"
                    value={selectedUser.email}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Vardas</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedUser.name || ""}
                    onChange={(e) =>
                      this.setState({
                        selectedUser: {
                          ...selectedUser,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formLastName">
                  <Form.Label>Pavardė</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedUser.surname || ""}
                    onChange={(e) =>
                      this.setState({
                        selectedUser: {
                          ...selectedUser,
                          surname: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formWorkplace">
                  <Form.Label>Institucija</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedUser.workplace || ""}
                    onChange={(e) =>
                      this.setState({
                        selectedUser: {
                          ...selectedUser,
                          workplace: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPersonalCode">
                  <Form.Label>Asmens kodas</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedUser.personalCode || ""}
                    onChange={(e) =>
                      this.setState({
                        selectedUser: {
                          ...selectedUser,
                          personalCode: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Telefono numeris</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedUser.phoneNumber || ""}
                    onChange={(e) =>
                      this.setState({
                        selectedUser: {
                          ...selectedUser,
                          phoneNumber: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                {selectedUser.roles.includes(1) &&
                  selectedUserSamples.length > 0 && (
                    <>
                      <h5 style={{ marginTop: "20px" }}>Mėginiai</h5>
                      <ul className="list-group">
                        {selectedUserSamples.map((sample) => {
                          return (
                            <li key={sample.id} className="list-group-item">
                              <strong>Mėginio kodas: </strong>{" "}
                              {sample.sampleCode}
                              <br />
                              <strong>Pridėjimo Data: </strong>
                              {new Date(sample.addedAt).toLocaleString("lt-LT")}
                              <br />
                              <strong>Tipas: </strong> {sample.sampleType}
                              <br />
                              <strong>Institucija: </strong>{" "}
                              {sample.institution}
                              <br />
                              <strong>Panaudotas: </strong>
                              {sample.sampleUsed ? "Taip" : "Ne"}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Uždaryti
              </Button>
              <Button variant="warning" onClick={this.handleSendPasswordReset}>
                Siųsti slaptažodžio pakeitimo laišką
              </Button>
              <Button variant="primary" onClick={this.handleSaveChanges}>
                Išsaugoti pakeitimus
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }
}

export default DalyviuInfo;
