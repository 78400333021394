import React from "react";
import dalyvisService from "../../../../services/dalyvis-service";

class MyInquiries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: "",
      user: JSON.parse(sessionStorage.getItem("user")),
      filterType: "all", // all, rec, sent
      loading: true,
      messageList: "",
    };
    this.setFilterState = this.setFilterState.bind(this);
    //this.changeButtonWord = this.changeButtonWord.bind(this);
    this.fetchData();
  }
  async fetchData() {
    const user = await dalyvisService.getAllPersonalData(this.state.user.id);
    this.setState({
      user: user,
      //loading: false,
    });
    sessionStorage.setItem("user", JSON.stringify(user));
    // await dalyvisService
    //   .getAllPersonalData(this.state.user.id)
    //   .then((user) => {
    // await dalyvisService
    //   .getAllMessages((messages) => {
    //     this.setState({
    //       //user: user,
    //       loading: false,
    //       messages: Object.values(messages),
    //     });

    //     sessionStorage.setItem("user", JSON.stringify(user));
    //   })
    //   .catch((error) => window.alert(error.message));
    //})
    //.catch((error) => window.alert(error.message));
  }

  componentDidMount() {
    this.setState({ loading: true });

    const messagesIds = Object.values(this.state.user.message);
    console.log(messagesIds);

    var messages = [];
    var messageList;

    dalyvisService
      .getAllMessages()
      .then((response) => {
        response.forEach((message) => {
          messagesIds.forEach((id) => {
            if (message.id === id) {
              messages.push(message);
            }
          });
        });

        messageList = messages
          .filter((data) => {
            console.log("data");
            console.log(data);
            if (data.messageType === 4) {
              if (this.state.filterType === "all") {
                return true;
              }

              if (this.state.filterType === "rec") {
                if (data.messageState === 2) {
                  return true;
                }
              }

              if (this.state.filterType === "sent") {
                if (data.messageState === 1) {
                  return true;
                }
              }
            }
            return false;
          })
          .sort(function (a, b) {
            console.log("sort:");
            console.log(a);
            console.log(b);

            if (a.eventDate > b.eventDate) {
              return 1;
            }
            if (a.eventDate < b.eventDate) {
              return -1;
            }
            return 0;
          })
          .map((data) => {
            console.log("map");
            console.log(data);
            return (
              <>
                <button
                  className="applicationList"
                  onClick={() => this.showInfo(data.id)}
                  style={{ width: "100%" }}
                >
                  <p style={{ float: "left" }}>{data.messageTopic}</p>
                  <p style={{ float: "right" }}>
                    Pateikimo data: {data.eventDate}{" "}
                  </p>
                </button>
                <div>
                  <li
                    key={data.id}
                    id={data.id}
                    style={{ display: "none", visibility: "hidden" }}
                  >
                    <table className="table">
                      <thead className="thead-dark">
                        {/* <tr>
                    
                    <th scope="col">Skiltis</th>
                    <th scope="col">Įvesta informacija</th>
                   
                  </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <th scope="row">Data</th>
                          <td>
                            <input
                              type="text"
                              id="inquirydate"
                              name="projectname"
                              style={{ width: "100%" }}
                              readOnly
                              value={data.eventDate}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Tema</th>
                          <td>
                            <input
                              type="text"
                              id="inquirytopic"
                              name="projectacronym"
                              style={{ width: "100%", wordBreak: "break-all" }}
                              readOnly
                              value={data.messageTopic}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Tekstas</th>
                          <td>
                            <textarea
                              id="inquirytext"
                              name="fname"
                              style={{ height: "100px", width: "100%" }}
                              readOnly
                              value={data.messageText}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </div>
              </>
            );
          });

        this.setState({
          //user: user,
          loading: false,
          messages: messageList,
        });
      })
      .catch((error) => window.alert(error.message));
  }
  showInfo(id) {
    // const list = ["uzklausa apie mane", "uzklausa apie jus", "uzklausa apie meginius"]; // get from database
    var value = document.getElementById(id);
    // for(let i = 0; i < list.length; i++){
    //   if(id.data !== list[i]){
    //     document.getElementById(list[i]).style.visibility = "hidden";
    //     document.getElementById(list[i]).style.display = "none";
    //     document.getElementById(list[i]).style.maxHeight = "0";

    //   }
    // }
    if (value.style.visibility === "hidden") {
      value.style.display = "block";
      value.style.visibility = "visible";
      value.style.maxHeight = "100%";
      // value.style.lineHeight = "100%";

      // value.style.height = "auto";
    } else {
      value.style.display = "none";
      value.style.visibility = "hidden";
      value.style.maxHeight = "0";
      // value.style.lineHeight = "0";

      // value.style.height = 0;
    }
  }
  setFilterState(filterType) {
    this.setState({
      filterType: filterType,
    });
  }

  render() {
    // let listApplications = ["uzklausa apie mane", "uzklausa apie jus", "uzklausa apie meginius"];

    return (
      <>
        <div>
          {this.state.loading ? (
            <>
              <div className="d-flex justify-content-center" style={{}}>
                <div
                  className="spinner-grow d-flex justify-content-center"
                  style={{
                    width: "20rem",
                    height: "20rem",
                    marginTop: "80px",
                    marginBottom: "80px",
                    color: "#78003f",
                  }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-centered col-lg-10 col-md-12 col-12">
                {/* <h2><b>Užpildytų paraiškų sąrašas</b></h2> */}

                <div className="list-group"></div>
                <div style={{ marginBottom: "25px" }}>
                  <button
                    className={
                      this.state.filterType === "all"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "all" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("all")}
                  >
                    Visi
                  </button>
                  <button
                    className={
                      this.state.filterType === "rec"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "rec" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("rec")}
                  >
                    Gauti
                  </button>
                  <button
                    className={
                      this.state.filterType === "sent"
                        ? "btn active-sidenav-btn"
                        : "btn"
                    }
                    style={
                      this.state.filterType === "sent" ? { color: "black" } : {}
                    }
                    onClick={() => this.setFilterState("sent")}
                  >
                    Išsiųsti
                  </button>
                </div>
                <ul className="designed-list">
                  {React.Children.toArray(this.state.messages)}
                </ul>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default MyInquiries;
