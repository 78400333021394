import axios from "axios";
// var user = { 'id' : 1, 'username': 'deivid', 'accessToken' : 12345, 'roles': ['ROLE_USER','ROLE_MODERATOR', 'ROLE_WORKER', 'ROLE_ADMIN']};
const API_URL = "http://localhost:8080/api/auth/";
class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }
  loginexam(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }
  loginworker(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }

  logout() {
    sessionStorage.removeItem("user");
  }

  register(email, password) {
    return axios.post(API_URL + "signupDalyvis", {
      email,
      password,
    });
  }

  registerTypeTyrejas(
    email,
    name,
    surname,
    personalcode,
    workplace,
    phonenumber,
    password
  ) {
    return axios.post(API_URL + "signupExam", {
      email,
      name,
      surname,
      personalcode,
      workplace,
      phonenumber,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem("user"));
  }
}

export default new AuthService();
