import React from 'react';
class ExamGroups extends React.Component {
    render() { 
        return <div>
            <section className="news-single section">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Tyrėjų grupėms</h2>
                <img src="img/section-img.png" alt="#" />
                <p></p>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                    {/* Contact Form */}
                    
                      <div className="row mx-5 my-5">
                        <h2 className="thick">Tyrėjų grupėms</h2>
                        <ul className="listStyleText">
                          <li>Biobankas kaupia Lietuvos populiacijos sveikų asmenų ir sergančių retomis ligomis asmenų biologinius mėginius ir sveikatos informaciją.</li>
                          <li>Lietuvos ir užsienio mokslininkai Biobanku gali naudotis kaip biblioteka: užuot kiekvienam moksliniam tyrimui ieškoję vis naujų savanorių, Jūs galite pasinaudoti Biobanke jau sukauptais aukštos kokybės mėginiais bei sveikatos informacija ir pasidalinti su Biobanku mokslinių tyrimų metu gauta informacija, tokiu būdu dar padidinant sukauptų mėginių mokslinę vertę.</li>
                          <li>Biobankas kaupdamas didelį skaičių biologinių mėginių ir vis papildydamas su mėginiais susijusią sveikatos informaciją, gali palengvinti ir pagerinti Jūsų mokslinių tyrimų organizavimą, atlikimą, gautų rezultatų patikimumą. </li>
                          <li>Mokslininkai, vykdantys biomedicininius mokslinius tyrimus, gali kreiptis į Biobanką, prašydami gauti biologinių mėginių ir su jais susijusią sveikatos informaciją remiantis Lietuvos Respublikos Biomedicininių tyrimų etikos įstatymu, reglamentuojančiu biobankų veiklą Lietuvoje. Įstatyme bei kituose susijusiuose teisės aktuose nurodyti reikalavimai tyrėjams, norintiems gauti biobankuose sukauptos biologinės medžiagos:</li>
                          <li><a className="hrefStyle" href="http://bioetika.sam.lt/index.php?2842770196">http://bioetika.sam.lt/index.php?2842770196</a></li>
                          <li>Biomedicininio tyrimo pagrindiniai tyrėjai, turintys biotetikos leidimą vykdyti biomedicininį tyrimą ir  norintys tapti partneriais, turi registruotis <a className="hrefStyle" href="/registertypetyrejas"><i>Biobanko tyrėjo paskyroje</i></a> ir pateikti prašymą gauti biologinių mėginių ir su jais susijusią sveikatos informaciją moksliniams tyrimams atlikti.</li>
                          <li>Biobanko paslaugų įkainiai patvirtinti VU rektoriaus/Biomedicinos mokslų instituto direktoriaus.</li>
                        </ul>
                            
                      </div>
                    
                    {/*/ End Contact Form */}
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
        </div>;
    }
}
 
export default ExamGroups;