import React from "react";
import { Route } from "react-router-dom";

import Contact from "./../dalyvis-subpages/susisiekti";
import Pranesimai from "./../dalyvis-subpages/pranesimai";
import MessageList from "./messagelist";

class messageSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const pathname = window.location.pathname;

    return (
      <>
        <div className="col-lg-12 col-12">
          <div className="comments-form">
            <div className="get-quote">
              <a
                href={`${this.props.match.path}/projektai`}
                className={
                  pathname.includes("/projektai") ? "btn active-btn" : "btn"
                }
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={this.changeStateAtstovas}
              >
                Informacija apie dalyvavimą projektuose
              </a>
              {/* <Route path={`${this.props.match.path}/pranesimai1`} component={() => (<Pranesimai state="2" />)} /> */}
              <a
                href={`${this.props.match.path}/radiniai`}
                className={
                  pathname.includes("/radiniai") ? "btn active-btn" : "btn"
                }
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={this.changeStateAtstovas}
              >
                Sveikatai svarbūs radiniai
              </a>
              {/* <Route path={`${this.props.match.path}/pranesimai2`} component={() => (<Pranesimai state="3" />)} /> */}
              <a
                href={`${this.props.match.path}/susisiekti`}
                className={
                  pathname.includes("/susisiekti") ? "btn active-btn" : "btn"
                }
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={this.changeStateDalyvis}
              >
                Susisiekti su LPRLB
              </a>
              <Route
                path={`${this.props.match.path}/susisiekti`}
                component={Contact}
              />
              {/* <Route path={`${this.props.match.path}/susisiekti/`} component={MessageList} /> */}
              <Route
                path={`${this.props.match.path}/projektai`}
                component={() => (
                  <Pranesimai
                    typetext="Informacija apie dalyvavimą projektuose"
                    state="2"
                  />
                )}
              />
              <Route
                path={`${this.props.match.path}/radiniai`}
                component={() => (
                  <Pranesimai typetext="Sveikatai svarbūs radiniai" state="3" />
                )}
              />
              <Route
                path={`${this.props.match.path}/susisiekti`}
                component={() => (
                  <Pranesimai typetext="Visi asmeniniai pranešimai" state="1" />
                )}
              />
              {/* <Pranesimai typetext="Visi asmeniniai pranešimai" state="1" /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default messageSelect;
