import React, { Component } from "react";
import axios from "axios";
import AuthService from "../../services/auth.service-reikiaderinti";
import authService from "../../services/auth-service";
import dalyvisService from "../../services/dalyvis-service";

export default class RegisterTypeTyrejas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerType: false,
      name: "",
      surname: "",
      phoneNumber: "",
    };
  }
  componentDidMount() {}
  submitForm(e) {
    e.preventDefault();
    var name = this.state.name;
    var surname = this.state.surname;
    // var personalcode = document.getElementById("personalcode").value;
    var workplace = document.getElementById("workplace").value;
    var phonenumber = this.state.phoneNumber;
    var email = document.getElementById("email").value;
    var password1 = document.getElementById("passwordinput1").value;
    var password2 = document.getElementById("passwordinput2").value;

    var allItems = [
      name,
      surname,
      workplace,
      phonenumber,
      email,
      password1,
      password2,
    ];

    var continueState = true;
    var userType = "";

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "") {
        continueState = false;
        window.alert("Kai kurie langeliai yra nepilnai užpildyti!");
        break;
      }
      continueState = true;
    }
    dalyvisService
      .getAllUsers()
      .then((response) => {
        for (let i = 0; i < response.length; i++) {
          if (response[i].email === email) {
            window.alert("Naudotojas su el. paštu jau egzistuoja");
            return;
          }
        }
        if (continueState === true) {
          var value = this.checkPasswords(password1, password2);
          if (value[0] === true) {
            // window.alert(value[1]);
            if (!this.validateEmail(email)) {
              window.alert(
                "Neteisingas el. pašto formatas arba institucija nėra sąraše"
              );
              return;
            }
            if (phonenumber.length !== 11) {
              window.alert("Įveskite pilną telefono numerį");
              return;
            }
            // AuthService.registerTypeTyrejas(email,name,surname,personalcode,workplace,phonenumber,password1);

            const role = ["researcher"];
            console.log(role);
            console.log(password1);

            // dalyvisService
            //   .postPersonalData(
            //     email,
            //     password1,
            //     name,
            //     surname,
            //     workplace,
            //     phonenumber
            //   )
            //   .then((response) => {
            //     // personalCode

            //     setTimeout(function () {
            //       // sessionStorage.setItem("user", JSON.stringify(response));
            //     }, 1000);
            //   })
            //   .catch((error) => window.alert(error.message));

            authService
              .register(email, password1, role)
              .then(() => {
                dalyvisService
                  .getAllUsers()
                  .then((response) => {
                    console.log(response);

                    const allUsers = response;
                    // window.alert("Elektroninis paštas: " + email);
                    // window.alert("Slaptažodis: " + password);

                    if (allUsers !== null) {
                      allUsers.forEach((user) => {
                        if (user.email === email) {
                          dalyvisService
                            .getAllPersonalData(user.id)
                            .then((response) => {
                              console.log(response);
                              var newUser = response;

                              newUser.name = name;
                              newUser.surname = surname;
                              newUser.workplace = workplace;
                              newUser.phoneNumber = phonenumber;

                              dalyvisService
                                .putCustomObjectPersonalData(
                                  newUser.id,
                                  newUser
                                )
                                .then(() => {
                                  window.alert("Tyrėjas užregistruotas");
                                  this.props.history.push("/login");
                                })
                                .catch((error) => window.alert(error.message));
                            })
                            .catch((error) => window.alert(error.message));
                        }
                      });
                    }
                  })
                  .catch((error) => window.alert(error.message));
              })
              .catch((error) => window.alert(error.message));

            // setTimeout(() => {
            //   this.props.history.push("/tyrejas/manoduomenys");
            //   window.location.reload();
            // }, 1000);
            // window.alert("Prisiregistruota");
          } else {
            window.alert(value[1]);
          }
        }
      })
      .catch((error) => window.alert(error.message));
  }

  checkPasswords(pass1, pass2) {
    var state = false;
    var message = "";
    if (
      pass1 === pass2 &&
      (pass1 !== "" || pass2 !== "") &&
      (pass1.length > 6 || pass2.length > 6)
    ) {
      //user services to save password to database
      message = "Sėkmingai užpildyta forma!";
      state = true;
    } else if (pass1 === "" || pass2 === "") {
      message = "Vienas iš slaptažodžių yra tuščias!";
      state = false;
    } else if (pass1.length <= 6 || pass2.length <= 6) {
      message = "Slaptažodis turi būti ilgesnis nei 6 simboliai (7+)!";
      state = false;
    } else {
      message = "Įvęsti slaptažodžiai nėra vienodi!";
      state = false;
    }
    return [state, message];
  }
  handleChangeNumber(event, id) {
    var value = event.target.value;
    if (!isNaN(value)) {
      this.setState({ [id]: event.target.value });
    }
  }
  handleChangeAlpha(event, id) {
    var value = event.target.value;
    if (/^[a-zA-Ząčęėįšųūž]+$/.test(value) || value === "") {
      this.setState({ [id]: event.target.value });
    }
  }
  validateEmail(email) {
    var validEmailEndings = ["@mf.vu.lt"];
    var endingValid = validEmailEndings.includes(
      email.substring(email.indexOf("@"))
    );
    console.log(email.substring(email.indexOf("@")));
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
      endingValid
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <>
        <div
          className="get-quote col-xl-8 col-lg-10 col-md-12 col-centered card card-container"
          style={{ padding: "50px" }}
        >
          <form
            className="form col-lg-10 col-md-12 col-12 border-edit-button col-centered"
            style={{ fontSize: "20px" }}
          >
            <h2 style={{ marginBottom: "25px", fontSize: "2rem" }}>
              Tyrėjo registracija
            </h2>
            <div className="row">
              {/* <div className="row">
            <div className="col-lg-6 col-md-6" style={{marginTop: "25px"}}>
            <label>Prisijungimo vardas</label>
            <div className="input-group mb-2">


                <input type="text" id="username"  className="form-control" placeholder="Prisijungimas"/>
                <div style={{}}>
                
                
                </div>
            </div>

                </div> */}

              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Vardas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Vardenis"
                      value={this.state.name}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "name")
                      }
                    />
                    <div style={{}}></div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Pavardė</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="surname"
                      className="form-control"
                      placeholder="Pavardenis"
                      value={this.state.surname}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "surname")
                      }
                    />
                    <div style={{}}></div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6" style={{ marginTop: "25px" }}>

                  <label>Asmens kodas</label>
                  <div className="input-group mb-2">
                    <input type="text" id="personalcode" className="form-control" maxLength={11} placeholder="90050050080" />
                    <div style={{}}>

                    </div>
                  </div>
                </div> */}

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Darbovietės pavadinimas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="workplace"
                      className="form-control"
                      placeholder="Darbovietė"
                    />
                    <div style={{}}></div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Telefono numeris</label>

                  <div className="input-group mb-1" style={{}}>
                    <div className="input-group-prepend">
                      <div className="input-group-text">+</div>
                    </div>
                    <input
                      type="text"
                      id="phoneNumber"
                      title="Įvęskite telefono numerį be simbolių"
                      className="form-control"
                      placeholder="37069999999"
                      maxLength={11}
                      value={this.state.phoneNumber}
                      onChange={(value) =>
                        this.handleChangeNumber(value, "phoneNumber")
                      }
                    />
                    <div style={{}}></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-8 col-md-8"
                  style={{ marginTop: "25px" }}
                >
                  <label>Elektroninis paštas</label>
                  <div className="input-group mb-2">
                    {/* <div className="input-group-prepend">
                    <div className="input-group-text">@</div>
                </div> */}
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="elektroninis@pastas.com"
                    />
                    <div style={{ marginTop: "0px" }}></div>
                  </div>
                </div>
                <div>
                  <label style={{ fontSize: "14px" }}>
                    <b>
                      *Gali registruotis tik vartotojai su institucijų
                      elektroniniais paštais
                    </b>
                  </label>
                </div>
              </div>

              {/* <div className="row">
            <div className="col-lg-6 col-md-6" style={{marginTop: "25px"}}>
                <label>Slaptažodis</label>
                <div className="input-group mb-2">
                <input type="password" id="password" readOnly className="form-control" placeholder="Slaptažodis"/>
                <div  style={{}}>

                &nbsp;<button className="btn" id="changebutton" onClick={(event) => this.changePassword(event,"newpassword")} style={{height:"32px", padding: "5px",whiteSpace: "nowrap"}}>Keisti slaptažodį</button>
                &nbsp;<button className="btn" id="cancelpasswordchange" onClick={ (event) =>this.cancelPassword(event)} style={{height:"32px", padding: "5px", display: "none",whiteSpace: "nowrap"}}>X</button>
            </div>
                </div>
            </div>

            </div> */}

              <div id="newpassword" style={{}} className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ display: "inline-block", marginTop: "25px" }}
                >
                  <label>Slaptažodis</label>
                  <input
                    type="password"
                    id="passwordinput1"
                    className="form-control"
                    placeholder="Naujas slaptažodis"
                  />
                </div>
                <div
                  className="col-lg-6 col-md-6"
                  style={{ display: "inline-block", marginTop: "25px" }}
                >
                  <label htmlFor="inputEmail4">Pakartoti slaptažodį</label>
                  <div className="input-group mb-2">
                    <input
                      type="password"
                      id="passwordinput2"
                      className="form-control"
                      placeholder="Pakartoti slaptažodį"
                    />
                    <div className="col-lg-1 col-md-1" style={{}}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-centered">
              <button
                className="btn col-lg-6 col-md-6 col-sm-12"
                type="submit"
                style={{ float: "right", marginTop: "50px" }}
                onClick={(event) => this.submitForm(event)}
              >
                Pateikti
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
