import React, { Component } from "react";

import UserService from "../../services/user.service-galimaspanaudojimas";
import forma1 from "../../docs/atsisakymoforma1.rtf";
import dalyvisService from "../../services/dalyvis-service";

export default class Participation2 extends Component {
  constructor(props) {
    super(props);
    var user = "";
    var atstovai = "";

    // sessionStorage.setItem("currentAtstovas", JSON.stringify({ "id": 1, "name": "deivid", "surname": "caikin", "personalCode": "1111111111", "addedAt": new Date() }))
    if (sessionStorage.getItem("user") !== null) {
      user = JSON.parse(sessionStorage.getItem("user"));
      atstovai = user.representedpeople;
      sessionStorage.setItem("atstovai", JSON.stringify(atstovai));
    }

    var atstovas = "";
    if (sessionStorage.getItem("currentAtstovas") !== null) {
      atstovas = JSON.parse(sessionStorage.getItem("currentAtstovas"));
    } else {
      if (atstovai.length > 0) {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(atstovai[0]));
      }
    }
    this.state = {
      parttype: true,
      atstovasSelect: false,

      loading: false,
      isAllowed: true,
      error: "",
      namerepres: "-----------------",
      surnamerepres: "-----------------",
      personalcoderepres: "-----------------",
      namereprenew: "",
      surnamerepresnew: "",
      personalcoderepresnew: "",
      user: user,
      atstovai: atstovai,
      currentAtstovas: atstovas,
      buttonDisabled: true,
    };

    this.changeStateAtstovasSelect = this.changeStateAtstovasSelect.bind(this);
    this.handleSubmitAtstovaujamas = this.handleSubmitAtstovaujamas.bind(this);
  }

  componentDidMount() {
    var user = this.state.user;
    var atstovai = this.state.atstovai;
    if (atstovai.length > 0) {
      this.setState({
        buttonDisabled: false,
      });
    }
    console.log(user);

    var userType = sessionStorage.getItem("selectedParticipation");
    if (userType === "atstovas") {
      this.setState({
        atstovas: true,
      });
      if (sessionStorage.getItem("currentAtstovas") !== null) {
        var atstovas = this.state.currentAtstovas;
        // var name = atstovas.name.charAt(0).toUpperCase() +
        //   atstovas.name.slice(1).toLowerCase();
        // var surname = atstovas.surname.charAt(0).toUpperCase() +
        //   atstovas.surname.slice(1).toLowerCase();
        this.setState({
          namerepres: atstovas.name,
          surnamerepres: atstovas.surname,
          personalcoderepres: atstovas.personalCode,
        });
      } else {
        if (atstovai.length > 0) {
          sessionStorage.setItem(
            "currentAtstovas",
            JSON.stringify(atstovai[0])
          );
        }
      }
      //   setTimeout(() => {
      //   UserService.getParticipantBoard().then(
      //     response => {
      //       this.setState({
      //         isAllowed: true,
      //         loading: false
      //       });
      //     },
      //     error => {
      //       this.setState({
      //         error:
      //           (error.response &&
      //             error.response.data &&
      //             error.response.data.message) ||
      //           error.message ||
      //           error.toString(),
      //         isAllowed: true,
      //         loading: false
      //       });

      //       // window.alert(parseInt(error.response.status));
      //       // if (error.response && error.response.status === 401) {
      //       //   EventBus.dispatch("logout");
      //       // }
      //       // else if(error.response && this.error.state == "Network Error"){
      //       //   EventBus.dispatch("logout");
      //       // }
      //     }
      //   );
      // }, 500);
    }
  }
  componentDidUpdate() {
    // if (this.state.atstovasSelect === true) {
    //   // window.alert("iam in")
    //   var atstovai = JSON.parse(sessionStorage.getItem("atstovai"));
    //   document.getElementById("namerepres").value = atstovai[0].name;
    //   document.getElementById("surnamerepres").value = atstovai[0].surname;
    //   document.getElementById("personalcoderepres").value = atstovai[0].personalcode;
    // }
  }

  changeStateAtstovasSelect() {
    this.setState({
      atstovasSelect: true,
      parttype: false,
    });
    sessionStorage.setItem("selectedParticipation", "atstovas");
  }
  selectAtstovas(e) {
    e.preventDefault();
  }
  changeAtstovaujamas(e) {
    e.preventDefault();
    var atstovasId = document.getElementById("represselect").value;
    dalyvisService
      .getRepresented(atstovasId)
      .then((data) => {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(data));
      })
      .catch((error) => window.alert(error.message));
    var delayInMilliseconds = 250; //1 second
    var atstovasObj;
    setTimeout(function () {
      atstovasObj = JSON.parse(sessionStorage.getItem("currentAtstovas"));

      console.log(atstovasObj);

      document.getElementById("namerepres").value = atstovasObj.name;
      document.getElementById("surnamerepres").value = atstovasObj.surname;
      document.getElementById("personalcoderepres").value =
        atstovasObj.personalCode;
    }, delayInMilliseconds);
    setTimeout(
      this.setState({
        currentAtstovas: atstovasObj,
      }),
      500
    );
  }
  saveAtstovaujamas(e) {
    e.preventDefault();
    var atstovasId = document.getElementById("represselect").value;
    dalyvisService
      .getRepresented(atstovasId)
      .then((data) => {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(data));
      })
      .catch((error) => window.alert(error.message));

    this.props.history.push("/dalyvis/manoprofilis");
  }
  saveNewAtstovas(e) {
    e.preventDefault();
    var namerepres = document.getElementById("namerepresnew").value;
    var surnamerepres = document.getElementById("surnamerepresnew").value;
    var personalcoderepres = document.getElementById(
      "personalcoderepresnew"
    ).value;
    dalyvisService
      .postRepresentedData(
        namerepres,
        surnamerepres,
        personalcoderepres,
        new Date()
      )
      .then((data) => {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(data));
      })
      .catch((error) => window.alert(error.message));
    setTimeout(
      sessionStorage.setItem(
        "user",
        dalyvisService.getPersonalData(this.state.user.id)
      ),
      500
    );
    setTimeout(() => {
      this.props.history.push("/dalyvis/manoprofilis");
    }, 750);
  }

  handleSubmitAtstovaujamas() {
    //do something else to direct information flow
    this.props.history.push("/dalyvis/pagrindinis");
  }
  setType(event) {
    event.preventDefault();
    sessionStorage.setItem("selectedParticipation", "dalyvis");
    this.props.history.push("/dalyvis/pagrindinis");
  }

  checkEmpty() {
    var namerepres = document.getElementById("namerepresnew").value;
    var surnamerepres = document.getElementById("surnamerepresnew").value;
    var personalcoderepres = document.getElementById(
      "personalcoderepresnew"
    ).value;
    // var gimimoliudijimas = document.getElementById("gimimoliudijimas").value;

    document.getElementById("submitButton1").disabled = true;
    var continueState = true;
    var quantity = 0;
    var allItems = [namerepres, surnamerepres, personalcoderepres]; //, gimimoliudijimas
    //leidimas.value, ,applicationpdf,additionalinfo
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        break;
      }

      quantity += 1;
    }

    if (
      quantity === allItems.length &&
      continueState === true &&
      personalcoderepres.length === 11
    ) {
      document.getElementById("submitButton1").disabled = false;
    }
  }

  render() {
    console.log(this.state.atstovai);

    const partType = this.state.parttype;
    const atstovasSelect = this.state.atstovasSelect;
    const isAllowed = this.state.isAllowed;
    const error = this.state.error;
    const loading = this.state.loading;
    //const dalyvis2 = this.state.dalyvis2;

    const represent = Object.values(this.state.atstovai);
    const options = represent.map((person) => {
      return (
        <>
          <option id={"represented" + person.id} value={person.id}>
            {person.name + " " + person.surname + " | " + person.personalCode}
          </option>
        </>
      );
    });

    return (
      <div>
        {isAllowed ? (
          partType ? (
            <div
              className="get-quote col-xl-8 col-lg-10 col-md-12 col-centered card card-container "
              style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                minHeight: "500px",
              }}
            >
              <a
                href="/dalyvis/pagrindinis"
                className="btn"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={(event) => this.setType(event)}
              >
                Dalyvauju pats
              </a>
              <a
                href="#"
                className="btn"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={this.changeStateAtstovasSelect}
              >
                Atstovauju kitą asmenį
              </a>
            </div>
          ) : (
            atstovasSelect && (
              <>
                <div
                  className="get-quote col-xl-8 col-lg-10 col-md-12 col-centered card card-container "
                  style={{
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    minHeight: "500px",
                  }}
                >
                  <h2
                    style={{
                      marginTop: "50px",
                      marginBottom: "25px",
                      fontSize: "2rem",
                    }}
                  >
                    Atstovaujamo asmens duomenys
                  </h2>
                  <form
                    onSubmit={(event) => this.saveAtstovaujamas(event)}
                    style={{ fontSize: "20px" }}
                  >
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="exampleFormControlSelect1">
                          Pasirinkite atstovaujamą asmenį:
                        </label>
                        <div className="input-group">
                          <select
                            id="represselect"
                            className="form-select"
                            onChange={(event) =>
                              this.changeAtstovaujamas(event)
                            }
                          >
                            {React.Children.toArray(options)}
                          </select>

                          {/* <button
                      type="submit"
                      className="btn"
                      id="changerepres"
                      style={{ padding: "0" }}
                    >
                      Išsaugoti
                    </button> */}
                        </div>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn"
                          type="submit"
                          id="submitButton2"
                          style={{ marginTop: "30px", marginBottom: "50px" }}
                          disabled={this.state.buttonDisabled}
                        >
                          Pasirinkti
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginTop: "25px" }}
                      >
                        <label>Vardas</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="namerepres"
                            readOnly
                            className="form-control"
                            placeholder="Vardenis"
                            value={this.state.namerepres}
                          />
                          <div style={{}}>
                            <button
                              className="btn"
                              id="changenamerepres"
                              onClick={(event) =>
                                this.editProfileAtstovaujamas(
                                  event,
                                  "name",
                                  "changenamerepres",
                                  "changenamerepresicon"
                                )
                              }
                              style={{ padding: "0px" }}
                            >
                              <i
                                id="changenamerepresicon"
                                style={{ fontSize: "28px" }}
                                className="fa fa-edit"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-lg-6 col-md-6"
                        style={{ marginTop: "25px" }}
                      >
                        <label>Pavardė</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="surnamerepres"
                            readOnly
                            className="form-control"
                            placeholder="Pavardenis"
                            value={this.state.surnamerepres}
                          />
                          <div style={{}}>
                            <button
                              className="btn"
                              id="changesurnamerepres"
                              onClick={(event) =>
                                this.editProfileAtstovaujamas(
                                  event,
                                  "surname",
                                  "changesurnamerepres",
                                  "changesurnamerepresicon"
                                )
                              }
                              style={{ padding: "0px" }}
                            >
                              <i
                                id="changesurnamerepresicon"
                                style={{ fontSize: "28px" }}
                                className="fa fa-edit"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-lg-6 col-md-6"
                        style={{ marginTop: "25px", marginBottom: "50px" }}
                      >
                        <label>Asmens kodas</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="personalcoderepres"
                            readOnly
                            className="form-control"
                            placeholder="90050050080"
                            maxLength={11}
                            value={this.state.personalcoderepres}
                          />
                          <div style={{}}>
                            <button
                              className="btn"
                              id="changepersonalcoderepres"
                              onClick={(event) =>
                                this.editProfileAtstovaujamas(
                                  event,
                                  "personalCode",
                                  "changepersonalcoderepres",
                                  "changepersonalcoderepresicon"
                                )
                              }
                              style={{ padding: "0px" }}
                            >
                              <i
                                id="changepersonalcoderepresicon"
                                style={{ fontSize: "28px" }}
                                className="fa fa-edit"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <label>Atstovavimo pagrindas</label>
                    <div className="input-group mb-2">
                      <a className=" btn" href={forma1} download>
                        Atsisiųsti
                      </a>
                      <div style={{}}></div>
                    </div>
                  </div> */}
                    </div>
                  </form>
                  <form
                    onChange={(event) => this.checkEmpty(event)}
                    onSubmit={(event) => this.saveNewAtstovas(event)}
                    style={{ fontSize: "20px" }}
                  >
                    <h2
                      style={{
                        marginTop: "50px",
                        marginBottom: "25px",
                        fontSize: "2rem",
                      }}
                    >
                      Pridėti naują atstovaujamą asmenį
                    </h2>
                    <div className="row">
                      <div
                        className="col-lg-6 col-md-6"
                        style={{ marginTop: "25px" }}
                      >
                        <label>Vardas</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="namerepresnew"
                            className="form-control"
                            placeholder="Vardenis"
                            defaultValue={this.state.namerepresnew}
                          />
                          <div style={{}}></div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-md-6"
                        style={{ marginTop: "25px" }}
                      >
                        <label>Pavardė</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="surnamerepresnew"
                            className="form-control"
                            placeholder="Pavardenis"
                            defaultValue={this.state.surnamerepresnew}
                          />
                          <div style={{}}></div>
                        </div>
                      </div>

                      <div
                        className="col-lg-6 col-md-6"
                        style={{ marginTop: "25px", marginBottom: "50px" }}
                      >
                        <label>Asmens kodas</label>
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            id="personalcoderepresnew"
                            className="form-control"
                            placeholder="90050050080"
                            maxLength={11}
                            defaultValue={this.state.personalcoderepresnew}
                          />
                          <div style={{}}></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn"
                        type="submit"
                        id="submitButton1"
                        style={{ float: "right", marginBottom: "50px" }}
                        disabled
                      >
                        Pridėti
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )
          )
        ) : loading ? (
          <div className="d-flex justify-content-center" style={{}}>
            <div
              className="spinner-grow d-flex justify-content-center"
              style={{
                width: "20rem",
                height: "20rem",
                marginTop: "80px",
                marginBottom: "80px",
                color: "#78003f",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="error-page section ">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  {/* Error Inner */}
                  <div className="error-inner">
                    {/* &nbsp;{error} */}
                    <h1>
                      <span>&nbsp;{error} Prieiga neleista</span>
                    </h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                    <br />
                  </div>
                  {/*/ End Error Inner */}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
