import React from 'react';



class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }


    render() {


        return <>


            {/* <div className="col-lg-12 col-12"> */}
            <div className="comments-form">


                <div className='row'>
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <h1 style={{ color: "#78003f", margin: "25px" }}>Sveiki atvykę į savo paskyrą!</h1>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        {/* <img   src="../img/woman-thumbs.jpg" alt="Woman showing thumbs up" style={{ width: "auto"}}></img> */}
                    </div>
                </div>
            </div>
            {/* </div> */}




        </>;
    }
}

export default Main;