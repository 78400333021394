import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth-service.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Užpildykite šį laukelį!
      </div>
    );
  }
};

const marginTopBot = {
  marginTop: "0px",
};

const font = {
  fontSize: "20px",
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      message: "",
      token: "",
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const token = params.get("token");

    if (email && token) {
      this.setState({ email, token });
    }
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.form.validateAll();

    if (
      this.checkBtn.context._errors.length === 0 &&
      this.state.password === this.state.confirmPassword
    ) {
      AuthService.changePassword(
        this.state.email,
        this.state.password,
        this.state.token
      )
        .then(() => {
          this.setState({ loading: false });
          window.alert("Slaptažodis pakeistas sėkmingai.");
        })
        .catch((error) => window.alert(error.message));
    } else {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ message: "Slaptažodžiai nesutampa!" });
      }
      this.setState({ loading: false });
    }
  }

  seePassword(event, id) {
    event.preventDefault();
    const input = document.getElementById(id);
    input.type = input.type === "password" ? "text" : "password";
  }

  render() {
    return (
      <div className="col-xl-5 col-centered">
        <div className="card card-container" style={{ margin: "0px" }}>
          <i
            style={{ marginTop: "100px" }}
            className="fa fa-user-circle fa-5x d-flex justify-content-center"
          ></i>

          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className=" d-flex justify-content-center">
              <label htmlFor="password" style={font}>
                Slaptažodis
              </label>
              <i className="fa fa-key fa-2x icon-cog iconcolor" />
            </div>
            <div className="form-group d-flex justify-content-center">
              <Input
                style={{ width: "200px" }}
                type="password"
                className="form-control"
                id="passwordinput"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
              <button
                className="btn"
                id="seepassword"
                onClick={(event) => this.seePassword(event, "passwordinput")}
                style={{
                  height: "32px",
                  padding: "5px",
                  whiteSpace: "nowrap",
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
            <div className="form-group d-flex justify-content-center">
              <Input
                style={{ width: "200px" }}
                type="password"
                className="form-control"
                id="confirmpasswordinput"
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.onChangeConfirmPassword}
                validations={[required]}
              />
              <button
                className="btn"
                id="seepassword"
                onClick={(event) =>
                  this.seePassword(event, "confirmpasswordinput")
                }
                style={{
                  height: "32px",
                  padding: "5px",
                  whiteSpace: "nowrap",
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: "75px" }}
            >
              <button
                className="btn"
                style={marginTopBot}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Pakeisti slaptažodį</span>
              </button>
            </div>
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
