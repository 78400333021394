import React from "react";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";
import { Route } from "react-router-dom";
import Atstovas from "../../loginuser/participation-subpages/atstovaujamas-folder/atstovas";
import UploadDocument from "../../loginuser/participation-subpages/uploaddocument";
import Scanning from "./skenavimas";

class PartManualRegisterAtstovas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name1: "",
      surname1: "",
      personalCode1: "",
      name2: "",
      surname2: "",
      personalCode2: "",
      namerepres: "",
      surnamerepres: "",
      personalCode: "",
      personalCoderepres: "",
    };

    //this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  handleChangeNumber(event, id) {
    var value = event.target.value;
    if (!isNaN(value) || value === "") {
      this.setState({ [id]: event.target.value });
    }
  }
  handleChangeAlpha(event, id) {
    var value = event.target.value;
    if (/^[a-zA-Ząčęėįšųūž]+$/.test(value) || value === "") {
      this.setState({ [id]: event.target.value });
    }
  }
  checkEmpty() {
    var name1 = this.state.name1;
    var surname1 = this.state.surname1;
    var personalCode1 = this.state.personalCode1;
    var email1 = document.getElementById("email1").value;

    var name2 = this.state.name2;
    var surname2 = this.state.surname2;
    var personalCode2 = this.state.personalCode1;
    var email2 = document.getElementById("email2").value;

    var namerepres = this.state.namerepres;
    var surnamerepres = this.state.surnamerepres;
    var personalCodeRepres =
      document.getElementById("personalCoderepres").value;

    document.getElementById("submitButton").disabled = true;
    var continueState = true;
    var quantity = 0;
    var allItems = [
      name1,
      surname1,
      personalCode1,
      email1,
      name2,
      surname2,
      personalCode2,
      email2,
      namerepres,
      surnamerepres,
      personalCodeRepres,
    ];
    for (let i = 0; i < allItems.length; i++) {
      console.log(allItems[i]);
    }
    //leidimas.value, ,applicationpdf,additionalinfo
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        break;
      }
      quantity += 1;
    }

    if (
      quantity === allItems.length &&
      continueState === true &&
      personalCodeRepres.length === 11 &&
      personalCode1.length === 11 &&
      personalCode2.length === 11
    ) {
      document.getElementById("submitButton").disabled = false;
    }
  }

  generateUniqueCode(users) {
    let code = "VU";

    for (let i = 0; i < 8; i++) {
      code += Math.floor(Math.random() * 10); // Generates a random digit from 0 to 9
    }

    return code;
  }

  handleSubmitNewDalyvis(e) {
    e.preventDefault();
    var name1 = this.state.name1;
    var surname1 = this.state.surname1;
    var personalcode1 = this.state.personalCode1;
    var email1 = document.getElementById("email1").value.trim();

    var name2 = this.state.name2;
    var surname2 = this.state.surname2;
    var personalcode2 = this.state.personalCode2;
    var email2 = document.getElementById("email2").value.trim();

    var namerepres = this.state.namerepres;
    var surnamerepres = this.state.surnamerepres;
    var personalcoderepres = this.state.personalCoderepres;

    var allItems = [
      name1,
      surname1,
      personalcode1,
      email1,
      name2,
      surname2,
      personalcode2,
      email2,
      namerepres,
      surnamerepres,
      personalcoderepres,
    ];

    var continueState = true;

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        window.alert(
          "Kai kurie langeliai yra nepilnai užpildyti arba nepasirinktas failas!"
        );
        break;
      }
      continueState = true;
    }

    var emailState = this.validateEmail(email1);
    if (!emailState) {
      window.alert("Neteisingas elektroninio pirmo atstovo pašto formatas!");
      return;
    }

    emailState = this.validateEmail(email2);
    if (!emailState) {
      window.alert("Neteisingas elektroninio antro atstovo pašto formatas!");
      return;
    }

    dalyvisService
      .getAllUsers()
      .then((response) => {
        var users = Object.values(response);

        for (let i = 0; i < response.length; i++) {
          if (response[i].email === email1 || response[i].email === email2) {
            continueState = false;
            emailState = false;
            break;
          }
        }

        var uniqueCode1 = this.generateUniqueCode();

        for (let i = 0; i < response.length; i++) {
          if (response[i].uniqueCode === uniqueCode1) {
            uniqueCode1 = this.generateUniqueCode();
            i = 0;
          }
        }

        var uniqueCode2 = this.generateUniqueCode();

        for (let i = 0; i < response.length; i++) {
          if (response[i].uniqueCode === uniqueCode2) {
            uniqueCode2 = this.generateUniqueCode();
            i = 0;
          }
        }

        var uniqueCodeRepres = this.generateUniqueCode();

        for (let i = 0; i < response.length; i++) {
          if (response[i].uniqueCodeRepres === uniqueCodeRepres) {
            uniqueCodeRepres = this.generateUniqueCode();
            i = 0;
          }
        }

        if (continueState) {
          console.log("Inside continue state----------------");
          const password1 = authService.generatePassword(8);
          const password2 = authService.generatePassword(8);
          const role = ["user"];

          //issiusti informacija i duombaze ---- Truksta dar vieno kintamojo duombazėj
          // dalyvisService
          //   .postRegisterPersonalRepres(
          //     email,
          //     name,
          //     surname,
          //     personalcode,
          //     namerepres,
          //     surnamerepres,
          //     personalcoderepres
          //   )
          //   .catch((error) => window.alert(error.message));

          const newUser1 = {
            email: email1,
            password: password1,
            role: role,
            name: name1,
            surname: surname1,
            personalCode: personalcode1,
            uniqueCode: uniqueCode1,
            registerType: 2,
          };

          const newUser2 = {
            email: email2,
            password: password2,
            role: role,
            name: name2,
            surname: surname2,
            personalCode: personalcode2,
            uniqueCode: uniqueCode2,
            registerType: 2,
          };

          const newUserRepres = {
            name: this.state.namerepres,
            surname: this.state.surnamerepres,
            personalcode: this.state.personalCoderepres,
            uniqueCode: uniqueCodeRepres,
            //representedPeople: user.id,
          };

          const registrationData = {
            regAtstovas1: newUser1,
            regAtstovas2: newUser2,
            regRepresented: newUserRepres,
          };
          // sessionStorage.setItem("regAtstovai", JSON.stringify(newUser1));
          // sessionStorage.setItem("regAtstovas2", JSON.stringify(newUser2));
          // sessionStorage.setItem(
          //   "regRepresented",
          //   JSON.stringify(newUserRepres)
          // );
          sessionStorage.setItem(
            "registrationData",
            JSON.stringify(registrationData)
          );
          this.props.history.push("/darbuotojas/dalyviu-reg-atstovas/atstovas");

          // authService
          //   .register(email, password, role)
          //   .then(() => {
          //     dalyvisService
          //       .getAllUsers()
          //       .then((response) => {
          //         console.log(response);

          //         const allUsers = response;

          //         if (allUsers !== null) {
          //           allUsers.forEach((user) => {
          //             if (user.email === email) {
          //               dalyvisService
          //                 .getAllPersonalData(user.id)
          //                 .then((response) => {
          //                   console.log(response);
          //                   var newUser = response;

          //                   newUser.name = name;
          //                   newUser.surname = surname;
          //                   newUser.personalCode = personalcode;
          //                   newUser.uniqueCode = uniqueCode;
          //                   newUser.registerType = 2;

          //                   var newUserRepres = {
          //                     name: this.state.namerepres,
          //                     surname: this.state.surnamerepres,
          //                     personalcode: this.state.personalCoderepres,
          //                     uniqueCode: uniqueCodeRepres,
          //                     representedPeople: user.id,
          //                   };
          //                   dalyvisService
          //                     .postPersonalDataObject(newUserRepres)
          //                     .then((response) => {
          //                       //newUser.representedPeople = response;

          //                       dalyvisService
          //                         .putCustomObjectPersonalData(
          //                           newUser.id,
          //                           newUser
          //                         )
          //                         .then(() => {
          //                           window.alert("Dalyvis užregistruotas");
          //                           window.alert(
          //                             "Elektroninis paštas: " + email
          //                           );
          //                           window.alert("Slaptažodis: " + password);
          //                         })
          //                         .catch((error) =>
          //                           window.alert(error.message)
          //                         );
          //                     })
          //                     .catch((error) => window.alert(error.message));
          //                 })
          //                 .catch((error) => window.alert(error.message));
          //             }
          //           });
          //         }
          //       })
          //       .catch((error) => window.alert(error.message));
          //   })
          //   .catch((error) => window.alert(error.message));
        } else if (!emailState) {
          window.alert("Emailas jau panaudotas. Pasirinkite kitą");
        } else {
          window.alert("Neatpažinta klaida");
        }
      })
      .catch((error) => window.alert(error.message));
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }
  render() {
    const pathname = window.location.pathname;
    const isNotRegistered =
      pathname === "/darbuotojas/dalyviu-reg-atstovas" ||
      pathname === "/darbuotojas*/dalyviu-reg-atstovas"
        ? true
        : false;

    return (
      <div className="col-lg-12 col-12">
        {isNotRegistered ? (
          <>
            <h2
              style={{
                marginBottom: "25px",
                paddingTop: "50px",
                fontSize: "2rem",
              }}
            >
              Užregistruoti naują pirmą atstovą
            </h2>

            <form
              className="form col-lg-12 col-md-12 border-edit-button px-1"
              style={{ fontSize: "20px", paddingBottom: "75px" }}
              onInput={(event) => this.checkEmpty(event)}
              onChange={(event) => this.checkEmpty(event)}
              onSubmit={(e) => this.handleSubmitNewDalyvis(e)}
            >
              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Vardas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="name1"
                      className="form-control"
                      placeholder="Vardenis"
                      value={this.state.name1}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "name1")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Pavardė</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="surname1"
                      className="form-control"
                      placeholder="Pavardenis"
                      value={this.state.surname1}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "surname1")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Asmens kodas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="personalCode1"
                      className="form-control"
                      placeholder="90050050080"
                      maxLength={11}
                      value={this.state.personalCode1}
                      onChange={(value) =>
                        this.handleChangeNumber(value, "personalCode1")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-8 col-md-8"
                  style={{ marginTop: "25px" }}
                >
                  <label>Elektroninis paštas</label>
                  <div className="input-group mb-2">
                    {/* <div className="input-group-prepend">
                          <div className="input-group-text">@</div>
                        </div> */}
                    <input
                      type="email"
                      className="form-control"
                      id="email1"
                      placeholder="elektroninis@pastas.com"
                    />
                  </div>
                </div>
              </div>

              <h2
                style={{
                  marginBottom: "25px",
                  paddingTop: "50px",
                  fontSize: "2rem",
                }}
              >
                Užregistruoti naują antrą atstovą
              </h2>

              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Vardas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="name2"
                      className="form-control"
                      placeholder="Vardenis"
                      value={this.state.name2}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "name2")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Pavardė</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="surname2"
                      className="form-control"
                      placeholder="Pavardenis"
                      value={this.state.surname2}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "surname2")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Asmens kodas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="personalCode2"
                      className="form-control"
                      placeholder="90050050080"
                      maxLength={11}
                      value={this.state.personalCode2}
                      onChange={(value) =>
                        this.handleChangeNumber(value, "personalCode2")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-8 col-md-8"
                  style={{ marginTop: "25px" }}
                >
                  <label>Elektroninis paštas</label>
                  <div className="input-group mb-2">
                    {/* <div className="input-group-prepend">
                          <div className="input-group-text">@</div>
                        </div> */}
                    <input
                      type="email"
                      className="form-control"
                      id="email2"
                      placeholder="elektroninis@pastas.com"
                    />
                  </div>
                </div>
              </div>

              <h2
                style={{
                  marginBottom: "25px",
                  paddingTop: "50px",
                  fontSize: "2rem",
                }}
              >
                Atstovaujamo asmens duomenys
              </h2>
              <div className="row">
                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Vardas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="namerepres"
                      className="form-control"
                      placeholder="Vardenis"
                      value={this.state.namerepres}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "namerepres")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Pavardė</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="surnamerepres"
                      className="form-control"
                      placeholder="Pavardenis"
                      value={this.state.surnamerepres}
                      onChange={(event) =>
                        this.handleChangeAlpha(event, "surnamerepres")
                      }
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6"
                  style={{ marginTop: "25px" }}
                >
                  <label>Asmens kodas</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      id="personalCoderepres"
                      className="form-control"
                      placeholder="90050050080"
                      maxLength={11}
                      value={this.state.personalCoderepres}
                      onChange={(value) =>
                        this.handleChangeNumber(value, "personalCoderepres")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-centered">
                <button
                  className="btn col-lg-6 col-md-6 col-sm-12"
                  id="submitButton"
                  type="submit"
                  style={{ float: "right", marginTop: "25px" }}
                  disabled
                >
                  Pateikti
                </button>
              </div>
            </form>
          </>
        ) : (
          <></>
        )}
        <Route
          path={`${this.props.match.path}/atstovas`}
          component={Atstovas}
        />
        <Route
          path={`${this.props.match.path}/uploaddocument`}
          component={UploadDocument}
        />
        <Route
          path={`${this.props.match.path}/skenavimas`}
          component={Scanning}
        />
      </div>
    );
  }
}

export default PartManualRegisterAtstovas;
