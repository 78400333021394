import React from "react";
import EventBus from "../../../common/EventBus";

class NotParticipating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    EventBus.dispatch("logout");
  }

  render() {
    return (
      <>
        <div
          className="get-quote col-xl-8 col-lg-10 col-md-12 col-centered card card-container"
          style={{
            paddingTop: "50px",
            paddingBottom: "50px",
            minHeight: "500px",
          }}
        >
          <div className="col-lg-10 col-md-12 col-centered custom-box">
            <h2 style={{textAlign: "left"}}>
              Dėkojame, kad domėjotės galimybe tapti LPRLB dalyviu, tikimės, kad
              apsispręsite/grįšite vėliau.
            </h2>
          </div>
        </div>
      </>
    );
  }
}

export default NotParticipating;
