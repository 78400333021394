import React, { Component } from "react";
import dalyvisService from "../../../../services/dalyvis-service";

class NewInquiry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(sessionStorage.getItem("user")),
      date: "",
      dateToday: new Date(),
    };

    //this.changeButtonWord = this.changeButtonWord.bind(this);
  }
  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;
    this.setState({
      date: today,
    });
  }
  submitForm(e) {
    e.preventDefault();
    var inquirydate = this.state.date;
    var inquirytopic = document.getElementById("inquirytopic").value;
    var inquirytext = document.getElementById("inquirytext").value;

    var allItems = [inquirydate, inquirytopic, inquirytext];

    var continueState = true;

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        window.alert(
          "Kai kurie langeliai yra nepilnai užpildyti arba nepasirinktas failas!"
        );
        break;
      }
      continueState = true;
    }

    if (continueState === true) {
      // window.alert("Sėkmingai išsiųsta!");
      // dalyvisService.postMessages(inquirytext, 4, this.state.dateToday)
      // dalyvisService
      //   .postMessages(inquirytext.trim(), 4, 1, inquirytopic.trim(), new Date())
      //   .catch((error) => window.alert(error.message));
      var userObjectUpdated = JSON.parse(sessionStorage.getItem("user"));
      console.log(userObjectUpdated);

      const messageSend = {
        messageText: inquirytext.trim(),
        messageState: 1,
        messageType: 4,
        messageTopic: inquirytopic.trim(),
        eventDate: new Date(),
      };

      dalyvisService
        .postMessage(messageSend)
        .then((response) => {
          userObjectUpdated.message.push(response);
          console.log(userObjectUpdated);

          dalyvisService
            .putCustomObjectPersonalData(
              userObjectUpdated.id,
              userObjectUpdated
            )
            .then(() => {
              dalyvisService
                .getAllPersonalData(userObjectUpdated.id)
                .then((response) => {
                  console.log(response);
                  window.alert("Sėkmingai išsiųsta!");
                  sessionStorage.setItem("user", JSON.stringify(response));
                  this.props.history.push("./uzklausos");
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));
      // window.location.reload();
    }
  }
  checkEmpty(e) {
    e.preventDefault();
    var inquirydate = this.state.date;
    var inquirytopic = document.getElementById("inquirytopic").value;
    var inquirytext = document.getElementById("inquirytext").value;
    var allItems = [inquirydate, inquirytopic, inquirytext];
    var continueState = true;
    var quantity = 0;
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;
        document.getElementById("submitButton").disabled = true;
        break;
      }
      quantity += 1;
    }
    if (quantity === allItems.length) {
      document.getElementById("submitButton").disabled = false;
    }
  }

  render() {
    return (
      <>
        <div className="col-centered col-lg-10 col-md-12 col-12">
          {/* <h2><b>Pildyti naują paraiška</b></h2> */}
          <form
            onInput={(event) => this.checkEmpty(event)}
            onChange={(event) => this.checkEmpty(event)}
            onSubmit={(event) => this.submitForm(event)}
          >
            <table className="table">
              <thead className="thead-dark">
                {/* <tr>
                      
                        <th scope="col">Skiltis</th>
                        <th scope="col">Įvedama informacija</th>
                       
                      </tr> */}
              </thead>
              <tbody>
                <tr>
                  {/* <th scope="row">1</th> */}
                  <th scope="row">Data</th>
                  <td>
                    <input
                      type="text"
                      id="inquirydate"
                      name="projectname"
                      style={{ width: "100%" }}
                      readOnly
                      value={this.state.date}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Tema*</th>
                  <td>
                    <input
                      type="text"
                      id="inquirytopic"
                      name="projectacronym"
                      style={{ width: "100%" }}
                      placeholder="Tema..."
                    />
                  </td>
                </tr>
                <tr>
                  <th>Tekstas*</th>
                  <td>
                    <textarea
                      id="inquirytext"
                      style={{ height: "5rem" }}
                      rows={7}
                      placeholder="Įrašyti duomenis čia ... "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-12">
              <div className="form-group button">
                <button
                  id="submitButton"
                  type="submit"
                  className="btn"
                  disabled
                >
                  <i className="fa fa-send" />
                  Pateikti
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default NewInquiry;
