import React from "react";
// import forma1 from "../../../docs/important/Informacija_apie_LPRLB_Dalyviui.pdf";
// import forma2 from "../../../docs/important/LPRLB_Sutikimo_atsaukimas.docx";
//import forma2 from "../../../docs/atsisakymoforma2.rtf";
import UserService from "../../../services/user.service-galimaspanaudojimas";
import EventBus from "../../../common/EventBus";
import dalyvisService from "../../../services/dalyvis-service";

class DalyvisDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(sessionStorage.getItem("user")),
      filenameatsisakymo: "Pasirinkite dokumentą",
      signedDoc: "",
      atstovas: false,
      doc: "Pasirinkite dokumentą",
      docfiles: Object.values(
        JSON.parse(sessionStorage.getItem("user")).docfile
      ),
      uploaded: false,
      uploadedfile: "None",
      uploadedFilelink: "",
      uploadedFilename: "",
      uploadCount: 0,
      docfiles2: "",
      userFile: "",
      selectedFile: null,
      form1: null,
      form2: null,
    };
  }
  componentDidMount() {
    var docfiles;
    dalyvisService
      .getAllFiles()
      .then((response) => {
        docfiles = Object.values(response);

        const form1 =
          "https://covid19.mf.vu.lt/proxy/api/files/download/Informacija_apie_LPRLB_Dalyviui.pdf";
        const form2 =
          "https://covid19.mf.vu.lt/proxy/api/files/download/LPRLB_Sutikimo_atsaukimas.docx";

        this.setState({
          docfiles: docfiles,
          form1: form1,
          form2: form2,
        });

        this.userSubmissionFile();

        console.log(docfiles);
        console.log(docfiles.length);
        if (docfiles.length > 1) {
          const regex = new RegExp(
            `${this.state.user.name}_${this.state.user.surname}_sutikimo_atsaukimas_${this.state.user.id}_(\\d+)\\.(pdf|docx)$`
          );

          let docFilesFiltered = docfiles.filter((item) =>
            regex.test(item.name)
          );
          console.log(docFilesFiltered);

          if (docFilesFiltered !== null) {
            let maxCount = 0;
            let fileWithMaxCount = null;

            docFilesFiltered.forEach((file) => {
              // Extract count using the same regex pattern
              const match = file.name.match(
                new RegExp(
                  `${this.state.user.name}_${this.state.user.surname}_sutikimo_atsaukimas_${this.state.user.id}_(\\d+)\\.(pdf|docx)$`
                )
              );
              if (match && match[1]) {
                const count = parseInt(match[1], 10);
                if (count > maxCount) {
                  maxCount = count;
                  fileWithMaxCount = file.name;
                }
              }
            });

            if (fileWithMaxCount !== null) {
              dalyvisService
                .getFile(fileWithMaxCount)
                .then((response) => {
                  console.log(response);

                  // The URL would be the endpoint you called
                  const fileURL =
                    "https://covid19.mf.vu.lt/proxy/api/files/download/" + fileWithMaxCount;

                  console.log("File Name:", fileWithMaxCount);
                  console.log("File URL:", fileURL);

                  this.setState({
                    uploaded: true,
                    //uploadedfile: maxValue,
                    uploadedFilelink: fileURL,
                    uploadedFilename: fileWithMaxCount,
                    uploadCount: maxCount,
                  });
                })
                .catch((error) => window.alert(error.message));
            }
          }
        }
      })
      .catch((error) => window.alert(error.message));

    //   var statePart = localStorage.getItem("selectedParticipation");
    //   if(statePart == "atstovas"){
    //     this.setState({
    //       atstovas: true
    //     });
    //   }
    //   setTimeout(() => {
    //   UserService.getParticipantBoard().then(

    //     response => {
    //       // window.alert(response.data);
    //       this.setState({
    //         isAllowed: true,
    //         loading: false
    //       });
    //     },
    //     error => {
    //       this.setState({
    //         error:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString(),
    //         isAllowed: false,
    //         loading: false
    //       });

    //       // window.alert(parseInt(error.response.status));
    //       if (error.response && error.response.status === 401) {
    //         EventBus.dispatch("logout");
    //       }
    //       else if(error.message === "Network Error"){
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );

    //   // if(localStorage.getItem("user") != null){
    // // var user = JSON.parse(localStorage.getItem("user"));
    // // console.log(user);
    // // var userId = user.id;
    // // var data = JSON.parse(localStorage.getItem("data"));
    // // // window.alert(localStorage.getItem("data"));
    // // //  window.alert(data);
    // // for(let i = 0; i < data.docs.length; i++){
    // //   // console.log("data id:" + data.docs[i].userid + "login id:" + userId);
    // //   if(data.docs[i].userid == userId && data.docs[i].filename != ""){
    // //     this.setState({
    // //       signedDoc: "Pasirašytas dokumentas: " + data.docs[i].filename
    // //     })
    // //     // window.alert(data.docs[i].filename);
    // //     break;
    // //   }
    // // }
    // // }
    // }, 500);
  }

  saveFileForUser(e) {
    e.preventDefault();
    if (this.state.user !== null) {
      // var file = document.getElementById("signedFile");
      // var fileName = document
      //   .getElementById("signedFile")
      //   .value.split("\\")
      //   .pop()
      //   .split("/")
      //   .pop();

      var newCount = this.state.uploadCount + 1;

      const selectedFile = this.state.selectedFile;

      if (this.state.uploadCount < 5) {
        if (selectedFile) {
          const formData = new FormData();

          const extension = selectedFile.name.split(".").pop();

          const newFileName = `${this.state.user.name}_${this.state.user.surname}_sutikimo_atsaukimas_${this.state.user.id}_${newCount}.${extension}`;
          console.log("New file name: " + newFileName);
          const newFile = new File([selectedFile], newFileName, {
            type: selectedFile.type,
          });

          formData.append("file", newFile);

          var userObjectUpdated = this.state.user;
          //userObjectUpdated.docfile.push(newFileName);
          console.log("new user");
          console.log(userObjectUpdated);

          dalyvisService
            .postFileUpload(formData)
            .then(() => {
              dalyvisService
                .putCustomObjectPersonalData(
                  this.state.user.id,
                  userObjectUpdated
                )
                .then((response) => {
                  console.log(response);
                  dalyvisService
                    .getAllPersonalData(this.state.user.id)
                    .then((response) => {
                      sessionStorage.setItem("user", JSON.stringify(response));

                      const fileURL =
                        "https://covid19.mf.vu.lt/proxy/api/files/download/" + newFileName;

                      var docfiles;
                      dalyvisService
                        .getAllFiles()
                        .then((response) => {
                          docfiles = Object.values(response);
                          this.setState({
                            docfiles: docfiles,
                            user: userObjectUpdated,
                            uploaded: true,
                            uploadedFilelink: fileURL,
                            uploadedFilename: newFileName,
                            uploadCount: newCount,
                          });

                          window.alert("Failas sėkmingai pateiktas");
                        })
                        .catch((error) => window.alert(error.message));
                    })
                    .catch((error) => window.alert(error.message));
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        } else {
          window.alert("Nepasirinktas failas");
        }
      } else {
        window.alert("Pasiektas įkeltų failų limitas");
      }
      // var newestData1 = JSON.parse(localStorage.getItem("data"));

      // var newestData = JSON.parse(localStorage.getItem("data"));
      // newestData.docs.push({"userid":userId, "filename":fileName})
      // localStorage.setItem("data", JSON.stringify(newestData));
      // var newestData = localStorage.getItem("data");

      // console.log(newestData.docs);

      // var firsttime = JSON.parse(localStorage.getItem("firsttime"));
      // firsttime.push({"userid":userId, "first":"false"});
      // localStorage.setItem("firsttime", JSON.stringify(firsttime));
      // save file and return link ----------------
      // console.log("saveFileForUser");
      //---------------------------------------------------------------
      // dalyvisService
      //   .postDocfile(
      //     fileName,
      //     "http://www.filedirectory.com/files/" + fileName,
      //     "atsisakymoforma",
      //     new Date()
      //   )
      //   .catch((error) => window.alert(error.message));
      //---------------------------------------------------------------
      // this.relationshipDocFileUser(userId, response.id)
      // window.alert("Failas sėkmingai pateiktas " + fileName);
      // this.props.history.push("/dalyvis");
      // window.location.reload();
    }
  }
  submitFile() {
    window.alert("Atsisakymo forma nusiųsta!");
  }
  changeDocText(e) {
    e.preventDefault();
    var fileName = document
      .getElementById("signedFile")
      .value.split("\\")
      .pop()
      .split("/")
      .pop();

    this.setState({ selectedFile: e.target.files[0] });

    if (fileName !== "" && fileName !== null) {
      this.setState({
        doc: "Pasirinkite dokumentą",
      });
      document.getElementById("button1").disabled = false;
    }
    if (fileName === "" || fileName === null) {
      this.setState({
        doc: "Pasirinkite dokumentą",
      });
      document.getElementById("button1").disabled = true;
    }
  }

  userSubmissionFile(e) {
    var fileName;
    if (this.state.user.registerType === 2) {
      fileName =
        "Atstovo_" +
        this.state.user.name +
        "_" +
        this.state.user.surname +
        "_" +
        this.state.user.id +
        "_sutikimas_LPRLB.pdf";
    } else {
      fileName =
        "Dalyvio_" +
        this.state.user.name +
        "_" +
        this.state.user.surname +
        "_" +
        this.state.user.id +
        "_sutikimas_LPRLB.pdf";
    }

    const url = "https://covid19.mf.vu.lt/proxy/api/files/download/" + fileName;
    this.setState({
      userFile: url,
    });
    // console.log(fileName);
    // dalyvisService
    //   .getFile(fileName)
    //   .then((response) => {
    //     this.setState({
    //       userFile: response.url,
    //     });
    //   })
    //   .catch((error) => window.alert(error.message));
  }

  downloadSubmission(e) {
    e.preventDefault();

    var fileName;
    if (this.state.user.registerType === 2) {
      fileName =
        "Atstovo_" +
        this.state.user.name +
        "_" +
        this.state.user.surname +
        "_" +
        this.state.user.id +
        "_sutikimas_LPRLB.pdf";
    } else {
      fileName =
        "Dalyvio_" +
        this.state.user.name +
        "_" +
        this.state.user.surname +
        "_" +
        this.state.user.id +
        "_sutikimas_LPRLB.pdf";
    }

    const url = "https://covid19.mf.vu.lt/proxy/api/files/download/" + fileName;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const pdfBlob = new Blob([blob], { type: "application/pdf" });

        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName; // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  }

  downloadForm1(e) {
    e.preventDefault();

    const url = this.state.form1;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const pdfBlob = new Blob([blob], { type: "application/pdf" });

        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "Informacija_apie_LPRLB_Dalyviui.pdf"; // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading the PDF:", error));
  }

  downloadForm2(e) {
    e.preventDefault();

    const url = this.state.form2;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const docxBlob = new Blob([blob], { type: "application/docx" });

        const blobUrl = window.URL.createObjectURL(docxBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "LPRLB_Sutikimo_atsaukimas.docx"; // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading the DOCX:", error));
  }

  render() {
    const signeddoc = this.state.signedDoc;

    return (
      <>
        {/* <div className="col-lg-12 col-md-12 col-12"> */}
        <div className="main-sidebar">
          {/* dokumentai */}

          <div className="single-widget category">
            <h3 className="title">Informuoto asmens sutikimo forma</h3>
            <span className="categor-list-style">{signeddoc}</span>
            {/* <a
              className="btn"
              href={this.state.userFile}
              // href="../documents/important/3_SUTIKIMAS_dalyviui_LPRLB.docx"
              // download
            >
              Atsisiųsti IASF
            </a> */}
            <button
              className="btn"
              // style={{
              //   margin: "0px 0 25px 25px",
              //   fontSize: "20px",
              // }}
              onClick={(e) => this.downloadSubmission(e)}
            >
              Atsisiųsti IASF
            </button>
          </div>

          <div className="single-widget category">
            <h3 className="title">Asmens informavimo forma</h3>
            <span className="categor-list-style">{signeddoc}</span>
            {/* <a
              className="btn"
              href={this.state.form1} //"src\docs\important\Informacija_apie_LPRLB_Dalyviui.pdf"
              // download
            >
              Atsisiųsti AIF
            </a> */}
            <button className="btn" onClick={(e) => this.downloadForm1(e)}>
              Atsisiųsti IASF
            </button>
          </div>

          {/* dokumentai */}
          <div className="single-widget category">
            <h3 className="title">
              Sutikimo atšaukimo dalyvauti biobanko veikloje forma{" "}
            </h3>
            <span>Sutikimo atšaukimo dalyvauti biobanko veikloje forma </span>
            <ul>
              {this.state.atstovas ? (
                <>
                  <li>
                    {" "}
                    {/* <a
                      className="btn"
                      href={this.state.form2}
                      // download
                    >
                      Sutikimo atšaukimo forma atstovui - atsisiųsti
                    </a> */}
                    <button
                      className="btn"
                      onClick={(e) => this.downloadForm2(e)}
                    >
                      Sutikimo atšaukimo forma atstovui - atsisiųsti
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    {" "}
                    {/* <a
                      className="btn"
                      // href="../../../docs/important/LPRLB_Sutikimo_atšaukimas.docx"
                      href={this.state.form2}
                      // download
                    >
                      Sutikimo atšaukimo forma dalyviui - atsisiųsti
                    </a> */}
                    <button
                      className="btn"
                      onClick={(e) => this.downloadForm2(e)}
                    >
                      Sutikimo atšaukimo forma dalyviui - atsisiųsti
                    </button>
                  </li>
                </>
              )}
            </ul>
            <span>
              *Norėdami užpildyti formą atstovaujamam asmeniui paspauskite
              "Įjungti atstovavimą" puslapio viršuje{" "}
            </span>
            <br />

            {this.state.uploaded ? (
              <>
                <form
                  className="hoover get-quote"
                  onSubmit={(e) => this.saveFileForUser(e)}
                >
                  <span>Keisti pasirašytą sutikimo atšaukimo formą</span>
                  <br />
                  <label
                    className="btn"
                    style={{ paddingTop: "2px", paddingBottom: "2px" }}
                  >
                    <input
                      className="col-centered"
                      type="file"
                      accept=".docx, .pdf"
                      id="signedFile"
                      onInput={(event) => this.changeDocText(event)}
                    />{" "}
                    {/*onChange={() =>this.changeButtonWord("signedFile")} */}
                    {this.state.doc}
                  </label>

                  <br />
                  <button
                    type="submit"
                    id="button1"
                    className="col-centered btn"
                    style={{ paddingTop: "2px", paddingBottom: "2px" }}
                    disabled
                  >
                    Keisti
                  </button>
                </form>
                <label>Pasirašyta sutikimo atšaukimo forma</label> <br />
                <div className="get-quote">
                  <a
                    className="btn"
                    href={this.state.uploadedFilelink}
                    download
                  >
                    {this.state.uploadedFilename}
                  </a>
                </div>
              </>
            ) : (
              <>
                <form
                  className="hoover get-quote"
                  onSubmit={(e) => this.saveFileForUser(e)}
                >
                  <span>Įkelti pasirašytą sutikimo atšaukimo formą</span>
                  <br />
                  <label
                    className="btn"
                    style={{ paddingTop: "2px", paddingBottom: "2px" }}
                  >
                    <input
                      className="col-centered"
                      type="file"
                      accept=".docx, .pdf"
                      id="signedFile"
                      onInput={(event) => this.changeDocText(event)}
                    />{" "}
                    {/*onChange={() =>this.changeButtonWord("signedFile")} */}
                    {this.state.doc}
                  </label>

                  <br />
                  <button
                    type="submit"
                    id="button1"
                    className="col-centered btn"
                    style={{ paddingTop: "2px", paddingBottom: "2px" }}
                    disabled
                  >
                    Pateikti
                  </button>
                </form>
              </>
            )}
          </div>
          {/*/ dokumentai */}
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default DalyvisDocs;
