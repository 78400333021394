import React from 'react';


class AboutSideNav extends React.Component {


  componentDidMount() {
    for(let i = 1; i < 6; i++){
      document.getElementById("teise" + i).style.display = "none";
      }
    for(let i = 1; i < 4; i++){
      document.getElementById("sklaida" + i).style.display = "none";
    }
    //document.getElementById("teisemain").disabled = true;

    var pathname = window.location.pathname;
    var paths_law = ["/licencija","/biobankoteises","/teiseteikti","/teisesaktai","/duomenutvarkymas","/institucijos"]
    var paths_sklaida = ["/publikacijos", "/mesziniasklaidoje", "/renginiai" ]
   for(const path of paths_law){
    if(pathname === path ){ //(pathname.includes(paths_law))
    for(let i = 1; i < 6; i++){
    document.getElementById("teise" + i).style.display = "block";
    //document.getElementById("teisemain").disabled = true;
    }
    }
  }
  for(const path of paths_sklaida){
    if(pathname === path ){
    for(let i = 1; i < 4; i++){
      document.getElementById("sklaida" + i).style.display = "block";
    }
  }
}
  document.getElementById("struktura").style.display = "none";
  if( ( pathname ==="/struktura")){
  document.getElementById("struktura").style.display = "block";
  }
  else if( ( pathname ==="/strukturakontaktai")){
    document.getElementById("struktura").style.display = "block";
    }
  
    
}

handleList(id, lengthList) {
  
  //alert('Sėkmingai išsiųsta!');
  
  //console.log(length);
  for(let i = 1; i < lengthList+1; i++){
   var x = document.getElementById(id + i);
  
  if (x.style.display === "none") {
    x.style.display = "block";

  } else {
    x.style.display = "none";
  }
}
if (x.style.display === "none") {
  document.getElementById(id).className = "fa fa-caret-right";

} else {
  
  document.getElementById(id).className = "fa fa-caret-down";
}

  //window.location.reload();
}

    render() { 
      const color = 'black';
      const backColor = 'white';
        const pathname = window.location.pathname;
        const apieActive = pathname === '/apievubio' ?  {color: color, backgroundColor: backColor} : {}
        const tikslaiActive = pathname === '/tikslaimisija' ?  {color: color, backgroundColor: backColor} : {}
        const strukturaActive = pathname === '/strukturakontaktai' ||  pathname === '/struktura' ?  {color: color, backgroundColor: backColor} : {}
        const struktura1Active = pathname === '/struktura' ?  {color: color,backgroundColor: backColor} : {}
        const meginiaiActive = pathname === '/meginiai' ?  {color: color, backgroundColor: backColor} : {}
        const projektaiActive = pathname === '/projektai' ?  {color: color, backgroundColor: backColor} : {}
        // const registraiActive = pathname === '/registraiduomenys' ?  {color: color, backgroundColor: backColor} : {}
        //teise beg

        const teiseMainActive = pathname === '/licencija' || pathname === '/biobankoteises' ||
        pathname === '/teiseteikti' || pathname === '/teisesaktai' ||pathname === '/duomenutvarkymas' || pathname === '/institucijos' ?  
        {color: color, backgroundColor: backColor} : {} // Main

        const licencijaActive = pathname === '/licencija' ?  {color: color, backgroundColor: backColor} : {}
        const teiseTvarkytiActive = pathname === '/biobankoteises' ?  {color: color, backgroundColor: backColor} : {}
        const teisesAktaiActive = pathname === '/teisesaktai' ?  {color: color, backgroundColor: backColor} : {}
        const duomenysActive = pathname === '/duomenutvarkymas' ?  {color: color, backgroundColor: backColor} : {}
        const institucijosActive = pathname === '/institucijos' ?  {color: color, backgroundColor: backColor} : {}

        //teise end
        //sklaida beg

        const sklaidaMainActive = pathname === '/publikacijos' || pathname === '/mesziniasklaidoje' || 
        pathname === '/renginiai' ?  {color: color, backgroundColor: backColor} : {} // Main

        const publikacijosActive = pathname === '/publikacijos' ?  {color: color, backgroundColor: backColor} : {} 
        const ziniosActive = pathname === '/mesziniasklaidoje' ?  {color: color, backgroundColor: backColor} : {} 
        const renginiaiActive = pathname === '/renginiai' ?  {color: color, backgroundColor: backColor} : {} 
        //sklaida end
        return (
            <div className="col-lg-3 mb-5 margin-nav">
              <div className = "position-button size">
                <a href="/apievubio" className="btn" style={apieActive}>Apie VU biobanką&nbsp; <i className="fa fa-caret-down" style={{fontSize: "20px",float: "right", textAlign: "right", marginRight: "10px", wordBreak: "keep-all"}}></i> </a>
                <ul className="dropdown-padding">
                
                <li><a href="/tikslaimisija" className="btn" style={tikslaiActive}>Tikslai ir misija </a></li>
                <li><a href="/strukturakontaktai" className="btn" style={strukturaActive}>Struktūra ir kontaktai</a></li>
                <ul className="dropdown-padding1">
                <li><a href="/struktura" className="btn" style={struktura1Active} id = "struktura">&nbsp;Struktūra</a></li>
                </ul>
                <li><a href="/meginiai" className="btn" style={meginiaiActive}>Saugomi mėginiai</a></li>
                <li><a href="/projektai" className="btn" style={projektaiActive}>Projektai</a></li>
                {/* <li><a href="/registraiduomenys" className="btn" style={registraiActive}>Registrai ir duomenų bazės</a></li> */}
                <li><a href="#" data-toggle="" id="teisemain" onClick={() => this.handleList("teise",5)} className="btn" style={teiseMainActive}>Teisinė informacija&nbsp;<i id="teise" className="fa fa-caret-right" style={{fontSize: "20px",float: "right", textAlign: "right", marginRight: "10px", wordBreak: "keep-all"}}></i></a></li>
                <ul className="dropdown-padding1">
                <li><a id = "teise1" href="/licencija" className="btn" style={licencijaActive }>Licencija</a></li>
                <li><a id = "teise2" href="/biobankoteises" className="btn" style={teiseTvarkytiActive}>Biobanko teisės</a></li>
                {/* <li><a id = "teise" href="/teiseteikti" className="btn" style={teiseTeiktiActive }><i className="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Teisė teikti mėginius ir sveikatos informaciją</a></li> */}
                <li><a id = "teise3" href="/teisesaktai" className="btn" style={teisesAktaiActive }>Teisės aktai</a></li>
                <li><a id = "teise4" href="/duomenutvarkymas" className="btn" style={duomenysActive }>Asmens duomenų tvarkymas</a></li>
                <li><a id = "teise5" href="/institucijos" className="btn" style={institucijosActive}>Institucijos vykdančios Biobanko veiklos valstybinę priežiūrą ir asmens duomenų tvarkymą</a></li>
                </ul>
                
                <li><a href="#" data-toggle="" onClick={() => this.handleList("sklaida",3)} className="btn" style={sklaidaMainActive}>Sklaida&nbsp;<i id="sklaida" className="fa fa-caret-right" style={{fontSize: "20px",float: "right", textAlign: "right", marginRight: "10px", wordBreak: "keep-all"}}></i></a></li>
                <ul className="dropdown-padding1">
                <li><a id = "sklaida1" href="/publikacijos" className="btn" style={publikacijosActive}>Publikacijos</a></li>
                <li><a id = "sklaida2" href="/mesziniasklaidoje" className="btn" style={ziniosActive }>Mes žiniasklaidoje</a></li>
                <li><a id = "sklaida3" href="/renginiai" className="btn" style={renginiaiActive }>Renginiai</a></li>
                </ul>
                </ul>
              </div>          
            </div>);
       
    }
}
 
export default AboutSideNav;