import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth-service.js";
import DalyvisService from "../services/dalyvis-service.js";

//var user = { 'id' : 1, 'username': 'deivid', 'accessToken' : 12345, 'roles': ['ROLE_USER','ROLE_MODERATOR', 'ROLE_WORKER', 'ROLE_ADMIN']};
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Užpildykite šį laukelį!
      </div>
    );
  }
};
const marginTopBot = {
  marginTop: "0px",
};
const marginBot = {
  marginBottom: "50px",
};
const font = {
  fontSize: "20px",
};

// const centered = {
//     float: none,
//     margin: auto
// };

export default class RemindLogin extends Component {
  constructor(props) {
    super(props);
    this.handleRemind = this.handleRemind.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      userExists: false,
      userData: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleRemind(e) {
    e.preventDefault();

    // this.setState({
    //   message: "",
    //   // loading: true
    // });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.remindPassword(this.state.email)
        .then((response) => {
          this.setState({
            loading: false,
          });

          window.alert("Išsiųsta naujas slaptažodis į jūsų el. paštą");
        })
        .catch((error) => window.alert(error.message));
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  seePassword(event, id) {
    event.preventDefault();
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  render() {
    return (
      <div className="col-xl-5 col-centered">
        <div className="card card-container" style={{ margin: "0px" }}>
          <i
            style={{ marginTop: "100px" }}
            className="fa fa-user-circle fa-5x d-flex justify-content-center"
          ></i>

          <Form
            onSubmit={this.handleRemind}
            ref={(c) => {
              this.form = c;
            }}
          >
            {" "}
            <div
              className=" d-flex justify-content-center"
              style={{ marginTop: "25px" }}
            >
              <label htmlFor="email" style={font}>
                Elektroninis paštas
              </label>
              <i className="fa fa-user fa-2x icon-cog iconcolor" />
            </div>
            <div className="form-group d-flex justify-content-center">
              <Input
                style={{ width: "200px" }} // font,
                type="text"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: "75px" }}
            >
              <button
                className="btn"
                style={marginTopBot}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Priminti/Sukurti slaptažodį</span>
              </button>
            </div>
            {/* <div  className="d-flex justify-content-center">
              <a
                href="/register"
                className="btn primary"
                style = {{marginTop: '20px', marginBottom: '100px', marginLeft: '5px', marginRight: '5px', color: 'white'}}
              >
                Registruotis
              </a>
            </div> */}
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
