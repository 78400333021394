import React from 'react';



class BendraInfo extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
         
        };
        
        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }
    

    render() { 
       
        
        return <>
                  
                  
        <div className="col-lg-12 col-12">
                <div className="comments-form">
                 
                <h1 style={{color: "#78003f"}}>Informacija ruošiama</h1>


                </div>
              </div>


      

                </>;
    }
}
 
export default BendraInfo;