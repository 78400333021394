import React from "react";
import forma1 from "../../../docs/atsisakymoforma1.rtf";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";
import { error } from "pdf-lib";
var atstovas = true;
class Profile extends React.Component {
  constructor(props) {
    super(props);
    // console.log(atstovai[0]);

    this.state = {
      filenamequest: "Pasirinkite dokumentą",
      atstovas: true,
      user: JSON.parse(sessionStorage.getItem("user")),
      // surname: "-----------------",
      // personalCode: "-----------------",
      // phoneNumber: "-----------------",
      // email: "-----------------",
      // password: "-----------------",
      namerepres: "-----------------",
      surnamerepres: "-----------------",
      personalcoderepres: "-----------------",
      namereprenew: "",
      surnamerepresnew: "",
      personalcoderepresnew: "",
      atstovaujami: [],
      currentAtstovaujamas: "",
      selectedItem: "",
      // selectedItem: atstovas.id,
      options: "",
    };

    // if (sessionStorage.getItem("currentAtstovas") !== null) {
    //   this.setState({
    //     currentAtstovas: JSON.parse(sessionStorage.getItem("currentAtstovas")),
    //   });
    // } else {
    //   if (this.state.user.representedpeople !== null) {
    //     sessionStorage.setItem(
    //       "currentAtstovas",
    //       JSON.stringify(this.state.user.representedpeople)
    //     );
    //   }
    // }

    // var atstovai = "";

    // sessionStorage.setItem("currentAtstovas", JSON.stringify({ "id": 1, "name": "deivid", "surname": "caikin", "personalCode": "1111111111", "addedAt": new Date() }))
    // if (sessionStorage.getItem("user") !== null) {
    //   user = JSON.parse(sessionStorage.getItem("user"));
    //   atstovai = user.representedpeople;
    // }

    // var atstovas = "";
    // if (sessionStorage.getItem("currentAtstovas") !== null) {
    //   if (atstovai !== null) {
    //     atstovas = JSON.parse(sessionStorage.getItem("currentAtstovas"));
    //   } else {
    //     sessionStorage.removeItem("currentAtstovas");
    //   }
    // } else {
    //   if (atstovai !== null) {
    //     sessionStorage.setItem("currentAtstovas", JSON.stringify(atstovai[0]));
    //   }
    // }

    this.changeButtonWord = this.changeButtonWord.bind(this);
    this.changeAtstovaujamas = this.changeAtstovaujamas.bind(this);
  }

  componentDidMount() {
    // const user = AuthService.getCurrentUser(); user.username
    // document.getElementById("username").value = "test1";
    // DalyvisService.getPersonalData(10).then
    dalyvisService
      .getAllUsers()
      .then((response) => {
        var users = Object.values(response);
        users = users.filter(
          (data) => data.representedPeople === this.state.user.id
        );
        console.log(users);

        if (this.state.user !== null) {
          var user = this.state.user;
          var atstovaujami = users; //this.state.atstovaujami;
          // user.map(
          //   (response) => {
          // console.log(response.data);

          const represent = Object.values(atstovaujami);
          const options = represent.map((person, i) => {
            // if(JSON.parse(sessionStorage.getItem("currentAtstovas")).id == person.id)
            return (
              <>
                <option
                  key={i}
                  id={"represented" + person.id}
                  value={person.id}
                >
                  {person.name +
                    " " +
                    person.surname +
                    " | " +
                    person.personalCode}
                </option>
              </>
            );
          });

          this.setState({
            options: options,
          });

          console.log(user);
          // response.
          var userExists = false;
          var userType = sessionStorage.getItem("selectedParticipation");
          if (userType !== null) {
            userExists = true;
            console.log("userexists");
          } else {
            sessionStorage.setItem("selectedParticipation", "dalyvis");
            userType = sessionStorage.getItem("selectedParticipation");
            console.log("doest");
            // window.location.reload();
          }
          if (userExists) {
            if (userType === "atstovas") {
              this.setState({
                atstovas: true,
              });
              // atstovas = true;
              if (sessionStorage.getItem("currentAtstovas") !== null) {
                var atstovas = this.state.currentAtstovaujamas;
                // console.log(atstovas.name);

                this.setState({
                  namerepres: atstovas.name,
                  surnamerepres: atstovas.surname,
                  personalcoderepres: atstovas.personalCode,
                });
                // document.getElementById("namerepres").value = name;
                // document.getElementById("surnamerepres").value = surname;
                // document.getElementById("personalcoderepres").value = atstovas.personalcode;
                // document.getElementById(atstovas.personalcode).selected = true;
              } else {
                if (atstovaujami.length > 0) {
                  sessionStorage.setItem(
                    "currentAtstovas",
                    JSON.stringify(atstovaujami[0])
                  );
                }
              }
            }

            if (userType === "dalyvis") {
              this.setState({
                atstovas: false,
              });
            }
            //   },
            //   (error) => {}
            // );
          }
        }
      })
      .catch((error) => alert.error(error.message));
  }
  editProfile(e, idInput, idButtonClick, idButtonEdit) {
    e.preventDefault();
    console.log(document.getElementById(idButtonEdit).className);
    if (document.getElementById(idButtonEdit).className === "fa fa-edit") {
      document.getElementById(idInput).readOnly = false;
      document.getElementById(idButtonEdit).className = "fa fa-save";
      // document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    } else {
      var valueToSave = document.getElementById(idInput).value;
      console.log(document.getElementById("phoneNumber").length);

      var user = JSON.parse(sessionStorage.getItem("user"));
      var object = { [idInput]: valueToSave.trim() };

      // sessionStorage.setItem("tempUser", JSON.stringify(user));
      if (
        idInput === "phoneNumber" &&
        document.getElementById("phoneNumber").value.length !== 11
      ) {
        window.alert("Netinkamas telefono numerio ilgis");
        return;
      }
      // if((idInput === "personalCode" && document.getElementById("personalCode").value.length !== 11)){
      //   window.alert("Netinkamas asmens kodo ilgis")
      //   return;
      // }
      if (idInput === "email" && !this.validateEmail(this.state.email)) {
        window.alert("Blogas elektroninio pašto formatas");
        return;
      }
      dalyvisService
        .putCustomObjectPersonalData(user.id, { ...user, ...object })
        .catch((error) => window.alert(error.message));
      document.getElementById(idButtonEdit).className = "fa fa-edit";
      document.getElementById(idInput).readOnly = true;
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
        window.location.reload();
      }, 1000);
    }
  }
  editProfileAtstovaujamas(e, idInput, idButtonClick, idButtonEdit) {
    e.preventDefault();
    console.log(document.getElementById(idButtonEdit).className);
    if (document.getElementById(idButtonEdit).className === "fa fa-edit") {
      document.getElementById(idInput).readOnly = false;
      document.getElementById(idButtonEdit).className = "fa fa-save";
      // document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
      }, 1000);
    } else {
      // if((idInput === "personalCoderepres" && document.getElementById("personalCoderepres").value.length !== 11)){
      //   window.alert("Netinkamas asmens kodo ilgis")
      //   return;
      // }
      var valueToSave = document.getElementById(idInput).value;
      var object = {
        id: this.state.currentAtstovaujamas.id,
        [idInput.replace("repres", "")]: valueToSave,
      };
      dalyvisService
        .putCustomObjectRepresented(this.state.currentAtstovaujamas.id, {
          ...this.state.currentAtstovaujamas,
          ...object,
        })
        .catch((error) => window.alert(error.message))
        .then((data) => {
          sessionStorage.setItem("currentAtstovas", JSON.stringify(data));
          this.setState({
            currentAtstovaujamas: data,
            atstovai: JSON.parse(sessionStorage.getItem("user"))
              .representedpeople,
            user: JSON.parse(sessionStorage.getItem("user")),
          });
        });
      document.getElementById(idButtonEdit).className = "fa fa-edit";
      document.getElementById(idInput).readOnly = true;
      document.getElementById(idButtonClick).disabled = true;
      setTimeout(function () {
        document.getElementById(idButtonClick).disabled = false;
        window.location.reload();
      }, 1000);
    }
  }

  changePassword(e) {
    e.preventDefault();
    // document.getElementById(id).style.display = "block";
    // document.getElementById("cancelpasswordchange").style.display =
    //   "inline-block";
    // document.getElementById("changebutton").disabled = true;
    authService.remindPassword(this.state.user.email).then(() => {
      alert("Slaptažodžio pakeitimo laiškas išsiųstas sėkmingai!");
    });
  }
  cancelPassword(e) {
    e.preventDefault();
    document.getElementById("cancelpasswordchange").style.display = "none";
    document.getElementById("newpassword").style.display = "none";
    document.getElementById("changebutton").disabled = false;
  }
  saveNewPassword(e, id, idpass1, idpass2) {
    e.preventDefault();

    var pass1 = document.getElementById(idpass1).value;
    var pass2 = document.getElementById(idpass2).value;
    console.log(pass1.length);
    if (
      pass1 === pass2 &&
      (pass1 !== "" || pass2 !== "") &&
      (pass1.length > 6 || pass2.length > 6)
    ) {
      //user services to save password to database
      document.getElementById(id).style.display = "none";
      document.getElementById("changebutton").disabled = false;
      window.alert("Sėkmingai pakeitėte slaptažodį!");
    } else if (pass1 === "" || pass2 === "") {
      window.alert("Kai kurie laukeliai neužpildyti!");
    } else if (pass1.length <= 6 || pass2.length <= 6) {
      window.alert("Slaptažodis turi būti ilgesnis nei 6 simboliai (7+)!");
    } else {
      document.getElementById(idpass1).value = "";
      document.getElementById(idpass2).value = "";
      window.alert("Įvęsti slaptažodžiai nėra vienodi!");
    }
  }
  saveRepresChoice(e) {
    e.preventDefault();
    var selection = document.getElementById("represselect").value;
    window.alert("Atstovaujamas asmuo pasirinktas ir pakeistas!");
  }
  changeAtstovaujamas(e) {
    e.preventDefault();
    var atstovasId = document.getElementById("represselect").value;
    dalyvisService
      .getAllPersonalData(atstovasId)
      .then((data) => {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(data));

        // var delayInMilliseconds = 500; //1 second
        var atstovasObj;
        // setTimeout(
        //   function () {
        atstovasObj = data; //JSON.parse(sessionStorage.getItem("currentAtstovas"));

        // console.log(atstovasObj);
        this.setState({
          currentAtstovaujamas: atstovasObj,
          selectedItem: atstovasObj.id,
        });
        document.getElementById("namerepres").value = atstovasObj.name;
        document.getElementById("surnamerepres").value = atstovasObj.surname;
        document.getElementById("personalCoderepres").value =
          atstovasObj.personalCode;
        //   }.bind(this),
        //   delayInMilliseconds
        // );

        window.location.reload();
      })
      .catch((error) => window.alert(error.message));
    // setTimeout(this.setState({
    //   currentAtstovas: atstovasObj,
    //   selectedItem: atstovasObj.id
    // }), 500);
  }
  saveAtstovaujamas(e) {
    e.preventDefault();
    var atstovasId = document.getElementById("represselect").value;
    dalyvisService
      .getAllPersonalData(atstovasId.id)
      .then((data) => {
        sessionStorage.setItem("currentAtstovas", JSON.stringify(data));
        this.props.history.push("/dalyvis/manoprofilis");
      })
      .catch((error) => window.alert(error.message));
  }

  changeButtonWord(id) {
    var filename = document
      .getElementById(id)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();

    if (filename !== "" && id === "epidemFile") {
      this.setState({ filenamequest: filename });
    } else if (filename !== "" && id === "signedFile") {
      this.setState({ filenameatsisakymo: filename });
    }
    //window.alert()
  }

  generateUniqueCode(users) {
    let code = "VU";

    for (let i = 0; i < 8; i++) {
      code += Math.floor(Math.random() * 10); // Generates a random digit from 0 to 9
    }

    return code;
  }

  saveNewAtstovas(e) {
    e.preventDefault();
    var namerepres = document.getElementById("namerepres1").value;
    var surnamerepres = document.getElementById("surnamerepres1").value;
    var personalcoderepres = parseInt(
      document.getElementById("personalcoderepres1").value,
      10
    );
    // var gimimoliudijimas = document.getElementById("gimimoliudijimas1").value;
    // var atstovai = JSON.parse(localStorage.getItem("atstovai"));
    // atstovai.push({
    //   namerepresnew: namerepres,
    //   surnamerepresnew: surnamerepres,
    //   personalcoderepresnew: personalcoderepres,
    // });
    // sessionStorage.setItem("atstovai", JSON.stringify(atstovai));
    var uniqueCodeRepres = this.generateUniqueCode();

    // for (let i = 0; i < response.length; i++) {
    //   if (response[i].uniqueCodeRepres === uniqueCodeRepres) {
    //     uniqueCodeRepres = this.generateUniqueCode();
    //     i = 0;
    //   }
    // }

    var newUserRepres = {
      name: namerepres,
      surname: surnamerepres,
      personalcode: personalcoderepres,
      uniqueCode: uniqueCodeRepres,
      representedPeople: this.state.user.id,
    };

    dalyvisService
      .postPersonalDataObject(newUserRepres)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => window.alert(error.message));
    // window.location.reload();
  }
  checkEmpty() {
    var namerepres = document.getElementById("namerepres1").value;
    var surnamerepres = document.getElementById("surnamerepres1").value;
    var personalcoderepres = document.getElementById(
      "personalcoderepres1"
    ).value;
    // var gimimoliudijimas = document.getElementById("gimimoliudijimas1").value;

    document.getElementById("submitButton1").disabled = true;
    var continueState = true;
    var quantity = 0;
    var allItems = [namerepres, surnamerepres, personalcoderepres];
    // relationshipRepresented
    //leidimas.value, ,applicationpdf,additionalinfo
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        break;
      }

      quantity += 1;
    }

    if (
      quantity === allItems.length &&
      continueState === true &&
      personalcoderepres.length === 11
    ) {
      document.getElementById("submitButton1").disabled = false;
    }
  }

  seePassword(event, id) {
    event.preventDefault();
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  handleChange(event, id) {
    this.setState({ [id]: event.target.value });
  }
  handleChangeNumber(event, id) {
    var value = event.target.value;
    if (!isNaN(value)) {
      this.setState({ [id]: event.target.value });
    }
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }
  //temp code
  // onKeyPress={(event)=>this.onlyNumberKey(event)}
  // onlyNumberKey(evt) {

  //   // Only ASCII character in that range allowed
  //   var ASCIICode = (evt.which) ? evt.which : evt.keyCode
  //   if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
  //     return false;
  //   this.handleChange(evt, evt.id);
  //   return true;

  // }

  render() {
    return (
      <>
        {/* <div className="col-lg-12 col-12"> */}
        <div className="comments-form">
          <h2
            style={{
              marginBottom: "25px",
              paddingTop: "50px",
              fontSize: "2rem",
            }}
          >
            Mano duomenys
          </h2>

          {this.state.atstovas ? (
            <>
              <h2
                style={{
                  marginTop: "50px",
                  marginBottom: "25px",
                  fontSize: "2rem",
                }}
              >
                Atstovaujamo asmens duomenys
              </h2>
              <form
                onSubmit={(event) => this.saveAtstovaujamas(event)}
                style={{ fontSize: "20px" }}
              >
                <div className="row">
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor="exampleFormControlSelect1">
                      Pasirinkite atstovaujamą asmenį:
                    </label>
                    <div className="input-group">
                      <select
                        id="represselect"
                        className="form-select"
                        onChange={(event) => this.changeAtstovaujamas(event)}
                        // value={this.state.selectedItem}
                      >
                        {React.Children.toArray(this.state.options)}
                      </select>
                      {/* <button
                      type="submit"
                      className="btn"
                      id="changerepres"
                      style={{ padding: "0" }}
                    >
                      Išsaugoti
                    </button> */}
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <button
                      className="btn"
                      type="submit"
                      id="submitButton2"
                      style={{ marginTop: "30px", marginBottom: "50px" }}
                      disabled={this.state.buttonDisabled}>
                      Pasirinkti</button>
                  </div> */}
                </div>
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Vardas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="namerepres"
                        readOnly
                        className="form-control"
                        placeholder="Vardenis"
                        value={this.state.namerepres}
                        onChange={(event) =>
                          this.handleChange(event, "namerepres")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changenamerepres"
                          onClick={(event) =>
                            this.editProfileAtstovaujamas(
                              event,
                              "namerepres",
                              "changenamerepres",
                              "changenamerepresicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changenamerepresicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Pavardė</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="surnamerepres"
                        readOnly
                        className="form-control"
                        placeholder="Pavardenis"
                        value={this.state.surnamerepres}
                        onChange={(event) =>
                          this.handleChange(event, "surnamerepres")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changesurnamerepres"
                          onClick={(event) =>
                            this.editProfileAtstovaujamas(
                              event,
                              "surnamerepres",
                              "changesurnamerepres",
                              "changesurnamerepresicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changesurnamerepresicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <label>Asmens kodas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="personalCoderepres"
                        readOnly
                        className="form-control"
                        placeholder="90050050080"
                        maxLength={11}
                        value={this.state.personalcoderepres}
                        onChange={(event) =>
                          this.handleChangeNumber(event, "personalCoderepres")
                        }
                      />
                      <div style={{}}>
                        {/* <button
                          className="btn"
                          id="changepersonalcoderepres"
                          onClick={(event) =>
                            this.editProfileAtstovaujamas(
                              event,
                              "personalCoderepres",
                              "changepersonalcoderepres",
                              "changepersonalcoderepresicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changepersonalcoderepresicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button> */}
                      </div>
                    </div>
                  </div>

                  {/* <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <label>Atstovavimo pagrindas</label>
                    <div className="input-group mb-2">
                      <a className=" btn" href={forma1} download>
                        Atsisiųsti
                      </a>
                      <div style={{}}></div>
                    </div>
                  </div> */}
                </div>
              </form>
              <form
                onChange={(event) => this.checkEmpty(event)}
                onSubmit={(event) => this.saveNewAtstovas(event)}
                style={{ fontSize: "20px" }}
              >
                <h2
                  style={{
                    marginTop: "50px",
                    marginBottom: "25px",
                    fontSize: "2rem",
                  }}
                >
                  Pridėti naują atstovaujamą asmenį
                </h2>
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Vardas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="namerepres1"
                        className="form-control"
                        placeholder="Vardenis"
                        defaultValue={this.state.namerepresnew}
                      />
                      <div style={{}}></div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Pavardė</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="surnamerepres1"
                        className="form-control"
                        placeholder="Pavardenis"
                        defaultValue={this.state.surnamerepresnew}
                      />
                      <div style={{}}></div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <label>Asmens kodas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="personalcoderepres1"
                        className="form-control"
                        placeholder="90050050080"
                        maxLength={11}
                        defaultValue={this.state.personalcoderepresnew}
                      />
                      <div style={{}}></div>
                    </div>
                  </div>
                  {/* <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <label>Atstovavimo pagrindas</label>
                    <div className="input-group mb-2">
                      <label className="btn" style={{ wordBreak: "normal" }}>
                        <input
                          type="file"
                          id="gimimoliudijimas1"
                          accept="application/pdf,image/png, image/gif, image/jpeg"
                          className=" btn"
                        />
                        Pasirinkite dokumentą
                      </label>
                      <div style={{}}></div>
                    </div>
                  </div> */}
                </div>
                <div className="col-12">
                  <div className="form-group button">
                    <button
                      className="btn"
                      type="submit"
                      id="submitButton1"
                      style={{ marginTop: "25px", float: "right" }}
                      disabled
                    >
                      Pridėti
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <form style={{ fontSize: "20px" }}>
                {/* <div className="row"> */}

                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Vardas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="name"
                        readOnly
                        className="form-control"
                        placeholder="Vardenis"
                        defaultValue={this.state.user.name}
                        onChange={(event) => this.handleChange(event, "name")}
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changename"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "name",
                              "changename",
                              "changenameicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changenameicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Pavardė</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="surname"
                        readOnly
                        className="form-control"
                        placeholder="Pavardenis"
                        defaultValue={this.state.user.surname}
                        onChange={(event) =>
                          this.handleChange(event, "surname")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changesurname"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "surname",
                              "changesurname",
                              "changesurnameicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changesurnameicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Asmens kodas</label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="personalCode"
                        readOnly
                        className="form-control"
                        placeholder="90050050080"
                        maxLength={11}
                        value={this.state.user.personalCode}
                        onChange={(event) =>
                          this.handleChangeNumber(event, "personalCode")
                        }
                      />
                      <div style={{}}>
                        {/* <button
                          className="btn"
                          id="changepersonalcode"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "personalCode",
                              "changepersonalcode",
                              "changepersonalcodeicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changepersonalcodeicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Telefono numeris</label>

                    <div className="input-group mb-1" style={{}}>
                      <div className="input-group-prepend">
                        <div className="input-group-text">+</div>
                      </div>
                      <input
                        type="text"
                        id="phoneNumber"
                        title="Įvęskite telefono numerį be simbolių"
                        readOnly
                        className="form-control"
                        placeholder="37069999999"
                        maxLength={11}
                        value={this.state.user.phoneNumber}
                        onChange={(event) =>
                          this.handleChangeNumber(event, "phoneNumber")
                        }
                      />
                      <div style={{}}>
                        <button
                          className="btn"
                          id="changephonenumber"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "phoneNumber",
                              "changephonenumber",
                              "changephonenumbericon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changephonenumbericon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-8 col-md-8"
                    style={{ marginTop: "25px" }}
                  >
                    <label>Elektroninis paštas</label>
                    <div className="input-group mb-2">
                      {/* <div className="input-group-prepend">
                  <div className="input-group-text">@</div>
                </div> */}
                      <input
                        type="email"
                        readOnly
                        className="form-control"
                        id="email"
                        placeholder="elektroninis@pastas.com"
                        defaultValue={this.state.user.email}
                        onChange={(event) => this.handleChange(event, "email")}
                      />
                      <div style={{ marginTop: "0px" }}>
                        <button
                          className="btn"
                          id="changeemail"
                          onClick={(event) =>
                            this.editProfile(
                              event,
                              "email",
                              "changeemail",
                              "changeemailicon"
                            )
                          }
                          style={{ padding: "0px" }}
                        >
                          <i
                            id="changeemailicon"
                            style={{ fontSize: "28px" }}
                            className="fa fa-edit"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ marginTop: "25px" }}
                  >
                    {/* <label>Slaptažodis</label> */}
                    <div className="input-group mb-2">
                      {/* <input
                        type="password"
                        id="password"
                        readOnly
                        className="form-control"
                        placeholder="Slaptažodis"
                        defaultValue={this.state.user.password}
                        onChange={(event) =>
                          this.handleChange(event, "password")
                        }
                      /> */}
                      <div style={{}}>
                        {/* &nbsp;
                        <button
                          className="btn"
                          id="seepassword1"
                          onClick={(event) =>
                            this.seePassword(event, "password")
                          }
                          style={{
                            height: "32px",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        &nbsp; */}
                        <button
                          className="btn"
                          id="changebutton"
                          onClick={(event) => this.changePassword(event)}
                          style={{
                            height: "32px",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Keisti slaptažodį
                        </button>
                        {/* &nbsp;
                        <button
                          className="btn"
                          id="cancelpasswordchange"
                          onClick={(event) => this.cancelPassword(event)}
                          style={{
                            height: "32px",
                            padding: "5px",
                            display: "none",
                            whiteSpace: "nowrap",
                          }}
                        >
                          X
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="row"
                  id="newpassword"
                  style={{ display: "none" }}
                >
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ display: "inline-block", marginTop: "25px" }}
                  >
                    <label>Naujas slaptažodis</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        id="newpasswordinput1"
                        className="form-control"
                        placeholder="Naujas slaptažodis"
                        style={{ display: "inline-block" }}
                      />
                      &nbsp;
                      <button
                        className="btn"
                        id="seepassword2"
                        onClick={(event) =>
                          this.seePassword(event, "newpasswordinput1")
                        }
                        style={{
                          height: "32px",
                          padding: "5px",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6"
                    style={{ display: "inline-block", marginTop: "25px" }}
                  >
                    <label htmlFor="inputEmail4">Pakartoti slaptažodį</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        id="newpasswordinput2"
                        className="form-control"
                        placeholder="Pakartoti slaptažodį"
                      />
                      &nbsp;
                      <button
                        className="btn"
                        id="seepassword3"
                        onClick={(event) =>
                          this.seePassword(event, "newpasswordinput2")
                        }
                        style={{
                          height: "32px",
                          padding: "5px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                      &nbsp;
                      <button
                        id="saveNewPassword"
                        className="btn"
                        onClick={(event) =>
                          this.saveNewPassword(
                            event,
                            "newpassword",
                            "newpasswordinput1",
                            "newpasswordinput2"
                          )
                        }
                        style={{ display: "inline-block", padding: "0px" }}
                      >
                        <i
                          style={{ fontSize: "30px" }}
                          id="saveNewPasswordIcon"
                          className="fa fa-save"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div> */}
              </form>
            </>
          )}
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default Profile;
