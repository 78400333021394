import React from "react";
var sortValueGlobal = "newdate";
class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      filterValue: "inq",
      sortValue: "newdate"
    };
  }
  componentDidMount() { }

  showInfo(id) {
    // -------- has to set message to seen on click if its new
    // const list = [
    //   "uzklausa apie mane",
    //   "uzklausa apie jus",
    //   "uzklausa apie meginius",
    // ]; // get from database
    var value = document.getElementById(id);
    // for (let i = 0; i < list.length; i++) {
    //   if (id !== list[i]) {
    //     document.getElementById(list[i]).style.visibility = "hidden";
    //     document.getElementById(list[i]).style.display = "none";
    //     document.getElementById(list[i]).style.maxHeight = "0";
    //   }
    // }
    if (value.style.visibility === "hidden") {
      value.style.display = "block";
      value.style.visibility = "visible";
      value.style.maxHeight = "100%";
      // value.style.lineHeight = "100%";

      // value.style.height = "auto";
    } else {
      value.style.display = "none";
      value.style.visibility = "hidden";
      value.style.maxHeight = "0";
      // value.style.lineHeight = "0";

      // value.style.height = 0;
    }
  }

  filterItemsSearch = () => {
    var searchTermInside = document.getElementById("searchDataInquiry").value;
    this.setState({ searchTerm: searchTermInside });
  };
  filterItems = (e) => {
    e.preventDefault();
    var filterValue1 = document.getElementById("filter-inquiry-selection").value;
    this.setState({ filterValue: filterValue1 });
    document.getElementById("searchDataInquiry").value = "";
    this.setState({ searchTerm: "" });
  };
  sortItems = (e) => {
    e.preventDefault();
    var sortValue = document.getElementById("sort-inquiry-selection").value;
    this.setState({ sortValue: sortValue });
    sortValueGlobal = sortValue;
    // }
  };

  render() {
    let uzklausos = [
      {
        id: 1,
        examinator_fullname: "kin tin",
        added_date: "2023-01-23T18:25:43.511Z",
        unique_code: "abc12345",
        theme: "zinute1",
        text: "zinute1zinute1zinute1zinute1zinute1zinute1 zinute1zinute1",
        state: "new",
        type: "part"
      },
      {
        id: 2,
        examinator_fullname: "bob bob",
        added_date: "2020-02-23T18:25:43.511Z",
        unique_code: "gba14254",
        theme: "uzklausa2",
        text: "uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1uzklausa1",
        state: "new",
        type: "inq"
      },
      {
        id: 3,
        examinator_fullname: "don don",
        added_date: "2021-03-23T18:25:43.511Z",
        unique_code: "gka148214",
        theme: "zinute1",
        text: "zinute1 zinute1zinute1 zinute1zinute1zinute1zinute1",
        state: "seen",
        type: "part"
      },
      {
        id: 4,
        examinator_fullname: "don son",
        added_date: "2021-04-23T18:25:43.511Z",
        unique_code: "adw5818",
        theme: "uzklausa1",
        text: "zinute1 zinute1zinute1 zinute1zinute1zinute1zinute1",
        state: "seen",
        type: "inq"
      },
      ,
      {
        id: 5,
        examinator_fullname: "don bon",
        added_date: "2021-02-25T18:25:43.511Z",
        unique_code: "hdfgdf9",
        theme: "zinute1",
        text: "zinute1 zinute1zinute1 zinute1zinute1zinute1zinute1",
        state: "new",
        type: "part"
      },
      ,
      {
        id: 6,
        examinator_fullname: "don din",
        added_date: "2021-02-28T18:25:43.511Z",
        unique_code: "dadw5889",
        theme: "zinute1",
        text: "zinute1 zinute1zinute1 zinute1zinute1zinute1zinute1",
        state: "seen",
        type: "part"
      },
    ];

    const paraiskos = uzklausos.filter((inquiry) => {
      // console.log(inquiry.state);
      // console.log(this.state.filterValue );
      console.log(this.state.searchTerm);
      if (this.state.filterValue === "inq" && this.state.searchTerm === "") {
        return inquiry;
      } else if (
        inquiry.unique_code
          .toLowerCase()
          .replaceAll(" ", "")
          .trim()
          .startsWith(
            this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
          ) &&
        this.state.searchTerm !== "" &&
        this.state.filterValue === "inq"
      ) {
        return inquiry;
      } else if (
        inquiry.unique_code
          .toLowerCase()
          .replaceAll(" ", "")
          .trim()
          .startsWith(
            this.state.searchTerm.toLowerCase().replaceAll(" ", "").trim()
          ) &&
        this.state.searchTerm !== "" && this.state.filterValue === "inq" &&
        this.state.filterValue === inquiry.state && this.state.filterValue === inquiry.type
      ) {

        return inquiry;
      } else {
        return "";
      }
    }).sort(function (a, b) {
      if (sortValueGlobal === "newdate") {
        if (a.added_date.toLowerCase() > b.added_date.toLowerCase())
          return -1;
        if (a.added_date.toLowerCase() < b.added_date.toLowerCase()) return 1;
        return 0;
      }


      if (sortValueGlobal === "olddate") {
        if (a.added_date.toLowerCase() < b.added_date.toLowerCase())
          return -1;
        if (a.added_date.toLowerCase() > b.added_date.toLowerCase()) return 1;
        return 0;
      }

    }).map((inquiry) => {
      var dateBefore = new Date(inquiry.added_date)
      var monthMax = parseInt(dateBefore.getMonth()) + 1;
      var datePassed = dateBefore.getFullYear() + "/" + (monthMax.toString().length > 1 ? monthMax.toString() : "0" + monthMax.toString()) + "/" + dateBefore.getDate();
      return (
        <>
          <button
            className={`applicationList ${inquiry.state === "new" ? "message-styling" : ""}`}
            onClick={() => this.showInfo("uniqueinquiry" + inquiry.id)}
            style={{ width: "100%" }}
          >
            <div className="col-lg-12 col-md-12 col-12">
              <div className="d-inline col-lg-4 col-md-6 col-12"><p style={{ float: "left", display: "inline-block" }}>{inquiry.theme}</p></div>
              <div className="d-inline col-lg-4 col-md-6 col-12"><p style={{ float: "center", display: "inline-block" }}>{inquiry.examinator_fullname}</p></div>
              <div className="d-inline col-lg-4 col-md-6 col-12"><p style={{ float: "center", display: "inline-block" }}>{inquiry.unique_code}</p></div>
              <div className="d-inline col-lg-4 col-md-6 col-12"><p style={{ float: "right", display: "inline-block" }}>{datePassed} </p></div>
            </div>
          </button>
          <div>
            <li
              key={inquiry.id}
              id={"uniqueinquiry" + inquiry.id}
              style={{ display: "none", visibility: "hidden" }}
            >
              <table className="table">
                <thead className="thead-dark">
                  {/* <tr>
                    
                    <th scope="col">Skiltis</th>
                    <th scope="col">Įvesta informacija</th>
                   
                  </tr> */}
                </thead>
                <tbody>
                  <tr>
                    {/* <th scope="row">1</th> */}
                    <th scope="row">Data</th>
                    <td>

                      {datePassed}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Tema</th>
                    <td>

                      {inquiry.theme}
                    </td>
                  </tr>
                  <tr>
                    <th>Tekstas</th>
                    <td>
                      {inquiry.text}
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </div>
        </>
      );
    });
    const dropdownListFilter = [
      { name: "Naujausios užklausos", value: "new" },
      { name: "Peržiūrėtos užklausos", value: "seen" },

    ];
    const dropdownListSort = [
      { name: "Seniausią datą", value: "olddate" },
      // { name: "Unikalų paraiškos kodą", value: "uniquecode" },
      // { name: "Tyrėją", value: "examinator" },
    ];
    const optionsFilter = dropdownListFilter.map((drop, i) => {
      return (
        <>
          <option key={i} id={drop.value} value={drop.value}>
            {drop.name}
          </option>
        </>
      );
    });

    const optionsSort = dropdownListSort.map((drop, i) => {
      return (
        <>
          <option key={i} id={drop.value} value={drop.value}>
            {drop.name}
            {/* <button>word</button> */}
          </option>
        </>
      );
    });

    return (
      <>
        {this.state.loading ? (<>
          <div className="d-flex justify-content-center" style={{}}>
            <div className="spinner-grow d-flex justify-content-center" style={{ width: "20rem", height: "20rem", marginTop: "80px", marginBottom: "80px", color: "#78003f" }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>) : (
          <>
            <div className="col-lg-12 col-md-12 col-12" style={{ marginTop: "50px" }}>
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-12 "
                style={{
                  margin: 0,
                  padding: "0 0 25px 0",
                  display: "inline-block",
                }}
              >
                <label>Filtruoti pagal:</label>
                <div className="" style={{ paddingRight: "25px" }}>
                  <select
                    id="filter-inquiry-selection"
                    className="form-select"
                    onChange={(event) => this.filterItems(event)}
                    style={{ display: "inline-block" }}
                  >
                    <option key="all" id="all" value="inq">
                      Visos užklausos
                    </option>
                    {React.Children.toArray(optionsFilter)}
                  </select>

                  {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                </div>
              </div>

              <div
                className="form-group col-lg-6 col-md-6 col-sm-12 col-12 "
                style={{
                  margin: 0,
                  padding: "0 0 25px 0",
                  display: "inline-block",
                }}
              >
                <label htmlFor="exampleFormControlSelect1">Rūšiuoti pagal:</label>
                <div className="input-group" style={{ paddingRight: "25px" }}>
                  <select
                    id="sort-inquiry-selection"
                    className="form-select"
                    onChange={(event) => this.sortItems(event)}
                  >
                    <i className="fa fa-sort-down"></i>
                    <option key="newdate" id="newdate" value="newdate">
                      Naujausią datą
                    </option>
                    {React.Children.toArray(optionsSort)}
                  </select>
                  {/* <button type="submit" className="btn" id="filter"  style={{padding: "0"}} >Filtruoti</button> */}
                </div>
              </div>
              <div
                className="col-centered"
                style={{ display: "block", margin: "auto" }}
              >
                <input
                  id="searchDataInquiry"
                  type="text"
                  placeholder="Ieškoti..."
                  onChange={this.filterItemsSearch}
                />
              </div>

              <h2 style={{ marginTop: "25px" }}>
                <b>Pranešimai/Užklausos</b>
              </h2>
              <ul className="designed-list">{React.Children.toArray(paraiskos)}</ul>
            </div>
          </>)}
      </>
    );
  }
}
export default Messages;
