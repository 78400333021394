import React, { Component } from "react";
import tyrejasService from "../../../../services/tyrejas-service";

// const listApplications = ["projektas apie mane", "projektas apie jus", "projektas apie meginius"];
class MyApplications extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     response: "",
  //     user: JSON.parse(sessionStorage.getItem("user")),
  //     showButton: false,
  //     examData: {},
  //     buttonStates: {},
  //     lastStageChecks: {},
  //     checksShow: {},
  //     showCurrentApplication: {},
  //     applications: [],
  //     //examinationInfos: [], //don't use
  //     // applications: tyrejasService.getAllApplications().then((data) => { sessionStorage.setItem("applications", JSON.stringify(data)); })
  //   };
  // }

  // componentDidMount() {
  //   //tyrejasService
  //   //.getAllExaminationInfos()
  //   //.then((data) => {
  //   var applications = [];
  //   this.state.user.application.forEach((id) => {
  //     tyrejasService
  //       .getApplications(id)
  //       .then((data) => {
  //         applications.push(data);
  //         sessionStorage.setItem("applications", JSON.stringify(applications));

  //         var today = new Date();
  //         var dd = String(today.getDate()).padStart(2, "0");
  //         var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //         var yyyy = today.getFullYear();

  //         today = yyyy + "/" + mm + "/" + dd;

  //         this.setState({
  //           //examinationInfos: data,
  //           date: today,
  //           response: this.state.user.applications,
  //         });
  //         const user = this.state.user;

  //         const promises = user.applications.map(async (data) => {
  //           try {
  //             return await tyrejasService.getApplications(data.id);
  //           } catch (error) {
  //             return window.alert(error.message);
  //           }
  //         });

  //         Promise.all(promises)
  //           .then((applications) => {
  //             sessionStorage.setItem(
  //               "applications",
  //               JSON.stringify(applications)
  //             );
  //             return applications;
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching applications:", error);
  //             return [];
  //           })
  //           .then((applicationsList) => {
  //             const dynamicStatesExamData = {};
  //             const dynamicStatesButtonStates = {};
  //             const dynamicStatesChecksShow = {};
  //             const dynamicStatesCurrentApplication = {};

  //             applicationsList.forEach((data) => {
  //               dynamicStatesCurrentApplication["display" + data.id] = "none";
  //               if (
  //                 this.getCurrentApplicationId() !== null &&
  //                 this.getCurrentApplicationId() == "application" + data.id
  //               ) {
  //                 dynamicStatesCurrentApplication["display" + data.id] =
  //                   "block";
  //               }

  //               dynamicStatesChecksShow["table" + data.id] = false;
  //               if (data.checkups.length > 0) {
  //                 dynamicStatesChecksShow["table" + data.id] = true;
  //               }

  //               dynamicStatesExamData["datasub" + data.id] = false;
  //               if (data.administerState === "4") {
  //                 dynamicStatesExamData["datasub" + data.id] = true;
  //               }

  //               if (data.applicationStatus === 0) {
  //                 dynamicStatesButtonStates["status" + data.id] = "Nauja";
  //                 dynamicStatesButtonStates["color" + data.id] = "#6699ff";
  //               } else if (data.applicationStatus === 2) {
  //                 dynamicStatesButtonStates["status" + data.id] =
  //                   "Administruojama";
  //                 dynamicStatesButtonStates["color" + data.id] = "#ffff00";
  //               } else if (data.applicationStatus === 3) {
  //                 dynamicStatesButtonStates["status" + data.id] =
  //                   "Laukiama atsakymo";
  //                 dynamicStatesButtonStates["color" + data.id] = "#ff3300";
  //               } else if (data.applicationStatus === 4) {
  //                 dynamicStatesButtonStates["status" + data.id] = "Atmesta";
  //                 dynamicStatesButtonStates["color" + data.id] = "#FE0007";
  //               } else if (data.applicationStatus === 1) {
  //                 dynamicStatesButtonStates["status" + data.id] = "Priimta";
  //                 dynamicStatesButtonStates["color" + data.id] = "#33cc33";
  //               } else {
  //                 dynamicStatesButtonStates["status" + data.id] =
  //                   "Kažkas blogai";
  //                 dynamicStatesButtonStates["color" + data.id] = "grey";
  //               }
  //             });

  //             this.setState({
  //               applications: applicationsList,
  //               examData: dynamicStatesExamData,
  //               buttonStates: dynamicStatesButtonStates,
  //               checksShow: dynamicStatesChecksShow,
  //               showCurrentApplication: dynamicStatesCurrentApplication,
  //             });

  //             for (let i = 0; i < user.applications.length; i++) {
  //               this.setState({
  //                 ["bioeticdoc" + user.applications[i].id]:
  //                   "Pasirinkite dokumentą",
  //               });
  //             }
  //           });
  //       })
  //       .catch((error) => window.alert(error.message));
  //   });
  //   //})
  //   //.catch((error) => window.alert(error.message));
  // }
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(sessionStorage.getItem("user")),
      applications: [],
      examData: {},
      buttonStates: {},
      checksShow: {},
      showCurrentApplication: {},
      date: this.getCurrentDate(),
    };
  }

  componentDidMount() {
    this.fetchApplications();
  }

  fetchApplications = async () => {
    try {
      const { user } = this.state;
      const applications = await Promise.all(
        user.application.map((id) =>
          tyrejasService.getApplications(id).catch((error) => {
            console.error("Error fetching application:", error);
            return null;
          })
        )
      );

      this.updateStateWithApplications(
        applications.filter((app) => app !== null)
      );
      sessionStorage.setItem("applications", JSON.stringify(applications));
    } catch (error) {
      console.error("Error in componentDidMount:", error);
      window.alert(error.message);
    }
  };

  updateStateWithApplications = (applications) => {
    const dynamicStates = this.calculateDynamicStates(applications);
    this.setState({ applications, ...dynamicStates });
  };

  calculateDynamicStates = (applications) => {
    let dynamicStates = {
      examData: {},
      buttonStates: {},
      checksShow: {},
      showCurrentApplication: {},
    };

    applications.forEach((app) => {
      const appId = app.id;
      dynamicStates.examData["datasub" + appId] = app.administerState === "4";
      dynamicStates.checksShow["table" + appId] = app.checkup.length > 0;
      dynamicStates.showCurrentApplication["display" + appId] = "none";

      dynamicStates.buttonStates["status" + appId] = this.getApplicationStatus(
        app.applicationStatus
      );
      dynamicStates.buttonStates["color" + appId] =
        this.getApplicationStatusColor(app.applicationStatus);
    });

    return dynamicStates;
  };

  getApplicationStatus = (status) => {
    switch (status) {
      case 0:
        return "Nauja";
      case 1:
        return "Priimta";
      case 2:
        return "Administruojama";
      case 3:
        return "Laukiama atsakymo";
      case 4:
        return "Atmesta";
      default:
        return "Kažkas blogai";
    }
  };

  getApplicationStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#6699ff";
      case 1:
        return "#33cc33";
      case 2:
        return "#ffff00";
      case 3:
        return "#ff3300";
      case 4:
        return "#FE0007";
      default:
        return "grey";
    }
  };

  getCurrentDate = () => {
    const today = new Date();
    return `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(today.getDate()).padStart(2, "0")}`;
  };

  showInfo(id, applicationId) {
    // const list = ["projektas apie mane", "projektas apie jus", "projektas apie meginius"]; // get from database

    this.saveCurrentApplicationId(id);
    var value = document.getElementById(id);

    var current;
    if (
      this.state.showCurrentApplication["display" + applicationId] === "block"
    ) {
      this.setState((prevState) => ({
        showCurrentApplication: {
          ...prevState.showCurrentApplication,
          ["display" + applicationId]: "none",
        },
      }));
    } else if (
      this.state.showCurrentApplication["display" + applicationId] === "none"
    ) {
      this.setState((prevState) => ({
        showCurrentApplication: {
          ...prevState.showCurrentApplication,
          ["display" + applicationId]: "block",
        },
      }));
    }
    //window.location.reload();
  }

  changeDocText(e, state, file) {
    e.preventDefault();

    var value = document.getElementById(file).value;
    if (value !== "" || value !== null) {
      var fileName = value.split("\\").pop().split("/").pop();
      this.setState({
        [state]: fileName,
      });

      // document.getElementById(button).disabled = false;
    } else {
    }
  }
  changeDocTextBiotetic(e, state, file) {
    e.preventDefault();

    var value = document.getElementById(file).value;
    if (value !== "" || value !== null) {
      var fileName = value.split("\\").pop().split("/").pop();
      this.setState({
        [state]: fileName,
        showButton: true,
      });

      // document.getElementById(button).disabled = false;
    } else {
    }
  }
  saveCurrentApplicationId(applicationId) {
    if (
      sessionStorage.getItem("currentApplicationId") === null ||
      sessionStorage.getItem("currentApplicationId") !== applicationId
    ) {
      sessionStorage.setItem("currentApplicationId", applicationId);
    } else if (
      sessionStorage.getItem("currentApplicationId") === applicationId
    ) {
      sessionStorage.removeItem("currentApplicationId");
    }
  }
  getCurrentApplicationId() {
    return sessionStorage.getItem("currentApplicationId");
  }
  saveBioeticFile(event, state, file, applicationId) {
    event.preventDefault();
    // this.saveCurrentApplicationId(applicationId);
    var value = document
      .getElementById(file)
      .value.split("\\")
      .pop()
      .split("/")
      .pop();
    if (window.confirm("Ar pasirinkote teisingą failą kad jį išsaugoti?")) {
      // save to backend
      // dalyvisService
      //   .postFileUpload(data)
      //   .then(() => {
      tyrejasService
        .getApplications(applicationId)
        .then((data) => {
          var object = {
            ...data,
            ...{
              permitFileLink: "https://covid19.mf.vu.lt/proxy/api/files/download/" + value,
            },
          };
          tyrejasService
            .putApplicationsCustom(data.id, object)
            .then((response) => {
              tyrejasService
                .userSet(this.state.user.id)
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));
      // })
      // .catch((error) => window.alert(error.message));
    } else {
      this.setState({
        [state]: "Pasirinkite dokumentą",
      });
      document.getElementById(file).value = "";
    }
    this.setState({
      showButton: false,
    });
  }
  saveExaminationInfo(e, applicationId) {
    e.preventDefault();
    var event = e.target;
    var object = {
      examText: event.text.value,
      examFileLink:
        "https://covid19.mf.vu.lt/proxy/api/files/download/" +
        event.file1.value.split("\\").pop().split("/").pop(),
      // "samplesFileLink": "https://link.com/"+ event.file2.value.split('\\').pop().split('/').pop(),
      sendingDate: new Date(),
      addedAt: new Date(),
      application: applicationId,
    };
    tyrejasService
      .postExaminationInfoObject(applicationId, object)
      .catch((error) => window.alert(error.message));
  }
  deleteBioeticFile(event, applicationId) {
    // this.saveCurrentApplicationId(applicationId);
    if (window.confirm("Ar tikrai norite panaikinti šitą failą?")) {
      tyrejasService
        .getApplications(applicationId)
        .then((data) => {
          var object = { ...data, ...{ permitFileLink: null } };
          tyrejasService
            .putApplicationsCustom(data.id, object)
            .then((response) => {
              tyrejasService
                .userSet(this.state.user.id)
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => window.alert(error.message));
    }
  }
  findMaxCheckup(applicationId, type) {
    return this.state.applications
      .find((foundData) => {
        if (foundData.id == applicationId) {
          return foundData;
        }
      })
      .checkup.filter((data) => {
        if (data.requirementsState === type) {
          return data;
        }
        return 0;
      })
      .reduce((prev, current) => {
        return prev.addedAt > current.addedAt ? prev : current;
      });
  }
  findExaminationInfo(applicationId) {
    return this.state.examinationInfos.find((data) => {
      if (data.applicationId === applicationId) {
        return data;
      }
    });
  }
  saveNewFile(e, application, type) {
    var object = {};
    if (
      window.confirm(
        "Ar tikrai norite išsaugoti '" +
          e.target.value.split("\\").pop().split("/").pop() +
          "' failą?"
      )
    ) {
      var applicationStatus = 2;
      var k = 0;
      if (application.protocolFileLink === null) {
        k++;
      }
      if (application.applicationPdflink === null) {
        k++;
      }
      if (application.permitFileLink === null) {
        k++;
      }
      if (k > 1) {
        applicationStatus = 3;
      }
      object = {
        [type]:
          "https://covid19.mf.vu.lt/proxy/api/files/download/" +
          e.target.value.split("\\").pop().split("/").pop(),
        applicationStatus: applicationStatus,
      };
      tyrejasService
        .putApplicationsCustom(application.id, { ...application, ...object })
        .catch((error) => window.alert(error.message));
    }
  }

  submitForm(e, text, doc1, doc2) {
    e.preventDefault();
    // window.alert(text);
    var tyrimastext = document.getElementById(text).value;
    var tyrimasdoc = document.getElementById(doc1);
    // var samplereturndate = this.state.date;
    var grazinamimeginiai = document.getElementById(doc2);
    var allItems = [tyrimastext, tyrimasdoc.value, grazinamimeginiai.value];

    var continueState = true;

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        window.alert(
          "Kai kurie langeliai yra nepilnai užpildyti arba nepasirinktas failas!"
        );
        break;
      }
      continueState = true;
    }

    if (continueState === true) {
      window.alert("Sėkmingai išsiųsta!");
      // api send
      window.location.reload();
    }
  }

  checkEmpty(e, text, doc1, doc2, buttonid) {
    e.preventDefault();
    var tyrimastext = document.getElementById(text).value;
    var tyrimasdoc = document.getElementById(doc1);
    // var samplereturndate = this.state.date;
    var grazinamimeginiai = document.getElementById(doc2);
    // ,grazinamimeginiai.value
    var allItems = [tyrimastext, tyrimasdoc.value];
    var continueState = true;
    var quantity = 0;
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;
        document.getElementById(buttonid).disabled = true;
        break;
      }
      quantity += 1;
    }
    if (quantity === allItems.length) {
      document.getElementById(buttonid).disabled = false;
    }
  }

  render() {
    // const mygtukai = listApplications.map( (data) =>{
    //   <button onClick={((event) => this.showInfo(event,data))} style={{width: "100%", border: "none"}}>

    //     <a href="#" className="list-group-item list-group-item-action flex-column align-items-start active">
    //       <div className="d-flex w-100 justify-content-between">
    //         <h5 className="mb-1">List group item heading</h5>
    //         <small>3 days ago</small>
    //       </div>
    //       <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
    //       <small>Donec id elit non mi porta.</small>
    //     </a>

    //     </button>
    // });

    // const listApplications = Object.values(this.state.response);

    // tyrejasService.getAllApplications().then((data)=>{sessionStorage.setItem("applications",JSON.stringify(data));})
    // const listApplications =JSON.parse( sessionStorage.getItem("applications"));
    // var applications = JSON.parse(sessionStorage.getItem("applications"));
    const paraiskos = this.state.applications
      .sort(function (a, b) {
        if (a.addedDate > b.addedDate) return -1;
        if (a.addedDate < b.addedDate) return 1;
        return 0;
      })
      .map((data) => {
        var biotetic = "bioeticdoc" + data.id;

        var tyrimai = "tyrimaidoc" + data.id;
        var meginiai = "meginiaidoc" + data.id;
        var bioeticfile = "";

        // for(let i = 0; listApplications.length; i++){
        if (bioeticfile !== null || bioeticfile !== "") {
          // this.setState({[ "showBioetic" +data.id]: true});
        }

        // }

        let administeredApplications;
        let consideredApplications;
        let lastStageApplications;
        var num1 = 0;
        var num2 = 0;
        var num3 = 0;
        if (data.id !== 1 && data.checkup.length > 0) {
          administeredApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 1) {
                return checkUp;
              }
              return 0;
            })
            .sort(function (a, b) {
              if (a.addedDate < b.addedDate) return -1;
              if (a.addedDate > b.addedDate) return 1;
              return 0;
            })
            .map((checkUp) => {
              num1 += 1;

              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num1}</th>
                    <td>{checkUp.addedDate}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });

          consideredApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 2) {
                return 1;
              }
              return 0;
            })
            .sort(function (a, b) {
              if (a.addedDate < b.addedDate) return -1;
              if (a.addedDate > b.addedDate) return 1;
              return 0;
            })
            .map((checkUp) => {
              num2 += 1;
              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num2}</th>
                    <td>{checkUp.addedDate}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka: <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });
          lastStageApplications = data.checkup
            .filter((checkUp) => {
              if (checkUp.requirementsState === 3) {
                return checkUp;
              }
              return 0;
            })
            .sort(function (a, b) {
              if (a.addedDate < b.addedDate) return -1;
              if (a.addedDate > b.addedDate) return 1;
              return 0;
            })
            .map((checkUp) => {
              num3 += 1;
              var showData1 = false;
              var showData2 = false;
              if (checkUp.checkupState === 1) {
                showData1 = true;
              } else {
                showData2 = true;
              }
              let structuredReasons;
              if (checkUp.reasonRequirementList !== null) {
                structuredReasons = checkUp.reasonRequirementList
                  .split(",")
                  .map((reason) => {
                    return (
                      <>
                        <li>{reason}</li>
                      </>
                    );
                  });
              }
              return (
                <>
                  <tr>
                    <th scope="row">{num3}</th>
                    <td>{checkUp.addedDate}</td>
                    <td>
                      {showData1 && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                    </td>
                    <td>
                      {showData2 && (
                        <>
                          <i className="fa fa-close" aria-hidden="true"></i>
                          Neatitinka: <br />
                          <ul className="listStyleList">{structuredReasons}</ul>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              );
            });
        }

        //var examinationInfo = this.findExaminationInfo(data.id);

        return (
          <>
            <button
              className="applicationList"
              onClick={() => this.showInfo("application" + data.id, data.id)}
              style={{ width: "100%" }}
            >
              <button
                id="statusbtn"
                className="status-button"
                style={{
                  float: "left",
                  marginRight: "25px",
                  borderColor: this.state.buttonStates["color" + data.id],
                }}
              >
                {this.state.buttonStates["status" + data.id]}
              </button>
              <p style={{ float: "left" }}>{data.projectName}</p>
              <p style={{ float: "right" }}>
                Pateikimo data: {data.addedDate}{" "}
              </p>
            </button>
            <div>
              {this.state.showCurrentApplication["display" + data.id] ===
                "block" && (
                <li
                  key={data.id}
                  id={"application" + data.id}
                  style={{
                    display:
                      this.state.showCurrentApplication["display" + data.id],
                  }}
                >
                  <table className="table">
                    <thead className="thead-dark">
                      {/* <tr>
                    
                    <th scope="col">Skiltis</th>
                    <th scope="col">Įvesta informacija</th>
                    
                  </tr> */}
                    </thead>
                    <tbody>
                      <tr>
                        {/* <th scope="row">1</th> */}
                        <th scope="row">Paraiškos unikalus numeris</th>
                        <td>{data.uniqueCode}</td>
                      </tr>
                      <tr>
                        {/* <th scope="row">1</th> */}
                        <th scope="row">Projekto pavadinimas</th>
                        <td>{data.projectName}</td>
                      </tr>
                      <tr>
                        {/* <th scope="row">1</th> */}
                        <th scope="row">Pagrindinis tyrėjas</th>
                        <td>{data.projectExaminatorFullname}</td>
                      </tr>
                      <tr>
                        {/* <th scope="row">2</th> */}
                        <th>Tyrimo protokolo santrauka</th>

                        {data.protocolFileLink === null ? (
                          <>
                            <td
                              style={{
                                border: "solid",
                                borderWidth: "2px",
                                borderColor: "red",
                              }}
                            >
                              <label
                                className="btn"
                                style={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  margin: 0,
                                  borderRadius: 0,
                                }}
                              >
                                <input
                                  className="col-centered btn"
                                  type="file"
                                  id="signedfile"
                                  accept="application/pdf,image/png, image/gif, image/jpeg"
                                  onInput={(event) =>
                                    this.saveNewFile(
                                      event,
                                      data,
                                      "protocolFileLink"
                                    )
                                  }
                                  style={{ margin: 0, borderRadius: 0 }}
                                />
                                Pasirinkite dokumentą
                              </label>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <a
                                className="btn"
                                href={data.protocolFileLink}
                                download
                              >
                                Atsisiųsti tyrimo protokolą
                              </a>
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        {/* <th scope="row">3</th> */}
                        <th>Bioetikos leidimas</th>

                        {data.permitFileLink !== null &&
                        data.permitFileLink !== "" ? (
                          <>
                            <td>
                              <a
                                className="btn"
                                href={data.permitFileLink}
                                style={{ marginBottom: "2px" }}
                                download
                              >
                                Peržiūrėti bioetikos leidimą
                              </a>
                              {/* <button className="btn" onClick={e => this.deleteBioeticFile(e, data.id)}>X</button> */}
                              <br />
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              style={{
                                border: "solid",
                                borderWidth: "2px",
                                borderColor: "red",
                              }}
                            >
                              <label
                                className="btn"
                                style={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  margin: 0,
                                  borderRadius: 0,
                                }}
                              >
                                <input
                                  className="col-centered btn"
                                  type="file"
                                  id={"bioetikosapplied" + data.id}
                                  onInput={(event) =>
                                    this.saveNewFile(
                                      event,
                                      data,
                                      "permitFileLink"
                                    )
                                  }
                                  style={{ margin: 0, borderRadius: 0 }}
                                />
                                {/* {this.state[biotetic]} */}
                                Pasirinkite dokumentą
                              </label>
                            </td>
                            {/* {this.state.showButton && (<>
                          &nbsp;<button className='btn' onClick={(e) => this.saveBioeticFile(e, biotetic, "bioetikosapplied" + data.id, data.id)}>Išsaugoti</button>

                        </>
                        )
                        } */}
                          </>
                        )}
                        {/* <button className="btn" id={data+"savebioetic"} style={{padding: "2px", border: "none", marginLeft: "5px"}} disabled><i className="fa fa-save" style={{fontSize: "20px"}}></i></button><br/> */}
                      </tr>
                      {data.applicationStatus === 1 && (
                        <tr>
                          <th>Garantinis raštas</th>
                          <td>
                            {data.guaranteeFileLink !== null &&
                            data.guaranteeFileLink !== "" &&
                            data.guaranteeFileLink !== undefined ? (
                              <a
                                className="btn"
                                href={data.guaranteeFileLink}
                                download
                              >
                                Peržiūrėti garantinį raštą
                              </a>
                            ) : (
                              <p>Garantinis raštas dar nepateiktas</p>
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        {/* <th scope="row">4</th> */}
                        <th>Projekto pradžia ir pabaiga</th>
                        <td style={{ width: "70%" }}>
                          Pradžia:&nbsp;&nbsp;&nbsp; {data.startDate} <br />
                          Pabaiga:&nbsp; {data.endDate}
                        </td>
                        {/* <td>Pabaiga: 2009-01-01</td> */}
                      </tr>
                      <tr>
                        {/* <th scope="row">5</th> */}
                        <th>Paraiškos PDF</th>
                        {data.applicationPdflink === null ? (
                          <>
                            <td
                              style={{
                                border: "solid",
                                borderWidth: "2px",
                                borderColor: "red",
                              }}
                            >
                              <label
                                className="btn"
                                style={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  margin: 0,
                                  borderRadius: 0,
                                  wordBreak: "normal",
                                }}
                              >
                                <input
                                  className="col-centered btn"
                                  type="file"
                                  id="applicationpdf"
                                  accept="application/pdf,image/png, image/gif, image/jpeg"
                                  onInput={(event) =>
                                    this.saveNewFile(
                                      event,
                                      data,
                                      "applicationPDFLink"
                                    )
                                  }
                                  style={{ margin: 0, borderRadius: 0 }}
                                />
                                Pasirinkite dokumentą
                              </label>
                              <br />
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <a
                                className="btn"
                                href={data.applicationPdflink}
                                download
                              >
                                Peržiūrėti paraiškos PDF
                              </a>
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        {/* <th scope="row">6</th> */}
                        <th>Papildoma informacija</th>
                        <td>{data.additionalRemarks}</td>
                      </tr>
                      <tr>
                        {/* <th scope="row">7</th> */}
                        <th>Pateikimo data</th>
                        <td>{data.addedDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  {this.state.checksShow["table" + data.id] && (
                    <>
                      <h2>
                        <b>Administracinės patikros</b>
                      </h2>

                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Atitinka</th>
                            <th scope="col">Neatitinka</th>
                          </tr>
                        </thead>
                        <tbody>{administeredApplications}</tbody>
                      </table>

                      <h2>
                        <b>LPRLB mokslo komiteto vertinimas</b>
                      </h2>

                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Suteikti</th>
                            <th scope="col">Nesuteikti</th>
                          </tr>
                        </thead>
                        <tbody>{consideredApplications}</tbody>
                      </table>
                      <h2>
                        <b>HBRC mokslo komiteto svarstymas</b>
                      </h2>

                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Suteikti</th>
                            <th scope="col">Nesuteikti</th>
                          </tr>
                        </thead>
                        <tbody>{lastStageApplications}</tbody>
                      </table>
                    </>
                  )}
                  {/* this.state.examData["datasub" + data.id] && */}
                  {this.state.examData["datasub" + data.id] &&
                    this.findMaxCheckup(data.id, 3)
                      .additionalFileLinkCheckup !== null &&
                    this.findMaxCheckup(data.id, 3).checkupState === 1 && (
                      <>
                        <a
                          className="btn"
                          href={
                            this.findMaxCheckup(data.id, 3)
                              .additionalFileLinkCheckup
                          }
                          download
                        >
                          Atsisiusti mėginių formą
                        </a>
                        {/* <form id={data + "form"} onInput={(event) => this.checkEmpty(event, data + "tyrimastext", data + "tyrimasdoc", data + "grazinamimeginiai", data + "dataButton")} onSubmit={(event) => this.saveExaminationInfo(event, data.id)}>
                    <h2><b>Atliktų tyrimų duomenys</b></h2>

                    <table className="table">

                      <thead style={{ width: "29%" }} className="thead-dark">
                        <tr>

                        


                        </tr>
                      </thead>
                      <tbody style={{ width: "70%" }}>

                        <tr>
                          <th style={{ width: "20%" }}>Tekstas*</th>
                          {examinationInfo !== null ?
                            <>

                              <td >
                                <textarea id={data + "tyrimastext"} name="text" style={{ width: "100%", height: "100px" }} />

                              </td>
                            </> :
                            <>
                              <td >
                                <label>{examinationInfo !== null && examinationInfo.examText}</label>

                              </td>
                            </>}



                        </tr>

                        <tr>
                          <th style={{ width: "20%" }}>Dokumentas*</th>
                          {examinationInfo !== null ? <>
                            <td>
                              <label className="btn" >

                                <input className="col-centered btn" type="file" name="file1" id={data + "tyrimasdoc"} accept="application/pdf,image/png, image/gif, image/jpeg" onInput={(event) => this.changeDocText(event, tyrimai, data + "tyrimasdoc")} />
                                Pasirinkite dokumentą
                              </label>
                              {this.state[tyrimai]}
                            </td>
                          </> :
                            <>
                              <td>
                                <a className='btn' href={examinationInfo.examFileLink} download> Atsisiųsti atlikto tyrimo dokumentą</a>
                              </td>
                            </>}


                        </tr>


                      </tbody>

                    </table>

                    <h2><b>Grąžinami mėginiai</b></h2>

                    <table className="table">

                      <thead className="thead-dark">
                        <tr style={{ width: "20%" }}>

                     


                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <th style={{ width: "20%" }}>Grąžinimo data</th>
                          {(examinationInfo !== null) ? <>
                            <td >
                              <input type="text" id={data + "samplereturndate"} name="date" value={this.state.date} readOnly style={{ width: "100%" }} />

                            </td>
                          </> : <>
                            <label>{examinationInfo.sendingDate}</label>
                          </>

                          }

                        </tr>

                        <tr>
                          <th style={{ width: "20%" }}>Grąžinamų nepanaudotų biologinių mėginių sąrašas</th>
                         
                          <td >
                            <label className="btn" >

                              <input className="col-centered btn" type="file" id={data + "grazinamimeginiai"} name="file2" accept="application/pdf,image/png, image/gif, image/jpeg" onInput={(event) => this.changeDocText(event, meginiai, data + "grazinamimeginiai")} />
                              Pasirinkite dokumentą

                            </label>
                            {this.state[meginiai]}
                          </td>
                        


                        </tr>


                      </tbody>

                    </table>
                    <button id={data + "dataButton"} type="submit" className="btn" disabled style={{ float: "right", marginBottom: "25px" }}><i className="fa fa-send" />Pateikti</button>
                  </form> */}
                      </>
                    )}
                </li>
              )}
            </div>
          </>
        );
      });

    return (
      <>
        <div className="col-centered col-lg-10 col-md-12 col-12">
          <h2>
            <b>Užpildytų paraiškų sąrašas</b>
          </h2>

          <div className="list-group"></div>
          <ul className="designed-list">{React.Children.toArray(paraiskos)}</ul>
        </div>
      </>
    );
  }
}

export default MyApplications;
