import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { parseQuestionnaire } from "./questionnaireParser";
import sampleExcelFile from "./klausimai.xlsx";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "./Questionnaire.css";
import dalyvisService from "../../../../services/dalyvis-service";
import authService from "../../../../services/auth-service";

const Questionnaire = () => {
    const [questionnaire, setQuestionnaire] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [groupedQuestions, setGroupedQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const { handleSubmit, control, watch, register, setValue, getValues } = useForm();

    useEffect(() => {
        fetch(sampleExcelFile)
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
                const file = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                parseQuestionnaire(file, (parsedQuestionnaire) => {
                    console.log("Parsed questionnaire:", parsedQuestionnaire);

                    // Initialize answers with default values
                    const initialAnswers = {};
                    parsedQuestionnaire.forEach((question) => {
                        switch (question.type) {
                            case "text":
                                initialAnswers[question.id] = "";
                                break;
                            case "date":
                                initialAnswers[question.id] = null;
                                break;
                            case "integer":
                                initialAnswers[question.id] = -1;
                                break;
                            case "select":
                                initialAnswers[question.id] = -1;
                                break;
                            case "multichoice":
                            case "multichoiceDropdown":
                                initialAnswers[question.id] = [];
                                break;
                            default:
                                initialAnswers[question.id] = null; // For introGroup, introSpecial, etc.
                                break;
                        }
                    });
                    setAnswers(initialAnswers);

                    const grouped = groupQuestions(parsedQuestionnaire);
                    setQuestionnaire(parsedQuestionnaire);
                    setGroupedQuestions(grouped);
                });
            });
    }, []);

    const groupQuestions = (questions) => {
        const grouped = [];
        let currentGroup = [];

        questions.forEach((question) => {
            if (question.type === "intro") {
                if (currentGroup.length > 0) {
                    grouped.push(currentGroup);
                    currentGroup = [];
                }
                currentGroup.push(question);
            } else {
                currentGroup.push(question);
            }
        });

        if (currentGroup.length > 0) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleBack = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const onSubmit = (data) => {
        console.log("Final data:", data);

        dalyvisService.postQuestionnaire(data).then((response) => {
            this.props.history.push("/dalyvis/manoprofilis");
        }).catch((error) => window.alert(error.message));
    };

    const handleAnswerChange = (questionId, value) => {
        setAnswers((prevAnswers) => {
            const updatedAnswers = { ...prevAnswers, [questionId]: value };
            console.log(`Question ${questionId} changed. New answer:`, value);
            return updatedAnswers;
        });
    };

    const renderQuestion = (question) => {
        switch (question.type) {
            case "intro":
                return (
                    <h3 key={question.id} className="mb-4 text-xl font-semibold">
                        {question.question}
                    </h3>
                );
            case "introSpecial":
                return (
                    <h4 key={question.id} className="mb-3 text-lg font-semibold">
                        {question.question}
                    </h4>
                );
            case "date":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        <Controller
                            name={question.id}
                            control={control}
                            defaultValue={answers[question.id]}
                            render={({ field }) => (
                                <Form.Control
                                    type="date"
                                    {...field}
                                    className="w-40"
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        handleAnswerChange(question.id, e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                );
            case "text":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        <Controller
                            name={question.id}
                            control={control}
                            defaultValue={answers[question.id]}
                            render={({ field }) => (
                                <Form.Control
                                    type="text"
                                    {...field}
                                    className="w-40"
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        handleAnswerChange(question.id, e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                );
            case "integer":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        <Controller
                            name={question.id}
                            control={control}
                            defaultValue={answers[question.id]}
                            render={({ field }) => (
                                <Form.Control
                                    type="number"
                                    {...field}
                                    min="0"
                                    className="w-40"
                                    onChange={(e) => {
                                        const intValue = parseInt(e.target.value, 10);
                                        field.onChange(intValue);
                                        handleAnswerChange(question.id, intValue);
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                );
            case "select":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        {Object.entries(question.answers).map(([key, value]) => (
                            <div key={key} className="flex items-center ml-4">
                                <Form.Check
                                    type="radio"
                                    label={value}
                                    value={key}
                                    {...register(question.id)}
                                    className="small-radio"
                                    onChange={() => handleAnswerChange(question.id, key)}
                                />
                            </div>
                        ))}
                    </Form.Group>
                );
            case "multichoice":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        {Object.entries(question.answers).map(([key, value]) => (
                            <div key={key} className="flex items-center ml-4">
                                <Form.Check
                                    type="checkbox"
                                    label={value}
                                    value={key}
                                    {...register(question.id)}
                                    className="small-checkbox"
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        const currentValues = answers[question.id] || [];
                                        const newValues = checked
                                            ? [...currentValues, key]
                                            : currentValues.filter((item) => item !== key);
                                        handleAnswerChange(question.id, newValues);
                                    }}
                                />
                            </div>
                        ))}
                    </Form.Group>
                );
            case "multichoiceDropdown":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        {Object.entries(question.answers).map(([key, value]) => (
                            <div key={key}>
                                <div className="flex items-center ml-4">
                                    <Form.Check
                                        type="checkbox"
                                        label={value}
                                        value={key}
                                        {...register(`${question.id}[${key}]`)}
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            const currentValues = answers[question.id] || [];
                                            const newValues = checked
                                                ? [...currentValues, key]
                                                : currentValues.filter((item) => item !== key);
                                            handleAnswerChange(question.id, newValues);
                                        }}
                                    />
                                </div>
                                {watch(`${question.id}[${key}]`) && (
                                    <Form.Group className="ml-8">
                                        {question.dropdownOptions.map((opt, optIndex) => (
                                            <div key={optIndex} className="flex items-center ml-6">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={opt}
                                                    value={optIndex}
                                                    {...register(`${question.id}[${key}][dropdown]`)}
                                                    className="small-checkbox"
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        const currentDropdownValues =
                                                            answers[`${question.id}-${key}-dropdown`] || [];
                                                        const newDropdownValues = checked
                                                            ? [...currentDropdownValues, optIndex]
                                                            : currentDropdownValues.filter(
                                                                (item) => item !== optIndex
                                                            );
                                                        handleAnswerChange(
                                                            `${question.id}-${key}-dropdown`,
                                                            newDropdownValues
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                )}
                            </div>
                        ))}
                    </Form.Group>
                );
            case "time":
                return (
                    <Form.Group key={question.id} className="mb-4">
                        <Form.Label className="block text-lg font-medium mb-1">
                            {question.question}
                        </Form.Label>
                        <Controller
                            name={question.id}
                            control={control}
                            defaultValue={answers[question.id]}
                            render={({ field }) => (
                                <Form.Control
                                    type="time"
                                    {...field}
                                    className="w-40"
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        handleAnswerChange(question.id, e.target.value);
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                );
            default:
                return null;
        }
    };

    return (
        <Container className="mx-auto px-4 mt-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
                {groupedQuestions[currentPage] &&
                    groupedQuestions[currentPage].map((question) => (
                        <Row key={question.id} className="mb-4">
                            <Col>{renderQuestion(question)}</Col>
                        </Row>
                    ))}
                <div className="d-flex justify-content-between mt-4">
                    {currentPage > 0 && (
                        <Button onClick={handleBack} className="custom-button">
                            ATGAL
                        </Button>
                    )}
                    {currentPage < groupedQuestions.length - 1 ? (
                        <Button onClick={handleNext} className="custom-button">
                            TOLIAU
                        </Button>
                    ) : (
                        <Button type="submit" className="custom-button">
                            IŠSAUGOTI IR PATEIKTI
                        </Button>
                    )}
                </div>
            </Form>
        </Container>
    );
};

export default Questionnaire;

// import React, { useEffect, useState } from "react";
// import { useForm, Controller } from "react-hook-form";
// import { parseQuestionnaire } from "./questionnaireParser";
// import sampleExcelFile from "./klausimai.xlsx";
// import { Button, Form, Table, Container, Row, Col } from "react-bootstrap";
// import "./Questionnaire.css";
//
// const Questionnaire = () => {
//   const [questionnaire, setQuestionnaire] = useState([]);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [groupedQuestions, setGroupedQuestions] = useState([]);
//   const [customQuestions, setCustomQuestions] = useState([]);
//   const { handleSubmit, control, watch, register, setValue, getValues } =
//     useForm();
//
//   useEffect(() => {
//     fetch(sampleExcelFile)
//       .then((response) => response.arrayBuffer())
//       .then((buffer) => {
//         const file = new Blob([buffer], {
//           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//         });
//         parseQuestionnaire(file, (parsedQuestionnaire) => {
//           const grouped = groupQuestions(parsedQuestionnaire);
//           console.log("Parsed questionnaire:");
//           console.log(parsedQuestionnaire);
//           setQuestionnaire(parsedQuestionnaire);
//           setGroupedQuestions(grouped);
//         });
//       });
//   }, []);
//
//   const groupQuestions = (questions) => {
//     const grouped = [];
//     let currentGroup = [];
//     const processedIds = new Set();
//
//     for (let i = 0; i < questions.length; i++) {
//       const question = questions[i];
//
//       if (processedIds.has(question.id)) {
//         continue;
//       }
//
//       if (question.type === "intro" || question.type === "introGroup") {
//         if (currentGroup.length > 0) {
//           grouped.push(currentGroup);
//           currentGroup = [];
//         }
//         currentGroup.push(question);
//
//         if (question.type === "introGroup") {
//           const introGroupQuestion = question;
//           const questionAfterIntroGroup = questions[i + 1];
//           if (
//             !questionAfterIntroGroup ||
//             questionAfterIntroGroup.type === "intro" ||
//             questionAfterIntroGroup.type === "introSpecial" ||
//             questionAfterIntroGroup.type === "introGroup"
//           ) {
//             continue;
//           }
//
//           for (let j = i + 1; j < questions.length; j++) {
//             const nextQuestion = questions[j];
//             if (
//               nextQuestion.type === questionAfterIntroGroup.type &&
//               JSON.stringify(nextQuestion.answers) ===
//                 JSON.stringify(questionAfterIntroGroup.answers)
//             ) {
//               currentGroup.push(nextQuestion);
//               processedIds.add(nextQuestion.id);
//             } else {
//               break;
//             }
//           }
//         }
//       } else {
//         currentGroup.push(question);
//       }
//     }
//
//     if (currentGroup.length > 0) {
//       grouped.push(currentGroup);
//     }
//
//     return grouped;
//   };
//
//   const handleNext = () => {
//     setCurrentPage(currentPage + 1);
//     console.log(questionnaire);
//   };
//
//   const handleBack = () => {
//     setCurrentPage(currentPage - 1);
//   };
//
//   const onSubmit = (data) => {
//     console.log(data);
//     // sendAnswers(data);
//   };
//
//   const renderQuestion = (question) => {
//     if (customQuestions.includes(question.id)) {
//       return renderCustomQuestion(question);
//     }
//
//     switch (question.type) {
//       case "intro":
//         return (
//           <h3 className="mb-4 text-xl font-semibold">{question.question}</h3>
//         );
//       case "introSpecial":
//         return (
//           <h4 className="mb-3 text-lg font-semibold">{question.question}</h4>
//         );
//       case "introGroup":
//         return renderIntroGroup(question);
//       case "date":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>{" "}
//             <Controller
//               name={question.id}
//               control={control}
//               defaultValue=""
//               render={({ field }) => (
//                 <Form.Control type="date" {...field} className="w-40" />
//               )}
//             />
//           </Form.Group>
//         );
//       case "text":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             <Controller
//               name={question.id}
//               control={control}
//               defaultValue=""
//               render={({ field }) => (
//                 <Form.Control type="text" {...field} className="w-40" />
//               )}
//             />
//           </Form.Group>
//         );
//       case "integer":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             <Controller
//               name={question.id}
//               control={control}
//               defaultValue=""
//               render={({ field }) => (
//                 <Form.Control
//                   type="number"
//                   {...field}
//                   min="0"
//                   className="w-40"
//                 />
//               )}
//             />
//           </Form.Group>
//         );
//       case "select":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             {question.answers.map((answer, index) => (
//               <div key={index} className="flex items-center ml-4">
//                 <Form.Check
//                   type="radio"
//                   label={answer.replace(/^\d+\s*/, "")}
//                   value={index}
//                   {...register(question.id)}
//                   className="small-radio"
//                 />
//               </div>
//             ))}
//           </Form.Group>
//         );
//       case "multichoice":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             {question.answers.map((answer, index) => (
//               <div key={index} className="flex items-center ml-4">
//                 <Form.Check
//                   type="checkbox"
//                   label={answer.replace(/^\d+\s*/, "")}
//                   value={index}
//                   {...register(question.id)}
//                 />
//               </div>
//             ))}
//           </Form.Group>
//         );
//       case "multichoiceDropdown":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             {question.answers.map((answer, index) => (
//               <div key={index}>
//                 <div className="flex items-center ml-4">
//                   <Form.Check
//                     type="checkbox"
//                     label={answer.replace(/^\d+\s*/, "")}
//                     value={index}
//                     {...register(`${question.id}[${index}]`)}
//                   />
//                 </div>
//                 {watch(`${question.id}[${index}]`) && (
//                   <Form.Group className="ml-8">
//                     {question.dropdownOptions.map((opt, optIndex) => (
//                       <div key={optIndex} className="flex items-center ml-6">
//                         <Form.Check
//                           type="checkbox"
//                           label={opt.replace(/^\d+\s*/, "")}
//                           value={optIndex}
//                           {...register(`${question.id}[${index}][dropdown]`)}
//                           className="small-checkbox"
//                         />
//                       </div>
//                     ))}
//                   </Form.Group>
//                 )}
//               </div>
//             ))}
//           </Form.Group>
//         );
//       case "time":
//         return (
//           <Form.Group className="mb-4">
//             <Form.Label className="block text-lg font-medium mb-1">
//               {question.question}
//             </Form.Label>
//             <Controller
//               name={question.id}
//               control={control}
//               defaultValue=""
//               render={({ field }) => (
//                 <Form.Control type="time" {...field} className="w-40" />
//               )}
//             />
//           </Form.Group>
//         );
//       default:
//         return null;
//     }
//   };
//
//   const renderCustomQuestion = (question) => {
//     return (
//       <Form.Group className="mb-4">
//         <Form.Label className="block text-lg font-medium mb-1">{`Custom Question: ${question.question}`}</Form.Label>
//         <Controller
//           name={question.id}
//           control={control}
//           defaultValue=""
//           render={({ field }) => (
//             <Form.Control
//               type="text"
//               placeholder="Custom Input"
//               {...field}
//               className="w-40"
//             />
//           )}
//         />
//       </Form.Group>
//     );
//   };
//
//   const renderIntroGroup = (introGroupQuestion) => {
//     const introGroupIndex = questionnaire.findIndex(
//       (q) => q.id === introGroupQuestion.id
//     );
//     if (introGroupIndex === -1) return null;
//
//     const groupedQuestions = [];
//     const questionAfterIntroGroup = questionnaire[introGroupIndex + 1];
//     if (
//       !questionAfterIntroGroup ||
//       questionAfterIntroGroup.type === "intro" ||
//       questionAfterIntroGroup.type === "introSpecial" ||
//       questionAfterIntroGroup.type === "introGroup"
//     ) {
//       return null;
//     }
//     const questionAnswers = questionAfterIntroGroup.answers.map((answer) =>
//       answer.replace(/^\d+\s*/, "")
//     );
//
//     for (let i = introGroupIndex + 1; i < questionnaire.length; i++) {
//       const question = questionnaire[i];
//       if (
//         question.type === questionAfterIntroGroup.type &&
//         JSON.stringify(question.answers) ===
//           JSON.stringify(questionAfterIntroGroup.answers)
//       ) {
//         groupedQuestions.push(question);
//       } else {
//         break;
//       }
//     }
//
//     return (
//       <Table striped bordered hover className="mb-4">
//         <thead>
//           <tr>
//             <th>{introGroupQuestion.question}</th>
//             {questionAnswers.map((answer, index) => (
//               <th key={index}>{answer}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {groupedQuestions.map((question) => (
//             <tr key={question.id}>
//               <td>{question.question}</td>
//               {questionAnswers.map((_, answerIndex) => (
//                 <td key={answerIndex} className="align-middle text-center">
//                   {questionAfterIntroGroup.type === "select" ? (
//                     <Form.Check
//                       type="radio"
//                       value={answerIndex}
//                       {...register(`${question.id}`)}
//                       className="small-radio"
//                     />
//                   ) : (
//                     <Controller
//                       name={`${question.id}-${answerIndex}`}
//                       control={control}
//                       defaultValue=""
//                       render={({ field }) => (
//                         <Form.Control
//                           type="number"
//                           {...field}
//                           className="w-20 mx-auto"
//                         />
//                       )}
//                     />
//                   )}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     );
//   };
//
//   return (
//     <Container className="mx-auto px-4 mt-4">
//       <Form onSubmit={handleSubmit(onSubmit)}>
//         {groupedQuestions[currentPage] &&
//           groupedQuestions[currentPage].map((question) => (
//             <Row key={question.id} className="mb-4">
//               <Col>{renderQuestion(question)}</Col>
//             </Row>
//           ))}
//         <div className="d-flex justify-content-between mt-4">
//           {currentPage > 0 && (
//             <Button onClick={handleBack} className="custom-button">
//               ATGAL
//             </Button>
//           )}
//           {currentPage < groupedQuestions.length - 1 ? (
//             <Button onClick={handleNext} className="custom-button">
//               TOLIAU
//             </Button>
//           ) : (
//             <Button type="submit" className="custom-button">
//               IŠSAUGOTI IR PATEIKTI
//             </Button>
//           )}
//         </div>
//       </Form>
//     </Container>
//   );
// };
//
// export default Questionnaire;
