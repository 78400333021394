import React, { Component } from 'react';
import document1 from "../../../../docs/important/3_SUTIKIMAS_dalyviui_LPRLB.pdf"


class Dalyvis2 extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            authorizedEVVLink: "/dalyvavimas/uploaddocument"
        };
        
        
    }
    scrollingBottom(e){
        e.preventDefault();
       
        const height = document.getElementById("pdfdoc").offsetHeight;
        window.alert(height + "px height");
    }
    componentDidMount(){
        //implementuoti checka state jei prisijungia per evv
        var authorizedevv = false;
        if(authorizedevv){
            this.setState({
                authorizedEVVLink: "/dalyvavimas/infoforma"
            });

        }
        else{
            this.setState({
                authorizedEVVLink: "/dalyvavimas/uploaddocument"
            });
        }
    }

    render() { 
       
        
        return <>
                 <embed id="pdfdoc" src={document1} type="application/pdf" width="100%" height="600px" style={{overflow: ""}}/>
                <a href={this.state.authorizedEVVLink} className="btn col-lg-6 col-md-8 col-sm-12 " style={{display: "block", margin: "0 auto", marginTop: "20px", marginBottom: "20px"}}>
                    Perskaičiau informuoto asmens sutikimo formą ir sutinku dalyvauti biobanko veikloje
                </a> {/* isDisabled */}
                <a href="#" onClick={(event) => this.scrollingBottom(event)} className="btn col-lg-6 col-md-8 col-sm-12" style={{display: "block", margin: "0 auto", marginTop: "20px", marginBottom: "20px"}}>
                    Nedalyvausiu
                </a>

                </>;
    }
}
 
export default Dalyvis2;