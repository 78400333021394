import React, { Component } from 'react';


class PartSideNav extends React.Component {
  componentDidMount() {
    var pathname = window.location.pathname;
    // if(pathname != "/dalyvioteises"){
    // for(let i = 1; i < 2; i++){
    // document.getElementById("teises" + i).style.display = "none";
    // }
    // }

  document.getElementById("teises1").style.display = "none";
  if( ( pathname ==="/dalyvioteises")){
  document.getElementById("teises1").style.display = "block";
  }
  else if( ( pathname ==="/teiseatsauktikeisti")){
    document.getElementById("teises1").style.display = "block";
  }

    
    
}
handleList(id, lengthList) {
  
  //alert('Sėkmingai išsiųsta!');
  
  //console.log(length);
//   for(let i = 1; i < lengthList+1; i++){
//    var x = document.getElementById(id + i);
  
//   if (x.style.display === "none") {
//     x.style.display = "block";

//   } else {
//     x.style.display = "none";
//   }
// }
// if (x.style.display === "none") {
//   document.getElementById(id).className = "fa fa-caret-right";

// } else {
  
//   document.getElementById(id).className = "fa fa-caret-down";
// }

//   //window.location.reload();
}

    render() { 
      const color = "black";
      const backColor = "white";
        const pathname = window.location.pathname;
        const dalyviamsActive = pathname === '/dalyviams' ?  {color: color, backgroundColor: backColor} : {}
        const kaipTaptiActive = pathname === '/kaiptapti' ?  {color: color, backgroundColor: backColor} : {}
        const registracijaDalyviams = pathname === '/registracija' ?  {color: color, backgroundColor: backColor} : {}
        const dalyvioTeisesActive = pathname === '/dalyvioteises' || pathname === '/teiseatsauktikeisti' ?  {color: color, backgroundColor: backColor} : {}
        const atsauktiKeistiActive = pathname === '/teiseatsauktikeisti' ?  {color: color, backgroundColor: backColor} : {}
        const formosActive = pathname === '/formos' ?  {color: color, backgroundColor: backColor} : {}
        const DUKActive = pathname === '/duk' ?  {color: color, backgroundColor: backColor} : {}
        
        const arrowDir = this.state
        return (
            <div className="col-lg-3 mb-5 margin-nav">
              <div className = "position-button size">
                <a href="/dalyviams" className="btn" style={dalyviamsActive}>Biobanko dalyviams&nbsp; <i class="fa fa-caret-down" ></i> </a>
                <ul className="dropdown-padding">
                  <li><a href="/kaiptapti" className="btn" style={kaipTaptiActive}>Kaip tapti dalyviu? </a></li>
                  <li><a href="/registracija" className="btn" style={registracijaDalyviams}>Registracija dalyviams </a></li>
                  {/* <a href="dalyvioteises" data-toggle="#" onClick={() => this.handleList("teises",1)} className="btn" style={dalyvioTeisesActive}><i className="fa fa-bank" aria-hidden="true"></i>&nbsp;Dalyvio teisės&nbsp;<i id="teises" class="fa fa-caret-right" style={{fontSize: "20px",float: "right", textAlign: "right", marginRight: "10px", wordBreak: "keep-all"}}></i></a>
                <a id = "teises1" href="/teiseatsauktikeisti" className="btn" style={atsauktiKeistiActive}><i className="fa fa-arrow-right" aria-hidden="true" ></i>&nbsp;Teisė atšaukti/keisti sutikimą</a> */}
                  <li><a href="dalyvioteises" className="btn" style={dalyvioTeisesActive}>Dalyvio teisės</a></li>
                  <ul className="dropdown-padding1">
                    <li><a href="/teiseatsauktikeisti" className="btn" style={atsauktiKeistiActive} id="teises1">Teisė
                      atšaukti sutikimą</a></li>
                  </ul>
                  <li><a href="/formos" className="btn" style={formosActive}>Sutikimo ir atsisakymo formos</a></li>
                  <li><a href="/duk" className="btn" style={DUKActive}>DUK</a></li>
                </ul>
              </div>
            </div>);

    }
}

export default PartSideNav;