import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect , useRouteMatch} from "react-router-dom";


import Contact from './../tyrejas-subpages/susisiekti'
import Pranesimai from './../tyrejas-subpages/pranesimai'

class SelectMessage extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
         
        };
        
        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }
    

    render() { 
       
        
        return <>
                  
                  
        <div className="col-lg-12 col-12">
                <div className="comments-form">
                 

                <div className="get-quote col-centered">
                    <div className="col-lg-4 col-md-5 col-sm-8 col-12 col-centered" style={{marginBottom: "20px"}}>
          <a href={`${this.props.match.path}/pranesimai`} className="btn " style={{display: "block", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateAtstovas}>
          Visi pranešimai
        </a>
        </div>
        <Route path={`${this.props.match.path}/pranesimai`} component={Pranesimai} />
        <div className="col-lg-4 col-md-5 col-sm-8 col-12 col-centered">
          <a href={`${this.props.match.path}/susisiekti`} className="btn" style={{display: "block"}} onClick={this.changeStateDalyvis} >
          Susisiekti su LPRLB
        </a>
        </div>
        <Route path={`${this.props.match.path}/susisiekti`} component={Contact} />
        
        
        
      
        </div>

                </div>
              </div>


      

                </>;
    }
}
 
export default SelectMessage;