import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Container, Row, Col } from 'react-bootstrap';
import SpecialistSelector from './SpecialistSelector';
import ServiceTypeSelector from './ServiceTypeSelector';
import CalendarComponent from './CalendarComponent';
import ReservationTable from './ReservationTable';
import ConfirmationModal from './ConfirmationModal';

let specialistsData = [
    { "id": "1", "name": "Pirmas Darbutojas" },
    { "id": "2", "name": "Tomas Jonaitis" }
]

let servicesData = [
    "Konsultacija",
    "Kraujo paėmimas"
]

let datesData = [
    { "date": "2024-08-04" },
    { "date": "2024-08-05" }
]

let timesData = [
    { "time": "10:00", "service": "Konsultacija" },
    { "time": "11:00", "service": "Konsultacija" }
]

const ReservationPage = () => {
    const [specialists, setSpecialists] = useState([]);
    const [selectedSpecialist, setSelectedSpecialist] = useState('');
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [times, setTimes] = useState([]);
    const [registeredVisits, setRegisteredVisits] = useState([]);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);

    useEffect(() => {
        // Fetch specialists and services from the server
        // axios.get('/api/specialists').then((response) => {
        //     setSpecialists(response.data);
        // });
        // axios.get('/api/services').then((response) => {
        //     setServices(response.data);
        // });

        setSpecialists(specialistsData);
        setServices(servicesData);
    }, []);

    useEffect(() => {
        if (selectedSpecialist && selectedService) {
            // Fetch available dates from the server

            setAvailableDates(datesData.map((item) => item.date));
            // axios
            //     .get(`/api/available-dates?specialist=${selectedSpecialist}&service=${selectedService}`)
            //     .then((response) => {
            //         setAvailableDates(response.data.map((item) => item.date));
            //     });
        }
    }, [selectedSpecialist, selectedService]);

    const handleDateSelect = (date) => {
        setSelectedDate(dayjs(date).format('YYYY-MM-DD'));


        // Fetch available times from the server
        // axios
        //     .get(`/api/available-times?date=${dayjs(date).format('YYYY-MM-DD')}&specialist=${selectedSpecialist}&service=${selectedService}`)
        //     .then((response) => {
        //         setTimes(response.data);
        //     });
        setTimes(timesData);
    };

    const handleRegister = (slot) => {
        setRegisteredVisits([...registeredVisits, { ...slot, date: selectedDate }]);
        setSuccessDialogOpen(true);
    };

    const handleCancelRegistration = (visit) => {
        setRegisteredVisits(registeredVisits.filter((v) => v !== visit));
    };

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <SpecialistSelector
                        specialists={specialists}
                        selectedSpecialist={selectedSpecialist}
                        onSelect={setSelectedSpecialist}
                    />
                </Col>
                <Col>
                    <ServiceTypeSelector
                        services={services}
                        selectedService={selectedService}
                        onSelect={setSelectedService}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <CalendarComponent
                        availableDates={availableDates}
                        onDateSelect={handleDateSelect}
                    />
                </Col>
                <Col md={8}>
                    <ReservationTable times={times} onRegister={handleRegister} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <ReservationTable
                        times={registeredVisits}
                        onRegister={handleCancelRegistration}
                    />
                </Col>
            </Row>
            <ConfirmationModal
                show={successDialogOpen}
                handleClose={() => setSuccessDialogOpen(false)}
                handleConfirm={() => setSuccessDialogOpen(false)}
                title="Registracija sėkminga"
                content="Jūsų registracija buvo sėkminga."
                confirmText="Gerai"
            />
        </Container>
    );
};

export default ReservationPage;