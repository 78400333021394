import React, { Component } from 'react';


class ExamSideNav extends React.Component {
  render() {
    const color = "black";
    const backColor = "white";
    const pathname = window.location.pathname;
    const tyrejamsActive = pathname === '/tyrejams' ? { color: color, backgroundColor: backColor } : {}
    const meginiaiActive = pathname === '/meginiuteikimotvarka' ? { color: color, backgroundColor: backColor } : {}
    return (
      <div className="col-lg-3 mb-5 margin-nav">
        <div className="position-button size">
          <a href="/tyrejams" className="btn active-side-nav" style={tyrejamsActive}>Tyrėjams&nbsp; <i class="fa fa-caret-down" style={{ fontSize: "20px", float: "right", textAlign: "right", marginRight: "10px", wordBreak: "keep-all" }}></i> </a>
          <ul className="dropdown-padding">
            <li><a href="/meginiuteikimotvarka" className="btn" style={meginiaiActive}>Mėginių ir sveikatos informacijos teikimo tvarka</a></li>
            <li><a href="/registertypetyrejas" className="btn" >Registruotis tyrėjams</a></li>
          </ul>
        </div>
      </div>);

  }
}

export default ExamSideNav;