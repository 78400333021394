import React, { Component } from "react";
import AuthService from "../../services/auth.service-reikiaderinti";
import EventBus from "../../common/EventBus";

class MobileSideNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showExaminatorBoard: false,
      showInstitucijosBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showParticipantBoard: false,
      currentUser: sessionStorage.getItem("user"),
    };
  }

  componentDidMount() {
    document.getElementById("apievu").style.display = "none";
    document.getElementById("dalyviams").style.display = "none";
    document.getElementById("tyrejams").style.display = "none";
    document.getElementById("teisineinformacija").style.display = "none";
    document.getElementById("sklaida").style.display = "none";
    document.getElementById("dalyvioteises").style.display = "none";

    // var user = sessionStorage.getItem("user");
    var user = JSON.parse(this.state.currentUser);

    // console.log(user.userRole )
    // console.log(typeof user.userRole )
    // console.log(user.userRole === 1);
    if (user) {
      this.setState({
        // currentUser: user,
        // showParticipantBoard: user.roles.includes("ROLE_USER"),
        // showExaminatorBoard: user.roles.includes("ROLE_MODERATOR"),
        // showWorkerBoard: user.roles.includes("ROLE_WORKER"),
        // showAdminBoard: user.roles.includes("ROLE_ADMIN")
        showParticipantBoard: Number(user.roles[0]) === 1,
        showExaminatorBoard: Number(user.roles[0]) === 2,
        showInstitucijosBoard: Number(user.roles[0]) === 3,
        showSuperAdminBoard: Number(user.roles[0]) === 4,
        showAdminBoard: Number(user.roles[0]) === 5,
      });
    }

    // EventBus.on("logout", () => {
    //   this.logOut();
    // });
  }
  handleSideNavOpen() {
    document.getElementById("mySidenav").style.borderLeft = "solid";
    document.getElementById("mySidenav").style.borderTop = "solid";
    document.getElementById("mySidenav").style.borderColor = "black";
    document.getElementById("mySidenav").style.borderWidth = "2px";
    document.getElementById("mySidenav").style.width = "320px";
    document.body.style.overflowY = "hidden";
  }
  handleSideNavClose() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.overflowY = "scroll";
    document.getElementById("mySidenav").style.border = "hidden";
  }
  openAbout() {
    var value = document.getElementById("apievu").style.display;
    if (value === "none") {
      document.getElementById("apievu").style.display = "block";
      document.getElementById("dalyviams").style.display = "none";
      document.getElementById("tyrejams").style.display = "none";
      document.getElementById("apievuicon").className = "fa fa-caret-down";
      document.getElementById("dalyviamsicon").className = "fa fa-caret-right";
      document.getElementById("tyrejamsicon").className = "fa fa-caret-right";
    } else {
      document.getElementById("apievu").style.display = "none";
      document.getElementById("apievuicon").className = "fa fa-caret-right";
    }
  }
  openDalyviams() {
    var value = document.getElementById("dalyviams").style.display;
    if (value === "none") {
      document.getElementById("apievu").style.display = "none";
      document.getElementById("dalyviams").style.display = "block";
      document.getElementById("tyrejams").style.display = "none";
      document.getElementById("apievuicon").className = "fa fa-caret-right";
      document.getElementById("dalyviamsicon").className = "fa fa-caret-down";
      document.getElementById("tyrejamsicon").className = "fa fa-caret-right";
    } else {
      document.getElementById("dalyviams").style.display = "none";
      document.getElementById("dalyviamsicon").className = "fa fa-caret-right";
    }
  }
  openTyrejams() {
    var value = document.getElementById("tyrejams").style.display;
    if (value === "none") {
      document.getElementById("apievu").style.display = "none";
      document.getElementById("dalyviams").style.display = "none";
      document.getElementById("tyrejams").style.display = "block";
      document.getElementById("apievuicon").className = "fa fa-caret-right";
      document.getElementById("dalyviamsicon").className = "fa fa-caret-right";
      document.getElementById("tyrejamsicon").className = "fa fa-caret-down";
    } else {
      document.getElementById("tyrejams").style.display = "none";
      document.getElementById("tyrejamsicon").className = "fa fa-caret-right";
    }
  }
  openTeisine() {
    var value = document.getElementById("teisineinformacija").style.display;
    if (value === "none") {
      document.getElementById("teisineinformacija").style.display = "block";
      document.getElementById("sklaida").style.display = "none";
      document.getElementById("dalyvioteises").style.display = "none";
      document.getElementById("teisineicon").className = "fa fa-caret-down";
      document.getElementById("sklaidaicon").className = "fa fa-caret-right";
      document.getElementById("dalyvioteisesicon").className =
        "fa fa-caret-right";
    } else {
      document.getElementById("teisineinformacija").style.display = "none";
      document.getElementById("teisineicon").className = "fa fa-caret-right";
    }
  }
  openSklaida() {
    var value = document.getElementById("sklaida").style.display;
    if (value === "none") {
      document.getElementById("teisineinformacija").style.display = "none";
      document.getElementById("sklaida").style.display = "block";
      document.getElementById("dalyvioteises").style.display = "none";
      document.getElementById("teisineicon").className = "fa fa-caret-right";
      document.getElementById("sklaidaicon").className = "fa fa-caret-down";
      document.getElementById("dalyvioteisesicon").className =
        "fa fa-caret-right";
    } else {
      document.getElementById("sklaida").style.display = "none";
      document.getElementById("sklaidaicon").className = "fa fa-caret-right";
    }
  }

  openDalyvioTeises() {
    var value = document.getElementById("dalyvioteises").style.display;
    if (value === "none") {
      document.getElementById("teisineinformacija").style.display = "none";
      document.getElementById("sklaida").style.display = "none";
      document.getElementById("dalyvioteises").style.display = "block";
      document.getElementById("teisineicon").className = "fa fa-caret-right";
      document.getElementById("sklaidaicon").className = "fa fa-caret-right";
      document.getElementById("dalyvioteisesicon").className =
        "fa fa-caret-down";
    } else {
      document.getElementById("dalyvioteises").style.display = "none";
      document.getElementById("dalyvioteisesicon").className =
        "fa fa-caret-right";
    }
  }

  render() {
    const color = "black";
    const backColor = "white";
    const pathname = window.location.pathname;
    const apieActive =
      pathname === "/apievubio"
        ? { color: color, backgroundColor: backColor }
        : {};
    const {
      currentUser,
      showParticipantBoard,
      showExaminatorBoard,
      showInstitucijosBoard,
      showSuperAdminBoard,
      showAdminBoard,
    } = this.state;
    return (
      <>
        <div id="mySidenav" className="sidenav">
          <a
            href="#"
            className="closebtn x-style"
            onClick={this.handleSideNavClose}
          >
            ×
          </a>
          <div
            className="btn-group"
            style={{ width: "100%", marginRight: "0" }}
          >
            <a
              href="/apievubio"
              id="dropdown1"
              className="btn"
              style={{ width: "90%", marginLeft: "0" }}
            >
              Apie VU biobanką{" "}
            </a>
            <a
              href="#"
              id="dropdown1icon"
              className="btn before-dropdown"
              style={{ width: "10%" }}
              onClick={this.openAbout}
            >
              <i
                id="apievuicon"
                style={{ float: "left" }}
                className="fa fa-caret-right"
              ></i>
            </a>
          </div>
          <ul id="apievu" style={{ marginLeft: "5%" }}>
            <li>
              <a
                href="/tikslaimisija"
                className="btn"
                style={{ width: "100%", marginRight: "0" }}
              >
                Tikslai ir misija
              </a>
              <a
                href="/strukturakontaktai"
                className="btn"
                style={{ width: "100%", marginRight: "0" }}
              >
                Struktūra ir kontaktai
              </a>
              <a
                href="/meginiai"
                className="btn"
                style={{ width: "100%", marginRight: "0" }}
              >
                Saugomi mėginiai
              </a>
              <a
                href="/projektai"
                className="btn"
                style={{ width: "100%", marginRight: "0" }}
              >
                Projektai
              </a>
              {/* <a href="/registraiduomenys" className='btn' style={{width:"100%", marginRight: "0"}}>Registrai ir duomenų bazės</a> */}

              <div className="btn-group" style={{ width: "100%" }}>
                <a
                  href="#"
                  id="dropdown1"
                  className="btn"
                  style={{ width: "90%", marginLeft: "0" }}
                >
                  Teisinė informacija{" "}
                </a>
                <a
                  href="#"
                  id="dropdown1icon"
                  className="btn before-dropdown"
                  style={{ width: "10%" }}
                  onClick={this.openTeisine}
                >
                  <i id="teisineicon" className="fa fa-caret-right"></i>
                </a>
              </div>
              <ul id="teisineinformacija">
                <li>
                  <a
                    href="/licencija"
                    className="btn"
                    style={{ width: "100%", marginRight: "0" }}
                  >
                    Licencija
                  </a>
                  <a
                    href="/biobankoteises"
                    className="btn"
                    style={{ width: "100%", marginRight: "0" }}
                  >
                    Biobanko teisės
                  </a>
                  <a
                    href="/teisesaktai"
                    className="btn"
                    style={{ width: "100%", marginRight: "0" }}
                  >
                    Teisės aktai
                  </a>
                  <a
                    href="/duomenutvarkymas"
                    className="btn"
                    style={{ width: "100%", marginRight: "0" }}
                  >
                    Asmens duomenų tvarkymas
                  </a>
                  <a
                    href="/institucijos"
                    className="btn"
                    style={{ width: "100%", marginRight: "0" }}
                  >
                    Institucijos vykdančios Biobanko veiklos valstybinę
                    priežiūrą ir asmens duomenų tvarkymą
                  </a>
                </li>
              </ul>

              <div className="btn-group" style={{ width: "100%" }}>
                <a
                  href="#"
                  id="dropdown1"
                  className="btn"
                  style={{ width: "90%", marginLeft: "0" }}
                >
                  Sklaida{" "}
                </a>
                <a
                  href="#"
                  id="dropdown1icon"
                  className="btn before-dropdown"
                  style={{ width: "10%", marginLeft: "0" }}
                  onClick={this.openSklaida}
                >
                  <i id="sklaidaicon" className="fa fa-caret-right"></i>
                </a>
              </div>
              <ul id="sklaida">
                <li>
                  <a
                    href="/publikacijos"
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Publikacijos
                  </a>
                  <a
                    href="/mesziniasklaidoje"
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Mes žiniasklaidoje
                  </a>
                  <a
                    href="/renginiai"
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Renginiai
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <div className="btn-group" style={{ width: "100%" }}>
            <a
              href="/dalyviams"
              id="dropdown1"
              className="btn"
              style={{ width: "90%", marginLeft: "0" }}
            >
              Biobanko dalyviams{" "}
            </a>
            <a
              href="#"
              id="dropdown1icon"
              className="btn before-dropdown"
              style={{ width: "10%", marginLeft: "0" }}
              onClick={this.openDalyviams}
            >
              <i id="dalyviamsicon" className="fa fa-caret-right"></i>
            </a>
          </div>
          <ul id="dalyviams" style={{ marginLeft: "5%" }}>
            <li>
              <a href="/kaiptapti" className="btn" style={{ width: "100%" }}>
                Kaip tapti dalyviu?
              </a>
              <div className="btn-group" style={{ width: "100%" }}>
                <a
                  href="/dalyvioteises"
                  id="dropdown1"
                  className="btn"
                  style={{ width: "90%", marginLeft: "0" }}
                >
                  Dalyvio teisės{" "}
                </a>
                <a
                  href="#"
                  id="dropdown1icon"
                  className="btn before-dropdown"
                  style={{ width: "10%", marginLeft: "0" }}
                  onClick={this.openDalyvioTeises}
                >
                  <i id="dalyvioteisesicon" className="fa fa-caret-right"></i>
                </a>
              </div>
              <ul id="dalyvioteises">
                <li>
                  <a
                    href="/teiseatsauktikeisti"
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Teisė atšaukti sutikimą
                  </a>
                </li>
              </ul>
              <a href="/formos" className="btn" style={{ width: "100%" }}>
                Sutikimo ir atsisakymo formos
              </a>
              <a href="/duk" className="btn" style={{ width: "100%" }}>
                DUK
              </a>
            </li>
          </ul>

          <div className="btn-group" style={{ width: "100%" }}>
            <a
              href="/tyrejams"
              id="dropdown1"
              className="btn"
              style={{ width: "90%", marginLeft: "0" }}
            >
              Tyrėjams{" "}
            </a>
            <a
              href="#"
              id="dropdown1icon"
              className="btn before-dropdown"
              style={{ width: "10%", marginLeft: "0" }}
              onClick={this.openTyrejams}
            >
              <i id="tyrejamsicon" className="fa fa-caret-right"></i>
            </a>
          </div>
          <ul id="tyrejams" style={{ marginLeft: "5%", width: "100%" }}>
            <li>
              <a
                href="/meginiuteikimotvarka"
                className="btn"
                style={{ width: "100%" }}
              >
                Mėginių ir sveikatos informacijos teikimo tvarka
              </a>
              <a
                href="/registertypetyrejas"
                className="btn"
                style={{ width: "100%" }}
              >
                Registruotis tyrėjams
              </a>
            </li>
          </ul>

          {/* <div className='button-group'>
            <button className="btn" style={{width: "70%"}}>Action</button>
            <button className="btn dropdown-toggle dropdown-toggle-split before-dropdown" style={{width: "30%"}}>
              
            </button>
            </div> */}
          <a
            href="/naujienos"
            className="btn"
            style={{ width: "100%", marginLeft: "0" }}
          >
            Naujienos
          </a>
          <a
            href="/kontaktai"
            className="btn"
            style={{ width: "100%", marginLeft: "0" }}
          >
            Kontaktai
          </a>
          {showParticipantBoard && (
            <a
              href="/dalyvis/pranesimai/susiekti"
              className="btn"
              style={{ width: "100%", marginLeft: "0" }}
            >
              Dalyvio paskyra
            </a>
          )}
          {showExaminatorBoard && (
            <a
              href="/tyrejas/manoparaiskos"
              className="btn"
              style={{ width: "100%", marginLeft: "0" }}
            >
              Tyrėjo paskyra
            </a>
          )}
          {showInstitucijosBoard && (
            <a
              href="/darbuotojas/dalyviuinfo"
              className="btn"
              style={{ width: "100%", marginLeft: "0" }}
            >
              Darbuotojo paskyra
            </a>
          )}
          {showSuperAdminBoard && (
            <a
              href="/administratorius/tyreju-info-admin"
              className="btn"
              style={{ width: "100%", marginLeft: "0" }}
            >
              Super admino paskyra
            </a>
          )}
          {showAdminBoard && (
            <a
              href="/mini-admin/tyrejuinfo"
              className="btn"
              style={{ width: "100%", marginLeft: "0" }}
            >
              Admino paskyra
            </a>
          )}
        </div>
        <span
          className=""
          onClick={this.handleSideNavOpen}
          style={{ display: "inline-block" }}
        >
          <i className="fa fa-bars" style={{ fontSize: "2rem" }}></i>
        </span>
      </>
    );
  }
}

export default MobileSideNav;
