import React, { Component } from 'react';
import dalyvisService from '../services/dalyvis-service';
import tyrejasService from '../services/tyrejas-service';


class ApiTest extends React.Component {

    testApi() {
        var administerState = 1; // default submission
        var guranteeFileLink = "";
        // 1 - dalyvis
        // 2 - tyrejas
        // 3 - institucija
        // 4 - superadmin
        // 5 - admin
        var object1 ={
            "email": "institucijos@gmail.com",
            "password": "institucija123",
            "name": "LPRLB",
            "surname": "institucija",
            "workplace": "LPRLB",
            "phonenumber":"3700000000",
            "userRole": 3
        };
        var object2 ={
            "email": "superadminas@gmail.com",
            "password": "superadminas123",
            "name": "Super",
            "surname": "Adminas",
            "workplace": "LPRLB",
            "phonenumber":"3700000000",
            "userRole": 4
        };
        var object3 ={
            "email": "adminas@gmail.com",
            "password": "adminas123",
            "name": "Adminas",
            "surname": "Adminas",
            "workplace": "LPRLB",
            "phonenumber":"3700000000",
            "userRole": 5
        };
        dalyvisService.postPersonalDataObject(object1);
        dalyvisService.postPersonalDataObject(object2);
        dalyvisService.postPersonalDataObject(object3);
        // email,
        // password,
        // name,
        // surname,
        // workplace,
        // phoneNumber,
        // dalyvisService.putPersonalData( "deivid.caikin@gmail.com", "password123", "Name", "Surname", "LBRLB", "1111111111", "+70048689289","2", 1);
        // tyrejasService.putTestApplications(1,4);
        // dalyvisService.postSamples();
        // dalyvisService.getAllSamples();
        // dalyvisService.getForUsersamples();
        //dalyvisService.putSamples("dawda187afwfa65");
        //tyrejasService.postApplications(administerState,"deivid deivid","deivid deivid","deivid deivid","deivid deivid","deivid deivid","deivid deivid","deivid deivid","deivid deivid");

    }
    render() {
        return <div>
            <br/>
            <br/>
            <br/>
            
            <button className='btn col-centered' onClick={this.testApi}>Test current api</button>

        </div>;
    }
}

export default ApiTest;