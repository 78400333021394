import React, { Component } from 'react';
class Error extends React.Component {
    render() { 
        return (<div>
            

{/* Error Page */}
<section className="error-page section">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 offset-lg-3 col-12">
        {/* Error Inner */}
        <div className="error-inner">
          <h1>404<span>Puslapis Nerastas</span></h1>
          <p>Puslapis šiuo metu neveikia arba puslapis neegzistuoja!</p>
          <br/>
          <br/>
        </div>
        {/*/ End Error Inner */}
      </div>
    </div>
  </div>
</section>	
{/*/ End Error Page */}
</div>
);
        
    }
}
 
export default Error;