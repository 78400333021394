import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class Struktura extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-9 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    {/* Contact Form */}




                    <h2 className="thick">Struktūra</h2>
                    <div>
                      {/* Button trigger modal */}
                      <a type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img src="img/struktura.png" width="80%" alt="zoom" style={{ marginBottom: "50px" }} />
                      </a>
                      {/* Modal */}
                      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLongTitle">Struktūra</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              {/* <img className="zoom"  src="img/struktura.png" alt="struktūra" /> */}

                              <div className="img-zoom-container">
                                <img id="myimage" src="img/struktura.png" alt="struktūra" />
                                <div id="myresult" className="img-zoom-result col-centered" style={{ marginTop: "25px" }} />
                              </div>
                              {/* <Zoom>
                              <img
                                alt="that wanaka tree"
                                src="img/struktura.png"
                                width="1000"
                              />
                            </Zoom> */}


                              {/* <div class="zoom_outer">
                          <div id="zoom">
                            <img src="img/struktura.png" alt="zoom"/>
                          </div>
                        </div> */}


                            </div>

                          </div>
                        </div>
                      </div>
                    </div>


                  </div>


                </div>

                {/*/ End Contact Form */}
              </div>
            </div>




          </div>
        </div>

      </div>
    </div>


      ;
  }
}

export default Struktura;