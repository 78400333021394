import React from "react";
import dalyvisService from "../../../services/dalyvis-service";
import authService from "../../../services/auth-service";
import { Route } from "react-router-dom";
// import Dalyvis from "../../loginuser/participation-subpages/dalyvis-folder/dalyvis";
import UploadDocument from "../../loginuser/participation-subpages/uploaddocument";
import PartSideNav from '../../partsidenav'

class UserRegister extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            surname: "",
            personalCode: "",
            isMobile: ""
        };

        //this.changeButtonWord = this.changeButtonWord.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 991
            });
        }, false);
    }

    checkEmpty() {
        var name = document.getElementById("name").value;
        var surname = document.getElementById("surname").value;
        var personalcode = document.getElementById("personalCode").value;
        var email = document.getElementById("email").value;

        document.getElementById("submitButton").disabled = true;
        var continueState = true;
        var quantity = 0;
        var allItems = [name, surname, personalcode, email];
        //leidimas.value, ,applicationpdf,additionalinfo
        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i] === "" || allItems[i] === null) {
                continueState = false;

                break;
            }
            quantity += 1;
        }
        // console.log(personalcode.length);
        if (
            quantity === allItems.length &&
            continueState === true &&
            personalcode.length === 11
        ) {
            document.getElementById("submitButton").disabled = false;
        }
    }
    handleChangeNumber(event, id) {
        var value = event.target.value;
        if (!isNaN(value)) {
            this.setState({ [id]: event.target.value });
        }
    }
    handleChangeAlpha(event, id) {
        var value = event.target.value;
        if (/^[a-zA-Ząčęėįšųūž]+$/.test(value) || value === "") {
            this.setState({ [id]: event.target.value });
        }
    }

    generateUniqueCode(users) {
        let code = "VU";

        for (let i = 0; i < 8; i++) {
            code += Math.floor(Math.random() * 10); // Generates a random digit from 0 to 9
        }

        return code;
    }

    handleSubmitNewDalyvis(e) {
        e.preventDefault();
        var name = document.getElementById("name").value;
        var surname = document.getElementById("surname").value;
        var personalcode = document.getElementById("personalCode").value;
        var email = document.getElementById("email").value.trim().trim();

        var allItems = [name, surname, personalcode, email];

        var continueState = true;

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i] === "" || allItems[i] === null) {
                continueState = false;

                window.alert(
                    "Kai kurie langeliai yra nepilnai užpildyti arba nepasirinktas failas!"
                );
                break;
            }
            continueState = true;
        }

        var emailState = this.validateEmail(email);
        if (!emailState) {
            window.alert("Neteisingas elektroninio pašto formatas!");
            return;
        }

        dalyvisService
            .getAllUsers()
            .then((response) => {
                var users = Object.values(response);

                for (let i = 0; i < response.length; i++) {
                    if (response[i].email === email) {
                        continueState = false;
                        emailState = false;
                        break;
                    }
                }

                var uniqueCode = this.generateUniqueCode();

                for (let i = 0; i < response.length; i++) {
                    if (response[i].uniqueCode === uniqueCode) {
                        uniqueCode = this.generateUniqueCode();
                        i = 0;
                    }
                }

                if (continueState === true) {
                    console.log("Inside continue state----------------");
                    const password = authService.generatePassword(8);
                    console.log("Slaptažodis: " + password);
                    const role = ["ROLE_USER"];
                    //issiusti informacija i duombaze ---- Truksta dar vieno kintamojo duombazėj
                    //   dalyvisService
                    //     .postManualPersonalData(email, name, surname, personalcode)
                    //     .catch((error) => window.alert(error.message));

                    const newUser = {
                        email: email,
                        password: password,
                        role: role,
                        name: name,
                        surname: surname,
                        personalCode: personalcode,
                        uniqueCode: uniqueCode,
                        registerType: 1,
                    };

                    sessionStorage.setItem("regUser", JSON.stringify(newUser));
                    this.props.history.push("/registracija/uploaddocument");

                    // authService
                    //   .register(email, password, role)
                    //   .then(() => {
                    //     dalyvisService
                    //       .getAllUsers()
                    //       .then((response) => {
                    //         console.log(response);

                    //         const allUsers = response;
                    //         window.alert("Dalyvis užregistruotas");
                    //         window.alert("Elektroninis paštas: " + email);
                    //         window.alert("Slaptažodis: " + password);

                    //         if (allUsers !== null) {
                    //           allUsers.forEach((user) => {
                    //             if (user.email === email) {
                    //               dalyvisService
                    //                 .getAllPersonalData(user.id)
                    //                 .then((response) => {
                    //                   console.log(response);
                    //                   var newUser = response;

                    //                   newUser.name = name;
                    //                   newUser.surname = surname;
                    //                   newUser.personalCode = personalcode;
                    //                   newUser.uniqueCode = uniqueCode;
                    //                   newUser.registerType = 1;

                    //                   dalyvisService
                    //                     .putCustomObjectPersonalData(newUser.id, newUser)
                    //                     .catch((error) => window.alert(error.message));
                    //                 })
                    //                 .catch((error) => window.alert(error.message));
                    //             }
                    //           });
                    //         }
                    //       })
                    //       .catch((error) => window.alert(error.message));
                    //   })
                    //   .catch((error) => window.alert(error.message));
                } else if (!emailState) {
                    window.alert("Emailas jau panaudotas. Pasirinkite kitą");
                } else {
                    window.alert("Neatpažinta klaida");
                }
            })
            .catch((error) => window.alert(error.message));
    }
    validateEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    render() {
        const pathname = window.location.pathname;
        const isNotRegistered =
            pathname === "/registracija" ||
            pathname === "/registracija*";

        const isMobile = this.state.isMobile ? 'row' : 'row mx-5';

        return (
            <div className="container-fluid" >

                <div className={isMobile} style={{marginTop:"25px", marginBottom: "50px"}}>
                    <PartSideNav/>
                    <div className="col-lg-6 col-6">
                        {isNotRegistered ? (
                            <>
                                <h2
                                    style={{
                                        marginBottom: "25px",
                                        paddingTop: "50px",
                                        fontSize: "2rem",
                                    }}
                                >
                                    Užregistruoti naują dalyvį
                                </h2>

                                <form
                                    className="form col-lg-8 col-md-8 border-edit-button px-1"
                                    style={{ fontSize: "20px", paddingBottom: "75px" }}
                                    onInput={(event) => this.checkEmpty(event)}
                                    onChange={(event) => this.checkEmpty(event)}
                                    onSubmit={(e) => this.handleSubmitNewDalyvis(e)}
                                >
                                    <div className="row">
                                        <div
                                            className="col-lg-6 col-md-6"
                                            style={{ marginTop: "25px" }}
                                        >
                                            <label>Vardas</label>
                                            <div className="input-group mb-2">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder="Vardenis"
                                                    value={this.state.name}
                                                    onChange={(event) =>
                                                        this.handleChangeAlpha(event, "name")
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="col-lg-6 col-md-6"
                                            style={{ marginTop: "25px" }}
                                        >
                                            <label>Pavardė</label>
                                            <div className="input-group mb-4">
                                                <input
                                                    type="text"
                                                    id="surname"
                                                    className="form-control"
                                                    placeholder="Pavardenis"
                                                    value={this.state.surname}
                                                    onChange={(event) =>
                                                        this.handleChangeAlpha(event, "surname")
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="col-lg-12 col-md-12"
                                            style={{ marginTop: "25px" }}
                                        >
                                            <label>Asmens kodas</label>
                                            <div className="input-group mb-4">
                                                <input
                                                    type="text"
                                                    id="personalCode"
                                                    className="form-control"
                                                    placeholder="90050050080"
                                                    value={this.state.personalCode}
                                                    maxLength={11}
                                                    onChange={(value) =>
                                                        this.handleChangeNumber(value, "personalCode")
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col-lg-8 col-md-8"
                                            style={{ marginTop: "25px" }}
                                        >
                                            <label>Elektroninis paštas</label>
                                            <div className="input-group mb-4">
                                                {/* <div className="input-group-prepend">
                                  <div className="input-group-text">@</div>
                                </div> */}
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="elektroninis@pastas.com"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-centered">
                                        <button
                                            className="btn col-lg-6 col-md-6 col-sm-12"
                                            id="submitButton"
                                            type="submit"
                                            style={{ float: "right", marginTop: "25px" }}
                                            disabled
                                        >
                                            Pateikti
                                        </button>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <></>
                        )}
                        <Route
                            path={`${this.props.match.path}/uploaddocument`}
                            component={UploadDocument}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default UserRegister;