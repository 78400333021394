import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'
import KontaktaiList from '../../kontaktailist.jsx';
class StrukturaKontaktai extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-9 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    {/* Contact Form */}





                    <h2 className="thick">Kontaktai </h2>
                    <h2 className="thick" style={{ fontSize: "18px" }}>Biobanko darbuotojų atsakomybių sritys ir jų kontaktinė informacija: </h2>
                    <KontaktaiList />

                  </div>


                </div>

                {/*/ End Contact Form */}
              </div>
            </div>




          </div>
        </div>

      </div>
    </div>


      ;
  }
}

export default StrukturaKontaktai;