import React from 'react';
import PartSideNav from '../../partsidenav.jsx'

class TeiseAtsaukti extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''
      
    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 991
        });
    }, false); 
}
    render() { 
        const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';
        
        return <div>
            
                
                {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}
                
                
              
        <div className="container-fluid" >
       
          <div className={mobileornot} style={{marginTop:"25px", marginBottom: "50px"}}>
            <PartSideNav/>
            <div className="col-lg-9 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                      <div className="row mx-1">
                        <h2 className="thick">Teisė atšaukti sutikimą  </h2>
                        
                        <ul className="listStyleText">
                          <li>Jūs bet kada galite atšaukti ar keisti  savo Sutikimą dalyvauti Biobanko veikloje 
                            nenurodydamas priežasčių ar motyvų.</li>
                          <li>Norėdamas (-a) atšaukti Sutikimą dalyvauti Biobanko veikloje turėsite užpildyti <a className="hrefStyle" href="/formos">Sutikimo/atšaukimo
                            formą </a> ir pasirašyta atsiųsti ją Biobankui (skenuotą ar fotografuotą el.paštu 
                           info.biobankas@mf.vu.lt ar popierinę adresu LPRLB, M.K.Čiurlionio g. 21, Vilnius 03101).</li>
                          <li>Norėdamas (–a) pakeisti Sutikime pateiktus asmens duomenis ar pasinaudoti kitomis Jums 
                            priklausančiomis teisėmis dėl <a className="hrefStyle" href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/85c525f37a6f11eaa38ed97835ec4df6">Jūsų asmens duomenų tvarkymo</a>, turėsite parašyti laisvos formos prašymą ir pasirašyta atsiųsti jį 
                            Biobankui (skenuotą ar fotografuotą el.paštu info.biobankas@mf.vu.lt ar popierinę adresu LPRLB, M.K.Čiurlionio 
                            g. 21, Vilnius 03101).</li>
                          
                        </ul>
                        
                      </div>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
     
      
        </div>;
    }
}
 
export default TeiseAtsaukti;