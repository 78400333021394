import React from 'react';
import PartSideNav from '../../partsidenav.jsx'

class Formos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''
      
    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 991
        });
    }, false); 
}
    render() { 
        const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';
        
        return <div>
            
                
                {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}
                
                
              
        <div className="container-fluid" >
       
          <div className={mobileornot} style={{marginTop:"25px", marginBottom: "50px"}}>
            <PartSideNav/>
            <div className="col-lg-9 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                      <div className="row mx-1">
                      <h2 className="thick"> Sutikimo ir atsisakymo formos  </h2>
                        <ul className="listStyleText">
                          <li><i><a className="hrefStyle" href="/forma1">Asmens informavimo forma (IAF) </a></i></li>
                          <li><i><a className="hrefStyle" href="/forma2">Informuoto asmens sutikimo forma dalyviui (IASF)</a></i></li>
                          <li><i><a className="hrefStyle" href="/forma3">Informuoto asmens sutikimo forma globotiniui (IASF)</a></i></li>
                          <li><i><a className="hrefStyle" href="/forma4">Atsisakymo forma</a></i></li>
                        </ul>
                      </div>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
     
      
        </div>;
    }
}
 
export default Formos;