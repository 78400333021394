import React from 'react';
import { Form } from 'react-bootstrap';

const ServiceTypeSelector = ({ services, selectedService, onSelect }) => {
    return (
        <Form.Group controlId="serviceSelect">
            <Form.Label>Pasirinkite paslaugą</Form.Label>
            <Form.Control
                as="select"
                value={selectedService}
                onChange={(e) => onSelect(e.target.value)}
            >
                <option value="">Pasirinkite paslaugą...</option>
                {services.map((service) => (
                    <option key={service} value={service}>
                        {service}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default ServiceTypeSelector;