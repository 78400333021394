import React, { Component } from 'react';
// import MapContainer from '../components/googlemaps.jsx'
import KontaktaiList from './kontaktailist';

const mapStyles = {
  width: '100%',
  height: '100%'
};

class Contacts extends React.Component {
    render() { 
        return <div>
            <section className="news-single section">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Kontaktai</h2>
                <img src="img/section-img.png" alt="#" />
                <p></p>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                    {/* Contact Form */}
                    
                      <div className="row mx-5" style={{marginBottom:'75px'}}>
                        {/* <div style={{height:"500px",marginBottom:"25px"}}><MapContainer /></div> */}
                        <KontaktaiList/>
                            
                      </div>
                    
                    {/*/ End Contact Form */}
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
        </div>;
    }
}
 
export default Contacts;