import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import document1 from "../../../../docs/important/Informacija_apie_LPRLB_Dalyviui.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Dalyvis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      divWidth: 0,
    };
    this.divRef = React.createRef();
  }

  componentDidMount() {
    this.updateDivWidth();
    window.addEventListener("resize", this.updateDivWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDivWidth);
  }

  updateDivWidth = () => {
    if (this.divRef.current) {
      this.setState({ divWidth: this.divRef.current.offsetWidth });
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { numPages } = this.state;

    return (
      <>
        {/* <embed
          src={document1}
          type="application/pdf"
          width="100%"
          height="800px"
        /> */}
        <div
          className="d-flex justify-content-center"
          ref={this.divRef}
          style={{
            width: "100%",
            overflow: "auto",
            height: "80vh",
            border: "5px solid #78003f",
          }}
        >
          <Document
            file={document1}
            onLoadSuccess={this.onDocumentLoadSuccess}
            className="d-flex flex-column align-items-center"
            options={{
              cMapUrl: "cmaps/",
              cMapPacked: true,
            }}
          >
            {Array.from({ length: numPages }, (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={this.state.divWidth}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className="my-0"
              />
            ))}
          </Document>
        </div>
        <a
          href="/darbuotojas/dalyviu-reg/uploaddocument"
          className="btn col-lg-6 col-md-8 col-sm-12"
          style={{
            display: "block",
            margin: "0 auto",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          onClick={this.changeStateDalyvis2}
        >
          Noriu dalyvauti biobanko veikloje
        </a>
      </>
    );
  }
}

export default Dalyvis;
