import React from 'react';
class News extends React.Component {
    render() { 
        return (<div>
        {/* Start Blog Area */}
        <section className="blog section" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>Biobanko naujienos</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog1.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="blog-single.html">Naujiena apie 1</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog2.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 2</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog3.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 3</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog3.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 4</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog3.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 5</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* Single Blog */}
                <div className="single-news">
                  <div className="news-head">
                    <img src="img/blog3.jpg" alt="#" />
                  </div>
                  <div className="news-body">
                    <div className="news-content">
                      <div className="date">2020 spalio 22</div>
                      <h2><a href="#">Naujiena 6</a></h2>
                      <p className="text">Biobankų veiklą Lietuvoje reglamentuoja Biomedicininių tyrimų etikos įstatymas. Remiantis šiuo
                        įstatymu, žmogaus biologiniai mėginiai ir sveikatos informacija gali būti saugomi ir tvarkomi
                        ateities moksliniams tyrimams.</p>
                    </div>
                  </div>
                </div>
                {/* End Single Blog */}
              </div>
            </div>
          </div>
        </section>
        {/* End Blog Area */}



        </div>);
    }
}
 
export default News;
