import React from 'react';
import { Form } from 'react-bootstrap';

const SpecialistSelector = ({ specialists, selectedSpecialist, onSelect }) => {
    return (
        <Form.Group controlId="specialistSelect">
            <Form.Label>Pasirinkite specialistą</Form.Label>
            <Form.Control
                as="select"
                value={selectedSpecialist}
                onChange={(e) => onSelect(e.target.value)}
            >
                <option value="">Pasirinkite specialistą...</option>
                {specialists.map((specialist) => (
                    <option key={specialist.id} value={specialist.id}>
                        {specialist.name}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export default SpecialistSelector;