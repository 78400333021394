import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'
import document1 from "../../../docs/important/1_Biobanko_asmens_duomenu_tvarkymo_taisykles.docx"

class AsmensDuomenys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Asmens duomenų tvarkymas</h2>

                    <ul className="listStyleText">
                      <li>Biobankas gautam žmogaus biologiniam mėginiui ir (ar) sveikatos informacijai suteikia kodą, o asmens duomenis, leidžiančius nustatyti asmens, kurio biologiniai mėginiai ir (ar) sveikatos informacija tvarkomi Biobanke, tapatybę, tvarko atskirai, užtikrindamas galimybę identifikuoti konkretų asmenį.</li>
                      <li>Biobanko veikloje dalyvaujančių asmenų asmens duomenys yra prieinami tik įgaliotiems Biobanko darbuotojams, kurie raštu įsipareigoja saugoti asmens duomenų paslaptį.</li>
                      <li>Susipažinti su duomenų subjektų teisėmis ir jų įgyvendinimo tvarka galite <a download href={document1} className="hrefStyle" >čia</a>  </li>
                    </ul>
                    <h4 className="thick" style={{ marginTop: "25px", fontSize: "15px", fontWeight: "600" }}>LPRLB duomenų apsaugos specialisto kontaktai</h4>
                    <ul className="listStyleText">
                      <li>Mark Siavris, <a href="#" className="hrefStyle">mark.siavris@mf.vu.lt</a></li>

                    </ul>





                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default AsmensDuomenys;