import axios from "axios";
import authHeader from "./auth-header.js";
import questionnaire from "../components/user-pages/dalyvis-subpages/klausimynas/Questionnaire";

const API_URL = "https://covid19.mf.vu.lt/proxy/api";

class DalyvisService {
  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  userSet(userId) {
    return this.getPersonalData(userId).then((user) => {
      sessionStorage.setItem("user", JSON.stringify(user));
    });
  }
  async postFileUpload(file) {
    return await axios
      .post(API_URL + "/files/uploads", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((response) => {
        return response.data;
      });
  }

  async getAllFiles() {
    return await axios.get(API_URL + "/files/list").then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  async getFile(filename) {
    return await axios.get(API_URL + "/files/download/" + filename).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  async postQuestionnaire(questionnaire)
  {
      return await axios.post(API_URL + "/questionnaire", questionnaire).then((response) => {
          return response.data;
      });
  }

  async getAllUsers() {
    return await axios.get(API_URL + "/users").then((response) => {
      return response.data;
    });
  }

  async getAllPersonalData(id) {
      return await axios.get(API_URL + "/users/" + id).then((response) => {
      return response.data;
    });
  }
  async postPersonalData(
    email,
    password,
    name,
    surname,
    workplace,
    phoneNumber
  ) {
    return await axios
      .post(API_URL + "/users", {
        email,
        password,
        name,
        surname,
        workplace,
        phoneNumber,
        uniqueCode: this.makeid(10),
        userRole: 2,
      })
      .then((response) => {
        // if (response.data.accessToken) {
        //     localStorage.setItem("user", JSON.stringify(response.data));

        // }
        return response.data;
      });

    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }
  async postPersonalDataObject(object) {
    return await axios.post(API_URL + "/users", object).then((response) => {
      return response.data;
    });
  }
  async postManualPersonalData(id, email, name, surname, personalCode) {
    return await axios
      .post(API_URL + "/users", {
        email,
        name,
        surname,
        personalCode,
        firstTime: 1,
        userRole: 1,
        password: "password123",
        register_type: 0,
        uniqueCode: this.makeid(20),
        createdAt: new Date(),
      })
      .then((response) => {
        this.userSet(id).then((response) => {
          window.location.reload();
        });

        return response.data;
      });
  }
  async postRegisterPersonalRepres(
    email,
    name,
    surname,
    personalCode,
    name1,
    surname1,
    personalcode1
  ) {
    return await axios
      .post(API_URL + "/users", {
        email,
        name,
        surname,
        personalCode,
        firstTime: 1,
        createdAt: new Date(),
        userRole: 1,
        register_type: 1,
        uniqueCode: this.makeid(20),
      })
      .then((response) => {
        axios
          .post(API_URL + "/representedpeople", {
            name: name1,
            surname: surname1,
            personalCode: personalcode1,
            addedAt: new Date(),
          })
          .then((data) => {
            // if (response.data.accessToken) {
            // localStorage.setItem("user", JSON.stringify(response.data));
            // this.userSet(userId);
            this.relationshipRepresented(response.data.id, data.data.id);
            // }
            return response.data;
          });
      });
  }
  async putPostAddPersonalRepres(
    id,
    objectUser,
    name1,
    surname1,
    personalcode1
  ) {
    return axios.put(API_URL + "/users/" + id, objectUser).then((response) => {
      axios
        .post(API_URL + "/representedpeople", {
          name: name1,
          surname: surname1,
          personalCode: personalcode1,
          addedAt: new Date(),
        })
        .then((data) => {
          // if (response.data.accessToken) {
          // localStorage.setItem("user", JSON.stringify(response.data));
          // this.userSet(userId);
          this.relationshipRepresented(response.data.id, data.data.id).then(
            (response) => {
              this.userSet(id).then((response) => {
                window.location.reload();
              });
            }
          );

          // }
          return response.data;
        });
    });
  }
  async putCustomObjectRepresented(id, object) {
    return axios
      .put(API_URL + "/representedpeople/" + id, object)
      .then((response) => {
        // if (response.data.accessToken) {

        this.userSet(id).then((response) => {
          window.location.reload();
        });

        // }
        return response.data;
      });
  }
  async putCustomObjectPersonalData(id, object) {
    return await axios
      .put(API_URL + "/users/" + id, object)
      .then((response) => {
        return response.data;
      });
  }

  async getPersonalData(id) {
    return axios.get(API_URL + "/users/" + id).then((response) => {
      // if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));

      // }
      return response.data;
    });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }

  async putPersonalData(
    email,
    password,
    name,
    surname,
    workplace,
    personalCode,
    phoneNumber,
    firstTime,
    userId
  ) {
    console.log(API_URL + "/users/" + userId);
    return axios

      .put(API_URL + "/users/" + userId, {
        email,
        name,
        surname,
        workplace,
        personalCode,
        phoneNumber,
        firstTime,
      })
      .then((response) => {
        // if (response.data.accessToken) {
        //     localStorage.setItem("user", JSON.stringify(response.data));

        // }
        // this.userSet(userId).then((response) => {
        //   window.location.reload();
        // });
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }

  async postSamples(sampleCode, addedAt, sampleType, institution, sampleUsed) {
    // turetu turet ID backende
    return axios
      .post(API_URL + "/samples", {
        sampleCode,
        addedAt,
        sampleType,
        institution,
        sampleUsed,
      })
      .then((response) => {
        this.relationshipSampleUser(response.data).then((response) => {});

        return response.data;
      });
  }

  async getForUsersamples(id) {
    return axios.get(API_URL + "/samples/" + id).then((response) => {
      // if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));
      // }
      return response.data;
    });
  }
  async getAllSamples() {
    return axios.get(API_URL + "/samples").then((response) => {
      // if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));

      // }
      return response.data;
    });
  }

  async putSamples(id, sampleCode) {
    // greičiausiai reikės tik atnaujinant steitą
    return axios
      .put(API_URL + "/samples/" + id, {
        sampleCode,
      })
      .then((response) => {
        this.userSet(id).then((response) => {
          window.location.reload();
        });
        return response.data;
      });
  }

  async getAllApplications() {
    return axios.get(API_URL + "/application").then((response) => {
      return response.data;
    });
  }

  getAllByIdMessages(id) {
    return axios.get(API_URL + "/message/" + id).then((response) => {
      // if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));

      // }
      return response.data;
    });
  }

  async getAllMessages() {
    return await axios.get(API_URL + "/message").then((response) => {
      // if (response.data.accessToken) {
      //     localStorage.setItem("user", JSON.stringify(response.data));

      // }
      return response.data;
    });
  }

  async postMessage(message) {
    return await axios
      .post(API_URL + "/message", message)
      .then((response) => {
        return response.data;
        // this.relationshipMessageUser(response.data.id, id).then((response) => {
        //   // this.userSet(id).then((response) => {
        //   //   window.location.reload();
        //   // });
        //   window.location.reload();
        // });

        // this.relationshipMessageUser(response.data.id, receiverID).then(
        //   (response) => {
        //     // this.userSet(receiverID).then(response => { window.location.reload() });
        //     // window.location.reload();
        //   }
        // );

        // window.location.reload();
        // if (response.data.accessToken) {
        //     localStorage.setItem("user", JSON.stringify(response.data));

        // }
        // return response.data;
      });
  }
  messagesSetSeen() {}

  postPersonalDataAfterFirstLogin(
    name,
    surname,
    personalcode,
    phonenumber,
    firsttime
  ) {
    return axios
      .post(API_URL + "/personaldata", {
        name,
        surname,
        personalcode,
        phonenumber,
        firsttime,
      })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }

  postRepresentedData(id, name, surname, personalCode, addedAt) {
    return axios
      .post(API_URL + "/representedpeople", {
        name,
        surname,
        personalCode,
        addedAt,
      })
      .then((response) => {
        // if (response.data.accessToken) {
        // localStorage.setItem("user", JSON.stringify(response.data));

        this.relationshipRepresented(id, response.data.id).then((response) => {
          this.userSet(id).then((response) => {
            window.location.reload();
          });
        });

        // }
        return response.data;
      });
    //     localStorage.setItem("user", JSON.stringify(user));
    //    return user;
  }

  getRepresented(id) {
    return axios.get(API_URL + "/representedpeople/" + id).then((response) => {
      return response.data;
    });
  }

  postDocfile(id, filename, fileLink, fileType, addedAt) {
    return axios
      .post(API_URL + "/docfile", {
        filename,
        fileLink,
        fileType,
        addedAt,
      })
      .then((response) => {
        console.log(response.data.id);
        this.relationshipDocFileUser(id, response.data.id).then((response) => {
          this.userSet(id).then((response) => {
            window.location.reload();
          });
        });
        // this.userSet(userId);

        return response.data;
      });
  }
  postNoRelationshipDocfilePostNoRelationship(
    id,
    filename,
    fileLink,
    fileType,
    addedAt
  ) {
    return axios
      .post(API_URL + "/docfile", {
        filename,
        fileLink,
        fileType,
        addedAt,
      })
      .then((response) => {
        this.userSet(id).then((response) => {});
        return response.data;
      });
  }
  deleteFile(filename) {
    return axios.delete(API_URL + "/files/delete/" + filename).then((response) => {
      // this.userSet(id).then((response) => {
      //   window.location.reload();
      // });
      window.location.reload();
      return response.data;
    });
  }

  async getAllDocfile() {
    return await axios.get(API_URL + "/docfile").then((response) => {
      console.log("getAllDocfile");
      console.log(response.data);
      sessionStorage.setItem("docfiles", JSON.stringify(response.data));
      return response.data;
    });
  }
  relationshipDocFileUser(userId, docfileId) {
    return axios
      .post(API_URL + "/userdocfile", {
        userId,
        docfileId,
      })
      .then((response) => {
        return response.data;
      });
  }
  relationshipSampleUser(id) {
    console.log("relationship");
    return axios.get(API_URL + "/api/samples/" + id, {
      id,
    });
  }
  relationshipMessageUser(messageId, receiverId) {
    return axios.post(API_URL + "/messagereceiver", {
      messageId,
      receiverId,
    });
  }
  relationshipRepresented(userId, representedpeopleId) {
    return axios.post(API_URL + "/representedpeopleuser", {
      userId,
      representedpeopleId: representedpeopleId,
    });
  }
}

export default new DalyvisService();
