import React from "react";
import dalyvisService from "../../../services/dalyvis-service";

class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      docfiles: [],
      user: JSON.parse(sessionStorage.getItem("user")),
    };

    // });

    //this.fetchData();
  }

  componentDidMount() {
    dalyvisService
      .getAllFiles()
      .then((response) => {
        this.setState({
          docfiles: response,
          loading: false,
        });
      })
      .catch((error) => window.alert(error.message));
  }

  async fetchData() {
    await dalyvisService
      .getAllFiles((response) => {
        var files = response;
        files = files
          .filter((data) => data.name.startsWith(this.state.user.workplace))
          .sort((a, b) => {
            const dateA = new Date(
              a.split("-").slice(1).join("-").split(".")[0]
            );
            const dateB = new Date(
              b.split("-").slice(1).join("-").split(".")[0]
            );
            return dateA - dateB;
          });

        this.setState({
          docfiles: files,
          loading: false,
        });
      })
      .catch((error) => window.alert(error.message));
    //sessionStorage.setItem("docfiles", JSON.stringify(docfiles));
  }

  deleteForm(e, filename) {
    e.preventDefault();
    if (
      window.confirm("Ar tikrai norite ištrinti formą: '" + filename + "'?")
    ) {
      dalyvisService
        .deleteFile(filename)
        .catch((error) => window.alert(error.message));
      setTimeout(this.forceUpdate(), 1000);
    } else {
      //canceled
    }
  }

  downloadFile(e, fileName) {
    e.preventDefault();

    const url = "https://covid19.mf.vu.lt/proxy/api/files/download/" + fileName;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const pdfBlob = new Blob([blob], { type: "application/pdf" });

        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.error("Error downloading the file:", error));
  }

  render() {
    // let samplesfromdatabase = [
    //     { "id": "1", "link": "https://link.com/1GSFAFIASFgusafas1231GSFAFIASFgusafas1231GSFAFIASFgusafas123", "filename": "forma1" },
    //     { "id": "2", "link": "https://link.com/2GSFAFIASFgusafas123", "filename": "forma2 forma2 forma2 " },
    //     { "id": "3", "link": "https://link.com/3GSFAFIASFgusafas123", "filename": "forma3" },
    //     { "id": "4", "link": "https://link.com/4GSFAFIASFgusafas123", "filename": "forma4" }
    // ];
    const { docfiles, loading } = this.state;
    //JSON.parse(sessionStorage.getItem("docfiles"));
    // console.log(pdfs);
    const samples = docfiles
      .filter((file) => file.endsWith(".pdf"))
      .filter((file) => file.startsWith(`${this.state.user.workplace}-`))
      .map((data) => {
        return (
          <>
            <tr>
              {/* <td>#{data.id}</td> */}
              <th scope="row">{data} </th>
              <td>
                {/* <a className="btn" href={data.url} download>
                  <i class="fa fa-download" style={{ fontSize: "24px" }}></i>
                </a> */}
                <button
                  className="btn"
                  onClick={(e) => this.downloadFile(e, data)}
                >
                  <i class="fa fa-download" style={{ fontSize: "24px" }}></i>
                </button>
              </td>
              <td>
                <button
                  id={data}
                  className="btn"
                  // onClick={(e) => this.deleteForm(e, data.name, data.id)}
                  onClick={(e) => this.deleteForm(e, data)}
                >
                  <i className="fa fa-trash-o" style={{ fontSize: "24px" }}></i>
                </button>
              </td>
            </tr>
          </>
        );
      });

    return (
      <>
        {loading ? (
          <>
            <div className="d-flex justify-content-center" style={{}}>
              <div
                className="spinner-grow d-flex justify-content-center"
                style={{
                  width: "20rem",
                  height: "20rem",
                  marginTop: "80px",
                  marginBottom: "80px",
                  color: "#78003f",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <h3 style={{ marginBottom: "25px" }}>
                Mėginių perdavimo formų archyvas
              </h3>
              <div
                className="col-12 col-centered"
                tyle={{ marginBottom: "50px" }}
              >
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Numeris</th>
                      <th scope="col">Mėginių perdavimo forma</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      whiteSpace: "initial",
                      overflow: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {React.Children.toArray(samples)}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Forms;
