import React from 'react';
import PartSideNav from '../../partsidenav.jsx'

class DUK extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''
      
    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 991
        });
    }, false); 
}
    render() { 
        const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';
        
        return <div>
            
                
                {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}
                
                
              
        <div className="container-fluid" >
       
          <div className={mobileornot} style={{marginTop:"25px", marginBottom: "50px"}}>
            <PartSideNav/>
            <div className="col-lg-9 col-12" >
              <div className="row">
                <div className="col-12">
                  <div className="comments-form">
                    
                      <div className="row mx-1">
                        <h2 className="thick">DUK </h2>
                        <h2 className="thick" style={{fontSize: "22px"}}>Kokie duomenys ir mėginiai saugomi Biobanke? </h2>
                        <h2 className="thick" style={{fontSize: "18px"}}>Biobanke neterminuotai bus saugoma ši koduota informacija:</h2>
                        <ol className="listStyleText">
                          <li>Asmens (Biobanko dalyvio) pateikta informacija atsakant į klausimyno klausimus 
                            (apie sveikatą, įpročius, dietą, gyvenimo būdą ir kt.);</li>
                          <li>Antropometriniai duomenys (ūgis, svoris ir kt.) bei tyrimų duomenys 
                            (kraujo spaudimas ir kt.);</li>
                          <li>Sveikatos informacija, kuri bus periodiškai atnaujinama iš Registrų centro Gyventojų 
                            registro, Privalomojo sveikatos draudimo informacinės sistemos (SVEIDRA), Elektroninės 
                            sveikatos paslaugų ir bendradarbiavimo infrastruktūros informacinės sistemos (ESPBI IS).</li>
                          <li>Biologiniai mėginiai: kraujo plazma ir serumas, kraujo ląstelės, kraujo DNR ir RNR, odos fibroblastai, kitų audinių biopsijos, šlapimas.</li>
                        </ol>
                        <ul className="listStyleText">
                          <li>Visa aukščiau išvardinta Biobanke saugoma informacija ir mėginiai mokslininkams ir kitiems tyrėjams bus pateikiama 
                            koduota, be galimybės nustatyti, kieno mėginiai ar sveikatos informacija bus tiriama. Tik Biobanko atsakingas 
                            darbuotojas, esant būtinybei, turės galimybę susieti kodutą informaciją su asmens duomenimis.</li>
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Kokiuose moksliniuose tyrimuose bus panaudoti Jūsų duomenys? </h2>
                        <ul className="listStyleText">
                          <li>Jūsų sveikatos informacija ir biologiniai mėginiai bus panaudojami moksliniuose tyrimuose, 
                            kurie bus vykdomi ateityje. Apie Jūsų mėginių ir sveikatos informacijos panaudojimą moksliniuose 
                            projektuose galėsite sužinoti savo asmeninėje <a className="hrefStyle" href="/login">dalyvio paskyroje </a>  arba VU LPRLB Biobanko 
                            puslapio skiltyje apie Biobanko vykdomus <a className="hrefStyle" href="/projektai">biomedicininius tyrimus </a>.</li>
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Kaip užtikrinamas duomenų konfidencialumas? </h2>
                        <ul className="listStyleText">
                          <li>Biobankas yra atsakingas už saugomų mėginių ir su jais susijusios informacijos konfidencialumo išsaugojimą ir tvarkymą įstatymų nustatyta tvarka.</li>
                          <li>Konfidencialumą užtikrina visi fiziniai ir juridiniai asmenys, kurie naudojasi Biobanko teikiama sveikatos informacija.</li>
                          <li>Pasirašius <a className="hrefStyle" download href="/sutikimas">sutikimą </a> Jums bus suteiktas unikalus Biobanko dalyvio kodas. Susieti koduotą informaciją su asmeniu turi teisę tik už tai atsakingas Biobanko darbuotojas.</li>
                          <li>Jūsų koduota sveikatos informacija ir biologiniai mėginiai bus laikomi Biobanko saugyklose.</li>
                          <li>Biobanko serveriuose ir duomenų saugyklose yra įdiegtos techninės, programinės bei organizacinės priemonės užtikrinančios duomenų saugumą. Serveriuose yra apsauga nuo automatizuotų programišių atakų, naudojama serverių ugniasienė ir kt.. Fizinė prieiga prie sistemų leidžiama tik įgaliotiems asmenims, atsižvelgiant į asmeniui priskirtas užduotis ir atsakomybės sritis ir tik asmenims, kurie yra tinkamai išmokyti ir prižiūrimi. Vykdoma nuolatinė serverių priežiūra ir monitoringas, užtikrinant programų nuolatinį atnaujinimą ir serverio veiklos žurnalų tikrinimą. Visi dalyvių duomenų analizės darbai vykdomi tik su koduotais duomenimis.</li>
                          <li>Koduotų mėginių ir sveikatos informacijos perdavimą tyrėjams svarsto Biobanko mokslinis komitetas.</li>
                          <li>Prieiga prie koduotų Biobanke saugomų mėginių ir sveikatos informacijos Lietuvos ar užsienio tyrėjams yra suteikiama tik įvertinus tyrėjų paraišką, mokslinio darbo aktualumą ir naudą, bei gavus Lietuvos bioetikos komiteto leidimą dėl Biobanke tvarkomų žmogaus biologinių mėginių ir (ar) sveikatos informacijos teikimo.</li>
                          <li>Mokslininkams ir tyrėjams bus perduodami tik koduoti duomenys ir mėginiai. </li>
                          <li>Pagal užkoduotus mėginius ir sveikatos duomenis Jūsų asmens tapatybę nustatyti bus neįmanoma.</li>
                          <li>Vadovaujantis Lietuvos Respublikos teisės aktais, teismo sprendimu Biobanke saugoma informacija galės būti prieinama ir ne su Biobanko veikla susijusiems asmenims.</li>
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Kokią informaciją apie biologinius mėginius ir sveikatą galėsite gauti? </h2>
                        <ul className="listStyleText">
                          <li>Atliekant biomedicininius tyrimus su Jūsų mėginiais ar sveikatos informacija, Jums bus pranešami 
                            tik tie tyrimų rezultatai, kurie turės svarbią įtaką Jūsų sveikatai pagal Lietuvos Respublikos 
                            sveikatos apsaugos ministro 2016 m. sausio 28 d. Nr. V-109 <a className="hrefStyle" href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/69f30570c92711e5a141fecd4d43d786?jfwid=-pw6827ocr">įsakymą</a> „Dėl asmens sveikatai svarbios 
                            informacijos, paaiškėjusios atliekant biomedicininį tyrimą su biobanke tvarkomais asmens biologiniu 
                            ėminiu ir sveikatos informacija, apie kurią privaloma pranešti, kriterijų ir pranešimo tvarkos aprašo 
                            patvirtinimo“ </li>
                            <li>Tyrimų rezultatai, svarbūs Jūsų sveikatai, bus pranešami tik tuo atveju, jei Sutikimo formoje išreikšite pageidavimą gauti šią informaciją. Apie sveikatai svarbius tyrimų rezultatus Jus informuos Biobanko darbuotojas. </li>
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Ar galima atšaukti sutikimą dalyvauti Biobanko veikloje? </h2>
                        <ul className="listStyleText">
                          <li>Jūs bet kada galite atšaukti savo ar savo globotinio/atstovaujamo asmens Sutikimą dalyvauti Biobanko veikloje nenurodydamas priežasčių ar motyvų.</li>
                          <li>Po asmens, dalyvaujančio Biobanko veikloje, ar kito sutikimą turinčio teisę duoti asmens mirties ar jį pripažinus neveiksniu, apribojus jo veiksnumą ar kai jis dėl sveikatos būklės negali būti laikomas gebančiu protingai vertinti savo interesų, teisę atšaukti Sutikimą turi asmenys įstatymų numatyta tvarka.</li>
                          <li>Norėdamas atšaukti Sutikimą dalyvauti Biobanko veikloje turėsite užpildyti <a className="hrefStyle" href="/login">Atsisakymo formą</a> ir pasirašyta atsiųsti ją Biobankui (skenuotą ar fotografuotą el.paštu info.biobankas@mf.vu.lt ar popierinę adresu LPRLB, Čiurlionio 21, Vilnius)</li>
                          <li>Gavus pasirašyta Atsisakymą, Jūsų mėginiai ir sveikatos informacija saugomi Biobanke bus sunaikinami ne vėliau nei per 6 mėnesius nuo Atsisakymo gavimo ir nebus panaudojami būsimuose biomedicininiuose tyrimuose.</li>
                          <li>Tačiau jau atliktų ar Atsisakymo gavimo metu tebevykstančių biomedicininių tyrimų rezultatai nebus naikinami.</li>
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Kokia nauda dalyvaujant Biobanko veikloje? </h2>
                        <ul className="listStyleText">
                          <li>Jūs  kartu su kitais Biobanko dalyviais padėsite mokslininkams atsakyti į daugelį su žmogaus sveikata susijusių klausimų.</li>
                          <li>Mokslininkams atliekant biomedicininius tyrimus naujomis technologijomis gali paaiškėti Jūsų sveikatai svarbi informacija, o tai leistų savailaikiai ir efektyviai suteikti Jums sveikatos priežiūros paslaugas siekiant išvengti ar palengvinti nustatytą sveikatos sutrikimą. Jeigu sutiksite, Jūsų sveikatai svarbi informacija bus perduodama tiesiogiai Jums (arba asmens sutikimą dalyvauti Biobanko veikloje davusiam kitam sutikimą turinčiam teisę duoti asmeniui) arba Jūsų šeimos gydytojui, kuris informuos Jus (arba asmens sutikimą dalyvauti Biobanko veikloje davusį kitą sutikimą turinčiam teisę duoti asmenį).</li>
                          
                        </ul>

                        <h2 className="thick" style={{fontSize: "22px"}}>Kur kreiptis iškilus klausimams?</h2>
                        <ul className="listStyleText">
                          <li>Skambinkite telefonu XXXXX (pirmadieniais–penktadieniais nuo 8:00 iki 16:00). </li>
                          <li>Susisiekite el. paštu: info.biobankas@mf.vu.lt.</li>
                          <li>Atvykite į Biobanką (Žaliųjų ežerų g. 2., Vilnius 12200).</li>
                          
                        </ul>
                        
                      </div>
                  </div>
                </div>
                
                
                
                
              </div>
            </div>
            
          </div>
        </div>
     
      
        </div>;
    }
}
 
export default DUK;