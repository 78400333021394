import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class SaugomiMeginiai extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Saugomi mėginiai</h2>


                    {/* <h2 className="thick" style={{fontSize:"18px"}}>Biobankas vykdydamas savo veiklą saugo:</h2> */}

                    <ul className="listStyleList">
                      <li>DNR/RNR mėginiai išskirti iš veninio ir (ar) kapiliarinio kraujo ir (ar) solidinių audinių;</li>
                      <li>Kapiliarinio kraujo ant Guthrie testo kortelės;</li>
                      <li>Limfocitų kultūros;</li>
                      <li>Fibroblastų kultūros iš odos bioptato ar kito audinio bioptato;</li>
                      <li>Kraujo plazmos ir serumo mėginiai;</li>
                      <li>Likutinė biologinė medžiaga.</li>
                    </ul>

                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default SaugomiMeginiai;