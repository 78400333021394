import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth-service.js";
import DalyvisService from "../services/dalyvis-service.js";

//var user = { 'id' : 1, 'username': 'deivid', 'accessToken' : 12345, 'roles': ['ROLE_USER','ROLE_MODERATOR', 'ROLE_WORKER', 'ROLE_ADMIN']};
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Užpildykite šį laukelį!
      </div>
    );
  }
};
const marginTopBot = {
  marginTop: "0px",
};
const marginBot = {
  marginBottom: "50px",
};
const font = {
  fontSize: "20px",
};

// const centered = {
//     float: none,
//     margin: auto
// };

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleRemindPassword = this.handleRemindPassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      userExists: false,
      userData: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    // this.setState({
    //   message: "",
    //   // loading: true
    // });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      var userExists = false;
      AuthService.login(this.state.email, this.state.password).then(
        (response) => {
          DalyvisService.getAllPersonalData(response.id).then((response) => {
            sessionStorage.clear();
            sessionStorage.setItem("user", JSON.stringify(response));
            this.setState({
              userExists: true,
              userData: Object.values(response),
              loading: false,
            });

            setTimeout(() => {
              // if (
              //   (response.firstTime === null || response.firstTime === 1) &&
              //   response.roles[0] === 1
              // ) {
              //   this.props.history.push("/dalyvavimas");
              //   window.location.reload();
              // }
              if (response.roles[0] === 1) {
                this.props.history.push("/dalyvis/manoprofilis");
                window.location.reload();
              }
              if (response.roles[0] === 2) {
                this.props.history.push("/tyrejas/manoduomenys");
                window.location.reload();
              }
              if (response.roles[0] === 4) {
                this.props.history.push("/darbuotojas/dalyviuinfo");
                window.location.reload();
              }
              if (response.roles[0] === 5) {
                this.props.history.push("/administratorius/dalyviai");
                window.location.reload();
              }
            }, 1000);

            // if (userExists) {
            //   this.setState({
            //     message: "Neteisingas prisijungimo vardas ar slaptažodis",
            //   });
            // }
          });
        }
      );
      if (userExists) {
        this.setState({
          message: "Neteisingas prisijungimo vardas ar slaptažodis",
        });
      }
      //.catch((error) => window.alert(error.message));
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  seePassword(event, id) {
    event.preventDefault();
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  handleRemindPassword(e) {
    e.preventDefault();
    this.props.history.push("/remindlogin");
    window.location.reload();
  }

  render() {
    return (
      <div className="col-xl-5 col-centered">
        <div className="card card-container" style={{ margin: "0px" }}>
          <i
            style={{ marginTop: "100px" }}
            className="fa fa-user-circle fa-5x d-flex justify-content-center"
          ></i>

          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            {" "}
            <div
              className=" d-flex justify-content-center"
              style={{ marginTop: "25px" }}
            >
              <label htmlFor="email" style={font}>
                Elektroninis paštas
              </label>
              <i className="fa fa-user fa-2x icon-cog iconcolor" />
            </div>
            <div className="form-group d-flex justify-content-center">
              <Input
                style={{ width: "200px" }} // font,
                type="text"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>
            <div className=" d-flex justify-content-center">
              <label htmlFor="password" style={font}>
                Slaptažodis
              </label>
              <i className="fa fa-key fa-2x icon-cog iconcolor" />
            </div>
            <div className="form-group d-flex justify-content-center">
              <div className="form-group d-flex justify-content-center">
                <Input
                  style={{ width: "200px" }} // font,
                  type="password"
                  className="form-control"
                  id="passwordinput"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
                <button
                  className="btn"
                  id="seepassword"
                  onClick={(event) => this.seePassword(event, "passwordinput")}
                  style={{
                    height: "32px",
                    padding: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <i class="fa fa-eye"></i>
                </button>
              </div>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: "75px" }}
            >
              <button
                className="btn"
                style={marginTopBot}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Prisijungti</span>
              </button>
            </div>
            {/* <div  className="d-flex justify-content-center">
              <a
                href="/register"
                className="btn primary"
                style = {{marginTop: '20px', marginBottom: '100px', marginLeft: '5px', marginRight: '5px', color: 'white'}}
              >
                Registruotis
              </a>
            </div> */}
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
          <Form
            onSubmit={this.handleRemindPassword}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: "75px" }}
            >
              <button
                className="btn"
                style={marginTopBot}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Pamiršau slaptažodį/Naujas naudotojas</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
