import React from 'react';
import ExamSideNav from '../../sidenavs/examsidenav.jsx'

class Tyrejams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <ExamSideNav />
          <div className="col-lg-9 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  <div className="row mx-1">
                    <h2 className="thick">Tyrėjams</h2>
                    <ul className="listStyleText">
                      <li>Biobankas kaupia Lietuvos populiacijos sveikų asmenų ir sergančių retomis ligomis asmenų biologinius mėginius ir sveikatos informaciją.</li>
                      <li>Lietuvos ir užsienio mokslininkai Biobanku gali naudotis kaip biblioteka: užuot kiekvienam moksliniam tyrimui ieškoję vis naujų savanorių,
                        Jūs galite pasinaudoti Biobanke jau sukauptais aukštos kokybės mėginiais bei sveikatos informacija ir pasidalinti su Biobanku mokslinių tyrimų
                        metu gauta informacija, tokiu būdu dar padidinant sukauptų mėginių mokslinę vertę. Biobankas kaupdamas didelį skaičių biologinių mėginių
                        ir vis papildydamas su mėginiais susijusią sveikatos informaciją, gali palengvinti
                        ir pagerinti Jūsų mokslinių tyrimų organizavimą, atlikimą, gautų rezultatų patikimumą.</li>
                      <li>Mokslininkai, vykdantys biomedicininius mokslinius tyrimus, gali kreiptis į Biobanką, prašydami gauti biologinių mėginių ir su
                        jais susijusią sveikatos informaciją remiantis <a className="hrefStyle" href="https://www.e-tar.lt/portal/lt/legalAct/TAR.234B15954C2F/asr">Lietuvos Respublikos biomedicininių tyrimų etikos įstatymu, reglamentuojančiu biobankų
                          veiklą Lietuvoje</a>. </li>
                      <li>Biomedicininio tyrimo pagrindiniai tyrėjai, turintys bioetikos leidimą vykdyti biomedicininį tyrimą ir norintys tapti partneriais,
                        turi registruotis Biobanko tyrėjo paskyroje ir pateikti prašymą gauti biologinių mėginių ir su jais susijusią sveikatos informaciją
                        moksliniams tyrimams atlikti.</li>
                    </ul>
                    <div className="get-quote col-centered" style={{ marginTop: "25px", marginRight: "5%", marginLeft: "5%" }}>
                      <a style={{ width: "45%", marginRight: "5px", textAlign: "center", paddingLeft: "0px" }} href="/registertypetyrejas" className="btn">Registruotis</a>
                      <a style={{ width: "45%", textAlign: "center", paddingLeft: "0px" }} href="/login" className="btn">Prisijungti</a>
                    </div>
                  </div>
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default Tyrejams;