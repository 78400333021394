import React, { Component } from "react";

import EventBus from "../../common/EventBus";
import UserService from "../../services/user.service-galimaspanaudojimas";
import { Route } from "react-router-dom";
//components
import Dalyvis from "./participation-subpages/dalyvis-folder/dalyvis";
import Dalyvis2 from "./participation-subpages/dalyvis-folder/dalyvis2";

import Atstovas from "./participation-subpages/atstovaujamas-folder/atstovas";
import Atstovas2 from "./participation-subpages/atstovaujamas-folder/atstovas2";
import InfoForma from "./participation-subpages/infoforma";
import UploadDocument from "./participation-subpages/uploaddocument";
import Questionnaire from "./participation-subpages/klausimynas";
import NotParticipating from "./participation-subpages/notparticipating.jsx";
import Approval from "./participation-subpages/approval";

export default class Participation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isAllowed: true,
      error: "",
      showButtons: false,
      authorizedEVV: false,
      user: JSON.parse(sessionStorage.getItem("user")),
      changeStateSelection: true,
    };
    // this.dalyvis = React.createRef();
    // this.atstovas = React.createRef();
  }
  // static propTypes = {
  //   location: React.PropTypes.object.isRequired
  // }
  componentDidMount() {
    // if(window.location.pathname === "/dalyvavimas"){
    // ReactDOM.findDOMNode(this.dalyvis).style.display = "block";
    // ReactDOM.findDOMNode(this.atstovas).style.display = "block";
    // }
    // window.location.reload();
    //-----------------------------------------------
    // var user = this.state.user;
    // if (
    //   user &&
    //   !sessionStorage.getItem("selectionMade") &&
    //   this.state.changeStateSelection
    // ) {
    //   sessionStorage.setItem("selectionMade", true);
    //   this.setState({
    //     changeStateSelection: false,
    //   });
    //   if (user.registerType === 2) {
    //     sessionStorage.setItem("selectedParticipation", "atstovas");
    //     this.props.history.push("/dalyvavimas/atstovas");
    //   } else {
    //     sessionStorage.setItem("selectedParticipation", "dalyvis");
    //     this.props.history.push("/dalyvavimas/dalyvis");
    //   }
    //   // window.location.reload();
    // }
    //-----------------------------------------------
    // var atstovai = [
    //   { name: "lukas", surname: "lukevicius", personalcode: "50080080070" },
    //   { name: "tomas", surname: "lukevicius", personalcode: "50080080080" },
    //   { name: "justina", surname: "lukeviciene", personalcode: "50080080090" },
    // ];
    // localStorage.setItem("atstovai", JSON.stringify(atstovai));

    //-----------------------------------------------
    // var stateOfFirstLogin = this.state.user.firstTime; // reikalinga logino informacija apie first log ina
    // if (stateOfFirstLogin !== null && stateOfFirstLogin > 1) {
    //   console.log("Inside participation");
    //   this.props.history.push("/dalyvis/manoprofilis");
    // }
    // if (window.location.pathname === "/dalyvavimas") {
    //   this.setState({
    //     showButtons: true,
    //   });
    // } else {
    //   this.setState({
    //     showButtons: false,
    //   });
    //   // window.onhashchange = function() {
    //   //   this.setState({
    //   //     showButtons: false

    //   //   });
    //   //  }

    //   // this.backListener = browserHistory.listen(location => {
    //   //   if (location.action === "POP") {
    //   //     // Do your stuff
    //   //   }
    //   // });
    // }
    //-----------------------------------------------

    // window.onpopstate = (event) => {
    //   if (!event.state.showButtons) {
    //     this.setState({ showButtons: false });
    //   }
    // };
    // addEventListener('popstate', event => { this.setState({ showButtons: true });});
    onpopstate = (event) => {
      if (window.location.pathname === "/dalyvavimas") {
        this.setState({ showButtons: true });
      } else {
        this.setState({ showButtons: false });
      }
      console.log("popstate");
    };

    //   setTimeout(() => {
    //   UserService.getParticipantBoard().then(
    //     response => {
    //       this.setState({
    //         isAllowed: true,
    //         loading: false
    //       });
    //     },
    //     error => {
    //       this.setState({
    //         error:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString(),
    //         isAllowed: true,
    //         loading: false
    //       });

    //       // window.alert(parseInt(error.response.status));
    //       if (error.response && error.response.status === 401) {
    //         EventBus.dispatch("logout");
    //       }
    //       else if(error.message === "Network Error"){
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );
    // }, 500);
    //this.test();
  }
  // componentDidUpdate(prevProps) {
  //   // will be true
  //   const locationChanged = this.props.location !== prevProps.location;
  //     if(locationChanged == true){
  //       this.setState({
  //         showButtons: false

  //       });
  //     }
  //   }

  test = () => {
    if (window.location.pathname === "/dalyvavimas") {
      document.getElementById("dalyvis").style.display = "block";
      document.getElementById("atstovas").style.display = "block";
    }
  };

  hideButtons(e, type, choice) {
    e.preventDefault();
    this.props.history.push("/dalyvavimas/" + type);
    document.getElementById("dalyvis").style.display = "none";
    document.getElementById("atstovas").style.display = "none";
    if (choice === 1) {
      sessionStorage.setItem("selectedParticipation", "dalyvis");
    } else {
      sessionStorage.setItem("selectedParticipation", "atstovas");
    }
    console.log(sessionStorage.getItem("selectedParticipation"));
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     this.hideButtons();
  //     window.alert("|||||");
  //   }
  // }

  render() {
    const isAllowed = this.state.isAllowed;
    const error = this.state.error;
    const loading = this.state.loading;
    return (
      <div>
        {isAllowed ? (
          <>
            <div
              className="get-quote col-xl-8 col-lg-10 col-md-12 col-centered card card-container"
              style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                minHeight: "500px",
              }}
            >
              {this.state.showButtons && (
                <>
                  <a
                    href={`${this.props.match.path}/dalyvis`}
                    id="dalyvis"
                    ref={(ref) => (this.dalyvis = ref)}
                    className="btn col-lg-6 col-md-8 col-sm-12"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    onClick={(event) => this.hideButtons(event, "dalyvis", 1)}
                  >
                    Dalyvausiu pats
                  </a>
                  <a
                    href={`${this.props.match.path}/atstovas`}
                    id="atstovas"
                    ref={(ref) => (this.atstovas = ref)}
                    className="btn col-lg-6 col-md-8 col-sm-12"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    onClick={(event) => this.hideButtons(event, "atstovas", 2)}
                  >
                    Atstovausiu kita asmenį
                  </a>
                </>
              )}

              <Route
                path={`${this.props.match.path}/dalyvis`}
                component={Dalyvis}
              />
              <Route
                path={`${this.props.match.path}/dalyvis2`}
                component={Dalyvis2}
              />
              <Route
                path={`${this.props.match.path}/atstovas`}
                component={Atstovas}
              />
              <Route
                path={`${this.props.match.path}/atstovas2`}
                component={Atstovas2}
              />
              <Route
                path={`${this.props.match.path}/infoforma`}
                component={InfoForma}
              />
              <Route
                path={`${this.props.match.path}/patvirtinimas`}
                component={Approval}
              />
              <Route
                path={`${this.props.match.path}/uploaddocument`}
                component={UploadDocument}
              />
              <Route
                path={`${this.props.match.path}/klausimynas`}
                component={Questionnaire}
              />

              {/* <Route path={`${this.props.match.path}/atstovasforma`} component={AtstovasForma} /> */}
            </div>
          </>
        ) : loading ? (
          <div className="d-flex justify-content-center" style={{}}>
            <div
              className="spinner-grow d-flex justify-content-center"
              style={{
                width: "20rem",
                height: "20rem",
                marginTop: "80px",
                marginBottom: "80px",
                color: "#78003f",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="error-page section ">
            <div className="container ">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  {/* Error Inner */}
                  <div className="error-inner">
                    {/* &nbsp;{error} */}
                    <h1>
                      <span>&nbsp;{error} Prieiga neleista</span>
                    </h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                    <br />
                  </div>
                  {/*/ End Error Inner */}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
