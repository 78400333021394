import React from 'react';
import { Table, Button } from 'react-bootstrap';

const ReservationTable = ({ times, onRegister }) => {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Laikas</th>
                <th>Paslauga</th>
                <th>Veiksmas</th>
            </tr>
            </thead>
            <tbody>
            {times.map((slot, index) => (
                <tr key={index}>
                    <td>{slot.time}</td>
                    <td>{slot.service}</td>
                    <td>
                        <Button variant="primary" onClick={() => onRegister(slot)}>
                            Registruotis
                        </Button>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default ReservationTable;