import React from 'react';
import Messages from './tyreju-info-subpages/messages';



class Pranesimai extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  componentDidMount() {

  }
  

  render() {
    



    return <>
      {/* <div className="col-centered col-lg-12 col-md-12 col-12"> */}
      <Messages/>
      {/* </div> */}
    </>;
  }
}
export default Pranesimai;