import React, { Component } from "react";
import {Route, Redirect} from "react-router-dom";

// Import the subpage components
import DalyviuInfo from "./adminas-subpages/dalyviuinfo";
import Pranesimai from "./adminas-subpages/pranesimai";
import TyrejuInfo from "./adminas-subpages/tyrejuinfo";
import Forms from "./institucijos-subpages/perdavimoformos";
import DocumentAdmin from "./adminas-subpages/documentadmin";

class AdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAllowed: true,
      error: "",
      loading: false,
    };
  }

  render() {
    const { isAllowed, error, loading } = this.state;
    const pathname = window.location.pathname;

    // Default redirection path if no specific subpage is selected
    // if (pathname === "/administratorius") {
    //   // return <Redirect to={`${this.props.match.path}/dalyviai`} />;
    //   redirect(`${this.props.match.path}/dalyviai`);
    // }

    return (
      <div>
        {isAllowed ? (
          <section className="news-single section margin-nav">
            <div className="">
              <div className="row">
                <div className="col-lg-1 col-md-2 col-12"></div>
                <div className="col-lg-2 col-md-8 col-12">
                  <div className="">
                    <div
                      className="position-button size"
                      align="center"
                      style={{ marginTop: "30px" }}
                    >
                      <a
                        href={`${this.props.match.path}/dalyviai`}
                        className={
                          pathname === `${this.props.match.path}/dalyviai`
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Dalyvių informacija
                      </a>
                      <a
                        href={`${this.props.match.path}/tyrejai`}
                        className={
                          pathname === `${this.props.match.path}/tyrejai`
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Tyrėjų paraiškos
                      </a>
                      <a
                        href={`${this.props.match.path}/pranesimai`}
                        className={
                          pathname === `${this.props.match.path}/pranesimai`
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Pranešimai
                      </a>
                      <a
                        href={`${this.props.match.path}/perdavimoformos`}
                        className={
                          pathname ===
                          `${this.props.match.path}/perdavimoformos`
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Perdavimo formos
                      </a>
                      {/* <a
                        href={`${this.props.match.path}/dokumentai`}
                        className={
                          pathname === `${this.props.match.path}/dokumentai`
                            ? "btn active-sidenav-btn"
                            : "btn"
                        }
                      >
                        Dokumentai
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-8 col-md-12 col-12"
                  style={{ minHeight: "500px" }}
                >
                  <div className="row">
                    <div className="comments-form">
                      <Route exact path={`${this.props.match.path}`}>
                        <Redirect
                            to={`${this.props.match.path}/dalyviai`}
                        ></Redirect>
                      </Route>
                      <Route
                        path={`${this.props.match.path}/dalyviai`}
                        component={DalyviuInfo}
                      />
                      <Route
                        path={`${this.props.match.path}/tyrejai`}
                        component={TyrejuInfo}
                      />
                      <Route
                        path={`${this.props.match.path}/pranesimai`}
                        component={Pranesimai}
                      />
                      <Route
                        path={`${this.props.match.path}/perdavimoformos`}
                        component={Forms}
                      />
                      {/* <Route
                        path={`${this.props.match.path}/dokumentai`}
                        component={DocumentAdmin}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : loading ? (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-grow d-flex justify-content-center"
              style={{
                width: "20rem",
                height: "20rem",
                marginTop: "80px",
                marginBottom: "80px",
                color: "#78003f",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <section className="error-page section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                  <div className="error-inner">
                    <h1>
                      <span>&nbsp;{error} Prieiga neleista</span>
                    </h1>
                    <p>Jūs neturite prieigos prie šio puslapio!</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default AdminForm;
