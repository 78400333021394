import React, { Component } from "react";
import tyrejasService from "../../../../services/tyrejas-service";
import dalyvisService from "../../../../services/dalyvis-service";

class NewApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(sessionStorage.getItem("user")),
      tyrimasdoc: "Pasirinkite dokumentą",
      leidimasdoc: "Pasirinkite dokumentą",
      paraiskapdf: "Pasirinkite dokumentą",
      administerState: 0,
      projectName: "",
      projectAcronym: "",
      projectExaminatorFullname: "",
      protocolFileLink: "",
      permitFileLink: "",
      startDate: "",
      endDate: "",
      applicationPDFLink: "",
      additionalRemarks: "",
      addedDate: new Date(),

      protocolFile: "",
      applicationPDF: "",
      permitFile: "",
    };

    //this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  componentDidMount() {
    document.getElementById("submitButton").disabled = true;
  }

  generateUniqueCode(users) {
    let code = "P";

    for (let i = 0; i < 8; i++) {
      code += Math.floor(Math.random() * 10); // Generates a random digit from 0 to 9
    }

    return code;
  }

  async submitForm(e) {
    e.preventDefault();
    var administerState = 1; // default submission
    var projectname = document.getElementById("projectname").value;
    var projectacronym = document.getElementById("projectacronym").value;
    var projectmainname = document.getElementById("projectmainname").value;
    var protocolFileLink = document.getElementById("signedfile");
    var permitFileLink = document.getElementById("leidimas");
    var startDate = document.getElementById("projectstartdate");
    var endDate = document.getElementById("projectenddate");
    var applicationPDFLink = document.getElementById("applicationpdf");
    var additionalinfo = document.getElementById("additionalinfo").value;
    var guaranteeFileLink = "";

    var allItems = [
      projectname,
      projectacronym,
      projectmainname,
      protocolFileLink.value,
      startDate.value,
      endDate.value,
      applicationPDFLink,
    ];

    var continueState = true;

    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        window.alert(
          "Kai kurie langeliai yra nepilnai užpildyti arba nepasirinktas failas!"
        );
        break;
      }
      continueState = true;
    }

    if (continueState === true) {
      // const protocolFile = this.state.protocolFile;
      // const applicationPDF = this.state.applicationPDF;
      // const permitFile = this.state.permitFile;

      // const newProtocolFile = new File([protocolFile], protocolFile.name, {
      //   type: protocolFile.type,
      // });
      // const newApplicationPDF = new File(
      //   [applicationPDF],
      //   applicationPDF.name,
      //   {
      //     type: applicationPDF.type,
      //   }
      // );
      // const newPermitFile = new File([permitFile], permitFile.name, {
      //   type: permitFile.type,
      // });

      // protocolFormData.append("file", newProtocolFile, "");
      // applicationPDFFormData.append("file", newApplicationPDF);
      // permitFormData.append("file", newPermitFile);

      const protocolFile = this.state.protocolFile;
      const applicationPDF = this.state.applicationPDF;
      const permitFile = this.state.permitFile;

      const protocolFormData = new FormData();
      const applicationPDFFormData = new FormData();
      const permitFormData = new FormData();

      const applications = await dalyvisService
        .getAllApplications()
        .catch((error) => window.alert(error.message));

      let uniqueCode = this.generateUniqueCode();

      for (let i = 0; i < applications.length; i++) {
        if (applications[i].uniqueCode === uniqueCode) {
          uniqueCode = this.generateUniqueCode();
          i = 0;
        }
      }

      const tyrimasName =
        "Tyrimo_" + projectname + "_protokolo_santrauka_" + uniqueCode + ".pdf";
      const paraiskaName =
        "Paraiska_" + projectname + "_" + uniqueCode + ".pdf";
      const leidimasName =
        "Bioetikos_leidimas_" + projectname + "_" + uniqueCode + ".pdf";

      const readFileAsBlobAndAppend = (file, formData, filename) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();

          reader.onload = function (event) {
            let blob = new Blob([event.target.result], { type: file.type });
            formData.append("file", blob, filename);
            resolve();
          };

          reader.onerror = function (error) {
            console.log("Error reading file:", error);
            reject(error);
          };

          reader.readAsArrayBuffer(file);
        });
      };

      let fileReadPromises = [
        await readFileAsBlobAndAppend(protocolFile, protocolFormData, tyrimasName),
        await readFileAsBlobAndAppend(
            applicationPDF,
            applicationPDFFormData,
            paraiskaName
        ),
        await readFileAsBlobAndAppend(permitFile, permitFormData, leidimasName),
      ];

      protocolFileLink = "https://covid19.mf.vu.lt/proxy/api/files/download/" + tyrimasName;
      //this.state.tyrimasdoc;
      //protocolFileLink.value.split("\\").pop().split("/").pop();

      applicationPDFLink = "https://covid19.mf.vu.lt/proxy/api/files/download/" + paraiskaName;
      //this.state.paraiskapdf;
      //applicationPDFLink.value.split("\\").pop().split("/").pop();
      permitFileLink = "https://covid19.mf.vu.lt/proxy/api/files/download/" + leidimasName;
      //this.state.leidimasdoc;
      //permitFileLink.value.split("\\").pop().split("/").pop();

      console.log(protocolFileLink);
      console.log(applicationPDFLink);
      console.log(permitFileLink);

      var startDate1 = new Date(startDate.value);
      var endDate1 = new Date(endDate.value);
      console.log(startDate);

      const application = {
        uniqueCode: uniqueCode,
        administerState: "state",
        projectName: projectname,
        projectAcronym: projectacronym,
        projectExaminatorFullname: projectmainname,
        protocolFileLink: protocolFileLink,
        permitFileLink: permitFileLink,
        startDate: startDate1,
        endDate: endDate1,
        guaranteeFileLink: "",
        additionalRemarks: additionalinfo,
        addedDate: new Date(),
        applicationStatus: 0,
        applicationPdflink: applicationPDFLink,
      };

      Promise.all(fileReadPromises)
        .then(() => {
          dalyvisService
            .postFileUpload(protocolFormData)
            .then(() => {
              dalyvisService
                .postFileUpload(applicationPDFFormData)
                .then(() => {
                  dalyvisService
                    .postFileUpload(permitFormData)
                    .then(() => {
                      tyrejasService
                        .postApplications(application)
                        .then((response) => {
                          var userId = JSON.parse(
                            sessionStorage.getItem("user")
                          ).id;
                          sessionStorage.setItem(
                            "currentApplicationId",
                            response
                          );

                          const updatedUser = this.state.user;
                          updatedUser.application.push(response);
                          dalyvisService
                            .putCustomObjectPersonalData(userId, updatedUser)
                            .then((response) => {
                              console.log(response);
                              dalyvisService
                                .getAllPersonalData(this.state.user.id)
                                .then((response) => {
                                  // window.alert("Duomenys sėkmingai išsiųsti");
                                  sessionStorage.setItem(
                                    "user",
                                    JSON.stringify(response)
                                  );
                                  console.log(sessionStorage.getItem("user"));
                                  this.props.history.push(
                                    "./manoparaiskos/manoparaiskos"
                                  );
                                  window.location.reload();
                                })
                                .catch((error) => window.alert(error.message));
                            })
                            .catch((error) => window.alert(error.message));
                        })
                        .catch((error) => window.alert(error.message));
                    })
                    .catch((error) => window.alert(error.message));
                })
                .catch((error) => window.alert(error.message));
            })
            .catch((error) => window.alert(error.message));
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  }
  checkEmpty() {
    var projectname = document.getElementById("projectname").value;
    var projectacronym = document.getElementById("projectacronym").value;
    var projectmainname = document.getElementById("projectmainname").value;
    var signedfile = document.getElementById("signedfile");
    var leidimas = document.getElementById("leidimas");
    var projectstartdate = document.getElementById("projectstartdate");
    var projectenddate = document.getElementById("projectenddate");
    var applicationpdf = document.getElementById("applicationpdf");
    var additionalinfo = document.getElementById("additionalinfo").value;

    document.getElementById("submitButton").disabled = true;
    var continueState = true;
    var quantity = 0;
    var allItems = [
      projectname,
      projectacronym,
      projectmainname,
      signedfile.value,
      projectstartdate.value,
      projectenddate.value,
      applicationpdf,
    ];
    //leidimas.value, ,applicationpdf,additionalinfo
    for (let i = 0; i < allItems.length; i++) {
      if (allItems[i] === "" || allItems[i] === null) {
        continueState = false;

        break;
      }
      quantity += 1;
    }

    if (quantity === allItems.length && continueState === true) {
      document.getElementById("submitButton").disabled = false;
    }
  }
  changeDocText(e, state, fileid) {
    e.preventDefault();

    let value = document.getElementById(fileid).value;
    // window.alert("|"+value+"|");
    if (value.length !== 0 || value !== "" || value !== null) {
      let fileName = value.split("\\").pop().split("/").pop();
      if (state === "tyrimasdoc") {
        this.setState({ protocolFile: e.target.files[0] });
      } else if (state === "leidimasdoc") {
        this.setState({ permitFile: e.target.files[0] });
      } else {
        this.setState({ applicationPDF: e.target.files[0] });
      }

      this.setState({
        [state]: fileName,
      });
      // window.alert("File set");
    } else {
      this.setState({
        [state]: "Pasirinkite dokumentą",
      });
      // window.alert("File not set");
    }
    // document.getElementById("button1").disabled = false;
  }
  docTextClose(e, state) {
    e.preventDefault();
    this.setState({
      [state]: "Pasirinkite dokumentą",
    });
  }

  render() {
    return (
      <>
        <div className="col-centered col-lg-10 col-md-12 col-12">
          {/* <h2><b>Pildyti naują paraiška</b></h2> */}
          <form
            onInput={(event) => this.checkEmpty(event)}
            onChange={(event) => this.checkEmpty(event)}
            onSubmit={(event) => this.submitForm(event)}
          >
            <table className="table">
              <thead className="thead-dark">
                {/* <tr>
                        <th scope="col">Skiltis</th>
                        <th scope="col">Įvedama informacija</th>
                      </tr> */}
              </thead>
              <tbody>
                <tr>
                  {/* <th scope="row">1</th> */}
                  <th scope="row">Projekto pavadinimas*</th>
                  <td>
                    <input
                      type="text"
                      id="projectname"
                      name="projectname"
                      style={{ width: "100%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Projekto akronimas*</th>
                  <td>
                    <input
                      type="text"
                      id="projectacronym"
                      name="projectacronym"
                      style={{ width: "100%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Projekto pagrindinis tyrėjas*</th>
                  <td>
                    <input
                      type="text"
                      id="projectmainname"
                      name="projectmainname"
                      style={{ width: "100%" }}
                    />
                  </td>
                </tr>
                <tr>
                  {/* <th scope="row">2</th> */}
                  <th>Tyrimo protokolo santrauka*</th>
                  <td>
                    <label
                      className="btn"
                      style={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        margin: 0,
                        borderRadius: 0,
                      }}
                    >
                      <input
                        className="col-centered btn"
                        type="file"
                        id="signedfile"
                        accept=".pdf"
                        onInput={(event) =>
                          this.changeDocText(event, "tyrimasdoc", "signedfile")
                        }
                        style={{ margin: 0, borderRadius: 0 }}
                        onFocus={(event) =>
                          this.docTextClose(event, "tyrimasdoc")
                        }
                      />
                      {this.state.tyrimasdoc}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>Bioetikos leidimas</th>
                  {/* gali but nebutinas*/}
                  <td>
                    <label
                      className="btn"
                      style={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        margin: 0,
                        borderRadius: 0,
                      }}
                    >
                      <input
                        className="col-centered btn"
                        type="file"
                        id="leidimas"
                        accept=".pdf"
                        onInput={(event) =>
                          this.changeDocText(event, "leidimasdoc", "leidimas")
                        }
                        style={{ margin: 0, borderRadius: 0 }}
                      />
                      {this.state.leidimasdoc}
                    </label>
                  </td>
                </tr>
                <tr>
                  {/* <th scope="row">4</th> */}
                  <th>Projekto pradžia ir pabaiga*</th>
                  <td style={{ width: "70%" }}>
                    <label>Pradžia:&nbsp;&nbsp;&nbsp;</label>
                    {/* <date-input date="{{date}}" timezone="[[timezone]]"></date-input> */}
                    <input
                      type="date"
                      placeholder="YYYY-MM-DD"
                      data-date-format="YYYY-MM-DD"
                      id="projectstartdate"
                      name="start"
                    ></input>
                    <br />
                    <label>Pabaiga:&nbsp;</label>
                    <input type="date" id="projectenddate" name="end"></input>
                  </td>

                  {/* <td>Pabaiga: 2009-01-01</td> */}
                </tr>

                <tr className="col-12">
                  <th>Paraiškos PDF*</th>
                  {/* gali but nebutinas*/}
                  <td>
                    <label
                      className="btn"
                      style={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        margin: 0,
                        borderRadius: 0,
                        width: "90%",
                        wordBreak: "normal",
                      }}
                    >
                      <input
                        className="col-centered btn"
                        type="file"
                        id="applicationpdf"
                        accept=".pdf"
                        onInput={(event) =>
                          this.changeDocText(
                            event,
                            "paraiskapdf",
                            "applicationpdf"
                          )
                        }
                        style={{ margin: 0, borderRadius: 0 }}
                      />
                      {this.state.paraiskapdf}
                    </label>
                    <br />
                    <a
                      className="btn primary"
                      href="/paraiskosforma"
                      download
                      style={{ marginTop: "2px", width: "90%" }}
                    >
                      Atsisiųsti paraiškos formą{" "}
                    </a>
                  </td>
                </tr>

                {/* <tr>
                       
                        <th>Mokslinis poreikis</th>
                        <td>Mėginių/ sveikatos informacijos (greičiausiai failiukas)
                        <label className="btn" style={{paddingTop: "2px",paddingBottom: "2px",margin:0,borderRadius: 0}}>
                          <input className="col-centered btn" type="file" id="signedFile1" style={{margin:0,borderRadius: 0}}/>
                          Pasirinkite dokumentą
                        </label>
                        </td>
                      </tr> */}
                <tr>
                  {/* <th scope="row">6</th> */}
                  <th>Papildoma informacija</th>
                  <td>
                    <textarea
                      type="textarea"
                      id="additionalinfo"
                      name="fname"
                      style={{ height: "100px", width: "100%" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col-12">
              <div className="form-group button">
                <button type="submit" id="submitButton" className="btn">
                  <i className="fa fa-send" />
                  Pateikti
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default NewApplication;
