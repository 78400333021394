import React from 'react';
import AboutSideNav from '../../sidenavs/aboutsidenav.jsx'

class TeisesAktai extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: ''

    };
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }, false);
  }
  render() {
    const mobileornot = this.state.isMobile ? 'row' : 'row mx-5';

    return <div>


      {/* <img style={{marginTop:"0px",height: "25px", width: "100%"}}src="img/heartrate6.png" alt="#" /> */}



      <div className="container-fluid" >

        <div className={mobileornot} style={{ marginTop: "25px", marginBottom: "50px" }}>
          <AboutSideNav />
          <div className="col-lg-8 col-12" >
            <div className="row">
              <div className="col-12">
                <div className="comments-form">

                  {/* Contact Form */}

                  <div className="row mx-1">
                    <h2 className="thick">Teisės aktai</h2>
                    <ul className="listStyleList">
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/7aa28cc261bb11e5b316b7e07d98304b" className="hrefStyle">
                        Lietuvos Respublikos biomedicininių tyrimų etikos įstatymas  </a></li>

                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/805a6470c60211e5a141fecd4d43d786?jfwid=nq76n0zcq" className="hrefStyle">
                        Lietuvos Respublikos sveikatos apsaugos ministro įsakymas dėl biobanko veiklos reikalavimų aprašo patvirtinimo (2016 m. sausio 26 d. Nr. V-101) </a></li>

                      <li><a href="https://eur-lex.europa.eu/legal-content/LT/TXT/?uri=celex%3A32016R0679"
                        className="hrefStyle">Europos Sąjungos Bendrasis duomenų apsaugos reglamentas (BDAR) 2016/679 </a> </li>
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/69f30570c92711e5a141fecd4d43d786?jfwid=-pw6827ocr"
                        className="hrefStyle">Lietuvos Respublikos sveikatos apsaugos ministro įsakymas dėl Asmens sveikatai svarbios informacijos, paaiškėjusios atliekant biomedicininį tyrimą su biobanke tvarkomais asmens biologiniu ėminiu ir sveikatos informacija, apie kurią privaloma pranešti, kriterijų ir pranešimo tvarkos aprašo patvirtinimo (2016 m. sausio 28 d. Nr. V-109) </a> </li>
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/b9895bf0ba3811e5be9bf78e07ed6470/asr"
                        className="hrefStyle">Lietuvos Respublikos biomedicininių tyrimų etikos įstatymas dėl informuoto asmens sutikimo dalyvauti biomedicininiame tyrime ir informacijos apie biomedicininį tyrimą reikalavimų ir informuoto asmens sutikimo dalyvauti biomedicininiame tyrime davimo ir atšaukimo tvarkos aprašo patvirtinimo (2016 m. sausio 8 d. Nr. V-28) </a> </li>
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/8478ed60c60211e5a141fecd4d43d786/asr"
                        className="hrefStyle">Lietuvos Respublikos biomedicininių tyrimų etikos įstatymas dėl informuoto asmens sutikimo dalyvauti biobanko veikloje ir informacijos apie dalyvavimą biobanko veikloje reikalavimų ir informuoto asmens sutikimo dalyvauti biobanko veikloje davimo ir atšaukimo tvarkos aprašo patvirtinimo (2016 m. sausio 26 d. Nr. V-100)  </a> </li>
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/TAIS.31932/asr"
                        className="hrefStyle">Lietuvos Respublikos pacientų teisių ir žalos sveikatai atlyginimo įstatymas (1996 m. spalio 3 d. Nr. I-1562) </a> </li>
                      <li><a href="https://e-seimas.lrs.lt/portal/legalAct/lt/TAD/TAIS.29546/asr"
                        className="hrefStyle">Lietuvos Respublikos sveikatos priežiūros įstaigų įstatymas (1996 m. birželio 6 d. Nr. I-1367) </a> </li>
                    </ul>





                  </div>

                  {/*/ End Contact Form */}
                </div>
              </div>




            </div>
          </div>

        </div>
      </div>


    </div>;
  }
}

export default TeisesAktai;