import React from "react";
import Applications from "./tyreju-info-subpages/applications";
import Inquiries from "./tyreju-info-subpages/messages";
class TyrejuInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <>
        {/* <div className="col-centered col-lg-10 col-md-12 col-12"> */}

        <Applications />
        {/* <Inquiries /> */}

        {/* </div> */}
      </>
    );
  }
}
export default TyrejuInfo;
