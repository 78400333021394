import React from "react";

import Modal from "react-bootstrap/Modal";

// import FormFill from "./formos-uzpildymas";
import dalyvisService from "../../../services/dalyvis-service";
import history from "../../history";

// https://react-bootstrap.github.io/components/modal/
// https://github.com/simonbengtsson/jsPDF-AutoTable
// https://www.npmjs.com/package/jspdf-autotable

class DalyviuInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      filterState: "all", // all, day,period,
      filterType: "",
      searchTerm: "",
      show: false,
      fullscreen: true,
      setShow: false,
      setFullscreen: true,
      values: [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"],
      selectedPart: [],
      allusers: [],
      allsamples: [],
      checkboxSampleChecked: {},
      loading: true,
      groupedSamplesByUserAndDate: {},
    };
    this.handleClose = this.handleClose.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
  }

  async fetchData() {
    await dalyvisService.getAllUsers().then((response) => {
      var allusers = [];
      allusers = Object.values(response);
      // console.log(allusers);

      var participants = [];

      if (allusers !== null) {
        allusers.forEach((user) => {
          if (user.roles[0] === 1) {
            participants.push(user);
          }
        });
      }

      // console.log(participants);

      const dynamicStateCheckboxes = {};
      var samples = [];
      dalyvisService
        .getAllSamples()
        .then((response) => {
          samples = Object.values(response);

          samples.forEach((sample) => {
            dynamicStateCheckboxes["sample" + sample.id] = false;
          });

          const groupedSamples = {};
          allusers.forEach((user) => {
            user.samples.forEach((sampleId) => {
              const sample = samples.find((item) => item.id === sampleId);
              const sampleDate = new Date(sample.addedAt).toDateString();

              if (!groupedSamples[user.uniqueCode]) {
                groupedSamples[user.uniqueCode] = {};
              }
              if (!groupedSamples[user.uniqueCode][sampleDate]) {
                groupedSamples[user.uniqueCode][sampleDate] = [];
              }

              if (
                !groupedSamples[user.uniqueCode][sampleDate].includes(sample)
              ) {
                groupedSamples[user.uniqueCode][sampleDate].push(sample);
              }
            });
          });

          this.setState({
            checkboxSampleChecked: dynamicStateCheckboxes,
            allusers: participants,
            allsamples: samples,
            loading: false,
            groupedSamplesByUserAndDate: groupedSamples,
          });
        })
        .catch((error) => window.alert(error.message));
    });

    //.catch((error) => window.alert(error.message));
  }
  componentDidMount() {
    this.fetchData();
  }

  handleShow() {
    // this.state.setFullscreen(breakpoint);
    this.setState({
      fullscreen: true,
      show: true,
    });

    // this.state.setShow(true);
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleSave = () => {
    window.alert("Išsaugota");
    this.setState({ show: false });

    // save document to backend
  };
  handleFormCreation(e) {
    e.preventDefault();
    if (window.confirm("Ar tikrai teisingai pasirinkote dalyvius?")) {
      this.handleShow();
    }
  }
  generatePDF = () => {
    const { pdfData } = this.state;

    // const doc = new jsPDF('p', 'mm', 'a4');//'p', 'mm', 'a4'

    // doc.autoTable(doc, {
    //   head: [['Name', 'Email']],
    //   body: [["Priėmimo-perdavimo akto nr.", "123145646"],
    //         ["Žmogaus biologinių ėminių paėmimo data, laikas", "123145646"],
    //         ["Priėmimo-perdavimo akto nr.", "123145646"],
    //         ["Priėmimo-perdavimo akto nr.", "123145646"],
    //         ["Priėmimo-perdavimo akto nr.", "123145646"],
    //         ["Priėmimo-perdavimo akto nr.", "123145646"]

    //   ]
    // }); //html: '#pdfcontent'

    //-------------
    //   var doc = new jsPDF('l')

    // var head = headRows()
    // head[0]['text'] = 'Text'
    // var body = bodyRows(4)
    // body.forEach(function (row) {
    //   row['text'] = faker.lorem.sentence(100)
    // })

    // doc.text("Overflow 'ellipsize' with one column with long content", 14, 20)
    // doc.autoTable({
    //   head: head,
    //   body: body,
    //   startY: 25,
    //   // Default for all columns
    //   styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
    //   // Override the default above for the text column
    //   columnStyles: { text: { cellWidth: 'auto' } },
    // })
    //   doc.save('table.pdf');
  };
  showInfo_old(id) {
    // const list = ["projektas apie mane", "projektas apie jus", "projektas apie meginius"]; // get from database

    var value = document.getElementById(id);

    if (value.style.visibility === "hidden") {
      value.style.display = "block";
      value.style.visibility = "visible";
      value.style.maxHeight = "100%";
      value.style.lineHeight = "100%";
      this.setState({ openedApplicationId: id });
      // value.style.height = "auto";
    } else {
      this.setState({ openedApplicationId: "" });
      value.style.display = "none";
      value.style.visibility = "hidden";
      value.style.maxHeight = "0";
      value.style.lineHeight = "0";

      // value.style.height = 0;
    }
  }

  showInfo(id) {
    var element = document.getElementById(id);

    if (element.style.display === "none" || element.style.display === "") {
      element.style.display = "block";
      element.style.visibility = "visible";
      element.style.maxHeight = "100%";
      element.style.lineHeight = "100%";
    } else {
      element.style.display = "none";
      element.style.visibility = "hidden";
      element.style.maxHeight = "0";
      element.style.lineHeight = "0";
    }
  }

  showAllDates = (event) => {
    this.setState({
      // startDate: "",
      // endDate: "",
      filterState: "all",
    });
  };
  showLastDay = (event) => {
    this.setState({
      filterState: "day",
    });
  };
  setStartDate = (event) => {
    var startDate1 = document.getElementById("dateselection1").value;
    // startDate1
    console.log("------------------" + startDate1);

    this.setState({
      startDate: new Date(startDate1),
    });
  };
  setEndDate = (event) => {
    var endDate1 = document.getElementById("dateselection2").value;
    this.setState({
      endDate: new Date(endDate1),
    });
  };
  filterByDate = (event) => {
    if (this.state.startDate !== "" && this.state.endDate !== "") {
      this.setState({
        filterState: "period",
      });
    } else {
      window.alert("Data nebuvo pasirinkta");
    }
    // this.state.startDate;
    // this.state.endDate;
  };
  filterItemsSearch = () => {
    var searchTermInside = document.getElementById("searchData").value;
    this.setState({ searchTerm: searchTermInside });
  };
  checkboxChange(e) {
    const { id } = e.target;
    if (this.state.checkboxSampleChecked[id] === true) {
      this.setState((prevState) => ({
        checkboxSampleChecked: {
          ...prevState.checkboxSampleChecked,
          [id]: false,
        },
      }));
    } else {
      this.setState((prevState) => ({
        checkboxSampleChecked: {
          ...prevState.checkboxSampleChecked,
          [id]: true,
        },
      }));
    }
  }

  // extractDate(offsetDateTimeString) {
  //   // Extract the first 10 characters of the string, which represent the date in YYYY-MM-DD format
  //   return offsetDateTimeString.substring(0, 10);
  // }

  render() {
    //last sample date should be gotten from a list of
    // const partData = [{ id: "1", unique_code: "agb12345", name: "Bobby Brown", last_sample_date: "2021-01-23T18:25:43.511Z", sample_quantity: "56" },
    // { id: "2", unique_code: "abc12345", name: "Bobby BLue", last_sample_date: "2022-10-05T18:25:43.511Z", sample_quantity: "3" },
    // { id: "3", unique_code: "acd12345", name: "Bobby Yellow", last_sample_date: "2022-10-04T18:25:43.511Z", sample_quantity: "7" }
    // ];
    const {
      loading,
      show,
      allusers,
      searchTerm,
      filterState,
      allsamples,
      checkboxSampleChecked,
      groupedSamplesByUserAndDate,
      startDate,
      endDate,
    } = this.state;

    var filteredData = [];
    var partData = Object.values(allusers);
    var samplesFiltered = [];
    const stats = partData
      .filter((data) => {
        // console.log("filterState: " + this.state.filterState);
        if (data.uniqueCode !== null) {
          if (filterState === "all" && searchTerm === "") {
            if (data.samples.length > 0) {
              return true;
            }
          } else if (
            filterState === "all" &&
            data.uniqueCode
              .toLowerCase()
              .replaceAll(" ", "")
              .trim()
              .startsWith(searchTerm.toLowerCase().replaceAll(" ", "").trim())
          ) {
            if (data.samples.length > 0 && data.uniqueCode !== null) {
              return true;
            }
          } else if (
            filterState === "period" &&
            data.uniqueCode
              .toLowerCase()
              .replaceAll(" ", "")
              .trim()
              .startsWith(searchTerm.toLowerCase().replaceAll(" ", "").trim())
          ) {
            var startDate = startDate;
            var endDate = endDate;
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            samplesFiltered = data.samples.filter((sample) => {
              const found = allsamples.find((item) => item.id === sample);
              var dataDate = new Date(found.addedAt);
              dataDate.setHours(0, 0, 0, 0);
              console.log("111111111");
              console.log(
                startDate.getTime() <= dataDate.getTime() &&
                  endDate.getTime() >= dataDate.getTime()
              );
              console.log(dataDate.getTime());
              // console.log("222222222");
              // console.log( endDate >= dataDate);
              if (
                startDate.getTime() <= dataDate.getTime() &&
                endDate.getTime() >= dataDate.getTime()
              ) {
                return true;
              }
              return false;
            });
            console.log("filtered length period" + samplesFiltered.length);

            if (samplesFiltered.length > 0) {
              data.samples = samplesFiltered;
              if (data.samples.length > 0 && data.uniqueCode !== null) {
                console.log("Return1" + data.id);
                return 1;
              }
            }
          } else if (
            filterState === "day" &&
            data.uniqueCode
              .toLowerCase()
              .replaceAll(" ", "")
              .trim()
              .startsWith(searchTerm.toLowerCase().replaceAll(" ", "").trim())
          ) {
            var today = new Date();
            //var samplesFiltered = [];
            samplesFiltered = data.samples.filter((sample) => {
              const found = allsamples.find((item) => item.id === sample);
              var dateFromJson = new Date(found.addedAt);
              if (
                today.getDate() === dateFromJson.getDate() &&
                today.getFullYear() === dateFromJson.getFullYear() &&
                today.getMonth() === dateFromJson.getMonth()
              ) {
                return true;
              }

              return false;
            });

            if (samplesFiltered.length > 0) {
              data.samples = samplesFiltered;

              if (data.samples.length > 0 && data.uniqueCode !== null) {
                return 1;
              }
            }

            // else if (today.getDate() !== dateFromJson.getDate()){
            //   return -1;
            // }
          }
        }
        return false;
      })
      .sort((a, b) => {
        // if (a.samples.length > 0) {
        //   a.samples.sort();
        //   a.samples.reverse();
        //   b.samples.sort();
        //   b.samples.reverse();
        //   var foundA = this.state.allsamples.find(
        //     (item) => item.id === a.samples[0]
        //   );
        //   var foundB = this.state.allsamples.find(
        //     (item) => item.id === b.samples[0]
        //   );
        //   if (foundA.addedAt > foundB.addedAt) {
        //     return -1;
        //   }
        //   if (foundA.addedAt < foundB.addedAt) {
        //     return 1;
        //   }
        // }
        // return 0;

        // Check if both objects have non-empty samples arrays
        if (a.samples.length > 0 && b.samples.length > 0) {
          // Find the corresponding objects in state.allsamples
          var foundA = allsamples.find((item) => item.id === a.samples[0]);
          var foundB = allsamples.find((item) => item.id === b.samples[0]);

          // Convert addedAt to Date objects if they are not already
          var dateA = new Date(foundA.addedAt);
          var dateB = new Date(foundB.addedAt);

          // Compare the dates for sorting
          return dateB - dateA; // This will sort in descending order
        }
        return 0; // Return 0 if either object does not have a samples array
      })
      .map((data) => {
        data.samples.sort((a, b) => {
          const foundA = allsamples.find((item) => item.id === a);
          const foundB = allsamples.find((item) => item.id === b);
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          if (foundA.addedAt > foundB.addedAt) return -1;
          if (foundA.addedAt < foundB.addedAt) return 1;
          return 0;
        });

        filteredData.push(data);

        const sample_map1 = data.samples.map((sample) => {
          //console.log(sample);
          //console.log(this.state.allsamples);
          var found = allsamples.find((item) => item.id === sample);
          var date = new Date(found.addedAt);
          var month = parseInt(date.getMonth()) + 1;

          return (
            <>
              <tr>
                <td>
                  <input
                    checked={checkboxSampleChecked["sample" + sample.id]}
                    type="checkbox"
                    id={"sample" + sample}
                    name={"sample" + sample}
                    value="newsletter"
                    style={{ display: "inline-block" }}
                    onChange={(e) => this.checkboxChange(e)}
                  />
                </td>
                <td>
                  <p
                    type="text"
                    id={"sampledate" + sample}
                    name={"sampledate" + sample}
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    {date.getFullYear() +
                      "/" +
                      (month.toString().length > 1
                        ? parseInt(month)
                        : "0" + parseInt(month)) +
                      "/" +
                      date.getDate()}
                  </p>
                </td>
                <td>
                  <p
                    type="text"
                    id={"qrcode" + sample}
                    name={"qrcode" + sample}
                    style={{ width: "100%" }}
                  >
                    {found.sampleCode}
                  </p>
                </td>
              </tr>
            </>
          );
        });

        return (
          <>
            {Object.entries(groupedSamplesByUserAndDate).map(
              ([userCode, dates]) => (
                <>
                  {Object.entries(dates).map(([date, samples]) => (
                    <div key={`${userCode}-${date}`}>
                      <button
                        className="applicationList"
                        onClick={() =>
                          this.showInfo(`uniquedata${userCode}-${date}`)
                        }
                        style={{ width: "100%" }}
                      >
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="d-inline col-lg-4 col-md-6 col-12">
                            <p
                              style={{ float: "left", display: "inline-block" }}
                            >
                              {date}
                            </p>
                          </div>
                          <div className="d-inline col-lg-4 col-md-6 col-12">
                            <p
                              style={{
                                float: "center",
                                display: "inline-block",
                              }}
                            >
                              &nbsp;Dalyvio kodas: {userCode}
                            </p>
                          </div>
                          <div className="d-inline col-lg-4 col-md-6 col-12">
                            <p
                              style={{
                                float: "right",
                                display: "inline-block",
                              }}
                            >
                              &nbsp;Mėgintuvėlių: {samples.length}
                            </p>
                          </div>
                        </div>
                      </button>
                      <div>
                        <li
                          key={`uniquedata${userCode}-${date}`}
                          id={`uniquedata${userCode}-${date}`}
                          style={{ display: "none", visibility: "hidden" }}
                        >
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Pasirinktas</th>
                                <th scope="col">Pridėjimo data</th>
                                <th scope="col">Mėgintuvėlio QR kodas</th>
                              </tr>
                            </thead>
                            <tbody>
                              {samples.map((sample) => (
                                <tr key={sample.id}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      id={`sample${sample.id}`}
                                      name={`sample${sample.id}`}
                                      style={{ display: "inline-block" }}
                                      onChange={(e) => this.checkboxChange(e)}
                                    />
                                  </td>
                                  <td>{sample.addedAt}</td>
                                  <td>{sample.sampleCode}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </li>
                      </div>
                    </div>
                  ))}
                </>
              )
            )}
          </>
        );
      });
    // filteredData.map((user) => {
    //   console.log(user.samples);
    //   return {
    //     name: user.name,
    //     samples: user.samples.filter((sample) => {
    //       return this.state.checkboxSampleChecked['sample' + sample.id] === true;
    //     })
    //   };
    // })
    filteredData = filteredData.map((user) => {
      // window.alert("atnaujintas");
      const updatedUser = {
        ...user,
        samples: user.samples.filter(
          (sample) => checkboxSampleChecked[`sample${sample}`] === true
        ),
      };
      return updatedUser;
    });

    console.log("after");
    console.log(filteredData);

    //sessionStorage.setItem("usersForPdf", JSON.stringify(filteredData));
    // this.setState({
    //   selectedPart: selectedParticipants
    // });

    // sessionStorage.setItem("SelectedParticipants", JSON.stringify(selectedParticipants));

    return (
      <>
        {loading ? (
          <>
            <div className="d-flex justify-content-center" style={{}}>
              <div
                className="spinner-grow d-flex justify-content-center"
                style={{
                  width: "20rem",
                  height: "20rem",
                  marginTop: "80px",
                  marginBottom: "80px",
                  color: "#78003f",
                }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {values.map((v, idx) => ( */}

            {/* ))} */}
            <Modal
              show={show}
              fullscreen={"xl-down"}
              size="xl"
              onHide={() => this.setState({ show: true })}
              style={{ width: "100%" }}
            >
              <Modal.Header>
                {/* closeButton={true} */}
                <Modal.Title>Mėginių perdavimo forma</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <PDFGenerator/> */}
                {/* <table id="pdfcontent">
            <thead>

            </thead>
            <tbody>
              <tr>
                
                <th scope="row">Priėmimo perdavimo akto nr.</th>
                <td>123A5C789</td>
              </tr>
              <tr>
              
                <th scope="row">Žmogaus biologinių ėminių <b>paėmimo</b> data, laikas Žmogaus biologinių ėminių <b>paėmimo</b> data, laikas Žmogaus biologinių ėminių <b>paėmimo</b> data, laikas</th>
                <td>123A5C789</td>
              </tr>
            </tbody>
          </table>
          <button className='btn' onClick={this.generatePDF}>Generate PDF</button> */}
                {/* < HTMLtoPDF/> */}
                {/*<FormFill*/}
                {/*  samples={allsamples}*/}
                {/*  part={filteredData} //{this.state.samples.sampleCode}*/}
                {/*  handleClose={this.handleClose}*/}
                {/*  history={history}*/}
                {/*></FormFill>*/}
              </Modal.Body>
              <Modal.Footer>
                {/* <btn className="btn" onClick={this.handleSave}> Išsaugoti</btn> */}
                <button className="btn" onClick={this.handleClose}>
                  {" "}
                  Atmesti
                </button>
              </Modal.Footer>
            </Modal>

            <div
              className="modal fade"
              id="modal2"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
              style={{ width: "100%" }}
            >
              <div
                className="modal-dialog modal-fullscreen-xxl-down"
                style={{ width: "100%" }}
              >
                <div className="modal-content" style={{ width: "100%" }}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel2">
                      Perdavimo forma
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div id="" className="modal-body">
                    <p>
                      text extextextextext extextext text extextextextext
                      extextexttext extextextextext extextexttext
                      extextextextext extextexttext extextextextext
                      extextexttext extextextextext extextext
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-bs-dismiss="modal"
                    >
                      Uždaryti
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <label
              style={{ display: "inline-block", margin: "25px 0 0px 25px" }}
            >
              <h2>Filtruoti pagal datą:</h2>
            </label>
            <button
              className="btn"
              style={{ float: "right" }}
              onClick={(e) => this.handleFormCreation(e)}
            >
              Suformuoti perdavimo formą
            </button>

            {/* <btn key={1} className="btn" onClick={() => this.handleShow()}>
        Full screen
        {typeof v === 'string' && `below ${v.split('-')[0]}`}
      </btn> */}
            <div>
              <div>
                <label
                  style={{ display: "inline-block", margin: "25px 0 0px 25px" }}
                >
                  <h2>Nuo:</h2>
                </label>
                <input
                  title="Pradžia"
                  type="date"
                  id="dateselection1"
                  name="dateselection1"
                  style={{
                    display: "inline-block",
                    margin: "0px 1% 25px 25px",
                  }}
                  onChange={(event) => this.setStartDate(event)}
                />
              </div>
              <div>
                <label
                  style={{ display: "inline-block", margin: "25px 0 0px 25px" }}
                >
                  <h2>Iki:</h2>
                </label>
                <input
                  title="Pabaiga"
                  type="date"
                  id="dateselection2"
                  name="dateselection2"
                  style={{
                    display: "inline-block",
                    margin: "0px 1% 25px 25px",
                  }}
                  onChange={(event) => this.setEndDate(event)}
                />
                <button
                  className="btn"
                  style={{
                    display: "inline-block",
                    margin: "25px 1% 25px 25px",
                  }}
                  onClick={(event) => this.filterByDate(event)}
                >
                  Filtruoti
                </button>
              </div>
            </div>

            <div
              className="col-centered"
              style={{ display: "block", margin: "0px 5% 25px 25px" }}
            >
              <input
                id="searchData"
                type="text"
                placeholder="Ieškoti..."
                onChange={this.filterItemsSearch}
              />
              <button
                id={"daybutton"}
                className="btn"
                style={{ display: "inline-block", margin: "25px 1% 25px 25px" }}
                onClick={(event) => this.showLastDay(event)}
              >
                Šios paros
              </button>
              <button
                id={"allbutton"}
                className="btn"
                style={{ display: "inline-block", margin: "25px 1% 25px 25px" }}
                onClick={(event) => this.showAllDates(event)}
              >
                Visas laikotarpis
              </button>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              {/* <h2 style={{marginTop: "50px",marginBottom: "25px",fontSize: "2rem"}}>Pranešimai</h2> */}
              <ul>{React.Children.toArray(stats)}</ul>
            </div>
          </>
        )}
        ;
      </>
    );
  }
}
export default DalyviuInfo;
