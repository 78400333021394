import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

import NewApplication from "./paraiskos-subpages/naujaparaiska.jsx";
import MyApplications from "./paraiskos-subpages/pateiktosparaiskos.jsx";
// import Contact from './paraiskos-subpages/susisiekti'
// import Pranesimai from './paraiskos-subpages/pranesimai'

class BendraInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    //this.changeButtonWord = this.changeButtonWord.bind(this);
  }

  render() {
    return (
      <>
        <div className="col-lg-12 col-12">
          <div className="comments-form">
            <div className="get-quote">
              <a
                href={`${this.props.match.path}/naujaparaiska`}
                className="btn"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                onClick={this.changeStateDalyvis}
              >
                Pildyti naują paraišką
              </a>
              <Route
                path={`${this.props.match.path}/naujaparaiska`}
                component={NewApplication}
              />
              {/* <a href={`${this.props.match.path}/manoparaiskos`} className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateAtstovas}>
          Pateiktos paraiškos
        </a> */}
              {/* <Route path={`${this.props.match.path}/manoparaiskos`} component={MyApplications} /> */}
              <MyApplications />
              {/* <a href={`${this.props.match.path}/susisiekti`} className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateAtstovas}>
          Susisiekti
        </a>
        <Route path={`${this.props.match.path}/susisiekti`} component={Contact} />
        
        <a href={`${this.props.match.path}/pranesimai`} className="btn" style={{display: "block", margin: "0 auto",width: "50%", marginTop: "20px", marginBottom: "20px"}} onClick={this.changeStateAtstovas}>
          Pranešimai
        </a>
        <Route path={`${this.props.match.path}/pranesimai`} component={Pranesimai} />
      */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BendraInfo;
